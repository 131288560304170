<template>
  <el-dialog
    :title="!dataForm.id ? '选择数据模型' : '修改数据模型'"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :append-to-body="true"
    :visible.sync="visible"
  >
  <!--
  左右两侧 左侧分类树  右侧列表 列表点击查看数据详情
  -->
    <el-row :gutter="10">
      <el-col :span="6">
        <el-input
          placeholder="输入关键字进行分类过滤"
          v-model="filterType">
        </el-input>

        <el-tree
          class="filter-tree"
          :data="typeData"
          :props="typeProps"
          default-expand-all
          @current-change="typeChange"
          :filter-node-method="filterNode"
          ref="tree">
        </el-tree>
      </el-col>
      <el-col :span="18">
         <el-form :inline="true" :model="dataForm" @keyup.enter.native="currentChangeHandle(1)" @submit.native.prevent>
          <table class="detailTable_search" style="width: 100%">
            <tr>
              <td class="titleTd width80px">模型名称</td>
              <td class="valueTd">
                <el-input v-model="dataForm.name" placeholder="模型名称" clearable></el-input>
              </td>
            </tr>
          </table>
        </el-form>
        <div class="wxts_msg_search" >
          <div class="f_t">
            <el-button-group  >
              <el-button @click="currentChangeHandle(1)" :disabled="dataListLoading">查询</el-button> 
            </el-button-group>
          </div>
        </div>
        <el-table
          :data="dataList"
          border
          v-loading="dataListLoading" 
          style="width: 100%;"
        > 
          <el-table-column
            prop="categoryId"
            header-align="center"
            align="center"
            label="分类"
            width="100"
          ><template >
            {{dataForm.categoryName}}
          </template>
          </el-table-column>
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="数据模型"
          >
          </el-table-column>
           
          <el-table-column
            header-align="center"
            align="center"
            width="100"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="update-button"
                size="small"
                @click="selectSubmit(scope.row)"
                >选择</el-button
              > 
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mod-footer"
          v-if="!dataListLoading"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalCount"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </el-col>
    </el-row>
    
    <div slot="footer" class="dialog-footer">
      <el-button @click="visible = false">返回</el-button> 
    </div>
  </el-dialog>
</template>

<script>
import { getAllList as getTypeAllList } from '@/api/hbi/datacategory.js'
import { getList as getDataModelList } from '@/api/hbi/datamodel.js'
import { treeDataTranslate } from "@/utils/index";
export default {
  data() {
     
    return {
      
      visible: false,
      loading: false,
       
      filterType: '',
      typeProps: {
        children: 'children',
        label: 'name'
      },
      typeData: [],

      dataForm: {
        name: '',
        categoryId: '',
        categoryName: '' ,
        id: ''
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
    };
  },
  watch: {
    filterType(val) {
      this.$refs.tree.filter(val);
    }
  },
  activated () {
     
  },
  methods: {
    initTypes() {
      getTypeAllList().then(({data})=> {
        if(data && data.code == 0) {

          this.typeData = treeDataTranslate(data.data, "id" , 'parentId');
        }

      })
    },
    typeChange(select) {
 
      this.dataForm.categoryId = select.id 
      this.dataForm.categoryName = select.name

      this.currentChangeHandle(1)
    },
    init(id) {
      this.dataForm.id = id;
      this.visible = true;
      this.dataForm.name = ''
      this.dataForm.categoryId = ''
      this.dataForm.categoryName = ''

      this.initTypes()
    },
    filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
    },
    // 表单提交
    selectSubmit(row) {
       this.$emit('select', row)
       this.visible = false
    },
    // 获取数据列表
    getDataList() {
      if(!this.dataForm.categoryId) {
        return
      }
      this.dataListLoading = true;
      getDataModelList({
          page: this.pageIndex,
          limit: this.pageSize,
          categoryId: this.dataForm.categoryId,
          name: this.dataForm.name
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalCount = data.data.totalCount;
          if(data.data.page != this.pageIndex) {
            this.pageIndex = data.data.page
          }
        } else {
          this.dataList = [];
          this.totalCount = 0;
          this.pageIndex = 1
        }

      }).finally(()=>{
        this.dataListLoading = false
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    }, 
     
  }
};
</script>
