<!--
	数据源中数据过滤配置
-->
<template>
    <div class="hbi-data-model">
      
        <div :key="datasetKey">
            <el-row v-for="(ds,dsIndex) in value" :key="dsIndex ">
                <el-col :span="9" >
                    <span class="pointer select" @click="addOrUpdateData(ds , dsIndex)">{{ds.column}}</span>
                </el-col>
                <el-col :span="5">
                    <span>{{ds.filterType}}</span>
                </el-col>
                <el-col :span="3">
                    <el-button type="text" icon=" el-icon-close" @click="removeData(dsIndex)"></el-button>
                </el-col>
            </el-row>
            <el-button type="text" icon="el-icon-plus" @click="addOrUpdateData()"></el-button>
        </div>

        <AddOrUpdate :dataColumns="dataColumns" v-if="addOrUpdateVisible" ref="addOrUpdate" @add="addData" @update="updateData"/>
    </div>
</template>
<script>
import AddOrUpdate from './add-or-update'
export default {
    name: 'hbi-datamodel-filterconfig',
    components: {
        AddOrUpdate 
    },
    data() {
        return {
            addOrUpdateVisible: false,
            datasetKey: 1 
        }
    }, 
    props: {
        // 数据模型ID
        value: {
            type: Array,
            required: true
        }, 
         // 数据模型字段列表
        dataColumns: {
            type: Array
        }
    }, 
    created() {
        //this.initDataColumns()
    },
    methods: { 
        removeData(deleteIndex){
       

           // 删除
            this.$emit(
                "input",
                this.value.filter((val, index) => index !== deleteIndex)
            );
            this.datasetKey++
        },
        addData(data) {
             
     
            let addData = [
                ...this.value,
                data
            ];
            this.$emit("input", addData);
           
            this.datasetKey++
             
             
        },
        updateData(data) {
            const index = data.index  

            //let dataset = this.selectItem.options.dataSet.splice(index , data)
 
             this.value[index] = data 
             this.$emit("input", this.value);
          
             this.datasetKey++
        },
        addOrUpdateData(row , index) {
            this.addOrUpdateVisible = true 
            if(row) {
               row.index = index 
            }
           
            this.$nextTick(()=> {
              this.$refs.addOrUpdate.init(row)
            })
        }
    }
}
</script>
<style lang="scss">
.hbi-data-model {
    .select {
        color: blue;
    }
}
</style>