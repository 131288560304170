<template>
  <!-- <monaco-editor v-model="code"
                 :read-only="disabled"
                 :language="language"
                 :options="options"
                 :height="height"></monaco-editor> -->

  <codemirror class="hd-code-editor" v-model="code" :options="cmOptions"></codemirror>

</template>

<script>
//import MonacoEditor from './monaco-editor.js'

import { codemirror } from 'vue-codemirror'

import 'codemirror/theme/mbo.css'
// require styles
import 'codemirror/lib/codemirror.css'

export default {
  name: 'hd-code-editor',
  components: {
    //MonacoEditor , 
    codemirror
  },
  data () {
    return {
      code: '',
      options: {
        fontSize: 12
      },
      cmOptions: {
        // codemirror options
        // tabSize: 4,
        // mode: 'text/javascript',
        // theme: 'base16-dark',
        // lineNumbers: true,
        // line: true,


        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: this.language,
        // lineWrapping: true,
        theme: 'mbo'
        // more codemirror options, 更多 codemirror 的高级配置...
      }
    }
  },
  props: {
    language: {
      type: String,
      default: 'javascript'
    },
    disabled: Boolean,
    height: {
      type: [String, Number],
      default: 400
    },
    value: [String, Object, Array]
  },
  watch: {
    code (val) {
      this.$emit('input', val);
    },
    value: {
      handler (val) {
        if (typeof (val) == 'object') {
          this.code = JSON.stringify(val, null, 4);
        } else {
          this.code = val || '';
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>
<style lang="scss">
.hd-code-editor {
  .CodeMirror-lines {
    line-height: 20px;
  }
}

</style>
