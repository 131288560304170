import http from '@/utils/httpRequest'

/**
 * 获取分页列表，可选参数：
 * page: 当前页；
 * limit: 每页数量；
 * name: 数据源名称-关键字；
 * dsType: 数据库类型；
 * dsDatabase: ；数据库名称-关键字；
 * @param {*} params 查询参数对象
 * @returns 分页对象
 */
export function getList (params) {
  return http({
    url: http.adornUrl('/hbi/dynamicdatasource/list'),
    method: 'get',
    params: params
  })
}
/**
 * 全部列表获取
 * @returns 全部数据源信息列表
 */
export function getAllDsList () {
  return http({
    url: http.adornUrl('/hbi/dynamicdatasource/alllist'),
    method: 'get',
    params: {}
  })
}
/**
 * 根据id数组删除数据
 * @param {Array} ids id数组
 * @returns 删除结果
 */
export function deleteData (ids = []) {
  return http({
    url: http.adornUrl("/hbi/dynamicdatasource/delete"),
    method: "post",
    data: http.adornData(ids, false)
  })
}

/**
 * 根据ID获取数据源信息
 * @param {number|string} id id主键
 * @returns 数据源信息
 */
export function getById (id) {
  return http({
    url: http.adornUrl(`/hbi/dynamicdatasource/info/${id}`),
    method: "get"
  })
}
/**
 * 根据id是否为空判断更新（id不为空）或新增（id为空）
 * @param {*} data 更新或新增的对象
 * @returns 更新或新增结果
 */
export function saveOrUpdate (data) {
  return http({
    url: http.adornUrl(`/hbi/dynamicdatasource/${!data.id ? "save" : "update"}`),
    method: "post",
    data: http.adornData(data)
  })
}

/**
 * 根据数据源id测试数据源连接是否成功
 * @param {*} id 数据源主键
 * @returns 链接测试结果
 */
export function testContainerById (id) {
  return http({
    url: http.adornUrl(`/hbi/dynamicdatasource/testcontainerbyid/${id}`),
    method: "get"
  })
}
/**
 * 根据数据源配置信息测试数据源连接是否成功
 * @param {*} params 数据源配置信息（包含：数据源id - id(密码为空时必须),数据源类型 - dsType, 数据源ip - dsIp, 数据源端口 - dsPort, 数据库名称 - dsDatabase, 用户名 - dsUser, 密码 - dsPass ）
 * @returns 链接测试结果
 */
export function testContainer (params) {
  return http({
    url: http.adornUrl('/hbi/dynamicdatasource/testcontainer'),
    method: 'get',
    params: params
  })
}