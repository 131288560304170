<!--
表单控件属性设置组件,因为配置数据是引用关系，所以可以直接修改
-->
<template> 
   
    <div >   
       <el-form-item  label="边距"  >
        <el-row v-if="selectItem.style && selectItem.style.margin && selectItem.style.margin.length == 4">
            <el-col :span="5">
              <el-input title="上边距" v-model="selectItem.style.margin[0]" />
            </el-col>
            <el-col :span="5">
              <el-input title="右边距" v-model="selectItem.style.margin[1]" />
            </el-col>
            <el-col :span="5">
              <el-input title="下边距" v-model="selectItem.style.margin[2]" />
            </el-col>
            <el-col :span="5">
              <el-input title="左边距" v-model="selectItem.style.margin[3]" />
            </el-col>
        </el-row>
      </el-form-item>
        <!-- 公共部分 标签 字段key 数据key start -->
        <el-form-item  label="标签" v-if="!hideModel">
          <el-input v-model="selectItem.label" placeholder="请输入" />
        </el-form-item>
       
        <el-form-item  label="数据字段" v-if="!hideModel && !noModel.includes(selectItem.type)" >
          <el-input v-model="selectItem.model" placeholder="请输入" :disabled="(selectItem.item != undefined && selectItem.item.id != undefined) "/>
        </el-form-item>
        <el-form-item label="标签宽度" v-if="!hideModel && !noModel.includes(selectItem.type)">
          <el-input-number v-model="selectItem.options.labelWidth" title="-1表示跟随整体表达配置的宽度"></el-input-number>
        </el-form-item>
         <el-divider ></el-divider>
        <!-- 公共部分 标签 字段key 数据key end -->
    

         <!-- 按钮 start-->
        <template v-if="selectItem.type == 'button'"> 
          <!-- 按钮类型 -->
          <el-form-item label="类型">
            <el-radio-group v-model="selectItem.options.type">
              <el-radio label="primary">Primary</el-radio>
              <el-radio label="default">Default</el-radio>
              <el-radio label="dashed">Dashed</el-radio>
              <el-radio label="danger">Danger</el-radio>
            </el-radio-group>
          </el-form-item>
            <el-divider ></el-divider>
          <el-form-item  label="按钮对齐方式">
            <el-radio-group v-model="selectItem.options.textAlign">
              <el-radio-button label="left">左</el-radio-button>
              <el-radio-button label="center">居中</el-radio-button>
              <el-radio-button label="right">右</el-radio-button>
            </el-radio-group>
          </el-form-item> 
          <el-divider ></el-divider>
          <el-form-item  label="按钮操作"> 
            <el-radio-group v-model="selectItem.options.click">
              <el-radio label="submit">提交</el-radio>
              <el-radio label="reset">重置</el-radio>
              <el-radio label="other">其他</el-radio> 
            </el-radio-group> 
          </el-form-item>
           <el-input type="textarea" v-if="selectItem.options.click == 'other'" v-model="selectItem.options.dynamicFun" placeholder="动态JS,表单数据绑定值符号$" ></el-input>
          <el-divider ></el-divider>
          <el-form-item   label="操作属性" >
            <el-checkbox v-model="selectItem.options.hidden"  label="隐藏" />
            <el-checkbox v-model="selectItem.options.disabled"  label="禁用" />   
          </el-form-item> 
        </template> 
        <!-- 按钮  end -->

        <!-- 标签 start-->
        <template v-if="selectItem.type == 'text'"> 
          <!-- 按钮类型 -->
          <el-form-item  label="文字对齐方式">
            <el-radio-group v-model="selectItem.options.textAlign">
              <el-radio-button label="left">左</el-radio-button>
              <el-radio-button label="center">居中</el-radio-button>
              <el-radio-button label="right">右</el-radio-button>
            </el-radio-group>
          </el-form-item> 
          <el-divider ></el-divider>
          <el-form-item   label="操作属性" >
            <el-checkbox v-model="selectItem.options.hidden"  label="隐藏" /> 
            <el-checkbox v-model="selectItem.options.showRequiredMark" label="显示必选标记" />
          </el-form-item> 
          <el-divider ></el-divider>
          <el-form-item   label="字体大小" >
            <el-input-number   v-model="selectItem.options.fontSize" controls-position="right" :min="5" :max="30"  />
          </el-form-item> 
          <el-form-item   label="字体颜色" >
            <avue-input-color v-model="selectItem.options.fontColor" placeholder="请选择颜色"  ></avue-input-color>
          </el-form-item>
          <el-form-item   label="加粗" >
            <avue-switch v-model="selectItem.options.fontBold" ></avue-switch>
          </el-form-item>
          <el-form-item   label="文本框背景" >
            <avue-input-color v-model="selectItem.options.background" placeholder="请选择颜色"  ></avue-input-color>
          </el-form-item>
          <el-form-item   label="动态必选" >
           <el-input type="textarea" v-model="selectItem.options.showRequiredMarkScript" :rows="4" placeholder="请输入表达式或者动态函数,数据实体以$标识"/>
          </el-form-item> 
        </template> 
        <!-- 标签  end -->

         <!-- 标签 start-->
        <template v-if="selectItem.type == 'alert'"> 
          <!-- 按钮类型 -->
          <el-form-item  label="内容">
             <el-input type="textarea" v-model="selectItem.options.title" :rows="4" placeholder="提示内容"/>
          </el-form-item> 
          <el-form-item  label="辅助文字">
             <el-input type="textarea" v-model="selectItem.options.description" :rows="4" placeholder="辅助文字"/>
          </el-form-item> 
          <el-form-item  label="类型">
            <el-radio-group v-model="selectItem.options.type">
              <el-radio-button label="success">success</el-radio-button>
              <el-radio-button label="warning">warning</el-radio-button> 
              <el-radio-button label="info">info</el-radio-button>
              <el-radio-button label="error">error</el-radio-button> 
            </el-radio-group>
          </el-form-item> 
          <el-form-item  label="主题">
            <el-radio-group v-model="selectItem.options.effect">
              <el-radio-button label="light">light</el-radio-button>
              <el-radio-button label="dark">dark</el-radio-button> 
            </el-radio-group>
          </el-form-item> 
          <el-divider ></el-divider>
          <el-form-item   label="操作属性" >
            <el-checkbox v-model="selectItem.options.closable"  label="可关闭" /> 
            <el-checkbox v-model="selectItem.options.center" label="居中" />
             <el-checkbox v-model="selectItem.options.showIcon" label="显示图标" />
          </el-form-item> 
           <el-form-item v-if="selectItem.options.closable" label="关闭按钮文本">
            <el-input  v-model="selectItem.options.closeText"  placeholder="不需要则不填"/>
          </el-form-item> 
          <el-divider ></el-divider>
          <el-form-item   label="动态必选" >
           <el-input type="textarea" v-model="selectItem.options.showRequiredMarkScript" :rows="4" placeholder="请输入表达式或者动态函数,数据实体以$标识"/>
          </el-form-item> 
        </template> 
        <!-- 标签  end -->

        <!-- html start-->
        <template v-if="selectItem.type == 'html'">  
          <el-form-item label="默认值">
            <el-input type="textarea" v-model="selectItem.options.defaultValue" :rows="4" />
          </el-form-item>
          <el-divider ></el-divider>
          <el-form-item   label="操作属性" >
            <el-checkbox v-model="selectItem.options.hidden"  label="隐藏" />  
          </el-form-item> 
        </template> 
        <!-- html  end -->


        <!-- ################### 布局  start ################################  -->
        <!-- 分割线 start-->
        <template v-if="selectItem.type == 'divider'">  
          

          <el-form-item  label="方向" >
            <el-radio-group  v-model="selectItem.options.direction">
              <el-radio-button label="horizontal">横向</el-radio-button>
              <el-radio-button label="vertical">竖向</el-radio-button> 
            </el-radio-group>
          </el-form-item>  
          <el-divider ></el-divider>
          <el-form-item  label="标签位置" v-if="selectItem.options.direction && selectItem.options.direction == 'horizontal'">
            <el-radio-group  v-model="selectItem.options.orientation">
              <el-radio-button label="left">左</el-radio-button>
              <el-radio-button label="center">居中</el-radio-button>
              <el-radio-button label="right">右</el-radio-button>
            </el-radio-group>
          </el-form-item> 
        </template> 
        <!-- 分割线  end -->
   
    </div>  
</template>
<script>  
export default {
  name: "HbiDataBaseProperties",
  data() {
    return {
      options: {},
      noModel : [ "button",
            "divider",
            "card", 
            "grid",
            "table",
            "alert",
            "text",
            "html"]
    };
  },
  watch: {
    selectItem(val) {  

      this.options = val.options || {}
 
    }
  },
    
  methods: {
    init() {
      this.options = this.selectItem.options || {}
  
      if( !Object.prototype.hasOwnProperty.call(this.selectItem, 'style')) {
        const margin = {margin: ['0', '0', '0', '0']}
        this.$set(this.selectItem , 'style' ,  margin)
      }
    },
  },
  props: {
    selectItem: {
      type: Object,
      required: true
    },

    hideModel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  } 
};
</script>
