<template>
    <!-- 数据源配置-静态数据 -->
    <el-dialog title="静态数据配置" :close-on-click-modal="false" :modal-append-to-body="false" :lock-scroll="false" :visible.sync="visible" width="70%">
        <!--   <div class="mod-config"> -->
        <el-row :gutter="15">
        	<el-col :span="12"> 
                <el-form ref="staticForm" :model="dataForm" :rules="dataRules">
                    <el-form-item label="数据类型" prop="dataStaticType">
                        <el-radio-group v-model="dataForm.dataStaticType">
                            <el-radio-button :label="1">静态数据</el-radio-button>
                            <el-radio-button :label="2">函数数据</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="静态数据" prop="dataStatic" v-if="dataForm.dataStaticType == 1">
                        <el-input type="textarea" :rows="10" v-model="dataForm.dataStatic" placeholder="静态数据(json)"></el-input>
                    </el-form-item>
                    <el-form-item label="函数数据" prop="dataStaticScript" v-if="dataForm.dataStaticType == 2">
                        <el-input type="textarea" :rows="10" v-model="dataForm.dataStaticScript" placeholder="静态数据(json)"></el-input>
                    </el-form-item>

                </el-form>

        		
        	</el-col>
        	<el-col :span="12">
                <code-editor :disabled="true" v-model="dataTips" />
        		<!-- <div style="height: 300px;overflow: auto;"> {{dataTips}} </div> -->
        	</el-col>
        </el-row>
       
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">返回</el-button>
            <el-button type="primary" @click="dataFormSubmit()" >确定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { tips } from '../common/datasource.js'
//import MonacoEditor from '@/views/modules/avueData/components/editor'
import cloneDeep from 'lodash/cloneDeep'
export default {
    components: {
        //MonacoEditor
    },
    data() {
        return {
            visible: false,
            dataForm: {
            	dataStaticType: '',
                dataStatic: '' ,
                dataStaticScript: ''
            },
            dataRules: {
                dataStaticType: [{ required: true, message: "数据类型不能为空", trigger: "blur" }],
                dataStatic: [{ required: true, message: "静态数据不能为空", trigger: "blur" }],
                dataStaticScript: [{ required: true, message: "函数数据不能为空", trigger: "blur" }],
            },
            dataTips: tips
        }
    }, 
    methods: {
    	init({dataStaticType , dataStatic , dataStaticScript}){
    		this.dataForm.dataStaticType = dataStaticType || '1'
            this.dataForm.dataStatic = dataStatic
            this.dataForm.dataStaticScript = dataStaticScript

            console.log('datafoirm' , this.dataForm)
    		this.visible = true
    	},
    	dataFormSubmit() {
    		// if(!this.dataForm.value) {
    		// 	this.$message.error('静态数据不能为空')
    		// 	return
    		// } 
            this.$refs.staticForm.validate(valid  => {
                 
                if (valid) {
                   // 尝试转json 判断格式
                    if(this.dataForm.dataStaticType == 1) {
                        try{
                            JSON.parse(this.dataForm.dataStatic)
                        }catch {
                            this.$message.error('静态数据JSON格式异常') 
                            return
                        }
                    } else {
                        try{
                            eval(this.dataForm.dataStaticScript)
                        }catch {
                            this.$message.error('函数数据格式异常')
                            return
                        }
                    }
                    
                     
                    const data = cloneDeep(this.dataForm)
                    this.$emit("change", data);
 
                    this.visible = false

                }  
            }) 

    		
    	}
    }
}
</script>