<template>
    <div>
        <el-input  readonly :clearable="true"  :placeholder="placeholder" v-model="name" @clear="onClear()"  @click.native="onInputClick()">
            <template #append>
                <!-- <el-button style="margin: 0 auto" icon="el-icon-plus" size="mini" type="primary" @click="onInputClick()"/> -->
                <el-button style="margin: 0 auto" size="mini" type="primary" @click="onInputClick()">请选择部门</el-button>
            </template>
        </el-input>
        <dept-select :dialogVisible="dialogVisible" :options="options" @dialog-close="dialogClose" @dialog-save="dialogSave"></dept-select>
    </div>    
</template>
<script>
import {getTree as unitDeptTree, treeByPid} from '@/api/sys/sysorg.js';
    import DeptSelect from "./dept-select";
    import log from "@/views/modules/sys/log";
    export default { 
        name: 'el-dept-select',
        //初始化数据
        data() {
            return { 
                dialogVisible: false,
                options: {
                    checkedIds: [], 
                    treeData: [],
                    title: '请选择部门' 
                }, 
                name: ''
            };
        },
        props: {
            placeholder : String,  
            value : String,
            singleCheck : {
                type : Boolean,
                require : false,
                default : false
            },
          orgId: String
        }, 
        components: {
            DeptSelect
        },
        watch: {
          async orgId(val){
              await this.loadTreeNode(val);
          },
            async value(val){
                if(!this.options.treeData || this.options.treeData.length === 0) {
                    await this.loadTreeNode(this.orgId);
                  console.log(this.options.treeData,"888")
                }

                // 将ID转意义为Name
                if(val) {
                    this.options.checkedIds = val.split(",");
                    this.convertIdToName(this.options.checkedIds);
                }
                this.value = val;
            }
        },
        //方法
        methods: {
            convertIdToName(val) { 
                // if(!this.name) { 
                let checkedNodes = [];
                let _this = this;
                val.forEach(id => {
                    _this.options.treeData.forEach(treeNode => {
                        let checked = _this.searchTreeNode(id, treeNode);
                        if(checked) {
                            checkedNodes.push(checked);
                        }
                    }) 
                })
                if(checkedNodes.length > 0) {
                    _this.name = checkedNodes.map(item => {return item.name}).join(",");
                } else {
                    _this.name = '';
                }
                // }
            },
            searchTreeNode(id, treeNode) {
                let checked = null
                if(treeNode.id === id) { 
                    checked = treeNode;
                }

                if(treeNode.children) {
                    treeNode.children.forEach(childNode => {
                        let _checked = this.searchTreeNode(id, childNode);
                        if(_checked) {
                            checked = _checked;
                        } 
                    })
                }
                return checked;
            },
            dialogClose() {
                this.dialogVisible = false;
            },
            dialogSave(selectedTreeNodes) { 
                let _this = this;
                _this.options.checkedIds = [];
                let checkedNames = [];
                selectedTreeNodes.forEach(element => {
                    _this.options.checkedIds.push(element.id);
                    checkedNames.push(element.name);
                });
                _this.name = checkedNames.join(',');
                let value = _this.options.checkedIds.join(",");
                _this.$emit('input', value); 
                _this.$emit('change', value);
                this.dialogClose();
            },
            // 监听clear事件，如果是清空行为，则不弹框
            onClear() {
                this.popDialogAction = false;
                this.clearInput();
            },
            // 点击输入框
            onInputClick() {
                if(this.popDialogAction) {
                    this.dialogVisible = true;
                } else {
                    this.popDialogAction = true;
                }
            },  
            clearInput() {
                let _this = this;
                _this.$emit('update:value', ''); 
                _this.$emit('input', '');  // 往父组件的v-model中传值
                _this.name = '';
                if(_this.onChange) {
                    _this.onChange('', '', '', '');
                }
            },
          async loadTreeNode(val) {
                let _this = this;
                await treeByPid(val).then(({data}) => {
                    const res = data; 
                    if (res && res.code === 0) { 
                        let da = res.data;
                        _this.options.treeData = da;
                    } else {
                        console.error(`[${res.code}]${res.msg}`);
                    }
                }).catch((err) => {
                    console.log(err);
                    this.$message.error(err)
                }).finally(_ => {
                }) 
            }
        },
        //页面渲染之前调用方法 函数
        created() {
          if (this.orgId == undefined){
            unitDeptTree().then(({data}) => {
              const res = data;
              if (res && res.code === 0) {
                let da = res.data;
                this.options.treeData = da;
              } else {
                console.error(`[${res.code}]${res.msg}`);
              }
            }).catch((err) => {
              console.log(err);
              this.$message.error(err)
            }).finally(_ => {
            })
          }
        },
        //页面渲染完成调用方法 函数
        mounted() {
            var _this = this; 
            this.options = { ...this.options, singleCheck: this.singleCheck};
            // this.loadTreeNode();
        }
    };
</script>
<style scoped>
    .el-tree-node__children {
        overflow: visible !important;
    }
</style>        