/* 数据字典的资源 */
export default {
    /* 模块名字 */
    name: 'config',

    /* 英文资源 */
    en_US: {
        paramName: 'Parameter Name',
        paramValue: 'Parameter Value',
        paramValueArr: 'Parameter Array',
        paramValueTab: 'Parameter Table',
        remark: 'Remark',
        confirmDelete: 'Are you sure you want to delete it?',
        addConfigParameter: 'Add Config Parameter',
        updateConfigParameter: 'Update Config Parameter',
        type: 'Type',
        label_string: 'String',
        label_array: 'Array',
        label_table: 'Table',
        addValue: 'Add Value',
        inputNewValueArr: 'input new value for array',
        delColumn: 'delete column',
        addColumn: 'add column',
        addRow: 'add row',
        addNewFieldName: 'add new field name',
        paramArrNotEmpty: 'The parameter array cannot be empty',
        paramTabNotEmpty: 'The parameter table cannot be empty',


    },

    /* 中文资源 */
    zh_CN: {
        paramName: '参数名',
        paramValue: '参数值',
        paramValueArr: '参数数组',
        paramValueTab: '参数表',
        remark: '备注',
        confirmDelete: '确定进行删除操作?',
        addConfigParameter: '新增配置参数',
        updateConfigParameter: '修改配置参数',
        type: '类型',
        label_string: '字符串',
        label_array: '数组',
        label_table: '表格',
        addValue: '添加值',
        inputNewValueArr: '请输入数组新增值',
        delColumn: '删除列',
        addColumn: '添加列',
        addRow: '添加行',
        addNewFieldName: '请输入新增字段名',
        paramArrNotEmpty: '参数数组不能为空',
        paramTabNotEmpty: '参数表格不能为空',


    }

}




