export default {
    name:'sysRes',
    zh_CN:{
        add:'新增',
        edit:'修改',
        operate:'操作',
        deleteBash:'批量删除',
        modelName:'模型名称',
        modelKey:'模型Key',
        modelDescribe:'模型描述',
        processSort:'流程分类',
        processFormBind:'流程表单绑定',
        formSelect:'表单选择',
        businessType:'业务类型',
        processModel:'流程模型',
        viewComponents:'显示组件',
        frontEchoComponents:'前端回显组件',
        processForm:'流程表单',
        processInstanceId:'流程实例ID',
        processName:'流程名称',
        currentNode:'当前节点',
        status:'状态',
        initiator:'流程发起人',
        startTime:'启动时间',
        hangUp:'挂起',
        recover:'恢复',
        stop:'终止',
        workItemManage:'工作项管理',
        viewProcessVariables:'查看流程变量',
        parameterName:'参数名称',
        parameterType:'参数类型',
        parameterValue:'参数值',
        workItemId:'工作项Id',
        workItemName:'工作项名称',
        participantId:'参与者Id',
        participantName:'参与者名称',
        participantDepartment:'参与者部门',
        dataModelName:'模型名称',
        dataModelCode:'模型编码',
        remark:'备注',
        dataModelType:'模型类型',
        tableNameOfEntity:'实体表名',
        modelProperties:'模型属性',
        entityTable:'实体表',
        view:'视图',
        formName:'表单名称',
        formCode:'表单编号',
        formId:'表单ID',
        templateName:'模板名称',
        createTime:'创建时间',
        processFormOrNot:'是否流程表单',
        version:'版本',
        form:'表单',
        tip:'提示',
        tips1:'请选择',
        tips2:'请输入',
        inFlow:'流转中',
        organization:'组织机构',
        tips3:'输入关键字进行过滤',
        showDepartment:'显示部门',
        notShow:'不显示部门',
        tips4:'确定要',
        person:'人员 [ ',
        org:'组织机构 [ ',
        duty:'职务 [ ',
        post:'岗位 [ ',
        excelExport:'EXCEL导出',
        tips5:'请选择导出字段（不选默认为导出所有字段）',
        selectField:'选择字段',
        selectAll:'选择全部',
        start:'开始导出',
        importFile:'导入文件',
        addFiles:'添加文件',
        downloadTemplate:'下载模板',
        tips6:'请选择*.xls,*.xlsx格式文件',
        upload:'上传',
        unit:'单位/部门',
        post1:'岗位',
        role:'角色',
        configScreen:'配置大屏',
        selectProcess:'流程选择',
        useForm:'使用表单',
        select:'选择',
        filesSort:'文件分类',
        addAttachment:'添加附件',
        tips7:'点击选择上级菜单',
        uploadFiles:'上传文件',
        selectFiles:'选择文件',
        uploadAttachment:'上传附件',
        uploadingFiles:'文件上传中...',
        tips8:'* 文件最大不能超过10Mb,文件格式支持(bmp,jpg,jpeg,png,xls,xlsx,doc,docx,pdf,mp4,wmv,zip,txt,csv)',
        NO:'序号',
        filesName:'文件名称',
        previewDownload:'预览下载',
    },
    en_US:{
        previewDownload: 'Preview Download',
        filesName:'Files Name',
        NO:'NO.',
        filesSort:'Files Sort',
        addAttachment:'Add Attachment',
        tips7:'Click on the select parent menu',
        uploadFiles:'Upload Files',
        selectFiles:'Select Files',
        uploadAttachment:'Upload Attachment',
        uploadingFiles:'Uploading Files',
        tips8:'* The maximum size of the file is 10Mb. The file format is supported(bmp,jpg,jpeg,png,xls,xlsx,doc,docx,pdf,mp4,wmv,zip,txt,csv)',
        select:'Select',
        selectProcess:'Process Select',
        useForm:'Use Form',
        configScreen:'Config Screen',
        unit:'Unit/Department',
        post1:'Post',
        role:'Role',
        add:'Add',
        edit:'Edit',
        operate:'Operate',
        deleteBash:'Delete Bash',
        modelName:'Model Name',
        modelKey:'Model Key',
        modelDescribe:'Model Describe',
        processSort:'Process Sort',
        processFormBind:'Process Form Bind',
        formSelect:'Form Select',
        businessType:'Business Type',
        processModel:'Process Model',
        viewComponents:'View Components',
        frontEchoComponents:'Front Echo Components',
        processForm:'Process Form',
        processInstanceId:'Process Instance ID',
        processName:'Process Name',
        currentNode:'Current Node',
        status:'Status',
        initiator:'Initiator',
        startTime:'Start Time',
        hangUp:'Hang Up',
        recover:'Recover',
        stop:'Stop',
        workItemManage:'Work Item Manage',
        viewProcessVariables:'View Process Variables',
        parameterName:'Parameter Name',
        parameterType:'Parameter Type',
        parameterValue:'Parameter Value',
        workItemId:'Work Item ID',
        workItemName:'Work Item Name',
        participantId:'Participant ID',
        participantName:'Participant Name',
        participantDepartment:'Participant Department',
        dataModelName:'Data Model Name',
        dataModelCode:'Data Model Code',
        remark:'Remark',
        dataModelType:'Data Model Type',
        tableNameOfEntity:'Table Name of Entity',
        modelProperties:'Property',
        entityTable: 'Entity Table',
        view: 'View',
        formName:'Form Name',
        formCode:'Form Code',
        formId:'Form ID',
        templateName:'Template Name',
        createTime:'Create Time',
        processFormOrNot:'Process Form Or Not',
        version:'Version',
        form:'Form',
        tip:'Tip',
        tips1:'Please select',
        tips2:'Please input ',
        inFlow:'In Flow',
        organization:'Organization',
        tips3:'Please input keywords to filter',
        showDepartment:'Show Department',
        notShow:'Not Show',
        tips4:'Are you sure to ',
        person:' person [ ',
        org:' organization [ ',
        duty:' duty [ ',
        post:' post [ ',
        excelExport:'EXCEL Export',
        tips5:'Please select export filed ( The default is export all filed ).',
        selectField:'Select Field',
        selectAll:'Select All',
        start:'Start',
        importFile:'Import Files',
        addFiles:'Add Files',
        downloadTemplate:'Download Template',
        tips6:'Please select *.xls,*.xlsx format files.',
        upload:'Upload',
    }
}