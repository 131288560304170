// 对外输出 包含组件的对外json定义、属性配置页面、展示页面 三部分
import cloneDeep from 'lodash/cloneDeep'
 
import BaseIndex from './index.vue'
import BaseProperties from './properties.vue'
import { legendConfig } from '../../../config/common/legend.js'
import { gridConfig } from '../../../config/common/grid.js' 
import { styleConfig } from '../../../config/common/style.js'

import { getDataSourceConfig } from '../../../config/common/datasource.js' 

// 默认的静态数据
const staticDefaultData = `
{
"xData": ["1年级","2年级","3年级"],
"yData":[10,11,8]
}
`

const datasourceConfig = getDataSourceConfig({dataSourceDefault : '1', dataStatic : staticDefaultData})

const barConfig = {
    type: "barChart", // 表单类型
    label: "柱状图", // 标题文字   
    span: 24,
   
    formOptions: {
        config: {
            size: 'mini',
            labelWidth: 70,
            emptyBtn: false,
            submitBtn: false
        },
        group: [
            {...styleConfig }, // 样式
            {...datasourceConfig} , // datasource
            {
                label: '数据模型',
                prop: 'data',
                collapse: false,
                show: '$.dataSource.type == 2',
                column: [
                	{
                        label: '数据来源',
                        prop: 'dataModelId',
                        tip: '点击选择数据来源',
                        span: 24,
                    },
                    {
                        label: '数据来源',
                        prop: 'dataModelName',
                        show: false,
                        span: 24,
                    },
                    {
                        label: '维度字段',
                        prop: 'xColumn',
                        type: "select",
                        dicData: [],
                        tip: '点击选择X轴数据指标',
                        span: 24,
                    },
                    {
                        label: '指标字段',
                        prop: 'yColumn',
                        type: "select",
                        dicData: [],
                        tip: '点击选择Y轴数据指标',
                        span: 24,
                    },
                    {
                        label: '指标聚合',
                        prop: 'aggMethod',
                        type: "select",
                        dicData: [
                            { label: 'avg', value: 'avg' },
                            { label: 'sum', value: 'sum' },
                            { label: 'count', value: 'count' },
                            { label: 'max', value: 'max' },
                            { label: 'min', value: 'min' },
                        ],
                        span: 24,
                    },
                    {
                        label: '分组',
                        prop: 'groupBy',
                        type: "select",
                        multiple: true,
                        dicData: [],
                        span: 24,
                    },
                    {
                        label: '排序',
                        prop: 'orderBy',
                        type: "select",
                        multiple: true,
                        dicData: [],
                        span: 24,
                    },
                    {
                        label: '维度格式化',
                        prop: 'xFormater',
                        type: "textarea",
                        tip: '指标数据格式函数,$1为当时指标数据,eg: return $1 + 10',
                        span: 24,
                    },
                    {
                        label: '指标格式化',
                        prop: 'yFormater',
                        type: "textarea",
                        tip: '指标数据格式函数,$1为当时指标数据,eg: return $1 + 10',
                        span: 24,
                    },

                ]
            },
             {
                label: '数据过滤',
                prop: 'filter',
                custom: true,
                collapse: false,
                show: '$.dataSource.type == 2',
                default: []
            },
            {
                label: '外观',
                prop: 'layout',
                collapse: false,
                column: [
                    {
                        label: '标题',
                        prop: 'title',
                        span: 24,
                    },
                    {
                        label: '标题位置',
                        prop: 'titlePosition',
                        span: 24,
                        type: 'select',
                        default: 'center',
                        dicData: ["left", "center", "right"].map(t => {
                            return { label: t, value: t }
                        }),
                    },
                    {
                        label: '高度',
                        prop: 'height',
                        type: 'number',
                        min: 50,
                        max: 1000,
                        default: 300,
                        span: 24,
                    },
                    {
                        label: '旋转角度',
                        prop: 'rotate',
                        type: 'number',
                        min: -90,
                        max: 90,
                        default: 0,
                        span: 24,
                    },
                    {
                        label: '颜色' ,
                        labelScript: "$.type == 'barChart' ? '柱体颜色' : '连线颜色'" ,
                        prop: 'color',
                        type: 'color',
                        default: '#FF0000',
                        span: 24,
                    },
                    {
                        label: '面积颜色',
                        prop: 'areaColor',
                        type: 'color',
                        span: 24, 
                        show: "$.type == 'areaChart'"
                    },
                    {
                        label: '字体颜色',
                        prop: 'fontColor',
                        type: 'color',
                        default: '#000000',
                        span: 24,
                    },
                    {
                        label: '柱体宽度',
                        prop: 'barWidth',
                        type: 'number',
                        tip: '百分比',
                        min: 1,
                        max: 100,
                        step: 5,
                        span: 24, 
                        default: 50,
                        show: "$.type == 'barChart'"
                    },
                    {
                        label: '连线宽度',
                        prop: 'lineWidth',
                        type: 'number',
                        min:   0,
                        max: 10,
                        step: 1,
                        span: 24, 
                        default: 1,
                        show: "$.type == 'lineChart'"
                    },
                    {
                        label: '文本标签',
                        prop: 'label',
                        type: "switch",
                        default: false,
                        span: 24,
                    },
                    {
                        label: '标签位置',
                        prop: 'labelPosition',
                        type: "select",
                        dicData: ["top", "left", "right", "bottom", "inside", "insideLeft", "insideRight", "insideTop", "insideBottom", "insideTopLeft", "insideBottomLeft", "insideTopRight", "insideBottomRight"].map(t => {
                            return { label: t, value: t }
                        }),
                        span: 24, 
                    },
                    {
                        label: '标签颜色',
                        prop: 'labelColor',
                        type: "color",
                        span: 24, 
                    },
                    {
                        label: '翻转',
                        prop: 'y',
                        type: "switch",
                        default: false,
                        span: 24,
                    },
                    {
                        label: '平滑',
                        prop: 'smooth',
                        type: "switch",
                        default: false,
                        span: 24, 
                        show: "$.type == 'lineChart' || $.type == 'areaChart'" //display: itemType == 'line' || itemType == 'area'
                    },
                    {
                        label: '工具条',
                        prop: 'toolbar',
                        type: 'switch',
                        default: false,
                        span: 24,
                    },

                ]
            },
           
            {
                label: '事件',
                prop: 'event',
                collapse: false
            },
            {
                label: '边距',
                prop: 'grid',
                collapse: false,
                column: gridConfig
            },
            {
                label: '图例',
                prop: 'legend',
                collapse: false,
                column: legendConfig
            },
        ]
    },
    options: { 
        type: "bar",
        tooptip: '', // 提示 
        hidden: false, // 是否隐藏，false显示，true隐藏 
    },
    model: "", // 数据字段
    key: ""
}

const lineConfig = cloneDeep(barConfig)
const areaConfig = cloneDeep(barConfig)

lineConfig.options.type = 'line'
lineConfig.type = 'lineChart'//, // 表单类型
lineConfig.label = '线图'//, // 标题文字   



areaConfig.options.type = 'area'
areaConfig.type = 'areaChart'//, // 表单类型
areaConfig.label = '面积图'//, // 标题文字   

const list = [barConfig , lineConfig , areaConfig]

list.forEach(t=> {
	t.component = BaseIndex
	t.properties = BaseProperties
})

export default list


  