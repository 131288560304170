// 对外输出 包含组件的对外json定义、属性配置页面、展示页面 三部分
import BaseIndex from './index.vue'
import BaseProperties from './properties.vue'
import {styleConfig} from "../../../config/common/style.js";
import { getDataSourceConfig } from '../../../config/common/datasource.js' 

const datasourceConfig = getDataSourceConfig({model: false})

/*
 * author wangchi
 * date 2020-07-06
 * description 数据组件件配置
 */
const list = [
  
  {
    type: "data-DataTabs", // 提示
    label: "DataTabs数据展示", // 标题
    component: BaseIndex,
    properties: BaseProperties,

    formOptions: {
      config: {
        size: 'mini',
        labelWidth: 70,
        emptyBtn: false,
        submitBtn: false
      },
      group: [
        {...styleConfig}, // 样式,
        {...datasourceConfig} , // datasource
        
        {
          label: '外观',
          prop: 'layout',
          collapse: false,
          column: [
            {
              label: '标题',
              prop: 'title',
              span: 24,
            },
            {
              label: '标题字体大小',
              type: 'number',
              default: 13, 
              prop: 'titleSize',
              span: 24,
            },
            {
              label: '标题字体颜色',
              type: 'color',
              default: '#000000',
              prop: 'titleColor',
              span: 24,
            },
            {
              label: '标题加粗',
              type: "switch",
              default: false, 
              prop: 'titleBold',
              span: 24,
            },
            {
              label: '内容字体大小',
              type: 'number',
              default: 33, 
              prop: 'contentSize',
              span: 24,
            },
            {
              label: '单位',
              prop: 'unit',  
              span: 24,
            }, 
            {
              label: '高度', 
              default: '100px', 
              prop: 'height',
              span: 24,
            }, 
             {
              label: '宽度', 
              prop: 'width',
              default: '100%', 
              span: 24,
            }, 
            {
              label: '背景图片',
              prop: 'backgroundImage', 
              type: 'image',
              span: 24,
            }, 
             {
              label: '备注',
              prop: 'description', 
              type: 'textarea',
              span: 24,
            }, 
          ]
        }, 
        {
          label: '事件',
          prop: 'event',
          custom: true,
          collapse: false
        },
      ]
    },
   /* style: {
      backgroundColor: '',
      backgroundImage: '',
      itemBorderRadius: 0,
      margin: ['0', '0', '0', '0'],
      height: 100,
      color: '',
    },*/
    options: {
      tooptip: '', // 提示 
      hidden: false, // 是否隐藏，false显示，true隐藏 
    },
    key: ""
  } 
];
 

export default list