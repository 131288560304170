// 对外输出 包含组件的对外json定义、属性配置页面、展示页面 三部分
import BaseIndex from './index.vue'
import BaseProperties from './properties.vue'
 
/*
 * author lyf
 * date 2020-07-06
 * description 表单控件配置
 */
// 基础控件
const list = [
  
  {
    type: "alert", // 提示
    label: "提示", // 标题 
    options: { 
      tooptip: '',  // 提示 
      title: '' ,  //title
      type: 'info', //类型
      description: '',
      closable: true , // 是否可关闭
      center: true , // 是否居中
      showIcon: false, //  是否西安市图标
      effect: 'light' , // 主题 light/dark
      closeText: '' , //关闭按钮自定义文本 
    }, 
    key: "" 
  }, 
  {
    type: "button", // 表单类型
    label: "按钮", // 标题文字  
    options: {
      type: "primary",
      handle: "submit",
      textAlign: 'left',
      dynamicFun: "",
      click: '' ,
      tooptip: '', // 提示
      hidden: false, // 是否隐藏，false显示，true隐藏
      disabled: false // 是否禁用，false不禁用，true禁用
    },
    key: ""
  } , 
  {
      type: "text",
      label: "标签", 
      options: {
        textAlign: "left",
        fontSize: 13 ,
        fontColor: '#000000',
        fontBold: false,
        tooptip: '', // 提示
        background: '#FFFFFFFF', // 文本框背景色
        hidden: false, // 是否隐藏，false显示，true隐藏
        showRequiredMark: false
      },
      key: ""
  },
  {
      type: "html",
      label: "HTML", 
      options: {
        tooptip: '', // 提示
        hidden: false, // 是否隐藏，false显示，true隐藏
        defaultValue: "<strong>HTML</strong>"
      },
      key: ""
  },
    {
    type: "divider",
    label: "分割线", 
    options: {
      tooptip: '', // 提示
      orientation: "left",
      direction: 'horizontal'
    },
    key: "",
    model: ""
  },
];
 
list.forEach(t=> {
	t.component = BaseIndex
	t.properties = BaseProperties
  t.style = {
    margin: ['0', '0', '0', '0'] 
  }
})
 

export const baseList = list 