<!--
柱状图 或者线图
-->
<template>
<div :style="{'height': record.layout.height + 'px'}" v-loading="loading"> 
	 <EChart  :noTool="!record.layout.toolbar"  :options="options" >

	</EChart>  

</div>
</template>
<script>
import EChart from '../../EChart'
import { getChartLegend } from '../../../config/common/legend.js'
 
import { getChartGrid } from '../../../config/common/grid.js'
import { getDataByDatasource } from '../../../config/common/datasource.js'

 
export default {
	components: {
		EChart
	},
	data() {
		return {
			loading: false,
			dataList: [] ,
			options: {},
			data:  {}, // 拿到的数据     
			 
		}
	},
	props: {
		 // 表单数组 
	    record: {
	      type: Object,
	      required: true
	    },
	    // form-item 宽度配置
	    formConfig: {
	      type: Object,
	      required: false
	    },
	    // form-item 宽度配置
	    models: {
	      type: Object,
	      required: true
	    }, 
	    disabled: {
	      type: Boolean,
	      default: false
	    } ,
	      // 是否预览结果表单
	    renderPreview: {
	      type: Boolean ,
	      default: false
	    } 
	},
	mounted(){ 
	    this.getData()
	},
	computed: {
		recordOptions() {
			const options = {...this.record.layout,...this.record.style , ...this.record.grid , ...this.record.legend}
			// for(let i in this.record) {
			// 	options[i] = {...this.record[i]}
			// }
			return options
		}
	},	
	watch: {
		recordOptions:{//深度监听，可监听到对象、数组的变化
	        handler(){ 
	           	this.init()
	        },
	        deep:true 
	    },
	},
	methods: {
		// 查询数据
		getData() { 
			this.loading = true
	      	getDataByDatasource(this.record , this.models)
	      	.then((data)=> {
	      		this.data = data
	      		this.init()
	      	}).catch(e=> {
	      		if(e) {
	      			this.$message.error(e)
	      			console.error(e)
	      		}
	      		this.init()
	      	}).finally(()=>{
	        	this.loading = false;
	      	});

		},
		// 初始化图
		init() { 

			// 复合图形默认的数据格式为:

			/*

				{
				    "categories":[
				        "8月",
				        "9月",
				        "10月",
				        "11月"
				    ],
				    "series":[
				        {
				            "name":"满分",
				            "type":"bar",
				            "data":[
				                240,
				                201,
				                192,
				                168
				            ]
				        },
				        {
				            "name":"良好",
				            "type":"bar",
				            "data":[
				                496,
				                680,
				                580,
				                480
				            ]
				        },
				        {
				            "name":"及格",
				            "type":"bar",
				            "data":[
				                2810,
				                2160,
				                2320,
				                2620
				            ]
				        },
				        {
				            "name":"未达标",
				            "type":"bar",
				            "data":[
				                168,
				                480,
				                360,
				                108
				            ]
				        }
				    ]
				}


			*/
 

 
			const title = this.record.layout.title 
			const titlePosition = this.record.layout.titlePosition || 'center'
			const fontColor = this.record.layout.fontColor
			 
		 
			const rotate = this.record.layout.rotate ? this.record.layout.rotate : 0

			 

			const toolbarShow = this.record.layout.toolbar
			   

			const chartLegend = getChartLegend(this.record.legend)
 			const chartGrid = getChartGrid(this.record.grid)
 			 
			const y = this.record.layout.y 

			const labelShow = this.record.layout.label 
			let seriesLabel = null 
			if(labelShow) {
				const labelPosition = this.record.layout.labelPosition
				const labelColor = this.record.layout.labelColor
				seriesLabel = {show: true , position: labelPosition , textStyle: {color: labelColor } }
			}
 
		 	// 从数据中获取需要的数据
		 	const categorys = this.data.categories
		 	const series = this.data.series

		 	if(series && series.length > 0) {
		 		series.forEach(t=> {
			 		t.label = seriesLabel
			 		if(t.type == 'area') { 
			 			t.type = 'line'
						t.areaStyle = {
							color : t.areaColor
						}
				 
			 		}
			 	})
		 	}
		 	
 
 			console.log('category data' , this.data)

	    	this.options = {
				tooltip : {
				    trigger: 'axis',
				    axisPointer : {            // 坐标轴指示器，坐标轴触发有效
				        type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
				    }
				},
				toolbox: {
			        show : toolbarShow,
			        right:30,
			        feature : {
			            //mark : {show: true},
			            dataView : {show: true, readOnly: true},
			            magicType: {show: true, type: ['line', 'bar']},
			            // restore : {show: true},
			            saveAsImage : {show: true}
			               
			        }
			    },
			    legend: chartLegend ,
			    grid: chartGrid ,
				//color: color ,
				title: {
				    text: title,
				    x: titlePosition,
				    textStyle : {
						color : fontColor 
					},
				},
				xAxis: {
					type: y ? 'value' : 'category', 
					data: y ? undefined :  categorys,
					axisLabel:{ 
						rotate: y ? 0 : rotate,
			        	textStyle : {
							color : fontColor,
							fontSize : 12
						}
			        }
				},
				yAxis: {
					type: y ? 'category' : 'value' , 
					data: y ? categorys  : undefined ,
					axisLabel:{ 
						rotate: y ? rotate : 0 ,
			        	textStyle : {
							color : fontColor,
							fontSize : 12
						}
			        }
				},
				 
				series: series
				/*series: {
					 name: title, 
					 data: yData,
					 color:  color , 
					 type:  type ,
					 barWidth: barWidth + '%',
					 smooth: smooth ,
					 lineStyle: {
					 	color: color ,
					 	width: lineWidth
					 },
					 areaStyle: {
					 	show: 
					 	color : areaColor
					 },
					 label: seriesLabel
				}*/
			};
  
 			console.log('this.options' , this.options)
		}
	   
	}
}
</script>