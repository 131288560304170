<template>
  <div>
    <el-input :clearable="true" size="small" :placeholder="placeholder" v-model="checkNode.name" @clear="onClear()"  @click.native="onInputClick()">
      <template #append>
        <!-- <el-button style="margin: 0 auto" icon="el-icon-plus" size="mini" type="primary" @click="onInputClick()"/> -->
        <el-button style="margin: 0 auto" size="small" type="primary" @click="onInputClick()">请选择</el-button>
      </template>
    </el-input>
    <el-dialog
        title="请选择区域"
        style="overflow-y: auto"
        :visible.sync="regionTreeVisible"
        :before-close="cancelRegionTree"
        v-if="regionTreeVisible"
        width="800px"
        :close-on-click-modal="false"
        append-to-body
    >
      <el-tree
          class="filter-tree"
          ref="tree"
          :data="treeNode"
          @node-click="handleNodeClick"
          @current-change="dataListTreeCurrentChangeHandle"
          :check-on-click-node="true"
          :expand-on-click-node="true"
          :props="treeProps"
          highlight-current
          :default-expanded-keys="defaultExpandKeys"
          node-key="id"
          :style="{height:treeHeight+'px',overflow:'auto'}"
      >
        <div
            style="width:90%;line-height:31px;"
            :title="node.label"
            slot-scope="{ node }"
        >
          <div
              class="iconfont icon-jigou"
              style="float:left;"
          ></div>
          <div class="iconfont icon-bumenkaoqin" style="float:left;"></div>
          <div class="cell-hidden" style="width:calc(100% - 20px);float:left;">
            {{ node.label }} {{node.type}}
          </div>
        </div>
      </el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelRegionTree">取消</el-button>
        <!-- <el-button type="primary" @click="comfirmRegionTree()">确认</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getTreeJurisdiction } from '@/api/sys/region'
export default {
  name: 'regionTree',
  //初始化数据
  data() {
    return {
      treeHeight: window.innerHeight - 250,
      treeNode: [],
      setCheckNodeFlag: false,
      popDialogAction: true,
      treeProps: {
        children: "children",
        label: "name",
        id: "id"
      },
      nodeData: {},
      root: "", //根id，如果删除的是跟id，需要将parentId复制为组机构机构id "1"
      defaultExpandKeys: [],
      regionTreeVisible: false,
      checkNode : {
        id: "",
        code: "",
        type: "",
        name: ""
      }
    };
  },
  props: {
    placeholder : String,
    rootId: String,
    type : { type : String, default : '0'},
    inType: { type : Boolean, default : false}, // type 开关，true表示只能选择type范围的数据，false表示选择type范围之外的数据，为空表示不限制
    value : String,
    // code : String  // 如果要获取code数据，请监听 @change 事件，获取 checkNode 对象
  },
  components: {},
  watch: {
    rootId (val) {
      if(val) {
        this.loadTreeNode();
      }
    },
    value(val){
      // 将ID转意义为Name
      if(val) {
        this.checkNode = null;
        if(this.treeNode && this.treeNode.length > 0) {
          this.defaultCheckedTreeNodeList(this.treeNode, val);
          if(!this.checkNode) {
            this.loadTreeNode();
          }
        }
      }
    },
  },
  //方法
  methods: {
    // 监听clear事件，如果是清空行为，则不弹框
    onClear() {
      this.popDialogAction = false;
      this.clearInput();
    },
    // 点击输入框
    onInputClick() {
      if(this.popDialogAction) {
        this.regionTreeVisible = true;
        this.setCheckNodeFlag = true;
        this.loadTreeNode();
      } else {
        this.popDialogAction = true;
      }
    },
    //关闭按钮
    cancelRegionTree() {
      this.regionTreeVisible = false;
    },
    // 复选改单选处理
    // handleCheckChange(data, checked, indeterminate) {
    //     if (checked) {
    //         this.$refs.tree.setCheckedKeys([data.id])
    //     }
    // },
    // 复选改单选处理
    // handleCheck(data, indeterminate) {
    //     if(data.type === this.type && this.inType
    //     || !this.inType && data.type !== this.type ) {
    //         this.$refs.tree.setCheckedKeys([data.id]);
    //         this.checkNode.id = data.id;
    //         this.checkNode.type = data.type;
    //         this.checkNode.name = data.name;
    //         this.checkNode.code = data.code;
    //         this.comfirmRegionTree();
    //     }
    // },
    //树节点点击事件
    handleNodeClick(data) {
      if(data.type === this.type && this.inType
          || !this.inType && data.type !== this.type ) {
        this.$refs.tree.setCheckedKeys([data.id]);
        this.checkNode = data;
        this.comfirmRegionTree();
      }
      this.$emit("typeChange",data);
    },
    // 菜单树选中
    dataListTreeCurrentChangeHandle(data, node) {
      // this.checkNode = data;
    },
    clearInput() {
      let _this = this;
      this.checkNode = { id : '', type : '', name: '', code: ''};
      _this.$emit('input', '');                  // 往父组件的v-model中传值
      _this.$emit('change', _this.checkNode);    // 往父组件传多个值
      _this.$emit("typeChange",_this.checkNode);
    },
    //确认按钮
    comfirmRegionTree() {
      let _this = this;
      let checkNodes = _this.$refs.tree.getCheckedNodes(false, true);
      if(checkNodes != null && checkNodes.length >=0) {
        _this.$emit('input', _this.checkNode.id);  // 往父组件的v-model中传值
        _this.$emit('change', _this.checkNode);    // 往父组件传多个值
        _this.name = _this.checkNode.name;
      } else {
        this.clearInput();
      }
      _this.regionTreeVisible = false;
    },
    defaultCheckedTreeNodeList(treeNodeList, id) {
      treeNodeList.forEach(element => {
        this.defaultCheckedTreeNode(element, id);
      });
    },
    defaultCheckedTreeNode(treeNode, id) {
      if(treeNode.id === id) {
        this.checkNode = treeNode;
      } else {
        if(treeNode.children) {
          this.defaultCheckedTreeNodeList(treeNode.children, id);
        }
      }
    },
    //请求方法
    loadTreeNode() {
      let _this = this;
      getTreeJurisdiction(this.rootId).then(({data}) => {
        if (data && data.code === 0) {
          this.treeNode = data.data;
          this.defaultExpandKeys = [];
          this.defaultExpandKeys.push(this.treeNode[0].id);
          if(this.value) {
            this.defaultCheckedTreeNodeList(this.treeNode, this.value);
          }
        } else {
          this.$message.error(data.msg)
        }
      });
    },
  },
  //页面渲染之前调用方法 函数
  created() {
  },
  //页面渲染完成调用方法 函数
  mounted() {
    var _this = this;
    this.loadTreeNode();
  }
};
</script>
<style scoped>
.el-tree-node__children {
  overflow: visible !important;
}
</style>
