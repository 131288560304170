import { dynamicFun } from '../../../utils/index.js'
import { getDatamodelQuery } from '@/api/hbi/datamodel.js'
import { getSqlQuery } from '@/api/hbi/datamodel.js'
import http from '@/utils/httpRequest'
import store from "@/store";
/**
 * 返回数据源配置
 */
export function getDataSourceConfig (config) {

  const defaultConfig = { staticData: true, model: true, sql: true, api: true, staticDefault: '', dataSourceDefault: '2' }
  if (!config) {
    config = defaultConfig
  } else {
    config = Object.assign(defaultConfig, config)
  }

  const staticData = config.staticData
  const model = config.model
  const sql = config.sql
  const api = config.api
  const staticDefault = config.dataStatic
  const dataSourceDefault = config.dataSourceDefault

  const sourceConfig = {
    label: '数据源',
    prop: 'dataSource',
    collapse: false
  }

  const dataSourceTypes = []
  if (staticData) {
    dataSourceTypes.push({ label: '静态数据', value: '1' })
  }
  if (model) {
    dataSourceTypes.push({ label: '数据模型', value: '2' })
  }
  if (sql) {
    dataSourceTypes.push({ label: '数据SQL', value: '3' })
  }
  if (api) {
    dataSourceTypes.push({ label: 'API', value: '4' })
  }

  const dataSourceColumn = {
    label: '数据来源',
    prop: 'type',
    type: "select",
    default: dataSourceDefault,
    dicData: dataSourceTypes,
    span: 24,
  }

  let columns = [dataSourceColumn]
  // static
  if (staticData) {
    const column = {
      label: '静态数据',
      prop: 'dataStatic',
      show: '$.dataSource.type == 1',
      custom: true,
      span: 24
    }

    if (staticDefault) {
      column.default = staticDefault
    }

    // 静态数据类型 和 静态数据函数
    const staticOtherColumn = [
      {
        label: '静态数据类型',
        prop: 'dataStaticType',
        type: 'radio',
        default: '1', // 1-静态数据 2-函数数据
        show: false,
        span: 24
      },
      {
        ...column
      },
      {
        label: '静态数据函数',
        prop: 'dataStaticScript',
        default: `(models)=>{
              // models 为当前查询表单的form

              return ${staticDefault}
             }`,
        show: false,
        span: 24
      }
    ]
    columns = columns.concat(staticOtherColumn)
  }

  //model
  if (sql) {
    const column = [{
      label: '数据SQL',
      prop: 'dataSql',
      show: '$.dataSource.type == 3',
      custom: true,
      span: 24,
    },
    {
      label: 'SQL参数',
      prop: 'dataSqlParams',
      show: false,
      default: [],
      custom: true,
      span: 24,
    },
    {
      label: '数据源ID',
      prop: 'datasourceId',
      show: false,
      span: 24,
    }]
    columns = columns.concat(column)
  }

  if (api) {
    const column = [{
      label: 'API配置',
      prop: 'dataApi',
      show: '$.dataSource.type == 4',
      custom: true,
      span: 24,
    },
    {
      label: '接口地址',
      prop: 'apiPath',
      show: false,
      default: '',
      custom: true,
      span: 24,
    },
    {
      label: '请求方式',
      prop: 'apiMethod',
      show: false,
      default: '1',
      custom: true,
      span: 24,
    },
    {
      label: '请求头',
      prop: 'apiHeaders',
      show: false,
      default: `()=>{return {}}`,
      custom: true,
      span: 24,
    },
    {
      label: '请求参数',
      prop: 'apiParams',
      show: false,
      default: `()=>{return {}}`,
      custom: true,
      span: 24,
    },
    {
      label: '数据格式化',
      prop: 'apiDataFormatter',
      show: false,
      default: `(data)=>{return data;}`,
      custom: true,
      span: 24,
    }]
    columns = columns.concat(column)
  }

  columns.push({
    label: '数据格式化',
    prop: 'dataFormatter',
    show: false,
    default: `(data)=>{
                return data;
             }`,
    span: 24,
  })
  sourceConfig.column = columns
  return sourceConfig
}

// 公共datasource配置
//export const datasourceConfig =  getDataSourceConfig()

export const tips = `
  #柱状图数据格式
  {
    "xData": ["1年级","2年级","3年级"],
    "yData":[10,11,8]
  }

  #折线图数据格式
  {
    "xData": ["1年级","2年级","3年级"],
    "yData":[10,11,8]
  }

  #饼图数据格式
  {
    "xData": ["1年级","2年级","3年级"],
    "yData":[10,11,8]
  }

  #复合图形数据格式
  {
    "categories":[
        "8月",
        "9月",
        "10月",
        "11月"
    ],
    "series":[
        {
            "name":"满分",
            "type":"bar",
            "data":[
                240,
                201,
                192,
                168
            ]
        },
        {
            "name":"良好",
            "type":"bar",
            "data":[
                496,
                680,
                580,
                480
            ]
        },
        {
            "name":"及格",
            "type":"bar",
            "data":[
                2810,
                2160,
                2320,
                2620
            ]
        },
        {
            "name":"未达标",
            "type":"bar",
            "data":[
                168,
                480,
                360,
                108
            ]
        }
    ]
  }

  #象型图数据格式
  {
    "categories": [
      "苹果",
    ],
      "series": [{
        "name": "手机品牌",
        "data": [
          1000879,
        ]
      }]
  }

  #雷达图数据格式
  {
    "indicator": [{
      "name": '销售',
      "max": 6500
    }],
    "series": [{
      "data": [{
        "value": [4300, 10000, 28000, 35000, 50000, 19000],
        "name": '预算分配（Allocated Budget）'
      }]
    }]
  }

  #散点图数据格式
  [{
    "data": [
      [1, 8.04],
      [2, 6.95]
    ]
  },
  {
    "data": [
      [1, 4.04],
      [2, 3.95]
    ]
  }]

  #漏斗图数据格式
  [{
    "value": 335,
    "name": '直接访问'
  },
  {
    "value": 310,
    "name": '邮件营销'
  },
  {
    "value": 234,
    "name": '联盟广告'
  }]

  #轮播图数据格式
  [{
    "value": '图片地址'
  },
  {
    "value": '图片地址2'
  }]

  #地图数据格式
  [
    {
      "name": "安康市",
      "value": 7
    },
    {
      "name": "宝鸡市",
      "value": 15
    },
    {
      "name": "汉中市",
      "value": 0
    },
    {
      "name": "商洛市",
      "value": 15
    },
    {
      "name": "铜川市",
      "value": 15
    },
    {
      "name": "渭南市",
      "value": 15
    },
    {
      "name": "西安市",
      "value": 23
    },
    {
      "name": "咸阳市",
      "value": 15
    },
    {
      "name": "延安市",
      "value": 15
    },
    {
      "name": "榆林市",
      "value": 15
    }
]
 `

// 根据datasource配置获取数据
export function getDataByDatasource (record, models) {
  const dataSource = record.dataSource
  const dtype = dataSource ? dataSource.type : 2
  if (dtype == 1) {
    // static data
    return new Promise((resolve, reject) => {
      const dataStaticType = dataSource.dataStaticType || '1'
      if (dataStaticType == '1') {
        const dataStatic = dataSource.dataStatic
        if (!dataStatic) {
          reject()
          return
        }
        resolve(JSON.parse(dataStatic))
      } else {
        const dataStaticScript = dataSource.dataStaticScript
        if (!dataStaticScript) {
          reject()
          return
        } else {
          const fn = eval(dataStaticScript)
          resolve(fn(models))
        }
      }
    })
  } else if (dtype == 2) {
    // data model
    return getDatamodelData(record.data, models, record.filter)
  } else if (dtype == 3) {
    // sql
    const datasourceId = dataSource.dataSourceId
    const sqlText = dataSource.dataSql
    const dataFormatter = dataSource.dataFormatter
    let dataSqlParams = dataSource.dataSqlParams
    // 动态参数不为空的时候开始转换参数

    dataSqlParams=dataSqlParams.map(t => {
      if(t=='userId'){

        return store.state.user.userId
      }
      if(t=='deptId'){
        return store.state.user.deptId
      }
      const tvalue = dynamicFun(t , models)
      return tvalue
    })
    return getSqlData(datasourceId, sqlText, dataFormatter, dataSqlParams, models)
  } else if (dtype == 4) {
    // api
    const apiInfo = {
      apiPath: dataSource.apiPath,
      apiMethod: dataSource.apiMethod,
      apiHeaders: getValueByFun(dataSource.apiHeaders, null),
      apiParams: getValueByFun(dataSource.apiParams, null),
      apiDataFormatter: dataSource.apiDataFormatter
    }
    if(apiInfo.apiMethod == '1'){
      return getMethod(apiInfo)
    }else if(apiInfo.apiMethod == '2'){
      return postMethod(apiInfo)
    }
  }
}
/** get 请求 */
function getMethod(apiInfo){
  return new Promise((resolve, reject) => {
    if(!apiInfo.apiPath){
      reject("api请求地址不存在")
      return
    }
    http({
      url: http.adornUrl(apiInfo.apiPath),
      method: 'get',
      params: apiInfo.apiParams,
      headers:apiInfo.apiHeaders
    }).then(({data}) => {
      let retData = getDataPreview(data, apiInfo.apiDataFormatter)
      resolve(retData)
    }).catch((err) => {
      console.error(err);
      reject("api请求异常")
    }).finally(_ => {})
  })
}
/** post 请求 */
function postMethod(apiInfo){
  return new Promise((resolve, reject) => {
    if(!apiInfo.apiPath){
      reject("api请求地址不存在")
      return
    }
    http({
      url: http.adornUrl(apiInfo.apiPath),
      method: 'post',
      params: apiInfo.apiParams,
      headers:apiInfo.apiHeaders
    }).then(({data}) => {
      let retData = getDataPreview(data, apiInfo.apiDataFormatter)
      resolve(retData)
    }).catch((err) => {
      console.error(err);
      reject("api请求异常")
    }).finally(_ => {})
  })
}
/** 获取api头及参数信息 */
function getValueByFun(funStr, data){
/*  if(!funStr)*/
  if(funStr){
    const fun = eval(funStr)
    if (fun) {
      const dvalue = fun(data)
      return dvalue
    }
  }
  return data
}

/** 获取格式化数据 */
function getDataPreview (retData, apiDataFormatter) {
  if (!retData) {
    return
  }
  if (apiDataFormatter) {
    const formatterFun = eval(apiDataFormatter)
    if (formatterFun) {
      return formatterFun(retData)
    }
  }
  return retData
}


export function getSqlData (datasourceId, sqlText, dataFormatter, params = [], models = {}) {
  return new Promise((resolve, reject) => {
    if (!datasourceId || !sqlText) {
      reject()
      return
    }
    const sqlData = { datasourceId: datasourceId, sqlText: sqlText, params: params }
    getSqlQuery(sqlData).then(({ data }) => {
      if (data && data.code == 0) {
        // const totalCount = data.data.totalCount
        // const currentCount = data.data.currentCount
        // if (totalCount > currentCount) {
        //     this.$message.info(`当前sql数据总量为:[${totalCount}],目前查询返回结果数量限制为:[${currentCount}]`)
        // }
        let retData = data.data.rows

        if (dataFormatter) {
          const formatterFun = eval(dataFormatter)
          // 加入当前过滤表单
          retData = formatterFun(retData, models)
          //retData = dynamicFun(dataFormatter , retData)
        }
        resolve(retData)
      } else if (data && data.msg) {
        reject(data.msg)
      }
    }).catch(e => {
      console.error(e)
      reject('sql查询异常')
    })
  })
}

function getDatamodelData (dataModel, models, filters = []) {
  const dataModelId = dataModel.dataModelId
  const xColumn = dataModel.xColumn
  const yColumn = dataModel.yColumn
  //const yFormater = dataModel.yFormater
  const aggMethod = dataModel.aggMethod
  const groupBy = dataModel.groupBy
  const orderBy = dataModel.orderBy
  if (!dataModelId || !xColumn || !yColumn) {
    return new Promise((resolve, reject) => {
      reject()
    })
  }

  const resColumns = [
    { column: xColumn, aliasName: xColumn },
    { column: yColumn, aggMethod: aggMethod, aliasName: yColumn }
  ]

  let filter = []
  if (filters && filters.length > 0) {
    filter = filters.map(t => {
      let fvalue = t.value
      if (t.type == '2') {
        fvalue = dynamicFun(fvalue, models)
      }
      return { column: t.column, filterType: t.filterType, value: fvalue }
    })
  }
  const params = {
    dataModelId: dataModelId,
    resColumns: resColumns,
    filters: filter,
    group: groupBy,
    order: orderBy,
    page: false

  }
  return new Promise((resolve, reject) => {
    getDatamodelQuery(params).then(({ data }) => {
      if (data && data.code === 0) {
        const dataList = data.data
        const xdatas = dataList.map(t => t[xColumn])
        const ydatas = dataList.map(t => t[yColumn])
        //this.init(xdatas, ydatas)
        resolve({ xData: xdatas, yData: ydatas })
      } else {
        if (data.msg) {
          reject(data.msg)
        }
      }
    }).catch(e => {
      console.error(e)
      reject('查询异常')
    })
  })
}
