export default {
    /* 模块名字 */
    name: 'rules',

    /* 英文资源 */
    en_US: {
        ruleName: 'Rule Name',
        ruleType: 'Type',
        ruleAdmin: 'admin',
        ruleDept: 'department',
        remark: 'Tip',
        deleteRuleInfo: 'Are you sure you want to delete it? Users with this role may not be able to log in after deleting this role.',
        addRuleInfo: 'Add Rule',
        updateRuleInfo: 'Update Rule',
        auths: 'authorize'

    },

    /* 中文资源 */
    zh_CN: {
        ruleName: '角色名称',
        ruleType: '角色类型',
        ruleAdmin: '管理角色',
        ruleDept: '部门角色',
        ruleMall: '商城管理',
        remark: '备注',
        deleteRuleInfo: '确定进行删除操作?删除此角色后拥有此角色的用户可能无法登陆.',
        addRuleInfo: '添加角色信息',
        updateRuleInfo: '修改角色信息',
        auths: '授权'




    }


}
