export default{
    name:'matters',
    en_US:{
        mattersTitle:'Matters Title',
        createTime:'Create Time',
        progressStatus:'Progress Status',
        operate:'Operate',
        query:'Query',
        editMatters:'Edit Matters',
        view:'View',
        edit:'Edit',
        delete:'Delete',
        submitAudit:'Submit Audit',
        approvalDetails: 'Approval Details',
        tip:'Tip',
        tips1:'Are you sure to delete data ?',
    },
    zh_CN:{
        mattersTitle:'事项标题',
        createTime:'创建时间',
        progressStatus:'流程状态',
        operate:'操作',
        query:'查询',
        editMatters:'编辑事项',
        view:'查看',
        edit:'修改',
        delete:'删除',
        submitAudit:'提交审核',
        approvalDetails:'审批详情',
        tip:'提示',
        tips1:'确定进行删除操作?',
    }
}