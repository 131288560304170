export default {
  namespaced: true,
  state: {
    // 页面文档可视高度(随窗口改变大小)
    documentClientHeight: 0,
    // 导航条, 布局风格, defalut(默认) / inverse(反向)
    navbarLayoutType: "inverse",
    // 侧边栏, 布局皮肤, light(浅色) / dark(黑色)
    sidebarLayoutSkin: "dark",

    // 顶部菜单是否开启 默认不开启
    menuLayout: false,

    // 是否打开面包屑导航
    breadcrumbOpen: true,
    // 打开面包屑导航的话,面包屑导航的位置
    breadcrumbLayout: "header",

    // 多页签支持
    tabOpen: true,

    // 侧边栏, 折叠状态
    sidebarFold: false,
    // 侧边栏, 菜单
    menuList: [],
    menuActiveName: "",
    // 主入口标签页
    mainTabs: [],
    // navbar中选中的菜单ID
    activeNavbar: '' ,
    mainTabsActiveName: ""
  },
  mutations: {
    updateDocumentClientHeight(state, height) {
      if(height) {
        state.documentClientHeight = height;
      } 
    },
    updateNavbarLayoutType(state, type) {
      state.navbarLayoutType = type;
    },
    updateSidebarLayoutSkin(state, skin) {
      state.sidebarLayoutSkin = skin;
    },
    updateSidebarFold(state, fold) {
      state.sidebarFold = fold;
    },
    updateMenuList(state, list) {
      state.menuList = list;
    },
    updateMenuActiveName(state, name) {
      state.menuActiveName = name;
    },
    updateMainTabs(state, tabs) {
      state.mainTabs = tabs;
    },
    updateMainTabsActiveName(state, name) {
      state.mainTabsActiveName = name;
    },
    updateMenuLayout(state, layout) {
      state.menuLayout = layout;
    },
    updateBreadcrumbOpen(state, open) {
      state.breadcrumbOpen = open;
    },
    updateBreadcrumbLayout(state, layout) {
      state.breadcrumbLayout = layout;
    },
    updateTabOpen(state, open) {
      state.tabOpen = open;
    },
    updateActiveNavbar(state, active){
      state.activeNavbar = active
    }
    
  }
};
