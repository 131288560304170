import http from '@/utils/httpRequest'

export function getRegionTree(pid) {
  return http({
        url: http.adornUrl('/sys/region/getTree'),
        method: 'get',
        params: {id : pid}
      })
}

export function getTreeJurisdiction(pid) {
    return http({
        url: http.adornUrl('/sys/region/getTreeJurisdiction'),
        method: 'get',
        params: {id : pid}
    })
}

export function getList(pid) {
  	return http({
          url: http.adornUrl('/sys/region/getlist'),
          method: 'get',
          params: {id : pid}
        })
} 
export function deleteOrg(id) {
	return http({
            url: http.adornUrl("/sys/region/delete"),
            method: "post",
            data: http.adornData(id, false)
          })
}
 

export function getById(id) {
	return http({
        url: http.adornUrl(`/sys/region/info/${id}`),
        method: "get"
        })
}

// 根据code 获取
export function getBycode(code) {
	return http({
        url: http.adornUrl(`/sys/region/infoByCode/${code}`),
        method: "get"
        })
}

//根据code获取他下级区域
export function childCode(id) {
    return http({
        url: http.adornUrl(`/sys/region/childCode/${id}`),
        method: "get"
    })
}
 
export function saveOrUpdate(data) {
	return http({
            url: http.adornUrl(
              `/sys/region/${!data.id ? "save" : "update"}`
            ),
            method: "post",
            data: data
        })
}

//根据当前区域获取上级级联的树列表
export function queryOrgParents(orgCode) {
    return http({
          url: http.adornUrl('/sys/region/queryOrgParents'),
          method: 'get',
          params: {orgCode : orgCode}
        })
} 

// 获取区域树状数据
export function organizationForDm(orgCode , maxlevel) {
    return http({
          url: http.adornUrl('/sys/region/organizationForDm'),
          method: 'get',
          params: {orgCode : orgCode , mlevel: maxlevel}
        })
} 

 
let orgs = {}; 
 

//从local中获取当前已有的列表
var sessionOrgs_ = window.sessionStorage.getItem('orgs');
if (sessionOrgs_ != null && sessionOrgs_ != '') {
  //console.log('sessionDicts_' , sessionDicts_)
  try {
    orgs =  JSON.parse(sessionOrgs_);
   }catch(error) {
    orgs = []
   }
 
}
 
function putOrg(org , name) {
 
  orgs[org] = name 
  const json_ = JSON.stringify(orgs)
  window.sessionStorage.setItem('orgs' , json_);
 
}


/**
* 根据区域ID返回区域名称
* @param {orgId} 区域ID 
*/
export function getOrgName(orgId) {
 
  
  //判断缓存中是否存在
  return new Promise((resolve, reject)=>{

    if(!orgId) {
      reject()
      return
    }

    if(orgs[orgId]) {
      resolve(orgs[orgId])
      return 
    }
 
    getBycode(orgId).then(({data}) => {
           
      if (data.code === 0) {
        const info = data.data
        if(info) { 
          // 放入缓存 然后返回
          putOrg(orgId , info.name) 

          resolve(info.name)
          return
        }
      }  
      reject()
    }).catch((e)=>{
      reject()
    })

  })
   
}

// 行政区域树状结构缓存
let organizations = []


function getChild(pid , orgs) {
  let ds = []
  if(!pid) {
    // 第一层
   return  orgs.map(t=>{return {value: t.value , label: t.label}})
  } 

  // 迭代
  let datas = []
  
  const fn = (data)=>{
   
    for(let i = 0 ; i < data.length ; i++) { 
      if(data[i].value == pid) {
        datas = data[i].children
        break
      } else if(data[i].children && data[i].children.length > 0){
        
        fn(data[i].children)
      }
    }
  }

  fn(orgs)
 
  return datas
}

/**
* 获取父级行政区域下的所有子集
*/
export function getOrgChild(pid) {


  //判断缓存中是否存在
  return new Promise((resolve, reject)=>{
    let ds = []
     
    if(!organizations || organizations.length == 0) {
      // 获取数据
      // httpRequest({
      //   url: httpRequest.adornUrl(`/sys/organization/organizationForDm` ),
      //   method: 'get',
      //   params: httpRequest.adornParams({
      //     mlevel: 4
      //   })
      // })
      organizationForDm(null,4).then(({data}) => { 
        if (data.code === 0) { 
          organizations = data.data

          ds = getChild(pid , organizations)
          resolve(ds)
        }  
        reject()
      }).catch((e)=>{
        reject()
      })
    } else {
      ds = getChild(pid , organizations)
      resolve(ds)
    } 
  })

}


export function clearOrg() {

  orgs = {}
  const json_1 = JSON.stringify(orgs)
  window.sessionStorage.setItem('orgs', json_1)
 
}