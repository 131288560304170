
import { list as inputList } from './input/index.js'
import { list as chartList } from './chart/index.js'
import { list as dataList } from './data/index.js'
import { list as weigetList } from './weiget/index.js'


const config = [
	{
		name: '输入组件',
		list: inputList
	},
	{
		name: '图表组件',
		list: chartList
	},
	{
		name: '数据组件',
		list: dataList
	},
	{
		name: '展示与布局组件',
		list: weigetList
	}
]

export default config

