export default {
    /* 模块名字 */
    name: 'syslog',

    /* 英文资源 */
    en_US: {
        userAction: 'User Action',
        requestMethod: 'Request Method',
        requestParams: 'Request Params',
        execTime: 'Execution Time (ms)',
        ipAddress: 'Ip Address',
        userName:'User Name',

    },

    /* 中文资源 */
    zh_CN: {
        userAction: '用户操作',
        requestMethod: '请求方法',
        requestParams: '请求参数',
        execTime: '执行时长(毫秒)',
        ipAddress: 'ip地址',
        userName:'用户名称'

    }


}