/* 数据字典的资源 */
export default {
    /* 模块名字 */
    name: 'suppliers',

    /* 英文资源 */
    en_US: {
        supplierName: 'Supplier Name',
        supplierNameTip: 'Please enter supplier name',
        supplierType: 'Supplier Type',
        supplierTypeTip: 'Please select supplier type',
        privateEnterprise: 'Private Enterprise',
        foreignCompanies: 'Foreign Companies',
        businessUnit: 'Business Unit',
        government: 'Government',
        supplierNumber: 'Credit Code',
        supplierNumberTip: 'Please enter the unified social credit code',
        sindustry: 'Technicality',
        sindustryTip: 'Please select the technicality of the supplier',
        metallurgicalMinerals: 'Metallurgical Minerals',
        waterResAndEl: 'Water Resources and Hydropower',
        petrochemical: 'Petrochemical',
        reset: 'Reset',
        manager: 'Manager',
        managerTip: 'Please enter the person in charge of the supplier',
        qq: "Manager's QQ",
        qqTip: "Please enter manager's QQ",
        look: 'Look',
        queryFail: 'failed to query',
        delFail: 'failed to delete',
        confirmDeleteInfo: 'This operation will permanently delete the data [{0}], do you want to continue?',
        updateSupplierInfo: 'Update Supplier',
        addSupplierInfo: 'Add Supplier',
        lookSupplierInfo: 'Look Supplier',
        supplierInfo: 'Supplier Info',
        phone: 'Phone',
        phoneTip: 'Please enter supplier phone number',
        sarea: 'Area',
        registeredFund:'Registered fund',
        registeredFundTip:'Please enter the registered capital',
        blacklist:'Blacklist',
        sareaTip: "Please select the supplier's region",
        blacklistTip:'Please select whether to join the blacklist',
        sarea1: 'South China',
        sarea2: 'East China',
        sarea3: 'Asia',
        sarea4: 'America',
        sscale: 'Supplier Scale',
        sscaleTip: 'Please select supplier size',
        big: 'big',
        middle: 'middle',
        small: 'small',
        sother: 'other',
        sDept: 'Supplier Department',
        sDeptTip: 'Please enter the department of the supplier',
        othInfo: 'Other Info',
        updateTime: 'Update Time',
        createTime: 'Create Time',
        operator: 'Operator',
        back: 'back',
        update: 'update',
        phoneFormatErr: 'Incorrect phone format',
        lengthFormatErr: 'incorrect length',
        qqFormatErr: 'Please enter the correct QQ',
        less18: 'Social credit code less than 18 digits',
        than18: 'Social credit code greater than 18 digits',
        formatErr: "doesn't fit the format",
        formConfirmInfo: 'Please complete the form content according to the prompts',
        updateFail: 'update failed',
        saveFail: 'save failed',
        sphone: 'Supplier Phone',
        saddress: 'Supplier Address',
        deBank: 'Depositary Bank',
        bankAccount: 'Bank Account',
        depositaryBankNumber: 'Bank Number',
        saddressTip: "Please enter the supplier's address",
        deBankTip: "Please enter the supplier's Depositary Bank",
        bankAccountTip: "Please enter the supplier's bank account number",
        depositaryBankNumberTip: "Please enter the bank number of the supplier",
        inputInt: 'Please enter integer',
        performance:'Performance',
        qualificationFile:'Qualification File or Business License',
        yuan:'yuan'

    },

    /* 中文资源 */
    zh_CN: {
        supplierName: '供应商名称',
        supplierNameTip: '请输入供应商名称',
        supplierType: '供应商类型',
        supplierTypeTip: '请选择供应商类型',
        privateEnterprise: '民营企业',
        foreignCompanies: '外资企业',
        businessUnit: '事业单位',
        government: '政府单位',
        supplierNumber: '统一社会信用代码',
        supplierNumberTip: '请输入统一社会信用代码',
        sindustry: '专业性质分类',
        sindustryTip: '请选择专业性质分类',
        metallurgicalMinerals: '冶金矿产',
        waterResAndEl: '水利水电',
        petrochemical: '石油化工',
        reset: '重置',
        manager: '供应商负责人',
        managerTip: '请输入供应商负责人',
        qq: '负责人联系QQ',
        qqTip: '请输入负责人的联系QQ',
        look: '查看',
        queryFail: '查询失败',
        delFail: '删除失败',
        confirmDeleteInfo: '此操作将永久删除该数据【{0}】, 是否继续?',
        updateSupplierInfo: '修改供应商信息',
        addSupplierInfo: '添加供应商信息',
        lookSupplierInfo: '查看供应商信息',
        supplierInfo: '供应商信息',
        phone: '供应商电话',
        phoneTip: '请输入供应商电话',
        sarea: '地区',
        blacklist: '黑名单',
        sareaTip: '请选择供应商所属区域',
        blacklistTip: '请选择是否要加入黑名单',
        sarea1: '华南',
        sarea2: '华东',
        sarea3: '亚洲',
        sarea4: '美洲',
        sscale: '供应商规模',
        sscaleTip: '请选择供应商规模',
        big: '大',
        middle: '中',
        small: '小',
        sother: '其他',
        sDept: '供应商部门',
        sDeptTip: '请输入供应商所属部门',
        othInfo: '其他信息',
        updateTime: '更新时间',
        createTime: '创建时间',
        operator: '操作人',
        back: '返回',
        update: '更新',
        phoneFormatErr: '电话格式不正确',
        lengthFormatErr: '长度不正确',
        qqFormatErr: '请输入正确的QQ',
        less18: '社会信用代码少于18位',
        than18: '社会信用代码大于18位',
        formatErr: '不符合格式',
        formConfirmInfo: '请根据提示完善表单内容',
        updateFail: '更新失败',
        saveFail: '保存失败',
        sphone: '联系电话',
        saddress: '供应商地址',
        deBank: '开户银行',
        bankAccount: '银行账户',
        depositaryBankNumber: '供应商开户行行号',
        registeredFund: '注册资金',
        saddressTip: "请输入供应商的地址",
        deBankTip: "请输入供应商的开户银行",
        registeredFundTip: "请输入注册资金",
        bankAccountTip: "请输入供应商的银行账户",
        depositaryBankNumberTip: "请输入供应商的开户行行号",
        inputInt: '请输入数字',
        performance:'业绩',
        qualificationFile:'资质文件或营业执照',
        yuan:'元'


    },
};
