<!--
表单控件属性设置组件,因为配置数据是引用关系，所以可以直接修改
-->
<template>
    <div>
        <BaseProperties ref="baseProperties" :selectItem="selectItem" :selectItemKey="selectItem.key" v-if="selectItem && selectItem.type ">
        
          <template slot="columns"  slot-scope="{dataColumns}"> 
             <el-collapse-item  title="字段列表" name="columns">
                <div :key="datasetKey">
                    <el-tree
                      :data="selectItem.columns"  
                      class="table-column-tree"
                      default-expand-all
                      :expand-on-click-node="false">
                      <span style="width: 100%;" slot-scope="{ node, data }">

                        <el-row  > 
                            <el-col :span="10"  >
                                <span class="pointer " @click="addOrUpdateColumn(node)">{{data.label}}</span>
                            </el-col>
                            <el-col :span="10"> 
                                <span class="pointer " @click="addOrUpdateColumn(node)">{{data.prop}}</span>
                            </el-col>
                            <el-col :span="4">
                                <el-button type="text" icon=" el-icon-plus" @click="appendColumn(node)"></el-button>
                                <el-button type="text" icon=" el-icon-close" @click="removeColumn( node)"></el-button>
                            </el-col>
                        </el-row> 
                      </span>
                    </el-tree>
                   
                    <el-button type="text" icon="el-icon-plus" @click="addOrUpdateColumn()"></el-button>
                </div>
            </el-collapse-item>
            <ColumnAddOrUpdate :dataColumns="dataColumns" :dataSourceType="selectItem.dataSource.type" :tableColumns="tableColumns" v-if="columnVisisble" ref="columnset" @add="addDataColumn" @update="updateDataColumn" />
          </template>
           
        </BaseProperties>
        
    </div> 
</template>
<script>
import BaseProperties from '../../../../config/data-properties.vue'
import ColumnAddOrUpdate from './add-or-update-column'
import { getDataByDatasource } from '../../../../config/common/datasource.js'

export default {
    name: "HbiTableProperties",
    components: {
        ColumnAddOrUpdate,
        BaseProperties
    },
    data() {
        return {
            columnVisisble: false, 
            datasetKey: 1,
              

            tableColumns: [] // 可选择的字段列表
        }
    }, 
    computed: {
        recordOptions() { 
            if(this.selectItem ) {
                const options = {...this.selectItem.dataSource } 
                return options 
            }
            return null
        },
    },
    watch: {
        recordOptions: { //深度监听，可监听到对象、数组的变化
            handler() {
                this.updateTableColumn()
            },
            deep: true 
        },
    },
    props: {
        selectItem: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        
    },
    methods: {
        updateTableColumn() {
            this.tableColumns = []
             console.log('ddddddddddddd111')

            if(this.selectItem && this.selectItem.dataSource) {

                 getDataByDatasource(this.selectItem , {}).then((data)=> {
                    // 这里应该是列表 取出第一行数据 的所有key
                    if(data && data.length > 0) {
                         const first = data[0]



                        this.tableColumns = Object.getOwnPropertyNames(first).map(t=> {
                            return {value: t , label: t}
                         })

                         console.log('tableColumns' , this.tableColumns)
                     }   

                }).catch(e=> {
                    if(e) {
                        this.$message.error(e)
                        console.error(e)
                    } 
                }) ;
            }
           
        },
        init() { 
            if (!Object.prototype.hasOwnProperty.call(this.selectItem, 'columns')) {
                this.$set(this.selectItem, 'columns', [])
            } else {
                // 迭代columns 判断每个节点是否由ID 没有ID 回填ID 
                const fs = (n)=> {
                    if(n instanceof Array) {
                        n.forEach(t=> fs(t))
                    } else { 
                        if(!Object.prototype.hasOwnProperty.call(n, 'id')) {
                            this.$set(n , 'id' , this.generateId())
                        }

                        if(n.children && n.children.length > 0) {
                            n.children.forEach(t=> fs(t))
                        }
                    }
                }

                fs(this.selectItem.columns)

            }  

            if(this.selectItem && this.selectItem.dataSource && (this.selectItem.dataSource.type != 2)) {
                this.updateTableColumn()
            }
        },
        generateId(){
            return new Date().getTime() + parseInt(Math.random() * 1000000)
        },
        // 追加字段 
        appendColumn(node){
            this.columnVisisble = true
             

            this.$nextTick(() => {
                this.$refs.columnset.init(null, node.data)
            })
        },
        removeColumn(node) { 

            const parent = node.parent 
            if(!parent) {
                // 顶层节点 直接从数组中删除
                let columns = this.selectItem.columns

                columns = columns.filter((val) => val && val.id != node.data.id)

                this.$set(this.selectItem, 'columns', columns)
            } else {
                console.log('parent' , parent)
                const pdata = parent.data 
                // 从parent中删除
                if(pdata instanceof Array) {
                    let columns = pdata
                      
                    const index = columns.findIndex(d => d.id === node.data.id);
                    columns.splice(index, 1);

                } else {
                    let columns = pdata.children
                    columns = columns.filter((val) => val && val.id != node.data.id)

                    this.$set(pdata, 'children', columns) 
                }  
 
            }
            this.datasetKey++

        },
        addDataColumn(data , parent) { 
            // 给一个id 
            data.id = this.generateId()
            if(!parent) {
                // 顶层节点 直接从数组中删除
                let columns = this.selectItem.columns

                columns.push(data)

                this.$set(this.selectItem, 'columns', columns)
            } else {
                console.log('parent' , parent)
                // 从parent中删除
                if(parent instanceof Array) {
                    let columns = parent
                    columns.push(data)


                } else {
                    let columns = parent.children
                    if(columns == undefined || columns == null) {
                        columns = [data]
                    } else {
                        columns.push(data)
                    }

                    this.$set(parent, 'children', columns)
                } 
                
            }

            this.datasetKey++


        },
        updateDataColumn(data , parent) { 
            const index = data.index
  

            if(!parent) {
                // 顶层节点 直接从数组中删除  
                let columns = this.selectItem.columns

                columns[index] = data

                this.$set(this.selectItem, 'columns', columns)
            } else {
                // 从parent中删除
                let columns = parent instanceof Array ? parent : parent.children

                columns[index] = data 

                this.$set(parent, 'children', columns)
            }

            this.datasetKey++
        },
        addOrUpdateColumn(node) {
            this.columnVisisble = true


            let row = null
            let parentData = null
            if (node) {
                row = node.data 

                const parent = node.parent;
                const children = parent.data.children || parent.data;
                const index = children.findIndex(d => d.id === node.data.id);
               
                row.index = index

                parentData = parent.data 
            }

            this.$nextTick(() => {
                this.$refs.columnset.init(row , parentData)
            })
        }
    }
};
</script>
<style lang="scss">
.dataset-item {
    .el-form-item__label {
        width: 100%;
    }
}

.hbi-avue-form {
    .avue-group {
        .el-collapse-item__header {
            margin-bottom: 0px;

            .avue-group__header {
                height: 30px;
                line-height: 30px;

                .avue-group__title {
                    font-size: 13px;
                }
            }
        }
    }

}

.hbi-avue-form-pie {
    .pie-radius {

        .el-input__inner,
        input {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }

}

.table-column-tree {
    .el-col {
        margin-bottom: 0px;
    }
}

</style>