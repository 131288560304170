<!--
表单控件属性设置组件,因为配置数据是引用关系，所以可以直接修改
-->
<template>
  <BaseProperties ref="baseProperties" :selectItem="selectItem" :selectItemKey="selectItem.key"
                  v-if="selectItem && selectItem.type ">
    <template slot="mapAreaColors">
      <div v-for="(item, index) in mapColors" :key='index'>
        <el-row :span="24">
          <el-col :span="22">
            <avue-input-color v-model="mapColors[index]" placeholder="请选择颜色"></avue-input-color>
          </el-col>
          <el-col :span="2" style="padding-left: 5px">
            <el-button type="text" icon="el-icon-close" @click="removeColor(index)"></el-button>
          </el-col>
        </el-row>
      </div>
      <el-button type="text" icon="el-icon-plus" @click="addMapColor()"></el-button>
    </template>
    <template slot="map">
      <div>
        <div class="el-input-group">
          <avue-input :disabled="true" v-model="map.mapName"></avue-input>
          <avue-input :disabled="true" v-show="false" v-model="map.mapCode"></avue-input>
          <div class="el-input-group__append" style="cursor:pointer;" @click="selectMap">
            <span>+</span>
          </div>
        </div>
        <el-dialog
            title="地图选择"
            :close-on-click-modal="false"
            :modal-append-to-body="false"
            :append-to-body="true"
            :visible.sync="mapShow"
        >
          <div class="dialogDiv">
            <el-tree ref="tree" :data="data" empty-text='加载中……'
                     node-key="value" :current-node-key='nodeKey' :check-on-click-node="false"
                     highlight-current show-checkbox check-strictly
                     @node-click="handleNodeClick"
                     @check-change="handleCheckChange"></el-tree>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" icon="el-icon-check" @click="submit('ruleForm')">确定</el-button>
            <el-button type="primary" icon="el-icon-close" @click="closeDialog">关闭</el-button>
          </div>
        </el-dialog>
      </div>
    </template>
  </BaseProperties>
</template>
<script>
import BaseProperties from '../../../config/data-properties.vue'
import {getMapTree} from "@/api/avueData/map";

export default {
  name: "HbiChartMapProperties",
  components: {
    BaseProperties
  },
  data() {
    return {
      mapColors: [],
      nodeKey: '',
      mapShow: false,
      map: {
        mapName: '陕西省',
        mapCode: '610000'
      },
      data: []
    }
  },
  created(){
    this.loadMapTree();
  },
  methods: {
    init() {
      this.mapColors = this.selectItem.mapLayout.mapAreaColors
    },
    addMapColor() {
      this.mapColors.push('#ffffff')
    },
    removeColor(index) {
      this.mapColors = this.mapColors.filter(function (value, i) {
        return i !== index;
      });
    },
    selectMap() {
      this.mapShow = true;
    },
    handleNodeClick(){},
    handleCheckChange(data, checked){
      if(checked){
        this.$refs.tree.setCheckedNodes([data]);
      }
    },
    submit(){
      const selectNode = this.$refs.tree.getCheckedKeys()[0];
      const selectNodeName = this.$refs.tree.getCheckedNodes()[0];
      this.map.mapCode = selectNode;
      this.map.mapName = selectNodeName.label;
      this.mapShow = false;
    },
    closeDialog(){
      this.mapShow = false;
    },
    loadMapTree(){
      getMapTree().then(res => {
        const result = res.data.data;
        this.data = [
          {
            value: '',
            label: '全国',
            children: result
          }
        ];
      });
    }
  },
  watch: {
    mapColors: {
      handler() {
        this.selectItem.mapLayout.mapAreaColors = this.mapColors
      }
    },
    'map.mapCode': {
      handler() {
        this.selectItem.mapLayout.map = this.map.mapCode
      }
    }
  },
  props: {
    selectItem: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
