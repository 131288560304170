<template> 
<div class="form-panel"  @click.prevent ="handleSelectItem(null)">  
	<p class="hint-text" v-if="isDrag && (!data || !data.list || data.list.length === 0)">
	  从右侧组件列表选择组件添加
	</p>
	<el-form 
	  :label-width="data.config.labelWidth + 'px'" 
	  class="form-build form-design"
	  :class="{'hbi-design-mobile' : data.config && data.config.mobile && (isDrag || renderPreview), 'preview': renderPreview  }"
	  :label-position="data.config ? data.config.labelPosition : 'left'"
	  :hide-required-asterisk="data.config ? data.config.hideRequiredMark : false" 
	  ref="form"  
	  :style="data.config ? data.config.customStyle : ''" 
	  :size="data.config ? data.config.size : 'mini'" 
      :rules="rules"
      :model="models"    
      
	>
	<el-row class="mobile_canvas_top" v-if="data.config.mobile && data.config.mobile == true && (isDrag || renderPreview)" > </el-row>

	<div class="panel" :style="{backgroundColor: data.config.backgroundColor || '#FFFFFF'}"> 

	  <el-row class="grid-row" 
	  	:gutter="data.config ? data.config.gutter : 10" 
	  	style="margin: 0px;" 
	  	:type="data.config && data.config.flex ? 'flex' : undefined" 
	  	:justify="data.config && data.config.flex && data.config.justify ? data.config.justify : 'start'" 
	  	:align="data.config && data.config.flex && data.config.align ? data.config.align : undefined">
		<draggable
		  v-if="isDrag"
		  tag="div"
		  class="draggable-box"
		  v-bind="{
			group: 'form-draggable',
			ghostClass: 'moving',
			animation: 180,
			handle: '.drag-move'
		  }"
		 :force-fallback="true"
		  v-model="data.list"  
		  @add="deepClone"
		  @start="dragStart($event, data.list)"
		>
		  <transition-group tag="div" name="list" class="list-main">
			<HbiNode
			  class="drag-move"
			  v-for="record in data.list"
			  :isDrag="isDrag"
			  :key="record.key"
			  :record="record"
			  :config="data.config"
			  :selectItem.sync="selectItem"
			  :startType="startType"
			  :dragType="dragType"
			  :hideModel="hideModel"
			  @dragStart="dragStart"

			  @handleSelectItem="handleSelectItem"
			  @handleCopy="handleCopy"
			  @handleDetele="handleDetele"
			 
			/>
		  </transition-group>
		</draggable>
		<template v-else>
			<HbiNode 
			  v-for="record in data.list"
			  :key="record.key"
			  :record="record"
			  :config="data.config" 
   
			  :isDrag="false"
	          @handleReset="reset()"
	          @change="$emit('handleChange') "
	          @forceUpdate="$emit('forceUpdate')" 
	          :disabled="disabled"
	          :renderPreview="renderPreview"
	          :models.sync="models"  
	          :title="record.options && record.options.tooptip ? record.options.tooptip : null" 
	          @submit="$emit('submit')"
	          @reset="reset()"
			 
			/>
		</template>
	  </el-row>
	   <el-row class="mobile_canvas_bottom" v-if="data.config.mobile && data.config.mobile == true && (isDrag || renderPreview)"></el-row>
	</div>
	</el-form> 
  </div>	 
</template>
<script>
//import HbiPanel from './item/index' 
import draggable from "vuedraggable"; 
import HbiNode from "./node";
import cloneDeep from 'lodash/cloneDeep'
export default {
	components:{
		//HbiPanel 
		draggable,
		HbiNode
	},
	data(){
		return {
			form: this.$refs.form,
			updateTime: 0,
			hideModel: false,  
			startType: "", 
			selectItem: {}, 
			rules: {}
		}
	},
	props: {
		data: {
			type: Object ,
			required: true
		},
		selectForm: {
			type: Object
		},
		// 当前正在拖拽的组件类型
		dragType: {
			type: String
		},
		// 当前是否拖拽面板
		isDrag: {
			type: Boolean,
			default: true
		},
		models: {
	      type: Object ,
	      default: ()=>{return {}}
	    },
	    renderPreview: {
	    	type: Boolean,
	    	default: false
	    },
	    disabled: {
      		type: Boolean,
      		default: false
    	}, 
	}, 
	methods: {
		handleSetSelectItem(record) {
			if(!this.isDrag) return 
		  // 操作间隔不能低于100毫秒
		  let newTime = new Date().getTime();
		  if (newTime - this.updateTime < 100) {
			return false;
		  }

		  this.updateTime = newTime;

		  // 设置selectItem的值
		  this.selectItem = record; 

		  this.$emit('changeSelectItem' , this.selectItem)

		  // 判断是否选中控件，如果选中则弹出属性面板，否则关闭属性面板
		  if (record && record.type) {
			this.startType = record.type; 
		  }  
		},
	   	deepClone(evt) { 
		  const newIndex = evt.newIndex; 

		  // json深拷贝一次
		  // const listString = JSON.stringify(this.data.list);
		  // this.data.list = JSON.parse(listString);
		  this.data.list = cloneDeep(this.data.list)
		  // 删除icon及compoent属性
		  
		  if(this.data.list && this.data.list.length > newIndex) {
			delete this.data.list[newIndex].icon;
			delete this.data.list[newIndex].component;
			delete this.data.list[newIndex].properties
			this.handleSetSelectItem( this.data.list[newIndex])
			 
		  }
		  
		},  
		dragStart(evt, list) {  
		  // 拖拽结束,自动选择拖拽的控件项
		  this.handleSetSelectItem(list[evt.oldIndex])
		  
		},
		handleSelectItem(record) {
		  // 修改选择Item 
		   this.handleSetSelectItem(record)
		   
		},
		handleCopy(isCopy = true, data) { 
		  const traverse = array => {
			array.forEach((element, index) => { 
			  if (element.key === this.selectItem.key) {
				if (isCopy) {
				  // 复制添加到选择节点后面
				  array.splice(index + 1, 0, cloneDeep(element)); 
				} else {
				  // 双击添加到选择节点后面
				  array.splice(index + 1, 0, cloneDeep(data));
				}
				// 复制完成，重置key值
				const evt = {
				  newIndex: index + 1
				};
				this.handleColAdd(evt, array, true);
				return;
			  }
			   
			
			});
		  };
		  traverse(this.data.list);
		},
		handleColAdd(evt, columns, isCopy = false) {
		  // 重置或者生成key值
		  const newIndex = evt.newIndex;
		 // if(!this.columns || this.columns.length < newIndex ){
		 //   return
		 // }
		  const key = columns[newIndex].type + "_" + new Date().getTime();
		  if (columns[newIndex].key === "" || isCopy) {
			this.$set(columns, newIndex, {
			  ...columns[newIndex],
			  key,
			  model: key
			});
			// if (this.noModel.includes(columns[newIndex].type)) {
			//   // 删除不需要的model属性
			//   delete columns[newIndex].model;
			// }
			if (typeof columns[newIndex].options !== "undefined") {
			  // 深拷贝options
			  const optionsStr = JSON.stringify(columns[newIndex].options);
			  columns[newIndex].options = JSON.parse(optionsStr);
			}
			if (typeof columns[newIndex].rules !== "undefined") {
			  // 深拷贝rules
			  const rulesStr = JSON.stringify(columns[newIndex].rules);
			  columns[newIndex].rules = JSON.parse(rulesStr);
			}
			if (typeof columns[newIndex].list !== "undefined") {
			  // list 不为空 则重置list下的组件model
			  columns[newIndex].list.forEach(t=>{
				t.model = t.model + 1
				t.key = t.key + 1
			  })
			}
			if (typeof columns[newIndex].columns !== "undefined") {
			  // 深拷贝columns
			  const columnsStr = JSON.stringify(columns[newIndex].columns);
			  columns[newIndex].columns = JSON.parse(columnsStr);
			  // 复制时，重置key和model
			  columns[newIndex].columns.forEach(item => {
				if(item.list && item.list.length > 0) {
					item.list.forEach(t => {
					  t.model = t.model + 1
					  t.key = t.key + 1
					});
				  }
				
			  });
			}
			
		  }
		  // 深拷贝数据
		  const listString = JSON.stringify(columns[newIndex]);
		  columns[newIndex] = JSON.parse(listString);
		  this.handleSetSelectItem(columns[newIndex]) 
		},
		handleDetele() {
			// 删除已选择  
			const array = this.data.list 

			for(let i = 0 ; i < array.length ; i++) {
				const element = array[i]

				if(element.key == this.selectItem.key) {

					// 
					if (array.length === 1) {
						this.handleSelectItem({ key: "" });
					} else if (array.length - 1 > i) {
						this.handleSelectItem(array[i + 1]);
					} else {
						this.handleSelectItem(array[i - 1]);
					}

					this.data.list.splice(i , 1)
					break
				}
			}

			 
			// console.log('length1' , this.data.list.length)
			// console.log('keys' , this.data.list.map(t=>t.key) , this.selectItem.key)
			// this.data.list = traverse(this.data.list);
			// 	console.log('length2' , this.data.list.length)
		},
		reset() {
	      // 重置表单
	      this.$refs.form.resetFields();
 

	      this.$emit('reset')

	    },
	     
	}
}
</script>