<!--
DataTabs数据组件
-->
<template>
  <BaseProperties ref="baseProperties" :selectItem="selectItem" :selectItemKey="selectItem.key" v-if="selectItem && selectItem.type ">

  </BaseProperties>
<!--  <div>-->
<!--    <el-collapse v-model="actives">-->
<!--      <el-collapse-item title="样式" :name="1">-->
<!--        <hd-form :config="form.config" :prop="form.prop" :record="selectItem" :columns="form.column"-->
<!--                 class="hbi-avue-form">-->
<!--          <template slot="margin">-->
<!--            <el-row v-if="selectItem.style && selectItem.style.margin && selectItem.style.margin.length == 4">-->
<!--              <el-col :span="6">-->
<!--                <el-input title="top" v-model="selectItem.style.margin[0]"/>-->
<!--              </el-col>-->
<!--              <el-col :span="6">-->
<!--                <el-input title="right" v-model="selectItem.style.margin[1]"/>-->
<!--              </el-col>-->
<!--              <el-col :span="6">-->
<!--                <el-input title="bottom" v-model="selectItem.style.margin[2]"/>-->
<!--              </el-col>-->
<!--              <el-col :span="6">-->
<!--                <el-input title="left" v-model="selectItem.style.margin[3]"/>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--          </template>-->
<!--        </hd-form>-->
<!--      </el-collapse-item>-->
<!--      <el-collapse-item title="外观" :name="2">-->
<!--        <el-form size="mini">-->
<!--          <el-form-item label="标题">-->
<!--            <el-input v-model="selectItem.options.title"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="数据">-->
<!--            <el-input v-model="selectItem.options.count"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="单位">-->
<!--            <el-input v-model="selectItem.options.unit"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="描述">-->
<!--            <el-input v-model="selectItem.options.description"></el-input>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
<!--      </el-collapse-item>-->
<!--    </el-collapse>-->
<!--  </div>-->
</template>
<script>
import BaseProperties from '../../../config/data-properties.vue'

export default {
  name: "HbiDataTabsProperties",
  components: {
    BaseProperties
  },
  data() {
    return {
      options: {},
      actives: [1],
      datasetKey: 1,
      form: {
        prop: 'style',
        config: {
          size: 'mini',
          labelWidth: 70,
        },
        column: [{
          label: '字体颜色',
          tip: '点击选择背景颜色',
          type: 'color',
          prop: 'color',
        },{
          label: '标题字体大小',
          tip: '标题字体大小',
          prop: 'titleSize',
          type: 'number',
          default: 13
        },{
          label: '标题加粗',
          tip: '标题加粗',
          prop: 'label',
          type: "switch",
          default: false,
          span: 24,
        },{
          label: '标题文字颜色',
          prop: 'titleColor',
          tip: '点击选择标题文字颜色',
          type: 'color',
          default: '#000000',
          span: 24,
        },{
          label: '内容字体大小',
          tip: '内容字体大小',
          prop: 'contentSize',
          type: 'number',
          default: 33
        },{
          label: '背景颜色',
          prop: 'backgroundColor',
          tip: '点击选择背景颜色',
          type: 'color',
          default: '#FFFFFF',
          span: 24,
        },{
          label: '背景图片',
          prop: 'backgroundImage',
          span: 24,
        },{
          label: '边距',
          prop: 'margin',
          default: ['0', '0', '0', '0'],
          tip: 'top right bottom left',
          span: 24,
        }, {
          label: '圆角',
          prop: 'itemBorderRadius',
          type: 'number',
          min: 0,
          max: 45,
          default: 0,
          span: 24,
        },{
          label: '高度',
          prop: 'height',
          type: 'number',
          min: 50,
          max: 1000,
        }
        ]
      }
    };
  },
  watch: {
    selectItem(val) {

      this.options = val.options || {}

    }
  },
  methods: {
    init() {

    },
  },
  props: {
    selectItem: {
      type: Object,
      required: true
    },
  }
};
</script>