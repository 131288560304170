<template>
  <div>
    <el-dialog
      :title="opts.title"
      :visible.sync="dialogVisible"
      width="435px"
      top="8vh"
      center:false
      :before-close="handleClose"
      style="z-index:9999;"
      @open="open"
      append-to-body
      class="cloud-dept-select"
    >
      <el-input
        placeholder="请输入搜索内容"
        clearable
        v-model="filterText"
        suffix-icon="el-icon-search"
        size="small"
      ></el-input>
      <el-tree
        class="filter-tree"
        ref="tree"
        :data="treeNode"
        :props="treeProps"
        node-key="id"
        check-strictly
        @check="singleCheck"
        :highlight-current="false"
        show-checkbox
        :default-expanded-keys="treeExpandData"
        :default-checked-keys="opts.checkedIds"
        :filter-node-method="filterNode"
        @node-expand="nodeExpands"
        style="height:400px;overflow:auto;"
      >
        <span slot-scope="{ node, data }" class="span-ellipsis">
          <i :class="[data.icon]"></i>
          <span :title="node.label" :class="[data.type]">{{ data.name }}</span>
          <span class="all-expend-button" v-if="data.id == '1'">
            <el-button size="big" type="text" @click="foldAll" :title="allEnpandInfo">
              <i :class="allEnpandIcon"></i>
            </el-button>
          </span>
        </span>
      </el-tree>
      <div slot="footer">
        <el-button @click="handleClose">{{ '取消' }}</el-button>
        <el-button type="primary" @click="handelConfirm">{{ '确认' }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {orgTreeByPid as getOrgTree} from '@/api/sys/sysorg.js'; 
export default {
  name: 'org-select',
  //初始化数据
  props: {
    dialogVisible: {
      type: Boolean,
      required: true
    },
    options: {
      type: Object,
      required: false
    },
  },
  data() {
    return {
      //默认参数
      opts: {
        title: '请选择部门', //标题
        checkedIds: [] //默认选中的角色列表
      },
      allEnpandInfo: '展开全部',
      allEnpandFlag: true,
      allEnpandIcon: 'all_enpand_icon el-icon-circle-plus',
      treeExpandData: [],
      filterText: '',
      treeNode: [],
      treeData: [],
      treeProps: {
        id: 'id',
        children: 'children',
        label: 'name',
        disabled: 'disabled',
        checked: 'checked'
      },
      id: '1',
      root: ''
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  //方法
  methods: { 
    // 复选改单选处理
    singleCheckChange(data, checked, indeterminate) {
        if (checked) {
            this.$refs.tree.setCheckedKeys([data.id]);
        }
    },
    // 复选改单选处理
    singleCheck(data, indeterminate) {
        this.$refs.tree.setCheckedKeys([data.id]);
    },
    nodeExpands(data, node, el) {
      let _this = this;
      let checkeds = this.$refs.tree.getCheckedKeys();
      node.childNodes.forEach(element => {
        if (_this.opts.checkedIds.indexOf(element.data.id) != -1 || _this.opts.checkedIds.indexOf(element.data.id) != -1) {
          checkeds.push(element.data.id);
        }
      });

      this.$refs.tree.setCheckedKeys(checkeds);
    },
    open() {
      Object.assign(this.opts, this.options);
      var _that = this;
      if(_that.opts.treeData) {
        let da = _that.opts.treeData;
        da.forEach(treeNode => {
          //默认展开“组织树”
          _that.treeExpandData.push(treeNode['id']);
        }); 
        _that.treeNode = da;
        
        // 展开所有选中的节点
        if(_that.checkedIds) {
          _that.checkedIds.forEach(checkedId => {
            _that.treeExpandData.push(checkedId);
          });
        }
      } else {
        getOrgTree().then(({data}) => {
          const res = data; 
          if (res && res.code === 0) { 
            let da = res.data;
            da.forEach(treeNode => {
              //默认展开“组织树”
              _that.treeExpandData.push(treeNode['id']);
            }); 
            _that.treeNode = da;
          } else {
            console.error(`[${res.code}]${res.msg}`);
          }
        }).catch((err) => {
          console.log(err);
          this.$message.error(err)
        }).finally(_ => {
        }) 
      }
    }, 
    //tree中的filter-node-method为检索提供服务
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    //全部展开/全部合并
    foldAll() {
      var allEnpandFlag = this.allEnpandFlag;
      let allNodes = this.$refs.tree.store._getAllNodes();
      for (var i = 0; i < allNodes.length; i++) {
        allNodes[i].expanded = allEnpandFlag;
      }
      if (this.allEnpandFlag == true) {
        this.allEnpandFlag = false;
        this.allEnpandIcon = 'all_enpand_icon el-icon-remove';
        this.allEnpandInfo = '全部合并';
      } else {
        this.allEnpandFlag = true;
        this.allEnpandIcon = 'all_enpand_icon el-icon-circle-plus';
        this.allEnpandInfo = '全部展开';
        this.treeExpandData.push('1');
      }
    },
    handelConfirm() {
      this.reset();
      var selectRows = this.$refs.tree.getCheckedNodes();
      this.$emit('dialog-save', selectRows);
    },
    handleClose() {
      this.reset();
      this.$emit('dialog-close');
    },
    reset() {
      this.filterText = '';
    }
  }
};
</script>
<style scoped>
.el-button--mini,
.el-button--small {
  border-radius: 2px;
}
.el-tree {
  font-size: 12px;
  background-color: #f3f5f7;
}
.el-dialog__body {
  padding: 16px 24px !important;
}
</style>
