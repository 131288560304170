<!--
柱状图 或者线图
-->
<template>
<div :style="{'height': record.layout.height + 'px'}" v-loading="loading"> 
	 <EChart  :noTool="!record.layout.toolbar"  :options="options" >

	</EChart>  

</div>
</template>
<script>
import EChart from '../../EChart'
import { getChartLegend } from '../../../config/common/legend.js' 
 
import { getChartGrid } from '../../../config/common/grid.js'
import { getDataByDatasource } from '../../../config/common/datasource.js'
export default {
	components: {
		EChart
	},
	data() {
		return {
			loading: false,
			dataList: [], 
			xData: [],
			yData: [],
      options:{}
		}
	},
	props: {
		 // 表单数组 
	    record: {
	      type: Object,
	      required: true
	    },
	    // form-item 宽度配置
	    formConfig: {
	      type: Object,
	      required: false
	    },
	    // form-item 宽度配置
	    models: {
	      type: Object,
	      required: true
	    }, 
	    disabled: {
	      type: Boolean,
	      default: false
	    } ,
	      // 是否预览结果表单
	    renderPreview: {
	      type: Boolean ,
	      default: false
	    } 
	},
	mounted(){ 
	    this.getData()
	},
	computed: {
		recordOptions() {
			 
			// const options = {} 
			// for(let i in this.record) {
			// 	options[i] = {...this.record[i]}
			// }
			const options = {...this.record.layout,...this.record.style , ...this.record.grid , ...this.record.legend}
			return options
		 
		}
	},	
	watch: {
		recordOptions:{//深度监听，可监听到对象、数组的变化
	        handler(){  
	           	this.init()
	        },
	        deep:true,
	        immediate: true
	    },
	},
	methods: {
		getData() { 

	      	this.loading = true
	      	getDataByDatasource(this.record , this.models).then((data)=> {
	      		this.xData = data.xData
	      		this.yData = data.yData
	      		this.init(data.xData , data.yData)
	      	}).catch(e=> {
	      		if(e) {
	      			this.$message.error(e)
	      			console.error(e)
	      		}
	      		
	      		this.init()
	      	}).finally(()=>{
	        	this.loading = false;
	      	});

		},
		init() {  
 

			//const color = this.record.layout.color
			const title = this.record.layout.title 
			const titlePosition = this.record.layout.titlePosition || 'center'
			const fontColor = this.record.layout.fontColor
			const roseType = this.record.layout.roseType
		 
			const toolbarShow = this.record.layout.toolbar
			const chartLegend = getChartLegend(this.record.legend)
			const chartGrid = getChartGrid(this.record.grid)

			let radius = this.record.layout.radius
			const borderRadius = this.record.layout.borderRadius

			if(radius){
				radius = radius.map(t=> t + '%')
			}

		 	

			let data = []
			if(this.yData && this.xData && this.xData.length > 0 && this.yData.length > 0) {
				data = []
				this.xData.forEach((t,index)=> {  
					data.push({name: t , value: this.yData[index]})
				})
			}
 
			this.options =  {
                title : {
                    text:  title,
                    x: titlePosition,
                    textStyle : {
						color : fontColor 
					},
                },
                tooltip : {
                    trigger: 'item',
                    formatter: "{a} <br/>{b} : {c} ({d}%)"
                }, 
                legend: chartLegend,
                grid: chartGrid ,
                toolbox: {
                        show : toolbarShow,
                        right:30,
                        feature : {
                            //mark : {show: true},
                            dataView : {show: true, readOnly: true},
                            saveAsImage : {show: true}
                           
                        }
                    },
                series : [
                    {
                        name: title,
                        type: 'pie',
					    radius: radius,
					    center: ['50%', '50%'],
					    roseType: roseType,
					    itemStyle: {
					        borderRadius: borderRadius
					    },
                        data: data,
                         
                    }
                ]
            }  
		 
			  
		}
	   
	}
}
</script>