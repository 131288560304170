<!-- 表单控件属性设置组件,因为配置数据是引用关系，所以可以直接修改 -->
<template>
  <div v-if="selectItem && selectItem.type">
    <el-collapse v-model="actives">
      <template v-for="(form,formIndex) in formOptions ">
        <el-collapse-item v-if="!form.custom && showCollapse(form)" :key="formIndex" :title="form.label" :name="formIndex + 1">
          <hd-form :config="form.config" :prop="form.prop" :record="selectItem" :columns="form.column" class="hbi-avue-form">
            <!-- 组件边距 配置 top right bottom left  -->
            <template slot="margin">
              <el-row v-if="selectItem.style && selectItem.style.margin && selectItem.style.margin.length == 4">
                <el-col :span="6">
                  <el-input title="上边距" v-model="selectItem.style.margin[0]" />
                </el-col>
                <el-col :span="6">
                  <el-input title="右边距" v-model="selectItem.style.margin[1]" />
                </el-col>
                <el-col :span="6">
                  <el-input title="下边距" v-model="selectItem.style.margin[2]" />
                </el-col>
                <el-col :span="6">
                  <el-input title="左边距" v-model="selectItem.style.margin[3]" />
                </el-col>
              </el-row>
            </template>
            <!-- 数据模型 -->
            <template slot="dataModelId" v-if="selectItem.data">
              <SelectDataModel
                ref="dataModel"
                v-model="selectItem.data.dataModelId"
                :name="selectItem.data.dataModelName"
                @selectChange="changeDataModel" />
            </template>
            <!-- 维度 -->
            <template slot="xColumn" v-if="selectItem.data">
              <el-select v-model="selectItem.data.xColumn" placeholder="请选择">
                <el-option
                  v-for="item in dataColumns"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
            <!-- 指数 -->
            <template slot="yColumn" v-if="selectItem.data">
              <el-select v-model="selectItem.data.yColumn" placeholder="请选择">
                <el-option
                  v-for="item in dataColumns"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
            <!-- 分组 -->
            <template slot="groupBy" v-if="selectItem.data">
              <el-select v-model="selectItem.data.groupBy" multiple placeholder="请选择">
                <el-option
                  v-for="item in dataColumns"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
            <!-- 排序 -->
            <template slot="orderBy" v-if="selectItem.data">
              <!-- 排序修改为 字段+排序方法 -->
              <el-row :gutter="8">
                <div class="option-change-box" v-for="(val, index) in selectItem.data.orderBy" :key="index">
                  <el-col :span="10">
                    <el-select v-model="val.column" placeholder="请选择">
                      <el-option
                        v-for="item in dataColumns"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <el-select v-model="val.orderType" placeholder="请选择">
                      <el-option label="asc" value="asc"> </el-option>
                      <el-option label="desc" value="desc"> </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="5">
                    <i class="el-icon-delete" @click="handleOrderDelete(index)" />
                  </el-col>
                </div>
                <el-col :span="24">
                  <el-button type="text" icon="el-icon-plus" @click="handleOrderAdd()"></el-button>
                </el-col>
              </el-row>
            </template>

            <template slot="dataStatic">
              <el-button type="text" @click="handleConfigDataStatic()">静态数据配置</el-button>
            </template>
            <template slot="dataSql">
              <el-button type="text" @click="handleConfigDataSql()">SQL数据配置</el-button>
            </template>
            <template slot="dataApi">
              <el-button type="text" @click="handleConfigDataApi()">API数据配置</el-button>
            </template>

            <template :slot="c.prop" v-for="c in form.column">
              <slot :name="c.prop" :dataColumns="dataColumns"></slot>
            </template>

          </hd-form>
        </el-collapse-item>
        <el-collapse-item v-if="form.prop == 'filter'  && showCollapse(form)" :key="formIndex + 'filter'" title="数据过滤" name="dataFilter">
          <DataFilterConfig ref="dataFilter" v-model="selectItem.filter" :dataColumns="dataColumns" />
        </el-collapse-item>
        <slot :name="form.prop" :dataColumns="dataColumns"></slot>
      </template>
    </el-collapse>

    <DataStaticConfig ref="dataStatic" v-if="dataStaticVisible" @change="changeDataStatic" />
    <DataSQLConfig ref="dataSql" v-if="dataSqlVisible" @change="changeDataSql" />
    <DataAPIConfig ref="dataApi" v-if="dataApiVisible" @change="changeDataApi" />
  </div>
</template>
<script>
//import DatasetAddOrUpdate from './add-or-update-dataset'
import SelectDataModel from '@/components/hbi/datamodel/index.vue'
import DataFilterConfig from '@/components/hbi/datamodel/filter-config/index.vue'
import DataStaticConfig from './datasource/dataStatic.vue'
import DataSQLConfig from './datasource/dataSql.vue'
import DataAPIConfig from './datasource/dataApi.vue'

import { dynamicFun } from '../../utils'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: "HbiChartProperties",
  components: {
    /* DatasetAddOrUpdate , */
    SelectDataModel,
    DataFilterConfig,
    DataStaticConfig,
    DataSQLConfig,
    DataAPIConfig
  },
  data () {
    return {
      actives: [1],
      datasetKey: 1,
      options: {},
      activeName: [1],
      dataColumns: [],
      itemConfig: {}, // 所有组件的formConfig 配置
      datasetVisisble: false,
      formOptions: [],

      // 静态数据配置
      dataStaticVisible: false,
      //sql 配置
      dataSqlVisible: false,
      //api 配置
      dataApiVisible: false,

    }
  },
  computed: {

  },
  watch: {
    selectItemKey: {
      handler () {
        this.formOptions = this.initFormOptions()
        this.$nextTick(() => {
          this.initDataColumns()
        })
      },
      deep: true,
      immediate: true
    }
  },
  created () { },
  methods: {
    initFormOptions () {
      const currentType = this.selectItem.type
      const configs = this.$hbiItemConfig
      if (configs && configs[currentType]) {
        const tformOptions = cloneDeep(configs[currentType].formOptions)

        let config_ = { ...tformOptions.config }

        const groups = tformOptions.group
        const this_ = this
        groups.forEach(t => {
          t.config = config_
          const prop = t.prop
          // 判断当前整个组的prop是否有值
          if (!this_.selectItem[prop]) {
            this_.$set(this_.selectItem, prop, {})
          }

          // 如果找到新的column有默认值 当前配置中没有值 则回填 
          const groupColumn = t.column
          if (groupColumn) {

            groupColumn.filter(gf => gf.default).forEach(gc => {
              // 判断column如果有默认值，但当前data没有值 则回填
              //if(!this_.selectItem[prop][gc.prop]) {
              if (!Object.prototype.hasOwnProperty.call(this_.selectItem[prop], gc.prop)) {
                this_.$set(this_.selectItem[prop], gc.prop, gc.default)
              }
            })
          }
        })
        return groups
      }
      return []
    },
    init () {
      this.options = this.selectItem.options || {}
      this.initDataColumns()
    },
    /** 静态数据配置 */
    handleConfigDataStatic () {
      this.dataStaticVisible = true
      this.$nextTick(() => {
        const data = {
          dataStatic: this.selectItem.dataSource.dataStatic,
          dataStaticType: this.selectItem.dataSource.dataStaticType,
          dataStaticScript: this.selectItem.dataSource.dataStaticScript
        }

        this.$refs.dataStatic.init(data)
      })
    },
    /** 更新静态数据配置 */
    changeDataStatic (data) {
      this.$set(this.selectItem.dataSource, 'dataStatic', data.dataStatic)
      this.$set(this.selectItem.dataSource, 'dataStaticType', data.dataStaticType)
      this.$set(this.selectItem.dataSource, 'dataStaticScript', data.dataStaticScript)
      this.dataStaticVisible = false
    },
    /** 打开sql数据配置 */
    handleConfigDataSql () {
      this.dataSqlVisible = true
      this.$nextTick(() => {
        this.$refs.dataSql.init(this.selectItem.dataSource.dataSourceId, this.selectItem.dataSource.dataSql, this.selectItem.dataSource.dataFormatter, this.selectItem.dataSource.dataSqlParams)
      })
    },
    /** 更新sql设置 */
    changeDataSql ({ dataSourceId, sqlText, dataFormatter, dataSqlParams }) {
      //dataSourceId: this.dataForm.dataSourceId , sqlText: this.dataForm.sqlText , dataFormatter: this.dataForm.formatter
      this.$set(this.selectItem.dataSource, 'dataSourceId', dataSourceId)
      this.$set(this.selectItem.dataSource, 'dataSql', sqlText)
      this.$set(this.selectItem.dataSource, 'dataFormatter', dataFormatter)
      this.$set(this.selectItem.dataSource, 'dataSqlParams', dataSqlParams)
      this.dataSqlVisible = false
    },
    /** 打开api设置 */
    handleConfigDataApi () {
      this.dataApiVisible = true
      this.$nextTick(() => {
        const data = {
          apiPath: this.selectItem.dataSource.apiPath,
          apiMethod: this.selectItem.dataSource.apiMethod,
          apiHeaders: this.selectItem.dataSource.apiHeaders,
          apiParams: this.selectItem.dataSource.apiParams,
          apiDataFormatter: this.selectItem.dataSource.apiDataFormatter
        }
        this.$refs.dataApi.init(data)
      })
    },
    /** 更新api设置信息 */
    changeDataApi ({ apiPath, apiMethod, apiHeaders, apiParams, apiDataFormatter }) {
      this.$set(this.selectItem.dataSource, 'apiPath', apiPath)
      this.$set(this.selectItem.dataSource, 'apiMethod', apiMethod)
      this.$set(this.selectItem.dataSource, 'apiHeaders', apiHeaders)
      this.$set(this.selectItem.dataSource, 'apiParams', apiParams)
      this.$set(this.selectItem.dataSource, 'apiDataFormatter', apiDataFormatter)
      this.dataSqlVisible = false
    },
    initDataColumns () {
      //if(this.selectItem.data.dataModelId) { 
      if (this.$refs.dataModel) {
        this.$refs.dataModel[0].getDataColumns().then(data => {
          this.dataColumns = data
        })
      }
    },
    /** 计算当前form是否需要显示 */
    showCollapse (form) {
      if (form.show == undefined || form.show == true) return true
      if (typeof form.show == 'string') {
        return dynamicFun(form.show, this.selectItem)
      }
      return true
    },
    changeDataModel (row) {
      const name = row.name

      this.$set(this.selectItem.data, 'dataModelName', name)

      this.$nextTick(() => {
        this.initDataColumns()
      })

    },
    // 排序数据新增
    handleOrderAdd () {
      // 添加栅格Col
      let addData = [
        ...this.selectItem.data.orderBy,
        {
          column: '',
          orderType: 'asc'
        }
      ];
      this.$set(this.selectItem.data, 'orderBy', addData)
    },
    // 排序数据删除
    handleOrderDelete (deleteIndex) {
      // 删除
      const orderData = this.selectItem.data.orderBy.filter((val, index) => val && index !== deleteIndex)
      this.$set(this.selectItem.data, 'orderBy', orderData)


    }
  },
  props: {
    selectItem: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selectItemKey: {
      type: String,
      required: true
    },
    // formOptions: {
    // 	type: Object,
    // 	required: true
    // }
  }
};
</script>
<style lang="scss">
.dataset-item {
  .el-form-item__label {
    width: 100%;
  }
}

.hbi-avue-form {
  .avue-group {
    .el-collapse-item__header {
      margin-bottom: 0px;

      .avue-group__header {
        height: 30px;
        line-height: 30px;

        .avue-group__title {
          font-size: 13px;
        }
      }
    }
  }
}
</style>