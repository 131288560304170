/**
 * 全站路由配置
 *
 * 建议:
 * 1. 代码中路由统一使用name属性跳转(不使用path属性)
 */
import Vue from "vue";
import Router from "vue-router";
import http from "@/utils/httpRequest";
import { isURL } from "@/utils/validate";
import { clearLoginInfo, urlQueryParse , getToken} from "@/utils";

import i18n from '@/i18n'

Vue.use(Router);

const locale = i18n.locale
const msg = i18n.messages[locale]

// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
const _import = require("./import-" + process.env.NODE_ENV);

// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [
  {
    path: "/redirect",
    component: _import("common/redirect"),
    name: "redirect",
    meta: { isLogin: false }
  },
  {
    path: "/404",
    component: _import("common/404"),
    name: "404",
    meta: { title: "404未找到", isLogin: false }
  },
  {
    path: "/login",
    component: _import("common/login"),
    name: "login",
    meta: { title: "登录", isLogin: false }
  },
  {
    path: "/stat",
    component: _import("modules/stat/home2"),
    name: "stat",
    meta: { title: "可视化", isLogin: false }
  },
  {
    path: "/vueedit",
    component: _import("modules/sys/vue-edit"),
    name: "vueedit",
    meta: { title: "vue-edit", isLogin: false }
  },
  {
    path: '/hbi/report/edit',
    component: () =>
        import('@/views/modules/hbi/report/edit'),
    name: 'hbi_report_edit',
    meta: {
      title: '报表模板编辑',
      isLogin: true,
      noMenu: true
    }
  },
  {
    path: '/hbi/report/preview',
    component: () =>
        import('@/views/modules/hbi/report/preview'),
    name: 'hbi_report_preview',
    meta: {
      title: '报表模板预览',
      isLogin: true,
      noMenu: true
    }
  },

  {
    path: '/view/:id',
    name: 'view',
    component: () =>
        import( /* webpackChunkName: "page" */ '@/views/modules/avueData/view')
  },
  {
    path: '/build/:id',
    name: 'build',
    component: () =>
        import( /* webpackChunkName: "page" */ '@/views/modules/avueData/build')
  },
  {
    path: '/hbi/app',
    component: () =>
        import('@/views/modules/hbi/app/index'),
    name: 'hbi_app_home',
    meta: {
      title: 'app',
      isLogin: true,
      noMenu: true
    }
  },
  {
    path: '/hbi/app/preview',
    component: () =>
        import('@/views/modules/hbi/app/preview'),
    name: 'hbi_app_preview',
    meta: {
      title: 'app模板预览',
      isLogin: true,
      noMenu: true
    }
  }
];

// 主入口路由(需嵌套上左右整体布局)
const mainRoutes = {
  path: "/",
  component: _import("main"),
  name: "main",
  redirect: { name: "home" },
  meta: { title: msg.notFound.home },
  children: [
    // 通过meta对象设置路由展示方式
    // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
    // 2. iframeUrl: 是否通过iframe嵌套展示内容, "以http[s]://开头": 是, "": 否
    // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
    {
      path: "/home",
      component: _import("common/home"),
      name: "home",
      meta: { title: msg.notFound.home, isLogin: true, breadcrumb: false }
    },
    {
      path: "/userinfo",
      component: _import("common/userinfo"),
      name: "userinfo",
      meta: { title: "个人信息", isLogin: true, breadcrumb: false }
    }
  ],

};

const router = new Router({
  mode: "history",
  base: "/",
  scrollBehavior: () => ({ y: 0 }),
  isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
  routes: globalRoutes.concat(mainRoutes)
});

router.beforeEach((to, from, next) => {
  // next() ;
  // 添加动态(菜单)路由
  // 1. 已经添加 or 全局路由, 直接访问
  // 2. 获取菜单列表, 添加并保存本地存储
  //  console.log("isAddDynamicMenuRoutes", router.options.isAddDynamicMenuRoutes)

  if (to.name == 'login' ||
    router.options.isAddDynamicMenuRoutes ||
    (to.meta && to.meta.isLogin != null && to.meta.isLogin == false)
  ) {
    next();
  } else {
    let token = getToken();
    // 判断账号是否已经登录

    if (!token || token.length < 1) {
      next({ name: "login" });
      return;
    }

    http({
      url: http.adornUrl("/sys/menu/nav"),
      method: "get",
      params: http.adornParams()
    })
      .then(({ data }) => {
        if (data && data.code === 0) {
          fnAddDynamicMenuRoutes(data.data.menuList);
          router.options.isAddDynamicMenuRoutes = true;
          sessionStorage.setItem(
            "menuList",
            JSON.stringify(data.data.menuList || "[]")
          );
          sessionStorage.setItem(
            "permissions",
            JSON.stringify(data.data.permissions || "[]")
          );

          next({ ...to,replace: true});
        } else {
          sessionStorage.setItem("menuList", "[]");
          sessionStorage.setItem("permissions", "[]");
          next({ name: "login" });
        }
      })
      .catch(err => {
        console.log("error", err);
        //  clearLoginInfo()
        next({ name: "login" });
        // reject(err);
      });
  }
});

/**
 * 根据菜单生产路由
 2020-06-30 修改 按钮不再创建路由 路由只创建到菜单一级
 */
function createRoutes(menuList = [], routes = []) {
  // console.log("menuList" , menuList)

  for (var i = 0; i < menuList.length; i++) {
    const menu = menuList[i];

    // 类型不是菜单和按钮得直接跳过去
    if (menu.type < 1) {
      if (menu.list && menu.list.length > 0) {
        createRoutes(menu.list, routes);
      }

      continue;
    }
    // 2目前为保留 无意义
    if (menu.type == 2) continue;

    // 3 是按钮 目前先不创建路由 页面全部改为弹框 2020-06-30
    if (menu.type == 3) continue;

    // 2022-05-18 lyf 判断路由是否存在 存在则跳过
    const has_ = mainRoutes.children.filter(t=>t.name == menu.routeName)

    if(has_ && has_.length > 0) {
      continue ;
    }

    let app = menu.app;
    let url = menu.url;

    const query = url && url.indexOf("?") > 0 ? urlQueryParse(url) : null;

    if (query && url) {
      //console.log("url1" , url)
      url = url.substring(0, url.indexOf("?"));
      //console.log("query" , query)
      //console.log("url" , url)
    }

    // 菜单和按钮级别必然要添加路由
    //console.log("menu", menu)
    let route = {
      path: url ? url : menu.routeName,
      component: null,
      props: null,
      name: menu.routeName,
      meta: {
        menuId: menu.menuId,
        title: menu.name,
        componentUrl: menu.componentUrl,
        isDynamic: true,
        isTab: false,
        query: query,
        iframeUrl: "",
        isMicro: false,
        microName: 'main'
      }
    };

    // 如果有url则挂载组件
    if (url) {
      if (isURL(menu.url)) {
        route["path"] = `i-${menu.menuId}`;
        route["name"] = `i-${menu.menuId}`;
        route["meta"]["iframeUrl"] = url;
      } else {
        try {
          //console.log("url", menu.url)
          if (menu.componentUrl && (!menu.app || menu.app === 'main') ) {
            route["component"] =
              _import(`modules/${menu.componentUrl}`) || null;
          } else if (app && app !== 'main') {
            // 如果有应用标识，注册到微前端子应用
            route["props"] = { microName: app , componentUrl: menu.componentUrl , path: menu.url};
            route["component"] =  _import(`microapp/MicroApp`);
            route["meta"]["isMicro"] = true;
            route["meta"]["microName"] = app;
          } else {
            continue;
          }
        } catch (e) {
          console.log("error", menu);
          console.log("load component ", e);
          continue;
        }
      }
    } else {
      continue;
    }

    

    // console.log("route", route)
    routes.push(route);
  }
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes(menuList = [], routes = []) {
  createRoutes(menuList, routes);
  // if (temp.length >= 1) {
  //   fnAddDynamicMenuRoutes(temp, routes)
  //  } else {
  // mainRoutes.name = "main-dynamic"

  mainRoutes.children = mainRoutes.children.concat(routes);
  //  console.log("mainRoutes", mainRoutes)
  router.addRoutes([mainRoutes, { path: "*", redirect: { name: "404" } }]);
  sessionStorage.setItem(
    "dynamicMenuRoutes",
    JSON.stringify(mainRoutes.children || "[]")
  );

  if (process.env.NODE_ENV === "development") {
    console.log("\n");
    console.log(
      "%c!<-------------------- 动态(菜单)路由 s -------------------->",
      "color:blue"
    );
    console.log(mainRoutes.children);
    console.log(
      "%c!<-------------------- 动态(菜单)路由 e -------------------->",
      "color:blue"
    );
  }
}

/* 解决 NavigationDuplicated 控制台报错 */
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router;
