<!--
 * author lyf
 * date 2020-07-06
 * description 可拖拽元素的列表 包含基础组件和布局组件
 --> 
<template>
	<!-- <el-card header="组件列表" class="box-card form-item "> -->
		<el-collapse v-model="actives" accordion >
			<!-- inputArray:: {{inputArray}} -->

			<template v-for="(colItem,colIndex) in dataList">
				<el-collapse-item :key="colIndex" :title="colItem.name" :name="colIndex + 1" v-if="colItem && colItem.list && colItem.list.length > 0">
			  		<DragItem 
		                :list="itemInitArray(colItem.list)"
		                @generateKey="generateKey"
		                @handleListPush="handleListPush"
		                @start="handleStart"
		                @end="handleEnd"/>
			  	</el-collapse-item>
			</template> 
		</el-collapse> 
	<!-- </el-card> -->
</template>
<script>
import itemIndex from "../../hbi-item/index.js";
import DragItem from './item'
export default {
	components: {
		DragItem
	},
	data(){
		return {
			actives:[1], 
		    startType: "",
		    dataList: itemIndex ,
		    data: {
		        list: [],
		        config: {
		          layout: "horizontal",
		          labelCol: { span: 4 },
		          wrapperCol: { span: 18 },
		          hideRequiredMark: false,
		          customStyle: ""
		        }
		      },
		      previewOptions: {
		        width: 850
		    }, 
		    selectItem: {
		       key: ""
		    } 
		}
	}, 
	methods: {
		// 组件初始化
		itemInitArray(list = []) {
			 
	    	if(list && list.length > 0) {
	    		list.forEach(t=>{
		      		t.options.dynamicVisible = false
	        		t.options.dynamicVisibleValue = ''
	        		if(!t.key) { 
	        		 	const key = t.type + "_" + new Date().getTime()
	        		 	t['key'] = key 
	        		 	t['model'] = key
	        		}
		      	}) ;

	    	} 
	    	
	      	return list
		},
		generateKey(list, index) {
	      // 生成key值 
	      const key = list[index].type + "_" + new Date().getTime();
	      this.$set(list, index, {
	        ...list[index],
	        key,
	        model: key
	      });
	      // if (this.noModel.includes(list[index].type)) {
	      //   // 删除不需要的model属性
	      //   delete list[index].model;
	      // }
	    },
	    handleListPush(item) {
	      // 双击控件按钮push到list
	      // 生成key值 
	      if (!this.selectItem.key) {
	        // 在没有选择表单时，将数据push到this.data.list
	        const key = item.type + "_" + new Date().getTime();
	        item = {
	          ...item,
	          key,
	          model: key
	        };
	       
	        const itemString = JSON.stringify(item);
	        const record = JSON.parse(itemString);
	        // 删除icon及compoent属性
	        delete record.icon;
	        delete record.component;
	        delete record.properties
	        this.data.list.push(record);
	       
	        this.$emit('handleSetSelectItem' , record)
	        return false;
	      }
	     
	    },
	    handleStart(list,index) {
	    	this.generateKey(list,index)
	      	const type = list[index].type
	      	this.startType = type;
 

	      	this.$emit('handleDragType' , type)

	    },
	    handleEnd(){
	    	this.startType = null
	    	this.$emit('handleDragType' , null)
	    }
	}
}

</script> 