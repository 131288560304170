// 所有需要全局装载的组件在这里进行
import Vue from "vue";

import IconSvg from "./icon-svg";

import sysDict from "./dict/dict.vue";
import sysDictSelect from "./dict/dictSelect.vue";
import sysDictRadio from "./dict/dictRadio.vue";
import sysDictCheckbox from "./dict/dictCheckbox.vue";
import RegionTree from './region/region-tree.vue'
import RegionTreeJurisdiction from './region/region-tree-jurisdiction.vue'

import organzition from './organization/organzition.vue'
import orgIndex from './organization/index.vue'
 

import user from './user/index.vue'


import HdForm from './hd-form/index.vue'


// 代码编辑器
import CodeEditor from './code-editor/index.vue'

// hbi 动态可视化组件
import HbiDesign from './hbi/index'
// 注册组件库
Vue.use(HbiDesign)
Vue.component("HdForm", HdForm);

Vue.component("IconSvg", IconSvg);
Vue.component("code-editor", CodeEditor);
Vue.component("sysDict", sysDict);
Vue.component("sysDictSelect", sysDictSelect);
Vue.component("sysDictRadio", sysDictRadio);
Vue.component("sysDictCheckbox", sysDictCheckbox);

Vue.component("region-tree", RegionTree);
Vue.component("region-tree-jurisdiction", RegionTreeJurisdiction);

Vue.component("sysOrg", organzition);
Vue.component("org", orgIndex);

Vue.component("user", user);


