<template>
    <div :style="{
  color: record.style && record.style.color ? record.style.color : '',
 
  width: '200px',
  height: record.layout && record.layout.height ? record.layout.height : '',
  backgroundImage: record.style && record.layout.backgroundImage ? 'url(' + record.layout.backgroundImage + ')' : '',
  }" style="background-size: 100% 100%;display: grid;">
        <span :style="{
        fontSize: (record.layout && record.layout.titleSize ? record.layout.titleSize:13)+'px',
        fontWeight: record.layout && record.layout.titleBold ? 'bold': '',
        color: record.layout && record.layout.titleColor ? record.layout.titleColor: '',
        }" style="margin: 15px 20px 0;">{{record.layout.title}}</span>
        <span>
            <span :style="{
                fontSize: (record.layout && record.layout.contentSize ? record.layout.contentSize:33)+'px',
            }" style="margin:20px">{{data}}</span>
            <span class="unit">{{record.layout.unit}}</span>
        </span>
        <span style="font-size: 11px;margin: 10px 20px 0;">{{record.layout.description}}</span>
    </div>
</template>
<script>
import { getDataByDatasource } from '../../../config/common/datasource.js'
export default {
    name: "hbi-data-tabs-item",
    data() {
        return {
            data: '',

        }
    },
    props: {
        // 表单数组
        record: {
            type: Object,
            required: true
        },
        // form-item 宽度配置
        models: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // 是否预览结果表单
        renderPreview: {
            type: Boolean,
            default: false
        },
        // 是否拖拽面板引用
        isDragPanel: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.getData()
    },
    computed: {

    },
    watch: {

    },
    methods: {
        getData() {
            this.loading = true
            getDataByDatasource(this.record, this.models)
                .then((data) => {
                    this.data = data
                     
                }).catch(e => {
                    if (e) {
                        this.$message.error(e)
                        console.error(e)
                    }
                   
                }).finally(() => {
                    this.loading = false;
                });
        }

    }
};
</script>