import http from '@/utils/httpRequest'
import { getTokenName , getToken } from '@/utils/index'

 
export function deleteFile(id) {
	return http({
            url: http.adornUrl(`/file/delete?id=${id}`),
            method: "get"
          })
}

export function getUploadUrl() {
  const tokenName = getTokenName()
  const token = getToken()
  return http.adornUrl(`/file/upload?${tokenName}=${token}`)
}


// 更新或新增
export function uploadFiles(data) {
    return http({
        url: http.adornUrl(
            `/file/uploads`
        ),
        method: "post",
        data: data
    })
}


// 更新或新增
export function uploadFile(data) {
    return http({
        url: http.adornUrl(
            `/file/upload`
        ),
        method: "post",
        data: data
    })
}

export function getDownUrl(id){
  return http.adornUrl(`/file/fileDown?uuid=${id}`) 
}

export function selectList(ids = []) {
  const ids_ = ids.join(',')
  return http({
        url: http.adornUrl(`/file/list?ids=` + ids_),
        method: "get",
        params: http.adornParams()
      })
}

 