<template>
  <!--  <span style="width: 24px;height: 24px;" :class="getClassName"   :width="width"  :height="height"></span>
  -->
 <!--  <svg :class="getClassName" :width="width" :height="height" aria-hidden="true">
    <use :xlink:href="getName"></use>
  </svg> -->
  <span :class="getClassName" :width="width" :height="height" aria-hidden="true"></span>
  <!-- <svg
    :class="getClassName"
    :width="width"
    :height="height"
    aria-hidden="true">
    <use :xlink:href="getName"></use>
  </svg> -->
</template>

<script>
export default {
  name: "icon-svg",
  props: {
    name: {
      type: String,
      required: true
    },
    className: {
      type: String
    },
    width: {
      type: String
    },
    height: {
      type: String
    }
  },
  computed: {
    getName() {
      if (this.name) {
        return (
          //"#" +
          (this.name.indexOf("icon-") == 0 ? this.name : "icon-" + this.name)
        );
      }
      return this.name;
    },
    getClassName() {
      return [
         'icon',
         `iconfont`,
        "icon-svg",
        this.getName && /\S/.test(this.getName) ? `${this.getName}` : ""
      ];
    }
  }
};
</script>

<style>
.icon-svg {
  width: 1em;
  height: 1em;
  font-size: 24px;
  fill: currentColor;
  overflow: hidden;
  visibility: visible !important;
}
</style>
