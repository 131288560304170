import http from '@/utils/httpRequest'

 

export function getList(params) {
  return http({
          url: http.adornUrl('/log/list'),
          method: 'get',
          params: params
        })
}