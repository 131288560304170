/* 数据字典的资源 */
export default {
    /* 模块名字 */
    name: 'management',

    /* 英文资源 */
    en_US: {
        Name:'Name',
        name:'Name',
        number:'Number',
        datatype:'Data type',
        classification:'Data classification',
        materials:'Filing method of materials',
        operation:'operation',
        refresh:'Refresh',
        add:'add',
        parent:'Parent menu',
        query:'Query',
        delete:'Delete',
        click:'Click to select the upper menu',
        pleaseselect:'Please select',
        filingmethod:'Filing method',
        process:'Process Classification',
        cancel:'cancel',
        determine:'determine',
        nameempty:'Name cannot be empty',
        processempty:'Process classification cannot be empty',
        dataempty:'Data type cannot be empty',
        archivingempty:'The archiving method cannot be empty',
        classificationempty:'Data classification cannot be empty',
        serialempty:'Serial number cannot be empty',
        documentname:'Document Name',
        categoryparent:'Category parent',
        category:'Permission Category',
        look:'Look',
        download:'Download',
        preservation:'Preservation',
        selectAll:'Select All',
        cancellation:'Total cancellation',
        queryy:'query',
        filtering:'Enter keywords for filtering',
        role:'role',
        projectdata:'Project data',
        dailydata:'Daily data',
        projectname:'Project name',
        itemno:'Item No',
        projecttype:'Project Type',
        filemanagement:'File management',
        navigation:'File Content Navigation',
        classi:'Classification',
        uploader:'Uploader',
        processStatus:'Process Status',
        borrowing:'Apply for borrowing',
        details:'Approval Details',
        dataname:'Data name',
        operator:'Operator',
        operatortime:'Operation time',
        filetype:'file type',
        datacontent:'Data content',
        enclosure:'enclosure',
        close:'close',
        archiving:'Direct archiving',
        approval:'Approval and filing',

    },

    /* 中文资源 */
    zh_CN: {
        Name:'姓名',
        name:'名称',
        number:'序号',
        datatype:'资料类型',
        classification:'资料分类',
        materials:'材料归档方式',
        operation:'操作',
        refresh:'刷新',
        add:'新增',
        parent:'上级菜单',
        query:'修改',
        delete:'删除',
        click:'点击选择上级菜单',
        pleaseselect:'请选择',
        filingmethod:'归档方式',
        process:'流程分类',
        cancel:'取消',
        determine:'确定',
        nameempty:'名称不能为空',
        processempty:'流程分类不能为空',
        dataempty:'资料类型不能为空',
        archivingempty:'归档方式不能为空',
        classificationempty:'资料分类不能为空',
        serialempty:'序号不能为空',
        documentname:'文档名称',
        categoryparent:'上级分类',
        category:'权限类别',
        look:'查看',
        download:'下载',
        preservation:'保存',
        selectAll:'全选',
        cancellation:'全消',
        queryy:'查询',
        filtering:'输入关键字进行过滤',
        role:'角色',
        projectdata:'项目资料',
        dailydata:'日常资料',
        projectname:'项目名称',
        itemno:'项目编号',
        projecttype:'项目类型',
        filemanagement:'档案管理',
        navigation:'档案内容导航',
        classi:'所属分类',
        uploader:'上传人员',
        processStatus:'流程状态',
        borrowing:'申请借阅',
        details:'审批详情',
        dataname:'资料名称',
        operator:'操作人',
        operatortime:'操作时间',
        filetype:'文件类型',
        datacontent:'资料内容',
        enclosure:'附件',
        close:'关闭',
        archiving:'直接归档',
        approval:'审批归档',

    },
};
