export default {
    /*
    * 邀请函签证国际化资源
    * */
    name:'invitation',
    en_US:{
        number:'NO.',
        projectCode:'Project Code',
        projectName:'Project Name',
        projectType:'Project Type',
        operate:'Operate',
        invitationVisa:'Invitation Visa',
        query:'Query',
        name:'Name',
        unit:'Unit',
        idNumber:'ID Number',
        profession:'Profession',
        arriveStatus:'Arrive Status',
        englishName:'English Name',
        arriveProjectTime:'Arrive Project Date',
        estimatedArriveTime:'Estimated Arrive Date',
        remark:'Remark',
        look:'View',
        delete:'Delete',
        edit:'Edit',
        export:'Export',
        import:'Import',
        add:'Add',
        tip:'Tip',
        tips1:'Are you sure to perform ',
        tips2:' can not be empty!',
        back:'Back',
        cancel:'Cancel',
        confirm:'Confirm',
        planname:'Plan Name',
        planno:'Plan Code',
        changename:'Changed Name',
        changeno:'Changed Plan Code',
        changeamount:'Changed Plan Amount(Yuan)',
        fillingtime:'Filling time',
        fillingby:'Filled by',
        approvalstatus:'Approval status',
        approvaldetails:'Approval Details',
        submit:'Submit for review',
        currentactualname:'Current Actual Cost Name',
        actualplanno:'Actual plan No',
        actualamount:'Amount of current actual(Yuan)',
        totalplan:'Total plan(Yuan)',
        preparedby:'Prepared by',
        projectreporting:'Project reporting',
        startdate:'Start Date',
        enddate:'End Date',
        expenditure:'Total planned expenditure(Yuan)',
        planchangeno:'Plan change No',
        planchangename:'Plan change name',
        selectdate:'Select date',
        originalplanname:'Original plan name',
        expenditurechange:'Total planned expenditure after change',
        monthlyName:'Name of monthly fund plan',
        requestedpaymentamount:'Requested payment amount(Yuan)',
        confirmpayment:'Confirm payment',
        confirmed:'Confirmed',
        unconfirmed:'Unconfirmed',
        confir:'confirm',
        originalPlanAmount:'Original Plan Amount(Yuan)',
        close:'Close',
        details:'Details',
        totalApprovedPlannedExpenditure:'Total Approved Planned Expenditure(Yuan)',
        requiredUnit:'Required Unit',
        planTheme:'Plan Theme',
        planDate:'Plan Date',
    },
    zh_CN:{
        planDate:'计划日期',
        planTheme:'计划主题',
        requiredUnit:'需求单位',
        totalApprovedPlannedExpenditure:'计划支出审批总额(元)',
        details:'展开明细',
        close:'关闭',
        originalPlanAmount: '原计划总额(元)',
        number:'序号',
        projectCode:'项目编号',
        projectName:'项目名称',
        projectType:'项目类型',
        operate:'操作',
        invitationVisa:'邀请函签证',
        query:'查询',
        name:'姓名',
        unit:'所属单位',
        idNumber:'证件号码',
        profession:'工种',
        arriveStatus:'到岗状态',
        englishName:'英文名字',
        arriveProjectTime:'到项目时间',
        estimatedArriveTime:'预计到岗时间',
        remark:'备注',
        look:'查看',
        delete:'删除',
        edit:'修改',
        export:'导出数据',
        import:'导入',
        add:'新增',
        tip:'提示',
        tips1:'确定要进行',
        tips2:'不能为空',
        back:'返回上一页',
        cancel:'取消',
        confirm:'确定',
        planname:'计划名称',
        planno:'计划编号',
        changename:'变更后计划名称',
        changeno:'变更后计划编号',
        changeamount:'变更后计划总额(元)',
        fillingtime:'填报时间',
        fillingby:'填报人',
        approvalstatus:'审批状态',
        approvaldetails:'审批详情',
        submit:'提交审核',
        currentactualname:'当前实际成本名称',
        actualplanno:'实际计划编号',
        actualamount:'本次实际计划总额(元)',
        totalplan:'计划总额(元)',
        preparedby:'编制人',
        projectreporting:'项目上报',
        startdate:'开始日期',
        enddate:'结束日期',
        expenditure:'计划支出总额(元)',
        planchangeno:'计划变更编号',
        planchangename:'计划变更名称',
        selectdate:'选择日期',
        originalplanname:'原计划名称',
        expenditurechange:'变更后计划支出总额(元)',
        monthlyName:'月度资金计划名称',
        requestedpaymentamount:'申请支付金额(元)',
        confirmpayment:'是否确认支付',
        confirmed:'已确认',
        unconfirmed:'未确认',
        confir:'确认',

    }
}