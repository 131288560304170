export default {
    name:'equipment',
    /*
    * 设备发运情况国际化资源
    * */
    en_US:{
        transportBatch:'Transport Batch',
        contractCode:'Contract Code',
        supplier:'Supplier',
        statusOfGoods:'Status of Goods',
        export:'Export',
        import:'Import',
        query:'Query',
        delete:'Delete',
        add:'Add',
        back:'Back',
        deleteBatch:'Delete Batch',
        signTime:'Sign Time',
        goodsBuyer:'Goods/Buyer/Delivery Time',
        contact:'Contact',
        phoneNumber:'Phone Number',
        deliveryTime:'Delivery Time',
        estimatedTimeOfArrival:'Estimated Time Of Arrival',
        remark:'Remark',
        operate:' Operate',
        documentsHandover:'Documents Handover',
        edit:'Edit',
        confirm:'Confirm',
        cancel:'Cancel',
        equipmentShipped:'Equipment Shipped',
        tip:'Tip',
        tips1:'Are you sure to perform ',
        tips2:' can not be empty!',
    },
    zh_CN:{
        tips2:'不能为空',
        equipmentShipped:'设备发运情况',
        transportBatch:'运输批次',
        contractCode:'合同号',
        supplier:'供应商',
        statusOfGoods:'货物物流状态',
        export:'导出数据',
        import:'导入',
        query:'查询',
        delete:'删除',
        add:'新增',
        back:'返回',
        deleteBatch:'批量删除',
        signTime:'签收时间',
        goodsBuyer:'品名/采购人/交货期',
        contact:'联系人',
        phoneNumber:'联系电话',
        deliveryTime:'（合同）交货期',
        estimatedTimeOfArrival:'预计到港时间',
        remark:'备注',
        operate:'操作',
        documentsHandover:'进口单证交接（与业主）',
        edit:'修改',
        confirm:'确认',
        cancel:'取消',
        tip:'提示',
        tips1:'确定要进行',
    }
}