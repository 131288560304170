<template>
    <div>
        <avue-data-display :option="option"></avue-data-display>
    </div>
</template>
<script>
    import { getDataByDatasource } from '../../../config/common/datasource.js'
    export default {
        name: "hbi-data-display-item",
        data() {
            return {
                data: {},
                option: {}
            }
        },
        props: {
            // 表单数组
            record: {
                type: Object,
                required: true
            },
            // form-item 宽度配置
            models: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            // 是否预览结果表单
            renderPreview: {
                type: Boolean,
                default: false
            },
            // 是否拖拽面板引用
            isDragPanel: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            this.getData()
        },
        computed: {
            recordOptions() {
                const options = { ...this.record.layout }
                return options
            }
        },
        watch: {
            recordOptions: { //深度监听，可监听到对象、数组的变化
                handler() {
                    this.init()
                },
                deep: true
            },
        },
        methods: {
            getData() {
                this.loading = true
                getDataByDatasource(this.record, this.models)
                    .then((data) => {
                        console.log('data',data)
                        this.data = data
                        this.init()
                    }).catch(e => {
                    if (e) {
                        this.$message.error(e)
                        console.error(e)
                    }

                }).finally(() => {
                    this.loading = false;
                });
            },

            init() {
                // count: 100,
                // decimals:2,
                // title: '日活跃数',
                // href:'https://avuejs.com',
                // target:'_blank'
                // animation  是否动画  Boolean false/true  true
                // decimals  小数点位数 Number  — 0

                console.log('this.record.layout' , this.record.layout)
                const count = this.data.count || 0

                this.option = {
                    span: 24 ,
                    animation: this.record.layout.animation ,
                    data: [{
                        title: this.record.layout.title,
                        decimals: this.record.layout.decimals,
                        count: count,
                        href: this.record.layout.href
                        // key: '类'
                    }]
                }
            }

        }
    };
</script>