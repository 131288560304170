<template> 

	 	<div :id="chart_id" :class="className" :ref="chart_id" style="height:100%;width: 100%;">

		</div> 
	  
	
</template>
<script>
	 

	export default {
		props: ['options','id','className' , 'noTool' , 'click'],
		data(){
			return{
				chart_id : parseInt(Math.random() * 100000) + '-chart',
				myChart : null
			}
		},
		watch:{
	        options:{//深度监听，可监听到对象、数组的变化
	            handler(){
	               
	           		this.loadOption()
	            },
	            deep:true
	        },
	        containerSize: {
	        	handler(){
	                if(this.myChart)
                		this.myChart.resize();
	            },
	            deep:true
	        }
	    },
		created(){
			if(this.id && !this.chart_id){
				this.chart_id = this.id
			}  

			if(this.myChart)
               	this.myChart.resize();

		},
		computed: {
			containerSize(){
				// 容器大小
				//const height = document.getElementById(this.chart_id)['clientHeight']
				//const width = document.getElementById(this.chart_id)['clientWidth']
				const ref_ =  document.getElementById(this.chart_id) //this.$refs[this.chart_id]
				if(ref_) { 
					const width = ref_.offsetWidth
					const height = ref_.offsetHeight
					const size = width + '-' + height 

					return size 
				}
				
				return null
			}
		},
		mounted(){
			
			//setTimeout(function(){
			this.myChart = this.$echarts.init(document.getElementById(this.chart_id));

			this.myChart.setOption(this.options);
			      
			if(this.click) {
				let click_function = this.click 
				// 点击回调时间
				this.myChart.on('click', function(param) {
					  //   console.log(param);//重要的参数都在这里！
					click_function(param);
				});
			}
			

			this.$nextTick(()=> {
				this.listenerContainSize()
			})

			
 
	    },
	    methods:{
	    	listenerContainSize() {
	    		const this_ = this 
	    	 
				 //elementResizeDetectorMaker，该全局函数是使元素调整大小检测器实例的maker函数。
			    const elementResizeDetectorMaker = require("element-resize-detector")
			 
			    
			    // 创建实例带参数
			    // 使用基于超快速滚动的方法。
			    // 这是推荐的策略。
			    var erd = elementResizeDetectorMaker({
			          strategy: "scroll",
			          callOnAdd: true   //callOnAdd选项，用于确定在添加侦听器时是否应调用它们。默认为true。            
			        //如果为true，则确保在添加侦听器后将对其进行调用。如果为false，则在添加侦听器时将不保证其             
			    });
			      //监听class为staticNextMain的元素 大小变化
			 
			     //侦听元素的调整大小事件，并使用元素作为调整大小事件的参数来调用侦听器函数。传递给函数的选项将
			    const chart_ = document.getElementById(this.chart_id)     
			    if(chart_ && chart_.children && chart_.children.length > 0) {
			    	// 监听此div下的一个组件 

			    	//覆盖实例选项
			      	erd.listenTo(chart_.children[0], function() {
			        	if(this_.myChart)
	                		this_.myChart.resize();
			    	});
			    }
			     
	 
		     
		      	 
		     	window.addEventListener("resize", function () {
		     		if(this_.myChart)
	                	this_.myChart.resize();
	            });
	    	},
	    	loadOption() {

	    		//console.log('chart' , this.myChart , 'options' , this.options)

	    		if(!this.myChart) return

	    		if(!this.options) return
	    		/*	
	    		if(!this.noTool){
	    			
		    		let toolbox = this.options.toolbox
		    		if(!toolbox){
		    			toolbox = { show : true , right:30}
		    			this.options.toolbox = toolbox
		    		}

		    		const mytool = {
					                show: true,
					                title: '明细导出',
					                icon: 'path://M839.488 445.12l0.192 0L525.696 112.512c-8.128-8.128-21.44-8.128-29.568 0L184.768 444.928C182.144 446.976 180.48 449.536 180.48 452.416c0 6.848 8.96 12.288 20.224 12.288 0 0 0.064 0 0.192 0l0 0 156.928 0c11.84 6.72 27.008 19.456 32.384 42.24l0 129.664c0 23.168 18.816 41.856 41.92 41.856l159.744 0c23.104 0 41.856-18.688 41.856-41.856L633.728 501.504c4.032-8.704 13.632-26.112 29.632-36.8l166.144 0 0-0.64c8.256-1.6 14.144-6.144 14.144-11.648C843.648 449.664 842.112 447.168 839.488 445.12z" p-id="2035"></path><path d="M390.272 721.728l0 17.92c0 23.04 18.816 41.856 41.92 41.856l159.808 0c23.168 0 41.92-18.816 41.92-41.856l0-17.92L390.272 721.728z" p-id="2036"></path><path d="M960 721.728c-0.256-11.968-11.264-21.568-24.832-21.568-13.696 0-24.768 9.6-24.832 21.568l-0.832 0 0 102.976c0 28.864-23.36 52.288-52.224 52.288L166.08 876.992c-28.864 0-52.288-23.424-52.288-52.288l0-102.976L113.664 721.728c-0.064-11.968-11.136-21.568-24.832-21.568-13.568 0-24.576 9.6-24.832 21.568l0 0 0 91.2c0 57.856 46.656 104.704 104.256 104.704l687.488 0c57.536 0 104.256-46.784 104.256-104.704L960 721.728 960 721.728z',
					                onclick: function (){
					                    alert('导出明细到excel')
					                }
					            }

		    		if(!toolbox.feature){
		    			toolbox.feature={}
		    		} 
		    		if(!toolbox.feature.myTool1){
		    			toolbox.feature.myTool1 = mytool
		    		}

		    		//console.log(this.options)
	    		}*/

	    		 
	           
	           	if(this.myChart){
	           		this.myChart.setOption(this.options , true)
	           		const this_ = this
			      	setTimeout(function(){
			      		this_.myChart.resize();
			      	},10)

			      	
	           	}
	           		
	    		  
	    	}
	    }
	}

</script>
<style>

</style>
