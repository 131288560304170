<template>
    <el-dialog :title="!dataForm.id ? '新增' : '修改'" :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="visible">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="95px">
            
            <el-form-item label="字段" prop="column">
                <el-select v-model="dataForm.column" placeholder="请选择">
                    <el-option v-for="item in dataColumns" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="过滤方法" prop="filterType">
                <el-select v-model="dataForm.filterType" placeholder="请选择">
                    <el-option v-for="item in filterTypes" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="对比值类型"  prop="type">
                <el-radio v-model="dataForm.type" label="1">固定值</el-radio>
                <el-radio v-model="dataForm.type" label="2">表达式</el-radio>
            </el-form-item>
            <el-form-item label="对比值" prop="value">
                <el-input type="textarea" v-model="dataForm.value" placeholder="请输入" />
            </el-form-item>
           
            
        </el-form>
        <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
            <el-button @click="visible = false">取消</el-button>
            <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
export default {
    data() {
        return {
            visible: false,
            isOnSubmit: false,
            status: -1, // 1-新增 2-更新
            filterTypes: [{
                    label: '大于',
                    value: 'ge'
                },
                {
                    label: '小于',
                    value: 'less'
                },
                {
                    label: '等于',
                    value: 'eq'
                },
                {
                    label: '包含',
                    value: 'like'
                } 
            ], 
            dataForm: {
                index: '',
                column: '',
                filterType: '', // 过滤类型
                type: '', //  1-固定值 2-表达式
                value: '', //  值或者表达式
                 
            },
            dataRule: {
                column: [{ required: true, message: "字段不能为空", trigger: "blur" }],
                filterType: [{ required: true, message: "过滤类型不能为空", trigger: "blur" }],
                type: [{ required: true, message: "对比值类型不能为空", trigger: "blur" }], 
                value: [
                    { required: true, message: "对比值不能为空", trigger: "blur" }
                ]
            }
        };
    },
    props: {
      // 数据模型字段列表
      dataColumns: {
        type: Array
      }
    },
    methods: {
        init(row) { 
            this.visible = true;
            this.isOnSubmit = false;

            this.$nextTick(()=>{
              this.$refs["dataForm"].resetFields();
              if (row) {
                this.dataForm = { ...row };
                this.status = 2
              } else { 
                delete this.dataForm.index
                this.status = 1
              }
            })


        },
        // 表单提交
        dataFormSubmit() {
          const data = cloneDeep(this.dataForm)
            this.$refs["dataForm"].validate(valid => {
                if (valid) {
                    if (this.status == 1) {
                        this.$emit("add", data);
                    } else if (this.status == 2) {
                        this.$emit("update", data);
                    }

                    this.visible = false

                }
            });
        }
    }
};
</script>