export default {
    /* 模块名字 */
    name: 'deptmanager',

    /* 英文资源 */
    en_US: {
        deptName: 'Department Name',
        creditCode: 'CreditCode',
        orgId: 'Administrative division',
        confirmDelete: 'Are you sure you want to delete it?',
        addNewDept: 'Add Department',
        updateNewDept: 'Update Department',
        sort: 'sort',
        remark: 'remark',
        deptNameNotEmpty: 'department name not empty',
        creditCodeErr: 'wrong format! Please enter the 18-digit unified social credit code!',
        chooiceOrgId: 'Please select an administrative division',


    },

    /* 中文资源 */
    zh_CN: {
        deptName: '部门名称',
        creditCode: '统一社会信用代码',
        orgId: '行政区域',
        confirmDelete: '确定进行删除操作?',
        addNewDept: '新增部门信息',
        updateNewDept: '更新部门信息',
        sort: '排序',
        remark: '备注',
        deptNameNotEmpty: '部门名称不能为空',
        creditCodeErr: '格式错误！请输入18位统一社会信用代码！',
        chooiceOrgId: '请选择行政区域',


    }

}