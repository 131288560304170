export default {
  namespaced: true,
  state: {
    id: '0',
    name: '',
    regionId: '0',
    regionCode: '1000',
    gridId: '0',
    gridCode: '100001',
    gridIds: [],
    gridCodes: [],
    orgId: '',
    orgName: '',
    orgLevel: '',
    deptIds: [],
    deptId: '',
    deptName: '',
    deptType: '',
    accountId: '',
    adcode: '',
    type:'',
    parentId:'',
    parentCode:'',
    pname:'',
    parentType:''
  },
  mutations: {
    updateId(state, id) {
      state.id = id
    },
    updateName(state, name) {
      state.name = name
    },
    updateRegionId(state, regionId) {
      state.regionId = regionId
    },
    updateRegionCode(state, regionCode) {
      state.regionCode = regionCode
    },
    updateOrgId(state, orgId) {
      state.orgId = orgId
    },
    updateOrgName(state, orgName) {
      state.orgName = orgName
    },
    updateOrgLevel(state, orgLevel) {
      state.orgLevel = orgLevel
    },
    updateDeptId(state, deptId) {
      state.deptId = deptId
    },
    updateDeptIds(state, deptIds) {
      state.deptIds = deptIds
    },
    updateDeptName(state, deptName) {
      state.deptName = deptName
    },
    updateDeptType(state, deptType) {
      state.deptType = deptType
    },
    updateLevelType(state, levelType) {
      state.levelType = levelType
    },
    updateGridId(state, gridId) {
      state.gridId = gridId
    },
    updateGridCode(state, gridCode) {
      state.gridCode = gridCode
    },
    updateGridIds(state, gridIds) {
      state.gridIds = gridIds
    },
    updateGridCodes(state, gridCodes) {
      state.gridCodes = gridCodes
    },
    updateAccountId(state, accountId) {
      state.accountId = accountId
    },
    updateAdCode(state, adcode) {
      state.adcode = adcode
    },
    updateType(state, type) {
      state.type = type
    },
    updateParentId(state, parentId) {
      state.parentId = parentId
    },
    updateParentCode(state, parentCode) {
      state.parentCode = parentCode
    },
    updatePname(state, pname) {
      state.pname = pname
    },
    updateParentType(state, parentType) {
      state.parentType = parentType
    },

    /**
     * 设置用户的全部信息
     */
    setUser(state, userData) {
      state.userId = userData.userId
      state.userName = userData.username
      state.orgId = userData.orgId
      state.orgName = userData.orgName
      state.orgLevel = userData.orgLevel
      state.deptId = userData.deptId
      state.deptName = userData.deptName
      state.deptType = userData.deptType
      state.regionId = userData.regionId
      state.gridId = userData.gridId
      state.regionCode = userData.regionCode
      state.gridCode = userData.gridCode
      state.adcode = userData.adcode
      state.type = userData.type
      state.parentId=userData.parentId
      state.parentCode=userData.parentCode
      state.pname=userData.pname
      state.parentType=userData.parentType
      console.log('设置用户信息 = ',state," | 传递的值 = ",userData)
    }
  }
}
