// 定制组件参数的规范和转换 
import cloneDeep from 'lodash/cloneDeep'
  

// 配置数组转 配置项 
// 配置最多两层，不走迭代
export function translateConfig(config = []) {

    const fs = (v) => {
    	const formOptions = v.formOptions 

    	delete v.formOptions

    	formOptions.group.forEach(t=> {
    		if(t.label && t.prop && t.default != null && !t.column) {
    			v[t.prop] = t.default
    		} else if(t.column){
    			v[t.prop] = {}
    			t.column.filter(tf=>tf.prop).forEach(tc=> {
    				let jdefault = tc['default']
                    if (jdefault == undefined || jdefault == null) {
                        jdefault = ''
                    }

                    v[t.prop][tc.prop] = jdefault
    			}) 
    		}

    	})

      /*  for (let i in v) {
            const ivalue = v[i]
            if (!(ivalue instanceof Object)) continue

            // 有且仅有一个label的时候进程类型判断
            if (ivalue['label'] && ivalue['default']) {
                v[i] = ivalue['default']
            } else {
                // 下一层
                for (let j in ivalue) {

                    const jvalue = ivalue[j]
                    if (!(jvalue instanceof Object)) continue

                    if (!jvalue['label']) continue

                    let jdefault = jvalue['default']
                    if (jdefault == undefined || jdefault == null) {
                        jdefault = ''
                    }

                    v[i][j] = jdefault
                }

            }


        }*/

    }

    const cloneConfig = cloneDeep(config)
    cloneConfig.map(t => fs(t))

    return cloneConfig

}