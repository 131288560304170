<!--
散点图
-->
<template>
  <div :style="{'height': record.layout.height + 'px'}" v-loading="loading">
    <EChart :noTool="!record.layout.toolbar" :options="options">

    </EChart>

  </div>
</template>
<script>
import EChart from '../../EChart'
import {getChartLegend} from '../../../config/common/legend.js'

import {getChartGrid} from '../../../config/common/grid.js'
import {getDataByDatasource} from '../../../config/common/datasource.js'

export default {
  components: {
    EChart
  },
  data() {
    return {
      loading: false,
      dataList: [],
      series: [],
      options:{}
    }
  },
  props: {
    // 表单数组
    record: {
      type: Object,
      required: true
    },
    // form-item 宽度配置
    formConfig: {
      type: Object,
      required: false
    },
    // form-item 宽度配置
    models: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否预览结果表单
    renderPreview: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    recordOptions() {
      const options = {...this.record.layout,...this.record.style, ...this.record.grid, ...this.record.legend}
      return options

    }
  },
  watch: {
    recordOptions: {//深度监听，可监听到对象、数组的变化
      handler() {
        this.init()
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    getData() {
      this.loading = true
      let record = this.record
      getDataByDatasource(this.record, this.models).then((data) => {
        let d = data
        if (record.dataSource && record.dataSource.type === "2") {
          this.xData = data.xData
          this.yData = data.yData
          d = []
          let dataItem = {data:[]}
          data.xData.forEach((x, index) => {
            let i = []
            i.push(x)
            i.push(data.yData[index])
            dataItem.data.push(i)
          })
          d.push(dataItem)
        }
        this.series = d
        this.init()
      }).catch(e => {
        if (e) {
          this.$message.error(e)
          console.error(e)
        }

        this.init()
      }).finally(() => {
        this.loading = false;
      });

    },
    init() {
      const title = this.record.layout.title
      const titlePosition = this.record.layout.titlePosition || 'center'
      const fontColor = this.record.layout.fontColor

      const toolbarShow = this.record.layout.toolbar
      const chartLegend = getChartLegend(this.record.legend)

      if (this.series && this.series.length > 0) {
        let legendData = []

        this.series.forEach((s) => {
          s.type = 'scatter'
          if (s.data) {
            s.data.forEach((sd) => {
              legendData.push(sd.name)
            })
          }
        })

        if (chartLegend) {
          chartLegend.data = legendData
        }
      }


      this.options = {
        xAxis: {},
        yAxis: {},
        title: {
          text: title,
          x: titlePosition,
          textStyle: {
            color: fontColor
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.value[0] + ', ' + params.value[1]
          }
        },
        legend: chartLegend,
        toolbox: {
          show: toolbarShow,
          right: 30,
          feature: {
            dataView: {show: true, readOnly: true},
            saveAsImage: {show: true}

          }
        },
        series: this.series
      }


    }

  }
}
</script>