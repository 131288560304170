import http from '@/utils/httpRequest'


// 获取所有，用于在前端翻译码值
export function getAllList(params) {
  return http({
          url: http.adornUrl('/sys/sysorg/listAll'),
          method: 'get',
          params: params
        })
} 

// 列表获取
export function getList(params) {
  return http({
          url: http.adornUrl('/sys/sysorg/list'),
          method: 'get',
          params: params
        })
} 

// 删除数据
export function deleteTree(id = []) {
	return http({
            url: http.adornUrl("/sys/sysorg/delete"),
            method: "post",
            data: http.adornData(id, false)
          })
}

// 根据ID 获取
export function getById(id) {
	return http({
        url: http.adornUrl(`/sys/sysorg/info/${id}`),
        method: "get"
        })
}
 
// 更新或新增
export function saveOrUpdate(data) {
	return http({
            url: http.adornUrl(
              `/sys/sysorg/${!data.id ? "save" : "update"}`
            ),
            method: "post",
            data: data
        })
}

//组织左边的树，含组织和部门
export function getTree(params) {
    return http({
        url: http.adornUrl('/sys/sysorg/treeList'),
        method: 'post',
        params: params
    })
}

//组织左边的树，含组织和部门
export function treeByPid(params) {
  return http({
      url: http.adornUrl(`/sys/sysorg/treeByPid/${params}`),
      method: 'get',
  })
}
export function treeByPids(params) {
    return http({
        url: http.adornUrl(`/sys/sysorg/treeByPids/${params}`),
        method: 'get',
    })
}
// 仅返回组织的树
export function orgTreeByPid(params) {
  return http({
      url: http.adornUrl('/sys/sysorg/orgTreeByPid'),
      method: 'post',
      params: params
  })
}