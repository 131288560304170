var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.multiple)?_c('el-select',{style:(_vm.styleclass),attrs:{"multiple":"","multiple-limit":_vm.multipleLimit,"placeholder":_vm.placeholder,"clearable":_vm.clearable,"disabled":_vm.disabled,"filterable":_vm.filterable},on:{"change":_vm.changeSelect,"blur":_vm.handleBlur},model:{value:(_vm.dictForm.values),callback:function ($$v) {_vm.$set(_vm.dictForm, "values", $$v)},expression:"dictForm.values"}},[_vm._l((_vm.dicts),function(item){return [( (!_vm.excludeValue || (_vm.excludeValue.indexOf(item.name) < 0  && _vm.excludeValue.split(',').filter(t=>item.name.indexOf(t) >=0).length <= 0 && _vm.excludeValue.indexOf(item.value) < 0  && _vm.excludeValue.split(',').filter(t=>item.value.indexOf(t) >=0).length <= 0) )
          && (
            !_vm.includeValue || _vm.includeValue.indexOf(item.name) >= 0 || _vm.includeValue.indexOf(item.value) >= 0
            )
        )?_c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}}):_vm._e()]})],2):_c('el-select',{style:(_vm.styleclass),attrs:{"placeholder":_vm.placeholder,"clearable":_vm.clearable,"disabled":_vm.disabled,"filterable":_vm.filterable},on:{"change":_vm.changeSelect,"blur":_vm.handleBlur},model:{value:(_vm.dictForm.value),callback:function ($$v) {_vm.$set(_vm.dictForm, "value", $$v)},expression:"dictForm.value"}},[_vm._l((_vm.dicts),function(item){return [((!_vm.excludeValue || (_vm.excludeValue.indexOf(item.name) < 0  && _vm.excludeValue.split(',').filter(t=>item.name.indexOf(t)>=0).length <= 0 && _vm.excludeValue.indexOf(item.value) < 0  && _vm.excludeValue.split(',').filter(t=>item.value.indexOf(t) >=0).length <= 0))
          &&
         (
            !_vm.includeValue || _vm.includeValue.indexOf(item.name) >= 0 || _vm.includeValue.indexOf(item.value) >= 0
            )
          )?_c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}}):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }