/* 数据字典的资源 */
export default {
    /* 模块名字 */
    name: 'orglevel',

    /* 英文资源 */
    en_US: {
        orglevelEncoding: 'Encoding',
        orglevelLevelNo: 'Level No',
        orglevelGroupNo: 'Group Job Level No',
        orglevelAffiliated: 'Affiliated unit',
        orglevelPleaseAffiliated: 'Please select the company',
        orglevelType: 'Type',
        orglevelPleaseType: 'Please select a type',

    },

    /* 中文资源 */
    zh_CN: {
        orglevelEncoding: '编码',
        orglevelLevelNo: '级别序号',
        orglevelGroupNo: '集团职务级别号',
        orglevelAffiliated: '所属单位',
        orglevelPleaseAffiliated: '请选择所属单位',
        orglevelType: '类型',
        orglevelPleaseType: '请选择类型',


    },
};
