<template>
	 
	<el-tabs type="card" v-model="active" >
	    <el-tab-pane :label="selectItem && selectItem.key ? '组件配置' : '面板配置'" name="item">
	    	<ItemProperties v-if="selectItem && selectItem.key" :selectItem="selectItem" ref="itemProperties" >
	    		<template slot="custom-properties" :selectItem="selectItem"> 
                         <component 
                         	ref="customProperties"
                         	v-if="customPropertiesComponent"
					          :selectItem="selectItem"   
					          :is="customPropertiesComponent"> 
					        </component>  
                </template>
	    	</ItemProperties>
	    	<FormProperties v-else ref="formProperties"  
	    	 	:config="data.config"
	            :previewOptions="previewOptions"
	    	 >  
	    	</FormProperties>
	    </el-tab-pane> 
	    <!-- <el-tab-pane label="面板属性" name="form"> 
	    	<FormProperties ref="formProperties"  
	    	 	:config="data.config"
	            :previewOptions="previewOptions"
	    	 > 
	    		<template slot="form-extend-properties" :data="data">
                    <slot name="form-extend-properties" :data="data"></slot>
                </template> 
	    	</FormProperties>
	    </el-tab-pane>  -->

	    <slot name="extend-tab" :data="data"  >
	     	<!-- 扩展插槽,扩展对应要素属性 -->
	    </slot>

	  </el-tabs>
		
	 
</template>
<script>
import ItemProperties from './item-properties'
//import ItemProperties2 from './item-properties2'
import FormProperties from './form-properties'
//import { mapState } from "vuex";

// 获取个性化属性配置 

import BaesProperties from '../../hbi-item/input/base/properties'
import itemIndex from "../../hbi-item/index.js";
export default {
	components: {
		ItemProperties,FormProperties/*,ItemProperties2*/,BaesProperties
	},
	props: {
		data: {
			type: Object ,
			default : () => {}
		},
		selectItem: {
			type: Object,
			default: ()=>{}
		}
	},
	watch: {
	    selectItem(val) {
	      this.active = 'item'
	      if(val) {
	      	this.$nextTick(()=> {
	      		if(this.$refs.customProperties && this.$refs.customProperties.init) {
			      	this.$refs.customProperties.init()
			    }
	      	})
	      	
	      }  
	    }
	},
	computed: {

	    customPropertiesComponent() {
  			if(this.selectItem && this.selectItem.type) {
  				const selectItemType = this.selectItem.type   
	          	// 将数组映射成json
		        if(itemIndex && itemIndex.length > 0) {
		        	for(let i = 0 ; i < itemIndex.length ; i++) {
		        		const itemList = itemIndex[i]

		        		if(itemList.list && itemList.list.length > 0) {
			        		const fs = itemList.list.filter(t=>t.type == selectItemType)
				        	if(fs && fs.length > 0) {
				        		return fs[0].properties
				        	} 
			        	} 

		        	}
		        }
  			} 
	         
	        return null
	    }
	},
	data(){
		return{
			//selectItem: {}
			active: 'item',
			previewOptions: {
		        width: 850
		    },
		}
	}, 
	methods: {

	}
}
</script> 