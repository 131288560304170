<template>
    <el-form 
    ref="form"  
    class="hd-form"
    @submit.native.prevent 
    :model="record[prop]" 
    :label-suffix="config.labelSuffix" 
    size="mini" 
    label-position="right" 
    :label-width="(config.labelWidth || 80) + 'px'"
    >  
    	<template v-for="column in columns">
    		<!-- show: {{column.show}} {{column.show == undefined || column.show == true || columnVisible(column.show)}} -->

    		<el-form-item 
	        :prop="column.prop" 
	        :label="column.label" 
	        :rules="column.rules"   
	        :title="column.tip"
	        :key="column.prop" 
	        v-if="column.show == undefined || column.show == true || columnVisible(column.show)"
	        > 
		        <template slot="label" v-if="column.labelScript">
		        	{{getScriptValue(column.labelScript , column.label)}}
		        </template>
		        <slot :value="column.prop"
                          :column="column"
                          :label="column.prop"   
                          :name="column.prop"
                          >
                          	
                          <!-- 输入类型判断 -->
			        <el-input v-if="!column.type || column.type == 'input'" v-model.trim="record[prop][column.prop]" :placeholder="column.placeholder"/>
			        <el-input type="textarea" v-else-if="column.type == 'textarea'" v-model="record[prop][column.prop]" :placeholder="column.placeholder"/> 
			        <el-input-number v-else-if="column.type == 'number'" v-model="record[prop][column.prop]" controls-position="right" :min="column.min || 0" :max="column.max" :precision="column.precision" :step="column.step"/>
			        <el-radio-group v-else-if="column.type == 'radio'" v-model="record[prop][column.prop]">
					    <el-radio :label="rv.value" v-for="rv in column.dicData" :key="rv.value">{{rv.label}}</el-radio> 
					</el-radio-group>
                    <el-radio-group v-else-if="column.type == 'radioButton'" v-model="record[prop][column.prop]">
                        <el-radio-button :label="rv.value" v-for="rv in column.dicData" :key="rv.value">{{rv.label}}</el-radio-button> 
                    </el-radio-group>
					<el-switch v-else-if="column.type == 'switch'" v-model="record[prop][column.prop]" ></el-switch>
					<el-checkbox-group  v-else-if="column.type == 'checkbox'" v-model="record[prop][column.prop]">
					    <el-checkbox :label="rv.value" v-for="rv in column.dicData" :key="rv.value">{{rv.label}}</el-checkbox> 
					</el-checkbox-group>
					<el-select  v-else-if="column.type == 'select'" v-model="record[prop][column.prop]" placeholder="请选择">
					    <el-option
					       	v-for="rv in column.dicData" 
					      	:label="rv.label"  
					      	:key="rv.value"  
					      	:value="rv.value">
					    </el-option>
					</el-select> 
					<el-slider v-else-if="column.type == 'slider'" v-model="record[prop][column.prop]" :show-input="column.showInput" :min="column.min || 0" :max="column.max"></el-slider>
					<el-color-picker  v-else-if="column.type == 'color'" v-model="record[prop][column.prop]" placeholder="请选择颜色"  ></el-color-picker>
                    <template v-else-if="column.type == 'image'">
                        <ul class="el-upload-list el-upload-list--picture" v-if="record[prop] && record[prop][column.prop]">
                             
                            <li class="el-upload-list__item is-success" style="padding: 0px;" >
                                <el-image 
                              style="height: 100px;cursor: pointer;"
                              @click="openImage(record[prop][column.prop])"
                              :src="record[prop][column.prop]"
                              fit="scale-down"></el-image>
                              <label class="el-upload-list__item-status-label">
                                  <i class="el-icon-upload-success el-icon-check" />
                              </label>
                              <i class="el-icon-close" @click="clearValue( column.prop )"/>
                            </li>
                        </ul> 
                        <FileUpload  v-model="images" accept="image/*" :showList="false"  @uploadChange="({id,url})=>{
                            record[prop][column.prop] = url
                        }" />
                    </template>
                    
                </slot> 

				<!-- 插槽 -->
				<!-- <slot :name="column.prop"></slot> -->
	        </el-form-item>
    	</template>
        
         <!--图片显示-->
    <el-dialog :append-to-body="true" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    </el-form>
</template>
<script>
import { dynamicFun } from '@/components/hbi/utils/index.js'
import FileUpload from '@/components/fileupload/index.vue'
export default {
    components: {
        FileUpload
    },
    data() {
        return {
            dialogVisible: false,
            dialogImageUrl: '' ,
            images: []
        }
    },
    props: {
        // 当前组件整个配置 record
        record: {
            type: Object,
            required: true
        },
        // 当前需要绑定的具体子项
        prop: {
            type: String,
            required: true
        },
        // 表单配置
        config: {
            type: Object,
            required: true
        },
        // 字段列表
        columns: {
            type: Array,
            required: true,
            default: ()=> []
        }
    },
    mounted() {

    },
    methods: {
    	// 返回函数值
    	getScriptValue(script , currentValue){

    		//script , model ,currentValue , key="$"
    		return dynamicFun(script , this.record , currentValue)
    	},
    	columnVisible(script) {
    		if(script == false) return false ;
    		if(typeof script == 'string') {
    			return dynamicFun(script , this.record)
    		}	
    		return true
    	},
        clearValue( key) {
            
            this.$set(this.record[this.prop] , key , '')
        },
        openImage(url) {
            this.dialogImageUrl = url
            this.dialogVisible = true;
        }
    }
}
</script>
<style lang="scss">
.hd-form {
    .el-radio {
        margin-right: 10px;

        .el-radio__label {
            padding-left: 5px;
        }
    }
}
</style>