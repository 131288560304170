<!--
地图
-->
<template>
  <div :style="{'height': record.layout.height + 'px'}" v-loading="loading">
    <EChart :noTool="!record.layout.toolbar" :options="options">

    </EChart>

  </div>
</template>
<script>
import EChart from '../../EChart'
import {getChartLegend} from '../../../config/common/legend.js'
import {getDataByDatasource} from '../../../config/common/datasource.js'
import { deepClone } from '@/utils/util'
import {getMap} from '@/api/avueData/map';

export default {
  components: {
    EChart
  },
  data() {
    return {
      loading: false,
      dataList: [],
      data: [],
      options: {},
      geoJson: {},
      maps: {}
    }
  },
  props: {
    // 表单数组
    record: {
      type: Object,
      required: true
    },
    // form-item 宽度配置
    formConfig: {
      type: Object,
      required: false
    },
    // form-item 宽度配置
    models: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否预览结果表单
    renderPreview: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.getData()
    this.pickMaps()
  },
  computed: {
    recordOptions() {
      const options = {...this.record.layout, ...this.record.mapLayout, ...this.record.style, ...this.record.grid, ...this.record.legend}
      return options

    }
  },
  watch: {
    recordOptions: {//深度监听，可监听到对象、数组的变化
      handler() {
        this.init()
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    getData() {
      this.loading = true
      let record = this.record
      getDataByDatasource(this.record, this.models).then((data) => {
        let d = data
        if (record.dataSource && record.dataSource.type === "2") {
          d = []
          data.xData.forEach((x, index) => {
            let i = {}
            i.name = x
            i.value = data.yData[index]
            d.push(i)
          })
        }
        this.data = d
        this.init()
      }).catch(e => {
        if (e) {
          this.$message.error(e)
          console.error(e)
        }

        this.init()
      }).finally(() => {
        this.loading = false;
      });

    },
    pickMaps() {
      let maps = {}
      let files = require.context('@/assets/map', true, /.*\.json/).keys()

      files.forEach(file => {
        let fileName = file.slice(file.indexOf('/') + 1, file.length)
        maps[fileName.slice(fileName.lastIndexOf('/') + 1, fileName.lastIndexOf('.'))] = fileName
      })
      this.maps = maps
    },
    init() {
      let optionData;
      getMap(this.record.mapLayout.map).then(res => {
        const data = res.data.data;
        optionData = deepClone(data);

        this.$echarts.registerMap('targetMap', optionData)
        const title = this.record.layout.title
        const titlePosition = this.record.layout.titlePosition || 'center'
        const fontColor = this.record.layout.fontColor

        const toolbarShow = this.record.layout.toolbar
        const chartLegend = getChartLegend(this.record.legend)

        let dataMin = -1 , dataMax = -1 ;

        if (this.data && this.data.length > 0) {
          let legendData = []

          this.data.forEach((s) => {
            s.type = 'map'

            legendData.push(s.name)

            if(dataMax < 0 || s.value > dataMax) {
              dataMax = parseFloat(s.value)
            }
            if(dataMin < 0 || s.value < dataMin) {
              dataMin = parseFloat(s.value)
            }
          })

          if (chartLegend) {
            chartLegend.data = legendData
          }
        }

        let mapData = this.data;

        this.options = {
          title: {
            text: title,
            x: titlePosition,
            textStyle: {
              color: fontColor
            },
          },
          tooltip: {
            trigger: 'item',
            formatter: function (params) {
              return params.name + ': ' + params.value
            }
          },
          legend: chartLegend,
          toolbox: {
            show: toolbarShow,
            right: 30,
            feature: {
              dataView: {show: true, readOnly: true},
              saveAsImage: {show: true}

            }
          },
          visualMap: {
            min: dataMin,
            max: dataMax,
            text: ['High', 'Low'],
            realtime: false,
            calculable: true,
            inRange: {
              color: this.record.mapLayout.mapAreaColors
            },
            textStyle: {
              color: this.record.mapLayout.mapFontColor,
            },
          },
          geo: {
            show: true,
            map: 'targetMap',
            zoom: this.record.mapLayout.zoom,
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: this.record.mapLayout.mapFontColor,
                  fontSize: this.record.mapLayout.mapFontSize
                }
              },
              emphasis: {
                show: true,
                textStyle: {
                  color: this.record.mapLayout.mapFontHighlightColor
                }
              }
            },
            roam: true,
            itemStyle: {
              normal: {
                borderColor: this.record.mapLayout.mapBorderColor,
                borderWidth: this.record.mapLayout.mapBorderSize,
              },
              emphasis: {
                areaColor: this.record.mapLayout.mapAreaHighlightColor,
              }
            },
            select: { // 地图选中区域样式
              label:{ // 选中区域的label(文字)样式
                color: this.record.mapLayout.mapFontHighlightColor
              },
              itemStyle: {// 选中区域的默认样式
                areaColor: this.record.mapLayout.mapAreaHighlightColor,
              },
            },
          },
          series: [
            {
              type: 'map',
              map: 'targetMap',
              geoIndex: 0,
              data: mapData
            }
          ]
        }
      })


    }

  }
}
</script>
