export default {
    name:'progress',
    /*
    * 流程审批国际化资源
    * */
    en_US:{
        progressName:'Progress Name',
        receiveTime:'Receive Time',
        to:'To',
        query:'Query',
        querying:'Querying',
        nowNode:'Now Node',
        submitTime:'Submit Time',
        progressStatus:'Progress Status',
        timeConsuming:'Time-Consuming',
        operate:'Operate',
        startTime:'Start Time',
        endTime:'End Time',
        details:'Details',
        delete:'Delete',
        cancel:'Cancel',
        initiateProgress:'Initiate Progress',
        ongoing:'Ongoing',
        cancelled:'Cancelled',
        finished:'Finished',
        warning:'Warning',
        tips1:'Are you sure to delete data of progress?',
        initiator:'Initiator',
        approvalNode: 'Approval Node',
        approvalTime:'Approval Time',
        approvalRecord: 'Approval Record',
        taskNode:'Task Node',
        handle:'Handle',
    },
    zh_CN:{
        progressName:'流程名称',
        receiveTime:'接收时间',
        to:'至',
        query:'查询',
        querying:'查询中',
        nowNode:'当前节点',
        submitTime:'提交时间',
        progressStatus:'流程状态',
        timeConsuming:'耗时',
        operate:'操作',
        startTime:'开始时间',
        endTime:'结束时间',
        details:'详情',
        delete:'删除',
        cancel:'取消',
        initiateProgress:'发起流程',
        ongoing:'进行中',
        cancelled:'已取消',
        finished:'已完成',
        warning:'警告',
        tips1:'确认删除流程数据？',
        initiator:'流程发起人',
        approvalNode:'审批节点',
        approvalTime:'审批时间',
        approvalRecord:'审批纪录',
        taskNode:'任务节点',
        handle:'处理',
    }
}