<!--
	数据源中数据模型的选择
-->

<template>
<div class="hbi-data-model">

	<!-- 当存在ID和名称的时候 只显示模型名称 点击后进行重选 没有的时候出现按钮 进行选择 -->
	<span v-if="value && name" class="pointer select" @click="addOrUpdate"> {{name}} </span>
	<el-button v-else type="primary" @click="addOrUpdate">选择</el-button>


	<Select ref="dataModelSelect" v-if="selectVisible" @select="selectSubmit" />
</div>
</template>
<script>
import Select from './select.vue'
import { getColumnsById } from '@/api/hbi/datamodel.js'
export default {
	name: 'hbi-datamodel',
	components: {
		Select
	} ,
	data() {
		return {
			selectVisible: false,
			dataColumns: []
		}
	},
	props: {
		// 数据模型ID
		value: {
			type: String ,
			required: true
		},
		// 数据模型名称
		name: {
			type: String
		}
	},
	watch: {
		value() {
			this.dataColumns = []
		}
	},
	created() {
		//this.initDataColumns()
	},
	methods: {
		addOrUpdate() {
			this.selectVisible = true 
			this.$nextTick(()=> {
				this.$refs.dataModelSelect.init(this.value)
			})
		},
		selectSubmit(row) {
			this.$emit("input", row.id);
			this.$emit('change' , row.id)
			this.$emit('selectChange' , row)

			//this.initDataColumns()
		},
		// initDataColumns() {
	 //      if(this.value) {
	 //        getColumnsById(this.value).then(({data})=> {
	 //          if(data && data.code == 0) {
	 //            this.dataColumns = data.data.map(t=> {label: t.columnRemark + `(${columnName})` , value: t.columnName}) 
	 //          } 
	 //        })
	 //      }
	 //    },

	    getDataColumns() {
	    	const this_ = this 
	    	return new Promise((resolve) => { 
 				if(this.value) {
 					if(this.dataColumns && this.dataColumns.length > 0) {
 						return resolve(this.dataColumns)
 					} else {
 						getColumnsById(this.value).then(({data})=> {
				          if(data && data.code == 0) {
				            this_.dataColumns = data.data.map(t=> {
				            	return {label: t.columnRemark + '(' + t.customColumnName + ')' , value:t.customColumnName}
				            }) 

				            resolve(this_.dataColumns)
				          } 
				        })
 					}
 				} else {
 					resolve([])
 				}
		 
		    });
	    	 
	    }
	}
}
</script>
<style lang="scss">
.hbi-data-model {
	.select{
		color: blue ;
	}
}
</style>
