export default {
    /* 模块名字 */
    name: 'orgmanager',

    /* 英文资源 */
    en_US: {
        refresh: "Refresh",
        areaName: 'District name',
        parentAreaName: 'Parent District name',
        areaCode: 'Zoning code',
        parentAreaCode: 'Parent Zoning code',
        city: 'city',
        confirmDelete: 'Are you sure you want to delete it?',
        areaType: 'Zoning Type',
        parentArea: 'Parent Area',
        longitudeLatitude:'longitudeLatitude',
        addArea: 'Add Zoning',
        updateArea: 'Update Zoning',
        // 校验的
        parentAreaNotEmpty: 'parent zoning not empty',
        areaNameNotEmpty: 'zoning name not empty',
        areaCodeNotEmpty: 'zoning code not empty',
        formatError: 'wrong format! Please enter a 6-digit zoning code!',
        typeNotEmpty: 'type not empty',
        parentCode: 'parentCode'
    },

    /* 中文资源 */
    zh_CN: {
        refresh: "刷新",
        areaName: '区域名称',
        parentAreaName: '上级区域名称',
        areaCode: '区域代码',
        parentAreaCode: '上级区域代码',
        city: '地市',
        confirmDelete: '确定进行删除操作?',
        areaType: '区域类型',
        parentArea: '上级区域',
        longitudeLatitude:'经纬度',
        addArea: '新增区域',
        updateArea: '修改区域',
        // 校验的
        parentAreaNotEmpty: '上级区域不能为空',
        areaNameNotEmpty: '区域名称不能为空',
        areaCodeNotEmpty: '区域代码不能为空',
        formatError: '格式错误！请输入6位区域代码！',
        typeNotEmpty: '类型不能为空',
        parentCode: '上级区域代码'
    }

}