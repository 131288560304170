<template>
  <!-- <sysDict  type="sex" value="1"/> -->
  <span :key="value instanceof Array ? value.join(',') : value">{{dictNames.join(splitChar)}}</span>
</template>
<script>
import { getDictName, putDict, getByTypeValue, clearByTypeValue } from '@/api/sys/dict'
export default {
  name: "sysDict",
  data () {
    return {
      name: "",
      dictNames: [],
      timer: null
    };
  },
  watch: {
    value: {
      //深度监听，可监听到对象、数组的变化
      handler (val) {
        if (
            val != null &&
            val != "" &&
            val != undefined &&
            val != "undefined"
        ) {
          this.init(val);
        } else {
          this.dictNames = [];
        }
      },
      deep: true
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    value: {
      type: [Number, String, Array]
    },
    splitChar: {
      type: String,
      default: ','
    }
  },
  created () {
    // 计算数据字典的名称
    this.init(this.value);
  },
  methods: {
    init (val) {
      if (val != null) {
        this.dictNames = [];
        let vals = []
        if (val instanceof Array) {
          vals = val
        } else {
          vals = (val + "").split(",");
        }
        vals.forEach(item => {
          this.initData(item);
        });
        /*  if(!this.dictNames) {
            this.dictNames = [val]
          }*/
      } else {
        this.dictNames = [];
      }
    },
    initData (val) {
      // 获取缓存的字典信息
      this.name = getDictName(this.type, val);
      //还是空的话 ajax
      if ((this.name == null || this.name == "") &&
          val != null &&
          val != "" &&
          val != undefined &&
          val != "undefined"
      ) {
        getByTypeValue(this.type, val).then(({ data }) => {
          if (this.timer) {
            clearTimeout(this.timer)
          }
          if (data.code == -999) {// 如果有正在请求的数据则等待
            let that = this
            this.timer = setTimeout(() => {
              that.initData(val)
            }, 150)
          } else {
            let hs = false;
            if (data.code === 0) {
              const dict = data.data;
              if (dict != null) {
                this.name = dict.name;
                this.dictNames.push(dict.name);
                // 回填此条数据
                putDict(this.type, dict.name, this.value);
                hs = true;
              }
            }
            if (!hs) {
              this.dictNames.push(val);
            }
            clearByTypeValue(this.type, val)
          }
        }).catch((err) => {
          this.$message.error(err)
          clearByTypeValue(this.type, val)
        });
      } else {
        this.dictNames.push(this.name);
      }
    }
  }
};
</script>
