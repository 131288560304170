import http from '@/utils/httpRequest' 
import cloneDeep from 'lodash/cloneDeep'

export function getList(params) {
    return http({
          url: http.adornUrl('/sys/sysdict/list'),
          method: 'get',
          params: params
        })
} 

export function getAll() {
  return http({
    url: http.adornUrl('/sys/sysdict/getAll'),
    method: 'get'
  })
}


export function getTypes(params) {
  return  http({
        url: http.adornUrl("/sys/sysdict/types"),
        method: "get",
        params: http.adornParams(params)
      })
}

/**
 * 根据字典类型及字典值查询字典信息 - 字典组件
 * @param {*} type 字典类型
 * @param {*} value 字典值
 * @returns 符合条件的字典信息
 */
export function getByTypeValue (type, value) {
    let kv = type + "_" + value
    if(kvs.indexOf(kv) != -1 && kvNum[kv] < 50){// 如果有正在请求的数据则跳过
        kvNum[kv] = kvNum[kv]+1;
        return new Promise((resolve/*, reject*/) => {
            resolve({data:{code:-999}});
        })
    }else{
        if(kvs.indexOf(kv) == -1){
            kvs.push(kv)
        }
        kvNum[kv] = 1
        return http({
            url: http.adornUrl(`/sys/sysdict/findByTypeValue?type=` + type + "&value=" + value),
            method: "get",
            params: http.adornParams()
        })
    }
}
 
/**
* 查询数据字典类型下的字典列表
*/
export function findByType(type) {
  return  http({
          url: http.adornUrl(
            `/sys/sysdict/findByType`
          ),
          method: "get",
          params: http.adornParams({
            type: type
          })
  })
}

export function deleteType(type) {
  return  http({
        url: http.adornUrl("/sys/sysdict/deleteType"),
        method: "post",
        data:  http.adornData(type, false)
      })
}
 
export function deleteDict(ids = []) {
  return http({
            url: http.adornUrl("/sys/sysdict/delete"),
            method: "post",
            data: http.adornData(ids, false)
          })
}

export function getById(id) {
  return http({
        url: http.adornUrl(`/sys/sysdict/info/${id}`),
        method: "get"
        })
}
 
export function updateType(data) {
  return http({
            url: http.adornUrl(`/sys/sysdict/updateType`),
            method: "post",
            data: data
        })
}

export function saveOrUpdate(data) {
  return http({
            url: http.adornUrl(
              `/sys/sysdict/${!data.id ? "save" : "update"}`
            ),
            method: "post",
            data: data
        })
}

 

let dicts = []
let dictTypes = {}
 

// 从local中获取当前已有的列表
var sessionDicts_ = window.sessionStorage.getItem('dicts')
if (sessionDicts_ != null && sessionDicts_ != '') {
  // console.log('sessionDicts_' , sessionDicts_)
  try {
    dicts = JSON.parse(sessionDicts_)
  } catch (error) {
    dicts = []
  }
}

var sessionDictTypes_ = window.sessionStorage.getItem('dictTypes')
if (sessionDictTypes_ != null && sessionDictTypes_ != '') {
  try {
    dictTypes = JSON.parse(sessionDictTypes_)
  } catch (error) {
    dictTypes = {}
  }
}

/**
* dict 数据字典根据类型和值翻译为名称
* @param {dictType} dictType
* @param {dictValue} dictValue
*/
export function getDictName (dictType, dictValue) {
  // 尝试 获取
  let name = getDict(dictType, dictValue) 

  return name
}

export function putDict (dictType, name, value) {
 // 如果已经有值则跳过
  for(let i in dicts) {
    if(dicts[i].dictType == dictType && dicts[i].value == value){
      return 
    }
  }

  dicts.push({'dictType': dictType, 'value': value, 'name': name})
  const json_ = JSON.stringify(dicts)
  window.sessionStorage.setItem('dicts', json_)
}

function getDict (dictType, value) {
  if (dicts != null && dicts.length > 0) {
    for (let i in dicts) {
      if (dicts[i].value == value &&
          dicts[i].dictType == dictType) {
        return dicts[i].name
      }
    }
  }

  return null
}

export function putDictType (dictType, value) {
 // console.log('dictType,value' , dictType , value , cloneDeep(value))
  dictTypes[dictType] = cloneDeep(value)

  const json_ = JSON.stringify(dictTypes)
  window.sessionStorage.setItem('dictTypes', json_)
}

export function getDictType (dictType) {
   // 判断缓存是否有数据
  const value = dictTypes[dictType]

  return value ? cloneDeep(value) : null
}
let kvs = []
let kvNum = {}
/**
 * 根据数据字典k-v清理标记
 * @param {*} type 字典类型
 * @param {*} value 字典值
 */
export function clearByTypeValue(type , value){
    let kv = type + "_" + value
    kvs.splice(kvs.indexOf(kv), 1)
    Reflect.deleteProperty(kvNum, kv)
}

/**
* 根据数据字典分类返回该分类下数据
*/
export function getDictTypeValues (dictType) {
  let key = ''
  if (dictType instanceof Array) {
    key = dictType.join('|')
  } else {
    key = dictType
  }

  return new Promise((resolve, reject) => {
    const value = getDictType(key)
    if (value) {
      resolve(value)
    } else { 
      findByType(key).then(({data}) => {
        if (data.code === 0) {
          putDictType(key, data.data)

          resolve(data.data)
        }
      })
    }
  })
}

export function clearAll () {
  dicts = []
  dictTypes = {}
  const json_1 = JSON.stringify(dicts)
  window.sessionStorage.setItem('dicts', json_1)

  const json_2 = JSON.stringify(dictTypes)
  window.sessionStorage.setItem('dictTypes', json_2)
}
