<!--
表单控件属性设置组件,因为配置数据是引用关系，所以可以直接修改
-->
<template>
    <div>
        <!-- 分割线  end -->
        <!--  {
                label: '样式',
                prop: 'style',
                collapse: false,
                column: [
                    {
                        label: '背景颜色',
                        prop: 'backgroundColor',
                        tip: '点击选择背景颜色',
                        type: 'color',
                        default: '#FFFFFF',
                        span: 24,
                    },
                     {
                        label: '边距',
                        prop: 'margin',  
                        default: ['0','0','0','0'],
                         tip: 'top right bottom left',
                        span: 24,
                    },
                     {
                        label: '圆角',
                        prop: 'itemBorderRadius', 
                        type: 'number',
                        min: 0,
                        max: 45,
                        default: 0,
                        span: 24,
                    } 
                ]
            }, -->
        <el-collapse v-model="actives">
            <el-collapse-item title="样式" :name="1">
                <hd-form :config="form.config" :prop="form.prop" :record="selectItem" :columns="form.column" class="hbi-avue-form">
                    <template slot="margin">
                        <el-row v-if="selectItem.style && selectItem.style.margin && selectItem.style.margin.length == 4">
                            <el-col :span="6">
                                <el-input title="上边距" v-model="selectItem.style.margin[0]" />
                            </el-col>
                            <el-col :span="6">
                                <el-input title="右边距" v-model="selectItem.style.margin[1]" />
                            </el-col>
                            <el-col :span="6">
                                <el-input title="下边距" v-model="selectItem.style.margin[2]" />
                            </el-col>
                            <el-col :span="6">
                                <el-input title="左边距" v-model="selectItem.style.margin[3]" />
                            </el-col>
                        </el-row>
                    </template>
                </hd-form>
            </el-collapse-item>
            <el-collapse-item title="标签" :name="2">
                <el-row v-for="(ds,dsIndex) in selectItem.list" :key="dsIndex " style="padding-left: 10px;">
                    <el-col :span="20">
                        <span class="pointer select" @click="addOrUpdateData(ds , dsIndex)">{{ds.label}}</span>
                    </el-col>
                    <el-col :span="4">
                        <el-button v-if="dsIndex > 0" type="text" icon=" el-icon-close" @click="removeData(dsIndex)"></el-button>
                    </el-col>
                </el-row>
                <el-button type="text" icon="el-icon-plus" @click="addData()"></el-button>
            </el-collapse-item>
            <el-collapse-item title="外观" :name="3">
                <el-form size="mini">
                    <el-form-item label="风格类型">
                        <el-radio-group v-model="selectItem.options.type">
                            <el-radio-button label="card">card</el-radio-button>
                            <el-radio-button label="border-card">border-card</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="选项卡位置">
                        <el-radio-group v-model="selectItem.options.tabPosition">
                            <el-radio-button label="top">top</el-radio-button>
                            <el-radio-button label="right">right</el-radio-button>
                            <el-radio-button label="bottom">bottom</el-radio-button>
                            <el-radio-button label="left">left</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    
                </el-form>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>
<script>
export default {
    name: "HbiWeightTabProperties",
    data() {
        return {
            options: {},
            actives: [1],
            datasetKey: 1,
            form: {
                prop: 'style',
                config: {
                    size: 'mini',
                    labelWidth: 70
                },
                column: [{
                        label: '背景颜色',
                        prop: 'backgroundColor',
                        tip: '点击选择背景颜色',
                        type: 'color',
                        default: '#FFFFFF',
                        span: 24,
                    },
                    {
                        label: '边距',
                        prop: 'margin',
                        default: ['0', '0', '0', '0'],
                        tip: 'top right bottom left',
                        span: 24,
                    },
                    {
                        label: '圆角',
                        prop: 'itemBorderRadius',
                        type: 'number',
                        min: 0,
                        max: 45,
                        default: 0,
                        span: 24,
                    }
                ]
            }
        };
    },
    watch: {
        selectItem(val) {

            this.options = val.options || {}

        }
    },
    methods: {
        init() {

        },
        removeData(deleteIndex) {

            const newlist = this.selectItem.list.filter((val, index) => index !== deleteIndex)
            // 删除
            this.$set(this.selectItem, 'list', newlist)

            this.datasetKey++
        },
        addData() {
            const this_ = this
            this.$prompt('请输入标签名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                // inputPattern: /.+/,
                //inputErrorMessage: '标签名称不能为空'
            }).then(({ value }) => {
                console.log('selectItem', this_.selectItem)
                this_.selectItem.list.push({ label: value, list: [] })
                // this_.$set(this_.selectItem , 'list' , newlist)
                this_.datasetKey++
            }).catch((e) => {
                console.error(e)
            });



        },
        addOrUpdateData(row, index) {
            const this_ = this
            this.$prompt('请输入标签名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: row.label
                //inputPattern: /.+/,
                //inputErrorMessage: '标签名称不能为空'
            }).then(({ value }) => {
                this_.$set(row, 'label', value)
                this_.datasetKey++
            }).catch(() => {

            });
        }
    },
    props: {
        selectItem: {
            type: Object,
            required: true
        },

        hideModel: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>