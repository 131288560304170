import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep'
// 图表类展示 
import {  translateConfig } from '../config/common/index.js'
//import BaseProperties from './properties.vue'
const itemFiles = require.context('./item', true, /index\.js$/)
 

let listConfig = []

itemFiles.keys().forEach((key) => {
 
  const config = itemFiles(key).default; 

  listConfig = listConfig.concat(config)
})
 

export const list = translateConfig(listConfig)


// 将所有的配置加入内存 放入全局变量 
let itemConfig = Vue.prototype.$hbiItemConfig
if(!itemConfig) {
	itemConfig = {}

	Vue.prototype.$hbiItemConfig = itemConfig
}
 
listConfig.forEach(t=> {
	const tclone = cloneDeep(t)

	delete tclone.component
	delete tclone.properties
	itemConfig[t.type] = tclone 

})

 
