// 对外输出 包含组件的对外json定义、属性配置页面、展示页面 三部分
//import BaseIndex from './index.vue'
import BaseProperties from './properties.vue'
 
/*
 * author lyf
 * date 2020-07-06
 * description 表单控件配置
 */
// 基础控件
const list = [
  
  {
    type: "tabs", // 提示
    label: "标签页", // 标题 
    list: [{
      label: '标签1',
      list: []
    }] ,
    style: {
      backgroundColor: '',
      itemBorderRadius: 0,
      margin: ['0', '0', '0', '0'] 
    },
    options: { 
      type: 'card',
      tabPosition: 'top',
      tooptip: '',  // 提示 
      title: '' ,  //title 
      description: '', 
    }, 
    key: "" 
  } 
];
 
list.forEach(t=> {
  // 实际容器是空的，依赖design-panel中的布局
	//t.component = BaseIndex
	t.properties = BaseProperties
})
 

export const baseList = list 