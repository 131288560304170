export default {
    /* 模块名字 */
    name: 'users',

    /* 英文资源 */
    en_US: {
        userNo: 'Account',
        userName: 'User Name',
        type: 'Type',
        phone: 'Phone',
        status: 'Status',
        resetPwd: 'Reset Pwd',
        addUserInfo: 'Add User',
        updateUserInfo: 'Update User',
        levelType:'levelType',
        gender: 'gender',
        pwd: 'password',
        comfirmPassword: 'repeat pwd',
        org: 'region',
        dept: 'department',
        chooice: 'chooice',
        backRule: 'rule',
        newPwd: 'input new password for ',
        inputErrorMessage: 'The password is required to be 8~20 characters long, and cannot be pure numbers or pure letters!',
        deleteMsg: 'Are you sure you want to delete it?',
        consistentPwd: 'Confirm password does not match the password entered',
        sourceType:'Source type',
        departmentId:'Company/Department',
        departmentIdPH:'Company/Department,Keyword search can be entered',
        chooseEmpty:'Please select a Company/Department first',
        post:'Post',
        tips:'Input keywords to search.',
    },

    /* 中文资源 */
    zh_CN: {
        userNo: '用户账号',
        userName: '用户名称',
        type: '类型',
        phone: '手机号',
        status: '状态',
        resetPwd: '密码重置',
        addUserInfo: '添加用户信息',
        updateUserInfo: '更新用户信息',
        levelType: '岗位类型',
        gender: '性别',
        pwd: '密码',
        comfirmPassword: '确认密码',
        org: '行政区域',
        dept: '部门',
        chooice: '请选择',
        backRule: '角色',
        newPwd: '输入新的密码 ',
        inputErrorMessage: '密码要求长度8~20位,并且不能为纯数字或纯字母!',
        deleteMsg: '确定进行删除操作?',
        consistentPwd: '确认密码与密码输入不一致',
        sourceType:'用户来源',
        departmentId:'主岗单位/部门',
        departmentIdPH:'主岗单位/部门，可输入关键字搜索',
        chooseEmpty:'请先选择主岗单位/部门',
        post:'职务',
        tips:'输入关键字搜索'
    }


}
