<!--
漏斗图
-->
<template>
  <div :style="{'height': record.layout.height + 'px'}" v-loading="loading">
    <EChart :noTool="!record.layout.toolbar" :options="options">

    </EChart>

  </div>
</template>
<script>
import EChart from '../../EChart'
import {getChartLegend} from '../../../config/common/legend.js'

import {getDataByDatasource} from '../../../config/common/datasource.js'

export default {
  components: {
    EChart
  },
  data() {
    return {
      loading: false,
      dataList: [],
      data: [],
      options:{}
    }
  },
  props: {
    // 表单数组
    record: {
      type: Object,
      required: true
    },
    // form-item 宽度配置
    formConfig: {
      type: Object,
      required: false
    },
    // form-item 宽度配置
    models: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否预览结果表单
    renderPreview: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    recordOptions() {
      const options = {...this.record.layout,...this.record.style, ...this.record.grid, ...this.record.legend}
      return options

    }
  },
  watch: {
    recordOptions: {//深度监听，可监听到对象、数组的变化
      handler() {
        this.init()
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    getData() {
      this.loading = true
      let record = this.record
      getDataByDatasource(this.record, this.models).then((data) => {
        let d = data
        if (record.dataSource && record.dataSource.type === "2") {
          this.xData = data.xData
          this.yData = data.yData
          d = []
          data.xData.forEach((x, index) => {
            let dataItem = {}
            dataItem.value = data.yData[index]
            dataItem.name = x
            d.push(dataItem)
          })
        }
        this.data = d
        this.init()
      }).catch(e => {
        if (e) {
          this.$message.error(e)
          console.error(e)
        }

        this.init()
      }).finally(() => {
        this.loading = false;
      });

    },
    init() {
      const title = this.record.layout.title
      const titlePosition = this.record.layout.titlePosition || 'center'
      const fontColor = this.record.layout.fontColor

      const toolbarShow = this.record.layout.toolbar
      const chartLegend = getChartLegend(this.record.legend)

      /*if (this.data && this.data.length > 0) {

      }*/


      this.options = {
        title: {
          text: title,
          x: titlePosition,
          textStyle: {
            color: fontColor
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}'
        },
        legend: chartLegend,
        toolbox: {
          show: toolbarShow,
          right: 30,
          feature: {
            dataView: {show: true, readOnly: true},
            saveAsImage: {show: true}

          }
        },
        series: [
          {
            name: title,
            type: 'funnel',
            center: ['50%', '50%'],
            data: this.data,

          }
        ]
      }


    }

  }
}
</script>