import http from '@/utils/httpRequest'


export function getList(params) {
  return http({
          url: http.adornUrl('/sys/config/list'),
          method: 'get',
          params: params
        })
} 
export function deleteConfig(ids = []) {
	return http({
            url: http.adornUrl("/sys/config/delete"),
            method: "post",
            data: http.adornData(ids, false)
          })
}

export function getById(id) {
	return http({
        url: http.adornUrl(`/sys/config/info/${id}`),
        method: "get"
        })
}
 
export function saveOrUpdate(data) {
	return http({
            url: http.adornUrl(
              `/sys/config/${!data.id ? "save" : "update"}`
            ),
            method: "post",
            data: data
        })
}
//获取参数
export function getValue(key) {
    return http({
        url: http.adornUrl(`/sys/config/getValue/${key}`),
        method: "get"
    })
}
