// 对外输出 包含组件的对外json定义、属性配置页面、展示页面 三部分


import BaseIndex from './index.vue'
import BaseProperties from './properties.vue'
import {legendConfig} from '../../../config/common/legend.js'
import {gridConfig} from '../../../config/common/grid.js'
import {styleConfig} from '../../../config/common/style.js'

import {getDataSourceConfig} from '../../../config/common/datasource.js'

// 默认的静态数据
const staticDefaultData = `
[{
    "data": [
      [1, 8.04],
      [2, 6.95]
    ]
  },
  {
    "data": [
      [1, 4.04],
      [2, 3.95]
    ]
  }]
`

const datasourceConfig = getDataSourceConfig({dataSourceDefault: '1', dataStatic: staticDefaultData})

const scatterConfig = {
    type: "scatterChart", // 表单类型
    label: "散点图", // 标题文字
    component: BaseIndex,
    properties: BaseProperties,

    span: 24,
    formOptions: {
        config: {
            size: 'mini',
            labelWidth: 70,
            emptyBtn: false,
            submitBtn: false
        },
        group: [
            {...styleConfig}, //style
            {...datasourceConfig}, // datasource
            {
                label: '数据模型',
                prop: 'data',
                show: '$.dataSource.type == 2',
                collapse: false,
                column: [{
                    label: '数据来源',
                    prop: 'dataModelId',
                    tip: '点击选择数据来源',
                    span: 24,
                },
                {
                    label: '数据来源',
                    prop: 'dataModelName',
                    show: false,
                    span: 24,
                },
                {
                    label: '维度字段',
                    prop: 'xColumn',
                    type: "select",
                    dicData: [],
                    tip: '点击选择X轴数据指标',
                    span: 24,
                },
                {
                    label: '指标字段',
                    prop: 'yColumn',
                    type: "select",
                    dicData: [],
                    tip: '点击选择Y轴数据指标',
                    span: 24,
                },
                {
                    label: '指标聚合',
                    prop: 'aggMethod',
                    type: "select",
                    dicData: [
                        {label: 'avg', value: 'avg'},
                        {label: 'sum', value: 'sum'},
                        {label: 'count', value: 'count'},
                        {label: 'max', value: 'max'},
                        {label: 'min', value: 'min'},
                    ],
                    span: 24,
                },
                {
                    label: '分组',
                    prop: 'groupBy',
                    type: "select",
                    multiple: true,
                    dicData: [],
                    span: 24,
                    default: []
                },
                {
                    label: '维度格式化',
                    prop: 'xFormater',
                    type: "textarea",
                    tip: '指标数据格式函数,$1为当时指标数据,eg: return $1 + 10',
                    span: 24,
                },
                {
                    label: '指标格式化',
                    prop: 'yFormater',
                    type: "textarea",
                    tip: '指标数据格式函数,$1为当时指标数据,eg: return $1 + 10',
                    span: 24,
                },

                ]
            },
            {
                label: '数据过滤',
                prop: 'filter',
                show: '$.dataSource.type == 2',
                custom: true,
                collapse: false,
                default: []
            },
            {
                label: '外观',
                prop: 'layout',
                collapse: false,
                column: [{
                    label: '标题',
                    prop: 'title',
                    span: 24,
                },
                    {
                        label: '标题位置',
                        prop: 'titlePosition',
                        span: 24,
                        type: 'select',
                        default: 'center',
                        dicData: ["left", "center", "right"].map(t => {
                            return {label: t, value: t}
                        }),
                    },
                    {
                        label: '字体颜色',
                        prop: 'fontColor',
                        type: 'color',
                        default: '#000000',
                        span: 24,
                    },
                    {
                        label: '高度',
                        prop: 'height',
                        type: 'number',
                        min: 50,
                        max: 1000,
                        default: 300,
                        span: 24,
                    },
                    {
                        label: '工具条',
                        prop: 'toolbar',
                        type: 'switch',
                        default: false,
                        span: 24,
                    },

                ]
            },

            {
                label: '事件',
                prop: 'event',
                collapse: false
            },
            {
                label: '边距',
                prop: 'grid',
                collapse: false,
                column: gridConfig
            },
            {
                label: '图例',
                prop: 'legend',
                collapse: false,
                column: legendConfig
            },
        ]
    },
    options: {

        tooptip: '', // 提示 
        hidden: false, // 是否隐藏，false显示，true隐藏 
    },
    model: "", // 数据字段
    key: ""
}


export default [scatterConfig]