/* 数据字典的资源 */
export default {
    /* 模块名字 */
    name: 'gmember',

    /* 英文资源 */
    en_US: {
        gmemberName:'Name',
        name:'Name',
        gmemberPost:'Post',
        gmemberPostTip:'Primary position, you can enter keywords to search',
        gmemberStatus:'Employee Status',
        gmemberStatusTip:'Please select employee status',
        orgdepartment:'department',
        gmemberInstitutionalFramework:'Institutional Framework',
        gmemberDuty:'Duty',
        gmemberAdmin:'Administrator or not',
        gmemberType:'Employee Type',
        gmemberEnable:'EnableOrNot',
        gmemberFormerName:'Former Name',
        gmemberInternal:'Internal employee or not',
        gmemberInternalPlease:'Please select whether to be an internal employee',
        gmemberLogin:'whether or not log in',
        gmemberPleaseLogin:'Please select whether you can wait',
        gmemberVirtual:'Virtual account or not',
        gmemberPleaseVirtual:'Please select virtual account',
        gmemberAdministrator:'Administrator or not',
        gmemberPleaseAdministrator:'Please select whether to be an administrator',
        gmemberAssigned:'Assigned or not',
        gmemberPleaseAssigned:'Please select whether to be assigned',
        gmemberPleaseType:'Please select an employee type',
        gmemberPleaseStatus:'Please select employee status',
        gmemberPleaseEnable:'Please select whether to enable',
        gmemberIdentification:'Internal and external identification',
        gmemberPleaseIdentification:'Please select internal and external identification',
        gmemberSort:'Sort',
        gmemberMajor:'Major position/Department',
        gmemberMajorKeyword:'Major position/Department，Keyword search available',
        gmemberMainpost:'Main post',
        gmemberMainpostKeyword:'Main post，Keyword search available',
        gmemberMainpostLevel:'Main post post level',
        gmemberMainpostLevelKeyword:'Main post post level，Keyword search available',
        gmemberDescribe:'Describe',
        gmemberGender:'Gender',
        gmemberPleaseGender:'Please select gender',
        gmemberAge:'Age',
        gmemberDateofbirth:'Date of birth',
        gmemberPleaseDateofbirth:'Please select the date of birth',
        gmemberNation:'Nation',
        gmemberPleaseNation:'Please select a nationality and enter keywords to search',
        gmemberNativeplace:'Native place',
        gmemberMaritalStatus:'MaritalStatus',
        gmemberPleaseMaritalStatus:'Please select marital status',
        gmemberIdentitycard:'Identity card/Passport',
        gmemberEntrytime:'Entry time',
        gmemberPleaseEntrytime:'Please select the entry time',
        gmemberWorkingyears:'Working years',
        gmemberSpecialty:'Specialty',
        gmemberFilewage:'File wage',
        gmemberPoliticSstatus:'Politics Status',
        gmemberPleasePoliticSstatus:'Please select the political outlook',
        gmemberMailbox:'Mailbox',
        gmemberNumber:'Cell phone number',
        gmemberOfficephone:'Office phone',
        gmemberSites:'Personal web sites',
        gmemberBlog:'Blog',
        gmemberWeChat:'WeChat',
        gmemberMicroblog:'Microblog',
        gmemberHighest:'Highest education',
        gmemberPleaseHighest:'Please select the highest degree',
        gmemberReportpeople:'Report people',
        gmemberPostcode:'Postcode',
        gmemberHomeAddress:'Home Address',
        gmemberMailingAddress:'Mailing Address',
        gmemberWorkplace:'Workplace',
        gmemberAvocation:'Avocation',
        gmembNameErmpty:'Name cannot be empty',
        gmembCodeErmpty:'Code cannot be empty',
        gmembSortErmpty:'Sort cannot be empty',
        gmembepartmentErmpty:'The main position department cannot be empty',
        gmembDepartmentErmpty:'The main position department cannot be empty',
        gmembPositionErmpty:'The main position cannot be empty',
        gmembLevelErmpty:'The job level of the main position cannot be blank',
        gmembCompanyErmpty:'The main position company cannot be empty',
        gmembDescriptionErmpty:'Description cannot be empty',
        gmembIdentificationErmpty:'The internal and external identification cannot be empty',
        gmembAgeErmpty:'Age cannot be blank',
        gmembDateErmpty:'Date of birth cannot be blank',
        gmembNationalityErmpty:'Nationality cannot be empty',
        gmembNativeErmpty:'Native place cannot be empty',
        gmembLengthErmpty:'Length of service cannot be blank',
        gmembMajorErmpty:'Major cannot be empty',
        gmembFileErmpty:'File salary cannot be blank',
        gmembHomeErmpty:'Home address cannot be empty',
        gmembWorkingErmpty:'The working place cannot be empty',
        gmembReporterErmpty:'The reporter cannot be blank',
        gmembExcelExport:'Excel Export',
        gmembExcelImport:'Excel Import',
        loginName:'Login Name',
        selectData:'Select Data',
        organization:'Organization',
        tips1:'Input keywords to filter!',

    },

    /* 中文资源 */
    zh_CN: {
        selectData:'选择数据 ',
        organization:'组织机构',
        tips1:'输入关键字进行过滤',
        loginName:'登录名',
        gmemberName:'姓名',
        name:'名称',
        gmemberPost:'岗位',
        gmemberPostTip:'主岗岗位，可输入关键字搜索',
        gmemberStatus:'员工状态',
        gmemberStatusTip:'请选择员工状态',
        orgdepartment:'部门',
        gmemberInstitutionalFramework:'组织机构',
        gmemberDuty:'职务',
        gmemberAdmin:'是否管理员',
        gmemberType:'员工类型',
        gmemberEnable:'是否启用',
        gmemberFormerName:'曾用名',
        gmemberInternal:'是否内部员工',
        gmemberInternalPlease:'请选择是否内部员工',
        gmemberLogin:'是否可以登录',
        gmemberPleaseLogin:'请选择是否可以登录',
        gmemberVirtual:'是否虚拟账号',
        gmemberPleaseVirtual:'请选择是否虚拟账号',
        gmemberAdministrator:'是否管理员',
        gmemberPleaseAdministrator:'请选择是否管理员',
        gmemberAssigned:'是否被分配',
        gmemberPleaseAssigned:'请选择是否被分配',
        gmemberPleaseType:'请选择员工类型',
        gmemberPleaseStatus:'请选择员工状态',
        gmemberPleaseEnable:'请选择是否启用',
        gmemberIdentification:'内外标识',
        gmemberPleaseIdentification:'请选择内外标识',
        gmemberSort:'排序',
        gmemberMajor:'主岗单位/部门',
        gmemberMajorKeyword:'主岗单位/部门，可输入关键字搜索',
        gmemberMainpost:'主岗岗位',
        gmemberMainpostKeyword:'主岗岗位，可输入关键字搜索',
        gmemberMainpostLevel:'主岗职务级别',
        gmemberMainpostLevelKeyword:'主岗职务级别，可输入关键字搜索',
        gmemberDescribe:'描述',
        gmemberGender:'性别',
        gmemberPleaseGender:'请选择性别',
        gmemberAge:'年龄',
        gmemberDateofbirth:'出生日期',
        gmemberPleaseDateofbirth:'请选择出生日期',
        gmemberNation:'民族',
        gmemberPleaseNation:'请选择民族，可输入关键字搜索',
        gmemberNativeplace:'籍贯',
        gmemberMaritalStatus:'婚姻状态',
        gmemberPleaseMaritalStatus:'请选择婚姻状态',
        gmemberIdentitycard:'身份证/护照',
        gmemberEntrytime:'入职时间',
        gmemberPleaseEntrytime:'请选择入职时间',
        gmemberWorkingyears:'工龄',
        gmemberSpecialty:'专业',
        gmemberFilewage:'档案工资',
        gmemberPoliticSstatus:'政治面貌',
        gmemberPleasePoliticSstatus:'请选择政治面貌',
        gmemberMailbox:'邮箱',
        gmemberNumber:'手机号',
        gmemberOfficephone:'办公电话',
        gmemberSites:'个人网页',
        gmemberBlog:'博客',
        gmemberWeChat:'微信',
        gmemberMicroblog:'微博',
        gmemberHighest:'最高学历',
        gmemberPleaseHighest:'请选择最高学历',
        gmemberReportpeople:'汇报人',
        gmemberPostcode:'邮编',
        gmemberHomeAddress:'家庭地址',
        gmemberMailingAddress:'邮寄地址',
        gmemberWorkplace:'工作地',
        gmemberAvocation:'业余爱好',
        gmembNameErmpty:'名称不能为空',
        gmembCodeErmpty:'编码不能为空',
        gmembSortErmpty:'排序不能为空',
        gmembDepartmentErmpty:'主岗部门不能为空',
        gmembPositionErmpty:'主岗岗位不能为空',
        gmembLevelErmpty:'主岗职务级别不能为空',
        gmembCompanyErmpty:'主岗单位不能为空',
        gmembDescriptionErmpty:'描述不能为空',
        gmembIdentificationErmpty:'内外标识不能为空',
        gmembAgeErmpty:'年龄不能为空',
        gmembDateErmpty:'出生日期不能为空',
        gmembNationalityErmpty:'民族不能为空',
        gmembNativeErmpty:'籍贯不能为空',
        gmembLengthErmpty:'工龄不能为空',
        gmembMajorErmpty:'专业不能为空',
        gmembFileErmpty:'档案工资不能为空',
        gmembHomeErmpty:'家庭地址不能为空',
        gmembWorkingErmpty:'工作地不能为空',
        gmembReporterErmpty:'汇报人不能为空',
        gmembExcelExport:'导出数据',
        gmembExcelImport:'导入数据',

    },
};
