import Vue from "vue";
import axios from "axios";
import router from "@/router";
import qs from "qs";
import merge from "lodash/merge";
import { clearLoginInfo, set401, is401 } from "@/utils";
import { overrideMessage } from "./message";
import { getTokenName , getToken } from './index'

const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  mode: "no-cors",
  headers: {
    "Content-Type": "application/json; charset=utf-8"
    //'Sec-Fetch-Mode': 'no-cors'
  }
});

/**
 * 请求拦截
 */
http.interceptors.request.use(
  config => {
    const tokenName = getTokenName()
    const token = getToken()
    config.headers[tokenName] = token; // 请求头带上token
    //config.headers['Sec-Fetch-Mode'] = 'cors'// 请求头带上token
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * 响应拦截
 */
http.interceptors.response.use(
  response => {
    if (response.data && (response.data.code === 400 || response.data.code === 401 )) {
      // 401, token失效
      // message 提示
      if (is401()) {
        const msg = response.data.msg ? response.data.msg :  "当前用户未登录或登录信息已失效!"

        overrideMessage.error(msg);

        set401(false);
      }

      clearLoginInfo();
      router.push({ name: "login" });
      return
    }
    set401(true);
    return response;
  },
  error => {
    // 尝试跳到首页去
    // router.push({ name: 'login' })
    return Promise.reject(error);
  }
);

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = actionName => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  return (
    (process.env.NODE_ENV !== "production" && process.env.OPEN_PROXY
      ? "/proxyApi/"
      : window.SITE_CONFIG.baseUrl) + actionName
  );
};

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  var defaults = {
    t: new Date().getTime()
  };
  return openDefultParams ? merge(defaults, params) : params;
};

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = "json") => {
  var defaults = {
    t: new Date().getTime()
  };
  data = openDefultdata ? merge(defaults, data) : data;
  return contentType === "json" ? JSON.stringify(data) : qs.stringify(data);
};


http.adornDataXml = (data = {}, openDefultdata = true, contentType = "json") => {
    var defaults = {
        t: new Date().getTime()
    };

    function dateFormat(date, fmt) {
        if (null == date || undefined == date) return '';
        var o = {
            "M+": date.getMonth() + 1, //月份
            "d+": date.getDate(), //日
            "h+": date.getHours(), //小时
            "m+": date.getMinutes(), //分
            "s+": date.getSeconds(), //秒
            "S": date.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    }

    data = openDefultdata ? merge(defaults, data) : data;
    Date.prototype.toJSON = function () { return dateFormat(this,'yyyy-MM-dd hh:mm:ss')}
    return contentType === "json" ? JSON.stringify(data) : qs.stringify(data);
};

export default http;
