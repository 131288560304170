<!--
通过json生成的单个表单节点 
 -->
<template>
  <el-col
    class="grid-col"
    :span="record.span || 24" 
    :class="{ 'active': selectItem && record.key === selectItem.key , 'drag-move-box': isDrag}"
    :offset="record.offset ? record.offset : 0"
  >  
   <!-- ################## start container #################### -->
   <template v-if="record && record.type === 'container'">
      <div
        v-if="isDrag"
        :class="[
          'grid-box','control-form',  
          selectItem && record.key === selectItem.key ? 'active' : '',
          record.options && record.options.bordered ? 'form-table-bordered' : '' 
        ]"  
        :style="{
          width: record.options.width , 
          backgroundColor: record.style && record.style.backgroundColor ? record.style.backgroundColor  : '#FFFFFF',
          margin: record.style && record.style.margin && record.style.margin.length > 0 ? record.style.margin.join('px ') + 'px' : '0px',
          borderRadius: (record.style && record.style.itemBorderRadius ? record.style.itemBorderRadius : 0) + 'px'
        }"
        style="min-height: 200px;"
        @click="handleSelectItem(record)"
      >
        <!-- <div class="batch-label">弹性容器</div>  -->
        <el-row class="grid-row" 
        :gutter="record.style ? record.style.gutter : 10" 
        style="margin: 0px;" 
        :type="record.style && record.style.flex ? 'flex' : undefined" 
        :justify="record.style && record.style.flex && record.style.justify ? record.style.justify : 'start'" 
        :align="record.style && record.style.flex && record.style.align ? record.style.align : undefined">
         <draggable
          tag="div"
          class="draggable-box"
          v-bind="{
            group:  'form-draggable' ,
            ghostClass: 'moving',
            animation: 180,
            handle: '.drag-move'
          }"
          style="min-height: 200px;background: white;"
          :force-fallback="true"
          v-model="record.list"
           @add="deepClone($event , record)"
          @start="dragStart($event, data.list)"
        >

          <transition-group tag="div" name="list"   style="min-height: 200px;">
            <hbi-item-node
              v-for="item in record.list"
              :key="item.key"
              class="drag-move"
              :selectItem.sync="selectItem"
              :record="item"
              :hideModel="hideModel"
              :models="models"
              :config="config"
              @handleSelectItem="handleSelectItem(item)" 
              @handleCopy="handleCopy" 
              @handleDetele="handleDetele()"
               :isDrag="isDrag"
            />
          </transition-group>
        </draggable>
        </el-row>
      </div>
      <div 
        v-else
        :style="{
          width: record.options.width , 
          backgroundColor: record.style && record.style.backgroundColor ? record.style.backgroundColor  : '#FFFFFF',
          margin: record.style && record.style.margin && record.style.margin.length > 0 ? record.style.margin.join('px ') + 'px' : '0px',
          borderRadius: (record.style && record.style.itemBorderRadius ? record.style.itemBorderRadius : 0) + 'px'
        }"
      >
           <hbi-item-node
              v-for="item in record.list"
              :key="item.key"
              class="drag-move"
              :selectItem.sync="selectItem"
              :record="item"
              :hideModel="hideModel"
              :config="config" 
              :models="models" 
              :isDrag="isDrag"
            />
      </div>
    </template> 
    <!-- ################## end container #################### -->
    <!-- ################## start tabs #################### -->
    <template v-if="record && record.type === 'tabs'">
      <div
        v-if="isDrag"
        :class="[
          'grid-box','control-form',  
          selectItem && record.key === selectItem.key ? 'active' : '',
          record.options && record.options.bordered ? 'form-table-bordered' : '' 
        ]"  
        :style="{
          width: record.options.width , 
          backgroundColor: record.style && record.style.backgroundColor ? record.style.backgroundColor  : '#FFFFFF',
          margin: record.style && record.style.margin && record.style.margin.length > 0 ? record.style.margin.join('px ') + 'px' : '0px',
          borderRadius: (record.style && record.style.itemBorderRadius ? record.style.itemBorderRadius : 0) + 'px'
        }"
        style="min-height: 200px;"
        @click="handleSelectItem(record)"
      > 
        <!-- tabs 展开  --> 
        <el-tabs v-model="activeName" :type="record.options.type || 'card'" :tab-position="record.options.tabPosition || 'top'">
          <el-tab-pane 
            v-for="(item,idx) in record.list"
            :key="item.label"
            :label="item.label" 
            :name="(idx+1) + ''"
          > 
           <draggable
            tag="div"
            class="draggable-box"
            v-bind="{
              group:  'form-draggable' ,
              ghostClass: 'moving',
              animation: 180,
              handle: '.drag-move'
            }"
            style="min-height: 200px;background: white;"
            :force-fallback="true"
            v-model="item.list"
             @add="deepClone($event ,item)"
            @start="dragStart($event, item.list)"
          >

            <transition-group tag="div" name="list"   style="min-height: 200px;">
              <hbi-item-node
                v-for="item2 in item.list"
                :key="item2.key"
                class="drag-move"
                :selectItem.sync="selectItem"
                :record="item2"
                :hideModel="hideModel"
                :models="models"
                :config="config"
                @handleSelectItem="handleSelectItem(item2)" 
                @handleCopy="handleCopy(true , item)" 
                @handleDetele="handleDetele(item)"
                 :isDrag="isDrag"
              />
            </transition-group>
          </draggable>
        </el-tab-pane>
          
        </el-tabs> 
      </div>
      <div v-else>
         <el-tabs v-model="activeName" :type="record.options.type || 'card'" :tab-position="record.options.tabPosition || 'top'">
          <el-tab-pane 
            v-for="(item,idx) in record.list"
            :key="item.label"
            :label="item.label" 
            :name="(idx+1) + ''"
          >  
              <hbi-item-node
                v-for="item2 in item.list"
                :key="item2.key"
                class="drag-move" 
                :record="item2"
                :hideModel="hideModel"
                :models="models"
                :config="config" 
                 :isDrag="isDrag"
              />  
          </el-tab-pane> 
        </el-tabs> 
      </div>
    </template> 
     <!-- ################## end tabs #################### -->
    <!-- ################## start item #################### -->
    <template v-else>
      <div   class="form-item-box" @click.stop="$emit('handleSelectItem', record)"> 
        <HbiItem 
          :formConfig="config" 
          :models="models" 
          :record="record" 
          :isDragPanel="isDrag"
          @forceUpdate="forceUpdate"
          @handleReset="$emit('handleReset')"
          @change="handleChange" 
          @submit="$emit('submit')"
          @reset="$emit('reset')"
          :disabled="disabled" 
          :renderPreview="renderPreview"   
          :key="refreshKey"
          @dragStart="$emit('dragStart')" 
          @handleSelectItem="handleSelectItem(record)"
          @handleCopy="$emit('handleCopy')"
          @handleDetele="$emit('handleDetele')"
        />
      </div>
    
    </template>
    <!-- ################## end item #################### -->
      <template v-if="isDrag">
        <div v-if="!hideModel" class="show-key-box" :class="{'active-key-box': selectItem && record.key === selectItem.key}" v-text="record.model" />
         <div
          class="refresh pointer"
          title="刷新"
          :class="selectItem && record.key === selectItem.key ? 'active' : 'unactivated'"
          @click.stop="handleRefresh"
        >
          <i class="el-icon-refresh" />
        </div>
        <div
          class="copy pointer"
          title="复制"
          :class="selectItem && record.key === selectItem.key ? 'active' : 'unactivated'"
          @click.stop="$emit('handleCopy')"
        >
          <i class="el-icon-document-copy" />
        </div> 
        <div
          class="delete pointer"
          title="删除"
          :class="selectItem && record.key === selectItem.key ? 'active' : 'unactivated'"
          @click.stop="$emit('handleDetele')"
        >
          <i class="el-icon-delete" />
        </div>
      </template>
   
  </el-col>
</template>
<script>
import draggable from "vuedraggable";
import {dynamicFun} from '../../utils' 
import HbiItem from "../../hbi-item/index.vue";
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'hbi-item-node' ,
  components: {
    HbiItem,draggable
  },
  props: {
    record: {
      type: Object,
      required: true
    }, 
    selectItem: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      required: true
    },
    hideModel: {
      type: Boolean,
      default: false
    },
    // 当前是否拖拽面板
    isDrag: {
      type: Boolean,
      default: true
    },
    renderPreview: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }, 
    models: {
      type: Object ,
      default: ()=>{return {}}
    },
  },
  data(){
    return {
      //models: {},
      activeName: '1',
      refreshKey: 1,
    }
  },
  computed: {
     // 返回true 显示 false 不显示
    dynamicVisibleItem(){
      if(this.isDrag) return true 
      
      if(!this.record.options || !this.record.options.dynamicVisible){
        return true
      }
      if(!this.record.options.dynamicVisibleValue){
        return true
      }
      let fstr = this.record.options.dynamicVisibleValue;
      // 打开了开关 这里获取函数内容
  
      const ret = dynamicFun(fstr,this.models) 
      return ret ;
    }
  },
  activated() {
    console.log('record' , this.record.key )
    if(this.record.type == 'tabs') {
      this.activeName = '1'
    }
    
  }, 
  methods: {
    handleChange(value, key) { 
      this.$emit("change", value, key);
    },
    forceUpdate(){ 
       this.$emit("forceUpdate" );
    } ,
    handleSelectItem(rec) {
      this.$emit('handleSelectItem' , rec)
    },
    handleRefresh(){
      this.refreshKey++
    },
    deepClone( evt, data) { 
      const newIndex = evt.newIndex; 

      // json深拷贝一次
      if(!data){
        data = this.record
      }
      // const listString = JSON.stringify(data.list);
      // data.list = JSON.parse(listString);
       data.list = cloneDeep(data.list)
      // 删除icon及compoent属性
      
      if(data.list && data.list.length > newIndex) {
        delete data.list[newIndex].icon;
        delete data.list[newIndex].component;
        delete data.list[newIndex].properties
        this.handleSelectItem(data.list[newIndex])
       
      }
      
    },  
    dragStart(evt, list) {  
      // 拖拽结束,自动选择拖拽的控件项
      this.handleSelectItem(list[evt.oldIndex])
      
    },
    handleDetele(data) {
      // 删除已选择 

      let array = undefined
      if(data) {
        array = data.list 
        //data.list = traverse(data.list);
      }else {
        array = this.record.list 
        //this.record.list = traverse(this.record.list);
      }

      for(let i = 0 ; i < array.length ; i++) {
        const element = array[i]

        if(element.key == this.selectItem.key) {

          // 
          if (array.length === 1) {
            this.handleSelectItem({ key: "" });
          } else if (array.length - 1 > i) {
            this.handleSelectItem(array[i + 1]);
          } else {
            this.handleSelectItem(array[i - 1]);
          }

          array.splice(i , 1)
          break
        }
      }



     
      
    },
    handleCopy(isCopy = true, data) { 
      const traverse = array => {
      array.forEach((element, index) => { 
        if (element.key === this.selectItem.key) {
        if (isCopy) {
          // 复制添加到选择节点后面
          array.splice(index + 1, 0, cloneDeep(element)); 
        } else {
          // 双击添加到选择节点后面
          array.splice(index + 1, 0, cloneDeep(data));
        }
        // 复制完成，重置key值
        const evt = {
          newIndex: index + 1
        };
        this.handleColAdd(evt, array, true);

        return;
        }
         
      
      });
      };
      if(data) {
        traverse(data.list)
      } else {
        traverse(this.record.list);
      }
     
    },
    handleColAdd(evt, columns, isCopy = false) {
      // 重置或者生成key值
      const newIndex = evt.newIndex;
     // if(!this.columns || this.columns.length < newIndex ){
     //   return
     // }
      const key = columns[newIndex].type + "_" + new Date().getTime();
      if (columns[newIndex].key === "" || isCopy) {
      this.$set(columns, newIndex, {
        ...columns[newIndex],
        key,
        model: key
      });
      // if (this.noModel.includes(columns[newIndex].type)) {
      //   // 删除不需要的model属性
      //   delete columns[newIndex].model;
      // }
      if (typeof columns[newIndex].options !== "undefined") {
        // 深拷贝options
        const optionsStr = JSON.stringify(columns[newIndex].options);
        columns[newIndex].options = JSON.parse(optionsStr);
      }
      if (typeof columns[newIndex].rules !== "undefined") {
        // 深拷贝rules
        const rulesStr = JSON.stringify(columns[newIndex].rules);
        columns[newIndex].rules = JSON.parse(rulesStr);
      }
      if (typeof columns[newIndex].list !== "undefined") {
        // list 不为空 则重置list下的组件model
        columns[newIndex].list.forEach(t=>{
        t.model = t.model + 1
        t.key = t.key + 1
        })
      }
      if (typeof columns[newIndex].columns !== "undefined") {
        // 深拷贝columns
        const columnsStr = JSON.stringify(columns[newIndex].columns);
        columns[newIndex].columns = JSON.parse(columnsStr);
        // 复制时，重置key和model
        columns[newIndex].columns.forEach(item => {
        if(item.list && item.list.length > 0) {
          item.list.forEach(t => {
            t.model = t.model + 1
            t.key = t.key + 1
          });
          }
        
        });
      }
      
      }
      // 深拷贝数据
      const listString = JSON.stringify(columns[newIndex]);
      columns[newIndex] = JSON.parse(listString);
      this.handleSelectItem(columns[newIndex]) 
    },
  }
  
};
</script>
