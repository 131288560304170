<template> 
    <el-table-column  :prop="column.prop" :width="column.width" :show-overflow-tooltip="true" :header-align="column.headerAlign || 'center'" :align="column.align || 'center'" :label="column.label">
    	
    	<template v-if="column.children && column.children.length > 0">
    		<hbi-table-column v-for="(item,index) in column.children"  :column="item" :key="item.label + index" :models="models">
          <div v-if="item.show == undefined || item.show == true" >
            <template slot="header" v-if="item.dynamicLabel && item.labelScript">
              {{dynamicLabel(item.labelScript )}}
            </template>
            <template slot="expand" slot-scope="{row}">
              <slot :row="row"
                    :prop="item.prop"
                    :col="item"
                    :index="row.$index"
                    name="expand"></slot>
            </template>
          </div>
    		</hbi-table-column>
    	</template>
    	<template slot="header" v-if="column.dynamicLabel && column.labelScript">
    		{{dynamicLabel(column.labelScript )}}
    	</template>
		<template slot-scope="{row}">
        	<slot :row="row"
        	 	:col="column"
        	   	:prop="column.prop"
              	:index="row.$index"
              	name="expand"></slot>
      	</template> 
    </el-table-column>
 
</template>
<script>
export default {
    name: "hbi-table-column", 
    props: {
        column: {
            type: Object
        },
         models: {
            type: Object,
            required: true
        },
    }, 
    methods: {
    	dynamicLabel(labelScript) {
    		try{
    			const fn = eval(labelScript)

    			return fn(this.models)

    		} catch (error) {
                console.error('hbi-table spanMethod error ',error)
            }
    	}
    }
};
</script>