import Vue from 'vue'
import App from './App.vue'
import i18n from '@/i18n'
import router from './router'
import store from './store'
import * as echarts from 'echarts'
import VueCookie from 'vue-cookie' // api: https://github.com/alfhen/vue-cookie
import '@/element-ui' // api: https://github.com/ElemeFE/element
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/scss/fdc.css'
import 'babel-polyfill'
import '@/assets/fonts/iconfont.css'

import './styles/common.scss'
import './styles/avue-data.scss'
import './styles/avue-echart.scss'
import '@/assets/fonts/iconfont.css'
import '@/assets/scss/index.scss'
import '@/assets/scss/table.scss'
import httpRequest from '@/utils/httpRequest' // api: https://github.com/axios/axios
import { isAuth, back } from '@/utils'
import { dateFormat_ymd, datePipe } from '@/utils'
import cloneDeep from 'lodash/cloneDeep'
import '@/assets/fonts/iconfont.css'

import orgSelect from './components/org-select'
import deptSelect from './components/dept-select'

import dayjs from 'dayjs'
import website from '@/config/website'
import '@/utils/es6'
import './echarts/index'
import 'vant/lib/index.css'
import AVUE from '@smallwei/avue'
import '@smallwei/avue/lib/index.css'
import BINAMESPACE from '@/utils/biutils'
// 加载内部组件
import '@/components/index'
// micro-app
import microApp from '@micro-zoe/micro-app'
microApp.start()

// 引入地图
import VueAMap from 'vue-amap'
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: '484328e007350918ff3d3ae09491ad29',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.Geolocation', 'AMap.Geocoder', 'AMap.AMapManager', 'AMap.Marker', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  v: '1.4.4', //版本
  uiVersion: '1.0' //ui版本
})

import { BarChart, PieChart, MapChart, LineChart } from 'echarts/charts'
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import { TitleComponent, TooltipComponent, GridComponent, LegendComponent, VisualMapComponent, GeoComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
Vue.use(VueCookie)
Vue.use(AVUE, {
  i18n: (key, value) => i18n.t(key, value)
})
echarts.use([BarChart, PieChart, MapChart, LineChart, TitleComponent, TooltipComponent, GridComponent, GeoComponent, VisualMapComponent, LegendComponent, CanvasRenderer])

import AFTableColumn from 'af-table-column'
const fontRate = {
  CHAR_RATE: 1.1, // 汉字比率
  NUM_RATE: 0.65, // 数字
  OTHER_RATE:10 // 除汉字和数字以外的字符的比率
}
const fontSize =20
// 注册组件
Vue.use(AFTableColumn, { fontRate, fontSize })

// 挂载全局
Vue.prototype.$http = httpRequest // ajax请求方法
Vue.prototype.isAuth = isAuth // 权限方法
Vue.prototype.back = back
Vue.prototype.dateFormat_ymd = dateFormat_ymd
Vue.prototype.datePipe = datePipe
Vue.prototype.$dayjs = dayjs
Vue.prototype.website = website
// bi数据
Vue.prototype.BINAMESPACE = BINAMESPACE

Vue.component('deptSelect', deptSelect)
Vue.component('orgSelect', orgSelect)


Vue.config.productionTip = false
Vue.prototype.$echarts = echarts

Vue.directive('loadmore', {
  bind(el, binding) {
    const selectWrap = el.querySelector('.el-table__body-wrapper')
    selectWrap.addEventListener('scroll', function() {
      let sign = 1
      const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
      if (this.scrollHeight == this.clientHeight) return
      if (scrollDistance <= sign) {
        binding.value(this.scrollTop)
      }
    })
  }
})

window.SITE_CONFIG['storeState'] = cloneDeep(store.state)
window._AMapSecurityConfig = {
  securityJsCode: 'a91de4d36aee02f647bbf4c2011aacda'
}

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
