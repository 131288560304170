/* 数据字典的资源 */
export default {
    /* 模块名字 */
    name: 'orgunit',

    /* 英文资源 */
    en_US: {
        orgunitSecondName:'Second name',
        orgunitShortenedName:'Short Name',
        orgunitParentName:'Parent Organization',
        orgunitPleaseParentName:'Please select a parent organization',
        orgunitState:'State',
        orgunitPleaseState:'Please select a status',
        orgunitOrganization:'Type of organization',
        orgunitPleaseOrganization:'Please select the organization type',
        orgunitInternal:'Internal organization or not',
        orgunitPleaseInternal:'Please select whether internal organization',
        orgunitPleaseShortenedName:'Please enter the English abbreviation',
        orgunitInstitutional:'Institutional type',
        orgunitPleaseInstitutional:'Please select the organization type',
        orgunitGroup:'Group or not',
        orgunitPleaseGroup:'Please select Group',
        orgunitPublic:'Public or not',
        orgunitPleasePublic:'Please select whether to publish',
        orgunitLevel:'Level',
        orgunitRoute:'Route',
        orgunitDescribe:'Describe',
        orgunitAbbreviation:'Abbreviation cannot be empty',
        orgunitThe:'The path cannot be empty',
        orgunitIdentification:'The internal and external identification cannot be empty',
        takeBack:'Take Back',

    },

    /* 中文资源 */
    zh_CN: {
        orgunitSecondName:'第二名称',
        orgunitShortenedName:'简称',
        orgunitParentName:'上级组织',
        orgunitPleaseParentName:'请选择上级组织',
        orgunitState:'状态',
        orgunitPleaseState:'请选择状态',
        orgunitOrganization:'机构类型',
        orgunitPleaseOrganization:'请选择机构类型',
        orgunitInternal:'是否内部机构',
        orgunitPleaseInternal:'请选择是否内部机构',
        orgunitPleaseShortenedName:'请输入英文简称',
        orgunitInstitutional:'机构类型',
        orgunitPleaseInstitutional:'请选择机构类型',
        orgunitGroup:'是否集团',
        orgunitPleaseGroup:'请选择是否集团',
        orgunitPublic:'是否公开',
        orgunitPleasePublic:'请选择是否公开',
        orgunitLevel:'级别',
        orgunitRoute:'路径',
        orgunitDescribe:'描述',
        orgunitAbbreviation:'简称不能为空',
        orgunitThe:'路径不能为空',
        orgunitIdentification:'内外标识不能为空',
        takeBack:'带回',

    },
};
