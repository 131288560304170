// 对外输出 包含组件的对外json定义、属性配置页面、展示页面 三部分
 

import BaseIndex from './index.vue'
import BaseProperties from './properties/index.vue' 
import { styleConfig } from '../../../config/common/style.js'

import { getDataSourceConfig } from '../../../config/common/datasource.js'

// 默认的静态数据
const staticDefaultData = `
[
    {"id":1,"name":"张三","sex":1},
    {"id":2,"name":"李四","sex":2}
]
`

const datasourceConfig = getDataSourceConfig({ dataSourceDefault: '2', dataStatic: staticDefaultData })


const tableConfig = {
    type: "table", // 表单类型
    label: "表格", // 标题文字  
    component: BaseIndex,
    properties: BaseProperties,
    backgroundColor: '#FFFFFF',
    span: 24,
    formOptions: {
        config: {
            size: 'mini',
            labelWidth: 70,
            emptyBtn: false,
            submitBtn: false
        },
        group: [
            {...styleConfig}, // 样式,
            {
                ...datasourceConfig
            },
            {
                label: '数据',
                prop: 'data',
                collapse: false,
                show: '$.dataSource.type == 2',
                column: [{
                        label: '数据来源',
                        prop: 'dataModelId',
                        tip: '点击选择数据来源',
                        span: 24,
                    },
                    {
                        label: '数据来源',
                        prop: 'dataModelName',
                        show: false,
                        span: 24,
                    },
                    {
                        label: '分页',
                        prop: 'page',
                        type: 'switch',
                        default: true,
                        span: 24,
                    },
                    {
                        label: '最大条数',
                        prop: 'limit',
                        type: 'number',
                        default: 100,
                        min: 1,
                        max: 1000,
                        span: 24,
                    },

                ]
            },
            {
                label: '外观',
                prop: 'layout',
                collapse: false,
                column: [{
                        label: '标题',
                        prop: 'title',
                        span: 24,
                    },
                    {
                        
                        label: '标题位置',
                        prop: 'titlePosition',
                        span: 24,
                        type: 'select',
                        default: 'left',
                        dicData: ["left", "center", "right"].map(t => {
                            return { label: t, value: t }
                        }),
                    },
                    {
                        
                        label: '行/列合并',
                        prop: 'spanMethod',
                        span: 24,
                        type: 'textarea',
                        placeholder: '参考element-ui中tablespan-method。方法的参数是一个对象，里面包含当前行row、当前列column、当前行号rowIndex、当前列号columnIndex四个属性。该函数可以返回一个包含两个元素的数组，第一个元素代表rowspan，第二个元素代表colspan。 也可以返回一个键名为rowspan和colspan的对象'
                    },
                    {
                        label: '自动高度',
                        prop: 'autoHeight',
                        type: 'switch', 
                        default: true
                    },
                    {
                        label: '高度',
                        prop: 'height',
                        type: 'number',
                        default: 400,
                        show: '!$.layout.autoHeight',
                        min: 50,
                        max: 1000,
                        span: 24,
                    },
                    {
                        label: '显示下标',
                        prop: 'showIndex',
                        type: 'switch',
                        default: false,
                        span: 24,
                    },

                ]
            },
            {
                label: '字段',
                prop: 'columns',
                custom: true,
                collapse: false,
                default: []
            },
            {
                label: '数据过滤',
                prop: 'filter',
                custom: true,
                show: '$.dataSource.type == 2',
                collapse: false,
                default: []
            },
            {
                label: '事件',
                prop: 'event',
                custom: true,
                collapse: false
            },  
        ]
    },
    options: {
        tooptip: '', // 提示 
        hidden: false, // 是否隐藏，false显示，true隐藏 
    },
    model: "", // 数据字段
    key: ""
}



export default [tableConfig]