var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-size":"100% 100%","display":"grid"},style:({
color: _vm.record.style && _vm.record.style.color ? _vm.record.style.color : '',

width: '200px',
height: _vm.record.layout && _vm.record.layout.height ? _vm.record.layout.height : '',
backgroundImage: _vm.record.style && _vm.record.layout.backgroundImage ? 'url(' + _vm.record.layout.backgroundImage + ')' : '',
})},[_c('span',{staticStyle:{"margin":"15px 20px 0"},style:({
      fontSize: (_vm.record.layout && _vm.record.layout.titleSize ? _vm.record.layout.titleSize:13)+'px',
      fontWeight: _vm.record.layout && _vm.record.layout.titleBold ? 'bold': '',
      color: _vm.record.layout && _vm.record.layout.titleColor ? _vm.record.layout.titleColor: '',
      })},[_vm._v(_vm._s(_vm.record.layout.title))]),_c('span',[_c('span',{staticStyle:{"margin":"20px"},style:({
              fontSize: (_vm.record.layout && _vm.record.layout.contentSize ? _vm.record.layout.contentSize:33)+'px',
          })},[_vm._v(_vm._s(_vm.data))]),_c('span',{staticClass:"unit"},[_vm._v(_vm._s(_vm.record.layout.unit))])]),_c('span',{staticStyle:{"font-size":"11px","margin":"10px 20px 0"}},[_vm._v(_vm._s(_vm.record.layout.description))])])
}
var staticRenderFns = []

export { render, staticRenderFns }