export default {
    /*
    * 项目立项，项目完善，项目注册，项目变更，CBS管理国际化资源
    * */
    name:'appl',
    /*
    * 英文资源
    * */
    en_US:{
        projectName:'Project Name',
        projectType:'Project Type',
        projectRequirementUnit:'Requirement Unit',
        principal:'Principal',
        projectBudget:'Project Budget(Yuan)',
        planImplementationDate:'Plan Implementation Date',
        applyDate:'Apply Date',
        approvalStatus:'Approval Status',
        number:'NO.',
        currency:'Currency',
        applier:'Applier',
        operate:' Operate',
        edit:'Edit',
        delete:'Delete',
        look:'Look',
        approvalDetails:'Approval Details',
        equipmentShipped:'Equipment Shipped',
        add:'Add',
        query:'Query',
        export:'Export',
        import:'Import',
        estimatesImplementationDate:'Estimate implementation cycle',
        tips1:'Input number',
        tips2:'Select date',
        tip3:'Please Select Template',
        submitAudit:'Submit Audit',
        dataInsert:'Data Insert',
        projectPerfect:'Project Perfect',
        manager:'Manager',
        projectLocation:'Project Location',
        projectStatus:'Project Status',
        projectCode:'Project Code',
        unregistered:'Unregistered',
        projectRegister:'Project Register',
        changeOrNot:'Change Or Not',
        CBSManage:'CBS Manage',
        CBSConfig:'CBS Config',
        copyTemplateName:'Copy Template Name',
        cancel:'Cancel',
        confirm:'Confirm',
        baseInfo:'The Basic Information',
        projectCBSManager:'Project CBS Manager',
        CBSCode:'CBS Code',
        CBSName:'CBS Name',
        type:'Type',
        back:'Back',
        tips3:'Are you sure to perform ',
        tip:'Tip',
        tips4:'Please Select ',
        highMenu:'Higher Menu',
        tips5:' can not be empty!',
        tips6:'Only input number and point,For example: 1.2',
        selectFeeApplyForm:'Select Fee Apply Form',
    },
    /*
    * 中文资源
    * */
    zh_CN:{
        selectFeeApplyForm:'选择费用申请单',
        projectName: '项目名称',
        projectType:'项目类型',
        projectRequirementUnit:'项目需求单位',
        principal:'项目负责人',
        projectBudget:'项目预算（元）',
        planImplementationDate:'计划实施日期',
        applyDate:'申请日期',
        approvalStatus:'审批状态',
        number:'序号',
        currency:'币种',
        applier:'申请人',
        operate:'操作',
        estimatesImplementationDate:'预估实施周期',
        edit:'修改',
        delete:'删除',
        look:'查看',
        approvalDetails:'审批详情',
        equipmentShipped:'设备发运情况',
        add:'新增',
        query:'查询',
        export:'导出数据',
        import:'导入',
        tips1:'请输入数字',
        tips2:'选择日期',
        submitAudit:'提交审核',
        dataInsert:'数据入库',
		tip3:'请选择模板',
        projectPerfect: '项目完善',
        manager:'项目经理',
        projectLocation:'项目所在地',
        projectStatus:'项目状态',
        projectCode:'项目编号',
        unregistered:'未注册',
        projectRegister:'项目注册',
        changeOrNot:'是否变更',
        CBSManage:'CBS管理',
        CBSConfig:'CBS配置',
        copyTemplateName:'复制模板名称',
        cancel:'取消',
        confirm:'确认',
        baseInfo:'基本信息',
        projectCBSManager:'项目CBS配置',
        CBSCode:'CBS编号',
        CBSName:'CBS名称',
        type:'类型',
        back:'返回',
        tips3: '确定要进行',
        tip:'提示',
        tips4:'请选择',
        highMenu:'上级菜单',
        tips5:'不能为空',
        tips6:'仅允许输入数字+点格式例如：1.2',

    }
}