const t = {}

t.loading = 'Loading...'

t.brand = {}
t.brand.lg = 'MIMOSA System'
t.brand.mini = 'S'

t.add = 'Add'
t.save='Save'
t.refresh='Refresh'
t.release = 'Release'
t.version='Version'
t.revoke='Revoke'
t.preview='Preview'
t.delete = 'Delete'
t.deleteBatch = 'Delete'
t.update = 'Edit'
t.query = 'Query'
t.querying='Querying'
t.export = 'Excel Export'
t.import = 'Excel Import'
t.handle = 'Action'
t.confirm = 'Confirm'
t.cancel = 'Cancel'
t.logout = 'Sign Out'
t.init = 'Initialize'
t.personalinformation = 'Personal Information'
t.currentPage = 'Current Page'
t.enable = 'enable'
t.disable = 'disable'
t.choice = 'choice'
t.success='Success'
t.failed='Failed'
t.netError='Network exception'
t.operate=' operate'

t.theme = {}
t.theme.title = 'Theme Setting'
t.theme.layout = 'top layout'
t.theme.openTabs = 'open Tabs'
t.theme.openBreadcrumb = 'open Breadcrumb'
t.theme.breadcrumbLayout = 'breadcrumb Layout'
t.theme.header = 'header'
t.theme.content = 'content'

t.prompt = {}
t.prompt.title = 'Prompt'
t.prompt.info = 'Confirm to carry out [{handle}] operation?'
t.prompt.success = 'Succeeded'
t.prompt.failed = 'Failed'
t.prompt.deleteBatch = 'Please select delete item'

t.validate = {}
t.validate.required = 'Required field cannot be empty'
t.validate.format = '{attr} format error'
t.validate.certNo = 'License number'
t.validate.mobile = 'Mobile'
t.validate.username = 'User name'
t.validate.custName = 'Customer name'
t.validate.password = 'Password'
t.validate.email = 'Email'
t.validate.bankCard = 'Bank card'

t.upload = {}
t.upload.text = 'Drop files here, or <em>Click Upload</em>'
t.upload.tip = 'Only support {format} format files! '
t.upload.button = 'Click to upload'

t.datePicker = {}
t.datePicker.range = 'To'
t.datePicker.start = 'Start Date'
t.datePicker.end = 'End Date'

t.fullscreen = {}
t.fullscreen.prompt = 'Your browser does not support this operation'

t.updatePassword = {}
t.updatePassword.title = 'Change Password'
t.updatePassword.username = 'Account'
t.updatePassword.password = 'Original'
t.updatePassword.newPassword = 'New Password'
t.updatePassword.comfirmPassword = 'Confirm'
t.updatePassword.validate = {}
t.updatePassword.validate.comfirmPassword = 'Confirm password is not consistent with new password input'

t.contentTabs = {}
t.contentTabs.closeCurrent = 'Close Current Tab'
t.contentTabs.closeOther = 'Close Other Tabs'
t.contentTabs.closeAll = 'Close All Tabs'

/* pages */
t.notFound = {}
t.notFound.desc = 'Sorry! <em>missing</em> on the page you visited...'
t.notFound.back = 'Previous Page'
t.notFound.home = 'Home'

t.login = {}
t.login.title = 'Sign In'
t.login.username = 'Username'
t.login.password = 'Password'
t.login.captcha = 'Verification Code'
t.login.demo = 'Demo'
t.login.copyright = 'Finewe'

t.home = {}
t.home.sysInfo = {}
t.home.sysInfo.name = 'System Name'
t.home.sysInfo.nameVal = 'finflow los'
t.home.sysInfo.version = 'Version Information'
t.home.sysInfo.versionVal = window.SITE_CONFIG['version']
t.home.sysInfo.osName = 'Operating System'
t.home.sysInfo.osVersion = 'System Version'
t.home.sysInfo.osArch = 'System Architecture'
t.home.sysInfo.processors = 'CPU Core Count'
t.home.sysInfo.totalPhysical = 'system Memory'
t.home.sysInfo.freePhysical = 'Remaining Memory'
t.home.sysInfo.memoryRate = 'Memory Usage'
t.home.sysInfo.userLanguage = 'System Language'
t.home.sysInfo.jvmName = 'JVM Information'
t.home.sysInfo.javaVersion = 'JVM Version'
t.home.sysInfo.javaHome = 'JAVA_HOME'
t.home.sysInfo.userDir = 'Working Directory'
t.home.sysInfo.javaTotalMemory = 'JVM Occupies Memory'
t.home.sysInfo.javaFreeMemory = 'JVM Free Memory'
t.home.sysInfo.javaMaxMemory = 'JVM Max Memory'
t.home.sysInfo.userName = 'Current User'
t.home.sysInfo.systemCpuLoad = 'CPU Load'
t.home.sysInfo.userTimezone = 'System Time Zone'

/* modules */
t.model = {}
t.model.name = 'Name'
t.model.key = 'Information'
t.model.version = 'Version'
t.model.createTime = 'Create Time'
t.model.lastUpdateTime = 'Update Time'
t.model.design = 'Online Design'
t.model.deploy = 'Deployment'
t.model.description = 'Description'

t.process = {}
t.process.name = 'name'
t.process.key = 'Identification'
t.process.deployFile = 'Deploy process file'
t.process.id = 'Process ID'
t.process.deploymentId = 'Deployment ID'
t.process.version = 'Version'
t.process.resourceName = 'XML'
t.process.diagramResourceName = 'Image'
t.process.deploymentTime = 'Deployment Time'
t.process.active = 'Activate'
t.process.suspend = 'Hang'
t.process.convertToModel = 'Convert to model'

t.running = {}
t.running.id = 'Instance ID'
t.running.definitionKey = 'Define Key'
t.running.processDefinitionId = 'Define ID'
t.running.processDefinitionName = 'Define the name'
t.running.activityId = 'Current Link'
t.running.suspended = 'Whether Hang'
t.running.suspended0 = 'No'
t.running.suspended1 = 'Yes'

t.news = {}
t.news.title = 'Title'
t.news.pubDate = 'Publish Time'
t.news.createDate = 'Create Time'
t.news.content = 'Content'

t.schedule = {}
t.schedule.beanName = 'Bean Name'
t.schedule.beanNameTips = 'Spring bean name, eg: testTask'
t.schedule.pauseBatch = 'Pause'
t.schedule.resumeBatch = 'Recovery'
t.schedule.runBatch = 'Execution'
t.schedule.log = 'Log List'
t.schedule.params = 'Parameters'
t.schedule.cronExpression = 'Cron Expression'
t.schedule.cronExpressionTips = 'Example: 0 0 12 * * ?'
t.schedule.remark = 'Remarks'
t.schedule.status = 'Status'
t.schedule.status0 = 'Pause'
t.schedule.status1 = 'Normal'
t.schedule.statusLog0 = 'Failed'
t.schedule.statusLog1 = 'Success'
t.schedule.pause = 'Pause'
t.schedule.resume = 'Restore'
t.schedule.run = 'Execute'
t.schedule.jobId = 'Task ID'
t.schedule.times = 'Time-consuming (unit: milliseconds)'
t.schedule.createDate = 'Execution Time'

t.mail = {}
t.mail.name = 'Name'
t.mail.config = 'Mail Configuration'
t.mail.subject = 'Theme'
t.mail.createDate = 'Create Time'
t.mail.send = 'Send Mail'
t.mail.content = 'Content'
t.mail.smtp = 'SMTP'
t.mail.port = 'Port Number'
t.mail.username = 'Email Account'
t.mail.password = 'Mailbox Password'
t.mail.mailTo = 'Recipient'
t.mail.mailCc = 'Cc'
t.mail.params = 'Template Parameter'
t.mail.paramsTips = 'Example: {"code": "123456"}'
t.mail.templateId = 'Template ID'
t.mail.status = 'Status'
t.mail.status0 = 'Send Failed'
t.mail.status1 = 'Successfully Sent'
t.mail.mailFrom = 'Sender'
t.mail.createDate = 'Send Time'

t.sms = {}
t.sms.mobile = 'Mobile Number'
t.sms.status = 'Status'
t.sms.status0 = 'Send Failed'
t.sms.status1 = 'Successfully Sent'
t.sms.config = 'SMS Configuration'
t.sms.send = 'Send SMS'
t.sms.platform = 'platform Type'
t.sms.platform1 = 'Alibaba Cloud'
t.sms.platform2 = 'Tencent Cloud'
t.sms.params = 'Parameters'
t.sms.paramsTips = 'eg: {"code": "123456"}'
t.sms.params1 = 'Parameter 1'
t.sms.params2 = 'Parameter 2'
t.sms.params3 = 'Parameter 3'
t.sms.params4 = 'Parameter 4'
t.sms.createDate = 'Send Time'
t.sms.aliyunAccessKeyId = 'Key'
t.sms.aliyunAccessKeyIdTips = 'Alibaba Cloud AccessKeyId'
t.sms.aliyunAccessKeySecret = 'Secret'
t.sms.aliyunAccessKeySecretTips = 'Alibaba Cloud AccessKeySecret'
t.sms.aliyunSignName = 'SMS Signature'
t.sms.aliyunTemplateCode = 'SMS Template'
t.sms.aliyunTemplateCodeTips = 'SMS Template CODE'
t.sms.qcloudAppId = 'AppId'
t.sms.qcloudAppIdTips = 'Tencent Cloud AppId'
t.sms.qcloudAppKey = 'AppKey'
t.sms.qcloudAppKeyTips = 'Tencent Cloud AppKey'
t.sms.qcloudSignName = 'SMS Signature'
t.sms.qcloudTemplateId = 'SMS Template'
t.sms.qcloudTemplateIdTips = 'SMS template ID'

t.oss = {}
t.oss.config = 'Cloud Storage Configuration'
t.oss.upload = 'Upload File'
t.oss.url = 'URL Address'
t.oss.createDate = 'Create Time'
t.oss.type = 'Type'
t.oss.type1 = 'Seven Cows'
t.oss.type2 = 'Alibaba Cloud'
t.oss.type3 = 'Tencent Cloud'
t.oss.type4 = 'FastDFS'
t.oss.type5 = 'Local Upload'
t.oss.qiniuDomain = 'Domain Name'
t.oss.qiniuDomainTips = 'Seven cattle bound domain name'
t.oss.qiniuPrefix = 'Path Prefix'
t.oss.qiniuPrefixTips = 'Do not set default to empty'
t.oss.qiniuAccessKey = 'AccessKey'
t.oss.qiniuAccessKeyTips = 'Seven cattle AccessKey'
t.oss.qiniuSecretKey = 'SecretKey'
t.oss.qiniuSecretKeyTips = 'Seven Cow SecretKey'
t.oss.qiniuBucketName = 'Space Name'
t.oss.qiniuBucketNameTips = 'Seven cattle storage space name'
t.oss.aliyunDomain = 'Domain Name'
t.oss.aliyunDomainTips = 'Alibaba Cloud bound domain name, such as: http://cdn.renren.io'
t.oss.aliyunPrefix = 'Path Prefix'
t.oss.aliyunPrefixTips = 'Do not set default to empty'
t.oss.aliyunEndPoint = 'EndPoint'
t.oss.aliyunEndPointTips = 'Ali Cloud EndPoint'
t.oss.aliyunAccessKeyId = 'AccessKeyId'
t.oss.aliyunAccessKeyIdTips = 'Alibaba Cloud AccessKeyId'
t.oss.aliyunAccessKeySecret = 'AccessKeySecret'
t.oss.aliyunAccessKeySecretTips = 'Alibaba Cloud AccessKeySecret'
t.oss.aliyunBucketName = 'BucketName'
t.oss.aliyunBucketNameTips = 'Alibaba Cloud BucketName'
t.oss.qcloudDomain = 'Domain Name'
t.oss.qcloudDomainTips = 'Tencent cloud bound domain name'
t.oss.qcloudPrefix = 'Path Prefix'
t.oss.qcloudPrefixTips = 'Do not set default to empty'
t.oss.qcloudAppId = 'AppId'
t.oss.qcloudAppIdTips = 'Tencent Cloud AppId'
t.oss.qcloudSecretId = 'SecretId'
t.oss.qcloudSecretIdTips = 'Tencent Cloud SecretD'
t.oss.qcloudSecretKey = 'SecretKey'
t.oss.qcloudSecretKeyTips = 'Tencent Cloud SecretKey'
t.oss.qcloudBucketName = 'BucketName'
t.oss.qcloudBucketNameTips = 'Tencent Cloud BucketName'
t.oss.qcloudRegion = 'Affiliate'
t.oss.qcloudRegionTips = 'Please Select'
t.oss.qcloudRegionBeijing1 = 'Beijing District 1 (North China)'
t.oss.qcloudRegionBeijing = 'Beijing'
t.oss.qcloudRegionShanghai = 'Shanghai (East China)'
t.oss.qcloudRegionGuangzhou = 'Guangzhou (South China)'
t.oss.qcloudRegionChengdu = 'Chengdu (Southwest)'
t.oss.qcloudRegionChongqing = 'Chongqing'
t.oss.qcloudRegionSingapore = 'Singapore'
t.oss.qcloudRegionHongkong = 'HongKong'
t.oss.qcloudRegionToronto = 'Toronto'
t.oss.qcloudRegionFrankfurt = 'Frankfurt'
t.oss.localDomain = 'Domain Name'
t.oss.localDomainTips = 'Binded domain name, eg http://cdn.renren.io'
t.oss.fastdfsDomain = 'Domain Name'
t.oss.fastdfsDomainTips = 'Binded domain name, eg http://cdn.renren.io'
t.oss.localPrefix = 'Path Prefix'
t.oss.localPrefixTips = 'Do not set default to empty'
t.oss.localPath = 'Storage Directory'
t.oss.localPathTips = 'eg: D:/upload'

t.dept = {}
t.dept.name = 'Name'
t.dept.parentName = 'Superior'
t.dept.sort = 'Sort'
t.dept.parentNameDefault = 'Top Department'

t.dict = {}
t.dict.dictName = 'Name'
t.dict.dictType = 'Type'
t.dict.dictValue = 'Value'
t.dict.sort = 'Sort'
t.dict.remark = 'Remarks'
t.dict.createDate = 'Create Date'

t.logError = {}
t.logError.requestUri = 'Request URI'
t.logError.requestMethod = 'Request Method'
t.logError.requestParams = 'Request Parameters'
t.logError.ip = 'IP'
t.logError.userAgent = 'User Agent'
t.logError.createDate = 'Create Date'
t.logError.errorInfo = 'Exception'

t.logLogin = {}
t.logLogin.createrName = 'Username'
t.logLogin.status = 'Status'
t.logLogin.status0 = 'Failed'
t.logLogin.status1 = 'Success'
t.logLogin.status2 = 'Locked'
t.logLogin.operation = 'User Action'
t.logLogin.operation0 = 'Login'
t.logLogin.operation1 = 'Exit'
t.logLogin.ip = 'IP'
t.logLogin.userAgent = 'User-Agent'
t.logLogin.createDate = 'Create Date'

t.logOperation = {}
t.logOperation.status = 'Status'
t.logOperation.status0 = 'Failed'
t.logOperation.status1 = 'Success'
t.logOperation.creatorName = 'Username'
t.logOperation.operation = 'User Action'
t.logOperation.requestUri = 'Request URI'
t.logOperation.requestMethod = 'Request Mode'
t.logOperation.requestParams = 'Request Parameters'
t.logOperation.requestTime = 'Request Duration'
t.logOperation.ip = 'IP'
t.logOperation.userAgent = 'User-Agent'
t.logOperation.createDate = 'Create Date'

t.menu = {}
t.menu.name = 'Name'
t.menu.icon = 'Icon'
t.menu.type = 'Type'
t.menu.type0 = 'Menu'
t.menu.type1 = 'Button'
t.menu.sort = 'Sort'
t.menu.url = 'Route'
t.menu.permissions = 'Auth ID'
t.menu.permissionsTips = 'Multiple separated by commas, such as: sys:menu:save,sys:menu:update'
t.menu.parentName = 'Superior'
t.menu.parentNameDefault = 'Top Menu'
t.menu.resource = 'Auth Resources'
t.menu.resourceUrl = 'Resource URL'
t.menu.resourceMethod = 'Request Method'
t.menu.resourceAddItem = 'Add an Item'

t.params = {}
t.params.paramCode = 'Code'
t.params.paramValue = 'Value'
t.params.remark = 'Remarks'

t.role = {}
t.role.name = 'Name'
t.role.remark = 'Remarks'
t.role.createDate = 'Create Date'
t.role.menuList = 'Menu Scope'
t.role.deptList = 'Data Scope'

t.user = {}
t.user.username = 'Username'
t.user.deptName = 'Department'
t.user.email = 'Email'
t.user.mobile = 'Mobile'
t.user.status = 'Status'
t.user.status0 = 'Disable'
t.user.status1 = 'Enable'
t.user.createDate = 'Create Date'
t.user.password = 'Password'
t.user.comfirmPassword = 'Confirm'
t.user.realName = 'Real Name'
t.user.gender = 'Gender'
t.user.gender0 = 'Male'
t.user.gender1 = 'Female'
t.user.gender2 = 'Secure'
t.user.roleIdList = 'Role Config'
t.user.validate = {}
t.user.validate.comfirmPassword = 'Confirm password is not consistent with password input'

t.report={}
t.report.title='Report Name'
t.report.NO='ID'
t.report.operate=' operate'
t.report.code='Report Code'
t.report.name='Report Name'
t.report.file='Report File'
t.report.describe='Describe'
t.report.ErrorEmpty='Input cannot be empty!'
t.report.ErrorSpace='Cannot be space!'
t.report.tip1='Are you sure you have saved the currently edited report?'
t.report.title='Tip'
t.report.tip2='Are you sure to [ \'deploy\'] the report?'
t.report.tip3='Are you sure to '
t.report.deleteBash='Bash delete'
t.report.flowSort='Flow Sort'
t.report.flowForm='Flow Form'
t.report.form='Form'


t.form={}
t.form.name='Form Name'
t.form.id='Form ID'
t.form.modelName='Model Name'
t.form.version='Version'
t.form.createTime='Create Time'
t.form.state='State'
t.form.operate=' Operate'
t.form.release='Release'
t.form.temporary='Temporary'
t.form.title='Tip'
t.form.tip1='Are you sure you want to undo the current template version?'
t.form.tip2='Are you sure you want to publish the current template?'
t.form.tip3='Are you sure for [name='
t.form.tip4='] to '

t.flow={}
t.flow.NO='ID'
t.flow.name='Flow Name'
t.flow.copy='Flow Copy'
t.flow.deploy='Deploy'
t.flow.deployOrNot='DeployOrNot'
t.flow.yes='Yes'
t.flow.not='No'
t.flow.operate='Operate'
t.flow.modelName='Model Name'
t.flow.modelKey='Model Key'
t.flow.modelDescribe='Model Describe'
t.flow.eventType='Event Type'
t.flow.bindModel='Bind Model'
t.flow.bindModelKey='Bind Model Key'
t.flow.displayComponents='Display Components'
t.flow.operate='Operate'
t.flow.addModelBind='Add Model Bind'
t.flow.updateModelBind='Update Model Bind'
t.flow.viewComponents='View Components'
t.flow.correspondFlow='Correspond Flow'
t.flow.beCopy='Be copy flow'
t.flow.beCover='Be cover flow'
t.flow.tip1='Are you sure you have saved the current edited process?'
t.flow.tip2='Are you sure you want to delete it?'
t.flow.tip3='Are you sure you want to perform a process copy operation?'
t.flow.tip4='Are you sure you want to [ \'deploy\'] the process?'
t.flow.title='Tip'
t.flow.tip5='Cannot be empty！'
t.flow.tip6='There must be no spaces at both ends of the input content!'
t.flow.tip7=' cannot be empty!'
t.flow.tip8='Are you sure you want to '
t.flow.deleteBash='Bash delete'


t.job={}
t.job.beanName='Bean Name'
t.job.methodName='Method Name'
t.job.remark='Remark'
t.job.parameter='Parameter'
t.job.cronExpression='Cron Expression'
t.job.state='State'
t.job.operate='Operate'
t.job.pauseBatch='Pause Batch'
t.job.recoverBatch='Recover Batch'
t.job.runBatch='Run Batch'
t.job.logList='Log List'
t.job.tip1='Spring Bean‘s Name，For Example testMask'
t.job.tip2='For Example 0 0 12 * * ?'
t.job.tip3='Bean name cannot be empty'
t.job.tip4='Method name cannot be empty'
t.job.tip5='Cron expression cannot be empty'
t.job.taskID='Task ID'
t.job.logID='Log ID'
t.job.executionTime='Exexution Time'
t.job.useTime='Use Time'
t.job.unit='Unit'
t.job.unitFor='ms'
t.job.NO='ID'
t.job.normal='Normal'
t.job.pause='Pause'
t.job.recover='Recover'
t.job.run='Run'
t.job.tip6= 'Are you sure to '
t.job.title= 'Tip'
t.job.deleteBash='Bash Delete'
t.job.recoverBash='bash recover'
t.job.runBash='bash run'


t.common={}
t.common.formmaterialtemplate={}
t.common.formmaterialtemplate.materialSort='Material Sort'
t.common.formmaterialtemplate.materialName='Material Name'
t.common.formmaterialtemplate.materialCode='Material Code'
t.common.formmaterialtemplate.materialID='Material ID'
t.common.formmaterialtemplate.fileType='File Type'
t.common.formmaterialtemplate.materialNecessary='Material Necessary'
t.common.formmaterialtemplate.from='From'
t.common.formmaterialtemplate.operate='Operate'
t.common.formmaterialtemplate.tip1='Select'
t.common.formmaterialtemplate.instructions='Instructions'
t.common.formmaterialtemplate.sampleTable='Sample Table'
t.common.formmaterialtemplate.tip1=' cannot be empty'
t.common.formmaterialtemplate.tip2='File cannot be empty'
t.common.formmaterialtemplate.tip3='Are you sure for [name= '
t.common.formmaterialtemplate.tip4='] to '
t.common.formmaterialtemplate.title='Tip'
t.common.formmaterialtemplate.deleteBash=' bash delete '

t.msg={}
t.msg.type={}
t.msg.type.name='Name'
t.msg.type.ID='ID'
t.msg.type.superiorName='Superior Name'
t.msg.type.modelTitle='Model Title'
t.msg.type.remark='Remark'
t.msg.type.operate='Operate'
t.msg.type.msgTitle='Msg Title'
t.msg.type.msgModel='Msg Model'
t.msg.type.superiorType='Superior Type'
t.msg.type.routeName='Route Name'
t.msg.type.tip1= 'Primary key,2 bits per level'
t.msg.type.tip2= 'ID cannot be empty'
t.msg.type.tip3= 'Name cannot be empty'
t.msg.type.tip4= 'Are you sure to '
t.msg.type.title= 'Tip'
t.msg.type.deleteBash='bash Delete'
t.msg.type.businessModel='Business Model'
t.msg.type.messageType='Message Type'
t.msg.type.messagePoint='Message Point'
t.msg.type.messageReceiver='Message Receiver'
t.msg.type.inStaProcessPage='In Station Process Page'
t.msg.type.oaProcessPage='OA Process Page'
t.msg.type.messageReceiverType='Message Receiver Type'


t.project={}
t.project.cbsTemplate={}
t.project.cbsTemplate.modelName='Model Name'
t.project.cbsTemplate.modelDescribe='Model Describe'
t.project.cbsTemplate.takeEffectOrNot='Take Effect Or Not'
t.project.cbsTemplate.operate='Operate'
t.project.cbsTemplate.modelUpdate='Model Update'
t.project.cbsTemplate.CBSListManage='CBS List Manage'
t.project.cbsTemplate.copyName='Copy Model Name'
t.project.cbsTemplate.select='Please Select '
t.project.cbsTemplate.tip1=' Cannot Be Empty'
t.project.cbsTemplate.model='Model'
t.project.cbsTemplate.copyModel='Copy Model'
t.project.cbsTemplate.basicInform='Basic Information'
t.project.cbsTemplate.tip2= 'Are you sure to '
t.project.cbsTemplate.title= 'Tip'
t.project.cbsTemplate.deleteBash='bash delete'


/* 模块 */
import dataDic from './modules/sys_mannager/dataDic'
import users from './modules/sys_mannager/users'
import rules from './modules/sys_mannager/rules'
import menus from './modules/sys_mannager/menus'
import config from './modules/sys_mannager/config'
import orgmanager from './modules/sys_mannager/orgmanager'
import deptmanager from './modules/sys_mannager/deptmanager'
import syslog from './modules/sys_mannager/syslog'
import fileupload from './modules/sys_mannager/fileupload'
import suppliers from './modules/project/suppliers'
import projectAppl from './modules/project/projectAppl'
import projectorgmember from './modules/project/projectorgmember'
import projectorgunit from './modules/project/projectorgunit'
import invitation from './modules/project/invitationVisa'
import home from './modules/sys_mannager/home'
import projectorglevel from './modules/project/projectorglevel'
import equipmentShipped from './modules/project/equipmentShipped'
import progress from './modules/progressApproval/index'
import matters from './modules/mattersManage/index'
import dataManagement from './modules/project/dataManagement'
import productionValue from './modules/project/productionValue'
import sysNationResource from "./modules/sys_mannager/sysNationResource";


/* 设置值 */
(modules => modules.forEach(value => t[value.name] = value['en_US']))
    ([
        dataDic, users,
        rules, menus,
        config, orgmanager,
        deptmanager, syslog,
        fileupload, suppliers,
        projectorgmember,projectorgunit,
        projectAppl,invitation,
        home,projectorglevel,
        equipmentShipped,
        dataManagement,productionValue,
        progress, matters,
        sysNationResource
    ]);

export default t
