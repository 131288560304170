<!--
表单属性设置面板组件
-->
<template>
    <div class="properties-centent kk-checkbox">
        <div class="properties-body">
            <el-form size="mini">
                <!--   <el-form-item label="标签对齐方式">
          <el-radio-group v-model="config.labelPosition">
            <el-radio-button label="left">左对齐</el-radio-button>
            <el-radio-button label="right">右对齐</el-radio-button>
            <el-radio-button label="top">顶部对齐</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-divider></el-divider>

        <el-form-item label="标签宽度">
          <el-input-number v-model="config.labelWidth" :min="0" :max="200" :step="10"></el-input-number>
        </el-form-item> -->
                <el-form-item label="栅格间距">
                    <el-input-number v-model="config.gutter" :min="0" :max="100" :step="1"></el-input-number>
                </el-form-item>
                <el-form-item label="flex模式">
                   <el-switch
                      v-model="config.flex"
                      active-text="打开"
                      :inactive-value="false"
                      :active-value="true"
                      inactive-text="关闭">
                    </el-switch> 
                </el-form-item>
                <el-form-item label="水平排列方式" v-if="config.flex">
                    <el-select v-model="config.justify" clearable placeholder="请选择">
                        <el-option v-for="item in ['start', 'center', 'end', 'space-between', 'space-around']" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="垂直排列方式" v-if="config.flex">
                    <el-select v-model="config.align" clearable placeholder="请选择">
                        <el-option v-for="item in ['top','middle','bottom']" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item label="手机端">
                    <el-radio-group v-model="config.mobile">
                        <el-radio-button :label="true">是</el-radio-button>
                        <el-radio-button :label="false">否</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="背景颜色">
                    <avue-input-color v-model="config.backgroundColor" placeholder="请选择颜色"></avue-input-color>
                </el-form-item>
                <el-form-item label="组件尺寸">
                    <el-radio-group v-model="config.size">
                        <el-radio-button label="medium">medium</el-radio-button>
                        <el-radio-button label="small">small</el-radio-button>
                        <el-radio-button label="mini">mini</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <!--  <el-form-item label="校验字段提示"> 
          <el-switch
            v-model="config.hideRequiredMark"
            active-text="打开"
            :inactive-value="true"
            :active-value="false"
            inactive-text="关闭">
          </el-switch> 
        </el-form-item> 
        <el-form-item label="输出隐藏组件值"> 
          <el-switch
            v-model="config.outputHidden"
            active-text="打开" 
            inactive-text="关闭">
          </el-switch> 
        </el-form-item>
        <el-divider></el-divider>

        <el-form-item label="表单CSS">
          <el-input  type="textarea" v-model="config.customStyle" />
        </el-form-item>
        <el-divider></el-divider> -->
                <slot name="form-extend-properties"></slot>
                <el-form-item label="提示">
                    实际预览效果请点击预览查看
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    name: "formProperties",
    data() {
        return {
            formDefaultConfig: {
                labelPosition: "left",
                labelWidth: 100,
                size: 'mini',
                mobile: false,
                flex: false,
                justify: 'start',
                align: '',
                backgroundColor: '#FFFFFF',
                gutter: 20, // 默认栅格间距
                outputHidden: true, //  是否输出隐藏字段的值 默认打开,所有字段都输出
                hideRequiredMark: true,
                customStyle: ""
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        previewOptions: {
            type: Object,
            required: true
        }
    },
    mounted() {
      this.init()
    },
    methods: {
        init() {
          // 遍历每个属性 如果config不存在则使用默认值进行回填
          for(let key in this.formDefaultConfig) {
            if(key && !Object.prototype.hasOwnProperty.call(this.config, key)) {
              this.$set(this.config , key , this.formDefaultConfig[key])
            } 
          } 

          console.log('config' , this.config)
        }
    }
};
</script>
<style scoped>
::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner{
    background: #1E7CDD;
    border-color: #1E7CDD;
}
</style>