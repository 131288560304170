<template>
  <div class="mod-config">
    <el-form
      :inline="true"
      :model="dataForm"
      @keyup.enter.native="getDataList()"
    >
      <el-form-item :label="$t('syslog.userName') + ':'">
        <el-input
          v-model="dataForm.key"
          :placeholder="$t('syslog.userName')"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" @click="getDataList()" type="success" >{{ $t('query') }}</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <!-- <el-table-column
        prop="id"
        header-align="center"
        align="center"
        width="80"
        label="ID">
      </el-table-column>-->
      <el-table-column
        prop="username"
        header-align="center"
        align="center"
        width="150"
        :label="$t('syslog.userName')"
      >
      </el-table-column>
      <el-table-column
        prop="operation"
        header-align="center"
        align="center"
        width="150"
        :label="$t('syslog.userAction')"
      >
      </el-table-column>
      <el-table-column
        prop="method"
        header-align="center"
        align="center"
        width="200"
        :show-overflow-tooltip="true"
        :label="$t('syslog.requestMethod')"
      >
      </el-table-column>
      <el-table-column
        prop="params"
        header-align="center"
        align="center"
        :show-overflow-tooltip="true"
        :label="$t('syslog.requestMethod')"
      >
      </el-table-column>
      <el-table-column
        prop="time"
        header-align="center"
        align="center"
        width="150"
        :label="$t('syslog.execTime')"
      >
      </el-table-column>
      <el-table-column
        prop="ip"
        header-align="center"
        align="center"
        width="150"
        :label="$t('syslog.ipAddress')"
      >
      </el-table-column>
      <!-- <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        width="180"
        label="创建时间">
      </el-table-column> -->
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getList } from '@/api/sys/log'
export default {
  data() {
    return {
      dataForm: {
        key: ""
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      selectionDataList: []
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      
      getList({
          page: this.pageIndex,
          limit: this.pageSize,
          key: this.dataForm.key
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalCount = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalCount = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    }
  }
};
</script>
