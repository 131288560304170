/* 数据字典的资源 */
export default {
    /* 模块名字 */
    name: 'dataDic',

    /* 英文资源 */
    en_US: {
        typeName: 'Type Name',
        enTypeName: 'English name of type',
        dicType: 'Dic Type',
        tagValue: 'Tag',
        enTagValue: 'English Tag',
        value: 'Value',
        tag: 'Tag',
        number: 'Number',
        updateTime: 'Update Time',
        status: 'Status',
        available: 'available',
        unavailable: 'unavailable',
        remark: 'remark',
        // 删除字典分类
        deleteDicTypeInfo: 'Are you sure you want to delete the dictionary classification ',
        deleteTag: 'Are you sure you want to delete',
        tips: 'tips',

    },

    /* 中文资源 */
    zh_CN: {
        typeName: '类型名称',
        enTypeName: '类型英文名称',
        dicType: '字典分类',
        tagValue: '标签名',
        enTagValue: '英文标签名',
        value: '值',
        number: '序号',
        updateTime: '更新时间',
        status: '状态',
        available: '可用',
        unavailable: '不可用',
        remark: '备注',
        // 删除字典分类
        deleteDicTypeInfo: '确定删除字典分类 ',
        deleteTag: '确定进行删除操作',
        tips: '提示'

    }

}




