/* 数据字典的资源 */
export default {
    /* 模块名字 */
    name: 'fileupload',

    /* 英文资源 */
    en_US: {
        uploadText: 'Uploaded file ID collection',
        testStep: 'test process',
        export: ' export',
        backEndExportTemp: 'Backend export template form',
        backEndExportTab: 'Back-end export data table',
        flowTypeNotEmpty: 'flowType cannot be empty',
        sexNotEmpty: 'sex cannot be empty',
        date: 'Date',
        name: 'Name',
        address: 'Address',


    },

    /* 中文资源 */
    zh_CN: {
        uploadText: '上传的文件ID集合',
        testStep: '测试流程',
        export: '导出',
        backEndExportTemp: '后端导出模板表格',
        backEndExportTab: '后端导出数据表格',
        flowTypeNotEmpty: 'flowType不能为空',
        sexNotEmpty: 'sex不能为空',
        date: '日期',
        name: '姓名',
        address: '地址',


    }

}




