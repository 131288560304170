import http from '@/utils/httpRequest'
import { encryptDes } from "@/utils/des"

/**
 * 获取分页列表，可选参数：
 * page: 当前页；
 * limit: 每页数量；
 * name: 模型名称-关键字；
 * dmType: 模型类型；
 * categoryId: ；分组id
 * @param {*} params 查询参数对象
 * @returns 分页对象
 */
export function getList(params) {
  return http({
    url: http.adornUrl('/hbi/datamodel/list'),
    method: 'get',
    params: params
  })
}

/**
 * 根据id数组删除数据
 * @param {Array} ids id数组
 * @returns 删除结果
 */
export function deleteData(ids = []) {
  return http({
    url: http.adornUrl("/hbi/datamodel/delete"),
    method: "post",
    data: http.adornData(ids, false)
  })
}

/**
 * 根据ID获取模型信息
 * @param {number|string} id id主键
 * @returns 模型信息
 */
export function getById(id) {
  return http({
    url: http.adornUrl(`/hbi/datamodel/info/${id}`),
    method: "get"
  })
}
/**
 * 根据ID获取 包含字段信息的模型信息
 * @param {number|string} id 主键id
 * @returns 包含字段信息的模型信息
 */
export function getAllById(id) {
  return http({
    url: http.adornUrl(`/hbi/datamodel/allinfo/${id}`),
    method: "get"
  })
}
/**
 * 根据id是否为空判断更新（id不为空）或新增（id为空）
 * @param {*} data 更新或新增的对象
 * @returns 更新或新增结果
 */
export function saveOrUpdate(data) {
  return http({
    url: http.adornUrl(`/hbi/datamodel/${!data.id ? "save" : "update"}`),
    method: "post",
    data: http.adornData(data)
  })
}

/**
 * 根据【数据源ID】获取数据源配置的数据库中所有表及注释信息
 * @param {number|string} id 数据源id
 * @returns 表名及表注释信息
 */
export function getTablesById(id) {
  return http({
    url: http.adornUrl(`/hbi/datamodel/gettables/${id}`),
    method: "get"
  })
}

/**
 * 通过【数据模型id】获取模型关联的字段信息
 * @param {number|string} id 数据模型id
 * @returns 模型关联的字段信息
 */
export function getColumnsById(id) {
  return http({
    url: http.adornUrl(`/hbi/datamodel/getcolumns/${id}`),
    method: "get",
  })
}

/**
 * 通过【数据源id】和数据模型配置的表名从数据库中获取表中字段信息，
 * dsId: 数据源id - 必须；
 * tableName：表名 - 必须；
 * @param {*} params 参数，包含【数据源id】和【表名】
 * @returns 指定表的字段信息
 */
export function getColumnsByDsIdAndTanleName(params) {
  return http({
    url: http.adornUrl(`/hbi/datamodel/getcolumnsbydatasource`),
    method: "get",
    params: params
  })
}

/**
 * 通过数据模型配置获取预览数据，
 * page: 当前页 - 可选，默认1；
 * limit: 每页数量 - 可选，默认10；
 * dsId:数据源id - 必须；
 * tableName: 表名 - 必须；
 * dmType: 模型类型 - 必须；
 * columns: 配置的列信息，列表 - 类型为表或视图时必须；
 * sqlText: sql语句 - 类型为sql时必须；
 * @param {*} data 查询参数，包含以上描述参数
 * @returns 预览数据
 */
export function getPreviewList(data) {
  return http({
    url: http.adornUrl('/hbi/datamodel/getpreviewlist'),
    method: 'post',
    data: http.adornData(data)
  })
}

/**
 * 通过sql解析字段数据，
 * dsId:数据源id - 必须；
 * sqlText: sql语句 - 必须；
 * @param {*} data 解析参数，包含DsId、SqlText
 * @returns 解析的字段信息
 */
export function analysisSql({ dsId, sqlText }) {
  let data_ = {
    dsId: dsId,
    sqlText: sqlText
  }
  return http({
    url: http.adornUrl('/hbi/datamodel/analysissql'),
    method: 'post',
    data: http.adornData(data_)
  })
}
/**
 * 通过文件解析字段
 * @param {*} data 解析参数及文件信息
 * @returns 解析的字段信息
 */
export function analysisByFile(data) {
  return http({
    url: http.adornUrl('/hbi/datamodel/analysisfile'),
    method: 'post',
    data: data
  })
}
/**
 * 根据配置字段创建表，并通过文件获取数据保存至创建表中
 * @param {*} data 需要修改或者保存的数据，包含文件及配置信息
 * @param {*} saveState 是否为保存，true保存新增，false修改
 * @returns 
 */
export function analysisFileAndSaveOrUpdate(data, saveState) {
  return http({
    url: http.adornUrl(`/hbi/datamodel/analysisfileand${saveState ? 'save' : 'update'}`),
    method: 'post',
    data: data
  })
  // return http({
  //   url: http.adornUrl(`/hbi/datamodel/analysisfileand${!id ? "save" : "update"}`),
  //   method: 'post',
  //   data: data
  // })
}


/**
* 基于数据模型查询数据
*  @param dataModelId 数据模型ID
* @param resColumns 需要查询返回的字段 eg:  [{"column": "title" , "aggMethod": null , "aliasName": null}] 
* @param filters 过滤条件  eg:   [{"column": "title","filterType": "like" , "value": "1"}] 
* @param order 排序字段  eg: ['groupId']
* @param group 分组字段 eg: ['groupId']
* @param page 是否分页
*/
export function getDatamodelQuery({ dataModelId, resColumns = [], filters = [], order = [], group = [], page = false, pageIndex = 1, pageSize = 10 ,limit}) {

  // filter尝试过滤 如果value为空 则不传递 ** 空字符串也算
  let fs = filters.filter(t => t.column && t.filterType && t.value != null && t.value != undefined)

  // 处理orderby 
  if (typeof order == 'string' || !order) {
    order = []
  }

  // 拼接查询结果
  const params = {
    dataModelId: dataModelId,
    query: fs,
    groupBy: group,
    orderBy: order,
    resColumn: resColumns,
    page: page,
    pageIndex: pageIndex,
    pageSize: pageSize
  }

  if(!page && limit) {
    params.limit = limit
  }

  return http({
    url: http.adornUrl('/hbi/dynamicquery/list/db'),
    method: 'post',
    data: http.adornData(params)
  })

}



/**
* 基于数据源查询数据
*  @param dataModelId 数据模型ID
* @param sqlText sql语句
* @param params 参数 array
*/
//export function getSqlQuery(data) {
export function getSqlQuery({datasourceId , sqlText = '' , params = [] }) {

  //console.log('datasourceId' , datasourceId)

  // 拼接查询结果
  const data = {
     datasourceId: datasourceId,
     sqlText: encryptDes(sqlText),
     params: params
    }

  return http({
     url: http.adornUrl('/hbi/dynamicquery/list/db/sql'),
    method: 'post',
     data: http.adornData(data)
  })

}
