/* 数据字典的资源 */
export default {
    /* 模块名字 */
    name: 'productionValue',

    /* 英文资源 */
    en_US: {
        projectName:'Project Name',
        PlanNo:'Plan Number',
        query:'Query',
        add:'Add',
        deletion:'Batch Deletion',
        planned:'Planned Date',
        topic:'Plan Topic',
        prepared:'Prepared By',
        status:'Approval Status',
        operation:'Operation',
        look:'look',
        details:'Approval Details',
        amend:'Amend',
        delete:'Delete',
        submit:'Submit for review',
        Filling:'Filling Name',
        FillingNO:'Filling No',
        Fillingby:'Filled by',
        data:'Data',
        number:'NO.',

    },

    /* 中文资源 */
    zh_CN: {
        projectName:'项目名称',
        PlanNo:'计划编号',
        query:'查询',
        add:'新增',
        deletion:'批量删除',
        planned:'计划日期',
        topic:'计划主题',
        prepared:'编制人',
        status:'审批状态',
        operation:'操作',
        look:'查看',
        details:'审批详情',
        amend:'修改',
        delete:'删除',
        submit:'提交审核',
        Filling:'填报名称',
        FillingNO:'填报编号',
        Fillingby:'填报人',
        data:'日期',
        number:'序号',

    },
};
