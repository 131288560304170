const t = {}
t.loading = '加载中...'

t.brand = {}
t.brand.lg = '广电用户数据运营平台'
t.brand.mini = '广电用户数据运营平台'

t.add = '新增'
t.save='保存'
t.refresh='刷新'
t.version='版本'
t.revoke='撤回'
t.preview='预览'
t.delete = '删除'
t.deleteBatch = '批量删除'
t.update = '修改'
t.query = '查询'
t.querying='查询中...'
t.export = 'Excel导出'
t.import = 'Excel导入'
t.handle = '操作'
t.confirm = '确定'
t.cancel = '取消'
t.logout = '退出'
t.init = '初始化'
t.open = '展开'
t.riskConfig = '配置'
t.back = '返回'
t.release = '发布'
t.copy = '复制'
t.personalinformation = '个人信息'
t.currentPage = '当前位置'
t.enable = '开启'
t.disable = '不开启'
t.choice = '选择'
t.success='操作成功'
t.failed='失败'
t.netError='网络异常'
t.operate='操作'

t.theme = {}
t.theme.title = '样式配置'
t.theme.layout = '顶部菜单'
t.theme.openTabs = '多标签'
t.theme.openBreadcrumb = '面包屑导航'
t.theme.breadcrumbLayout = '导航位置'
t.theme.header = '顶部'
t.theme.content = '内容'

t.prompt = {}
t.prompt.title = '提示'
t.prompt.info = '确定进行[{handle}]操作?'
t.prompt.success = '操作成功'
t.prompt.failed = '操作失败'
t.prompt.deleteBatch = '请选择删除项'
t.prompt.updateBatch = '请选择更新限额项'
t.prompt.liftingBatch = '请选择提额项'
t.prompt.deratingBatch = '请选择降额项'
t.prompt.release = '请选择发布项'

t.validate = {}
t.validate.required = '必填项不能为空'
t.validate.format = '{attr}格式错误'
t.validate.certNo = '证件号'
t.validate.mobile = '手机号'
t.validate.username = '用户名'
t.validate.custName = '客户名称'
t.validate.password = '密码'
t.validate.email = '邮箱'
t.validate.bankCard = '银行卡'
t.validate.emoji = '不允许输入表情符号'
t.validate.empty = '必填项不能为空'
t.validate.number = '必须输入数字'

t.upload = {}
t.upload.text = '将文件拖到此处，或<em>点击上传</em>'
t.upload.tip = '只支持{format}格式文件！'
t.upload.button = '点击上传'
t.upload.unsupportedmany = '不支持多文件上传'

t.download = {}
t.download.button = '点击下载'

t.datePicker = {}
t.datePicker.range = '至'
t.datePicker.start = '开始日期'
t.datePicker.end = '结束日期'

t.fullscreen = {}
t.fullscreen.prompt = '您的浏览器不支持此操作'

t.updatePassword = {}
t.updatePassword.title = '修改密码'
t.updatePassword.username = '账号'
t.updatePassword.password = '原密码'
t.updatePassword.newPassword = '新密码'
t.updatePassword.comfirmPassword = '确认密码'
t.updatePassword.validate = {}
t.updatePassword.validate.comfirmPassword = '确认密码与新密码输入不一致'

t.contentTabs = {}
t.contentTabs.closeCurrent = '关闭当前标签页'
t.contentTabs.closeOther = '关闭其它标签页'
t.contentTabs.closeAll = '关闭全部标签页'

/* 页面 */
t.notFound = {}
t.notFound.desc = '抱歉！您访问的页面<em>失联</em>啦...'
t.notFound.back = '上一页'
t.notFound.home = '首页'

t.login = {}
t.login.title = '登录'
t.login.username = '用户名'
t.login.password = '密码'
t.login.captcha = '验证码'
t.login.demo = '在线演示'
t.login.copyright = 'Finewe'

t.home = {}
t.home.sysInfo = {}
t.home.sysInfo.name = '系统名称'
t.home.sysInfo.nameVal = '工程项目管理系统'
t.home.sysInfo.version = '版本信息'
t.home.sysInfo.versionVal = window.SITE_CONFIG['version']
t.home.sysInfo.osName = '操作系统'
t.home.sysInfo.osVersion = '系统版本'
t.home.sysInfo.osArch = '系统架构'
t.home.sysInfo.processors = 'CPU核数'
t.home.sysInfo.totalPhysical = '系统内存'
t.home.sysInfo.freePhysical = '剩余内存'
t.home.sysInfo.memoryRate = '内存使用'
t.home.sysInfo.userLanguage = '系统语言'
t.home.sysInfo.jvmName = 'JVM信息'
t.home.sysInfo.javaVersion = 'JVM版本'
t.home.sysInfo.javaHome = 'JAVA_HOME'
t.home.sysInfo.userDir = '工作目录'
t.home.sysInfo.javaTotalMemory = 'JVM占用内存'
t.home.sysInfo.javaFreeMemory = 'JVM空闲内存'
t.home.sysInfo.javaMaxMemory = 'JVM最大内存'
t.home.sysInfo.userName = '当前用户'
t.home.sysInfo.systemCpuLoad = 'CPU负载'
t.home.sysInfo.userTimezone = '系统时区'

t.report={}
t.report.title='报表名称'
t.report.NO='序号'
t.report.operate='操作'
t.report.code='报表编码'
t.report.name='报表名称'
t.report.file='报表文件'
t.report.describe='报表描述'
t.report.ErrorEmpty='输入内容不能为空!'
t.report.ErrorSpace='输入内容两端不能存在空格！'
t.report.tip1='请确定是否已经保存当前编辑的报表?'
t.report.title='提示'
t.report.tip2='确定对报表进行[ \'部署\' ]操作?'
t.report.tip3='确定要进行'
t.report.deleteBash='批量删除'
t.report.flowSort='流程分类'
t.report.flowForm='流程表单'
t.report.form='表单'

t.form={}
t.form.name='表单名称'
t.form.id='表单编号'
t.form.modelName='模板名称'
t.form.createTime='创建时间'
t.form.state='状态'
t.form.operate='操作'
t.form.release='发布'
t.form.temporary='暂存'
t.form.title='提示'
t.form.tip1='确定对当前模板版本进行撤销操作?'
t.form.tip2='确定对当前模板进行发布操作?'
t.form.tip3='确定对[名称='
t.form.tip4=']的数据进行'

t.flow={}
t.flow.NO='序号'
t.flow.name='流程名称'
t.flow.copy='流程复制'
t.flow.deploy='部署'
t.flow.deployOrNot='是否部署'
t.flow.yes='已部署'
t.flow.not='未部署'
t.flow.operate='操作'
t.flow.modelName='模型名称'
t.flow.modelKey='模型Key'
t.flow.modelDescribe='模型描述'
t.flow.eventType='事件类型'
t.flow.bindModel='绑定模型'
t.flow.bindModelKey='绑定模型Key'
t.flow.displayComponents='显示组件'
t.flow.operate='操作'
t.flow.addModelBind='新增流程模板绑定'
t.flow.updateModelBind='修改流程模板绑定'
t.flow.viewComponents='前端回显组件'
t.flow.correspondFlow='对应流程'
t.flow.beCopy='被复制流程'
t.flow.beCover='被覆盖流程'
t.flow.tip1='请确定是否已经保存当前编辑的流程?'
t.flow.tip2='确定进行删除操作?'
t.flow.tip3='确定进行流程复制操作?'
t.flow.tip4='确定对流程进行[ \'部署\' ]操作?'
t.flow.title='提示'
t.flow.tip5='输入内容不能为空！'
t.flow.tip6='输入内容两端不能存在空格！'
t.flow.tip7='不能为空'
t.flow.tip8='确定要进行'
t.flow.deleteBash='批量删除'


t.job={}
t.job.beanName='bean名称'
t.job.methodName='方法名称'
t.job.remark='备注'
t.job.parameter='参数'
t.job.cronExpression='Cron 表达式'
t.job.state='状态'
t.job.operate='操作'
t.job.pauseBatch='批量暂停'
t.job.recoverBatch='批量恢复'
t.job.runBatch='批量立即执行'
t.job.logList='日志列表'
t.job.tip1='Spring Bean名称，如testMask'
t.job.tip2='如 0 0 12 * * ?'
t.job.tip3='bean名称不能为空'
t.job.tip4='方法名称不能为空'
t.job.tip5='cron表达式不能为空'
t.job.taskID='任务ID'
t.job.logID='日志ID'
t.job.executionTime='执行时间'
t.job.useTime='耗时'
t.job.unit='单位'
t.job.unitFor='毫秒'
t.job.NO='序号'
t.job.normal='正常'
t.job.pause='暂停'
t.job.recover='恢复'
t.job.run='立即执行'
t.job.tip6= '确定要进行'
t.job.title= '提示'
t.job.deleteBash='批量删除'
t.job.recoverBash='批量恢复'
t.job.runBash='批量执行'


t.common={}
t.common.formmaterialtemplate={}
t.common.formmaterialtemplate.materialSort='材料分类'
t.common.formmaterialtemplate.materialName='材料名称'
t.common.formmaterialtemplate.materialCode='材料编码'
t.common.formmaterialtemplate.materialID='材料序号'
t.common.formmaterialtemplate.fileType='文件类型'
t.common.formmaterialtemplate.materialNecessary='材料必要性'
t.common.formmaterialtemplate.from='来源渠道'
t.common.formmaterialtemplate.operate='操作'
t.common.formmaterialtemplate.instructions='填报须知'
t.common.formmaterialtemplate.sampleTable='材料样表'
t.common.formmaterialtemplate.tip1='不能为空'
t.common.formmaterialtemplate.tip2='文件不能为空'
t.common.formmaterialtemplate.tip3='确定对[名称='
t.common.formmaterialtemplate.tip4=']进行'
t.common.formmaterialtemplate.title='提示'
t.common.formmaterialtemplate.deleteBash='批量删除'

t.msg={}
t.msg.type={}
t.msg.type.name='消息名称'
t.msg.type.ID='编号'
t.msg.type.superiorName='上级名称'
t.msg.type.modelTitle='模板标题'
t.msg.type.remark='备注'
t.msg.type.operate='操作'
t.msg.type.messagePoint='消息埋点'
t.msg.type.messageType='消息类型'
t.msg.type.messageReceiverType='消息接收方类型'
t.msg.type.messageReceiver='消息接收方'
t.msg.type.msgTitle='消息标题'
t.msg.type.msgModel='消息模板'
t.msg.type.superiorType='上级类型'
t.msg.type.routeName='路由名称'
t.msg.type.tip1= '主键,每个层级占2位'
t.msg.type.tip2= '编号不能为空'
t.msg.type.tip3= '名称不能为空'
t.msg.type.tip4= '确定要进行'
t.msg.type.title= '提示'
t.msg.type.deleteBash='批量删除'
t.msg.type.businessModel='业务模型'
t.msg.type.inStaProcessPage='站内处理页面'
t.msg.type.oaProcessPage='OA处理页面'
t.msg.type.proDomainMsg='是否项目域消息'



t.project={}
t.project.cbsTemplate={}
t.project.cbsTemplate.modelName='模板名称'
t.project.cbsTemplate.modelDescribe='模板描述'
t.project.cbsTemplate.takeEffectOrNot='是否生效'
t.project.cbsTemplate.operate='操作'
t.project.cbsTemplate.modelUpdate='模板修改'
t.project.cbsTemplate.CBSListManage='CBS清单管理'
t.project.cbsTemplate.copyName='复制模板名称'
t.project.cbsTemplate.select='请选择'
t.project.cbsTemplate.tip1='不能为空'
t.project.cbsTemplate.model='模板'
t.project.cbsTemplate.copyModel='复制模板'
t.project.cbsTemplate.basicInform='基本信息'
t.project.cbsTemplate.tip2= '确定要进行'
t.project.cbsTemplate.title= '提示'
t.project.cbsTemplate.deleteBash='批量删除'


/* 模块 */
import dataDic from './modules/sys_mannager/dataDic'
import users from './modules/sys_mannager/users'
import rules from './modules/sys_mannager/rules'
import menus from './modules/sys_mannager/menus'
import config from './modules/sys_mannager/config'
import orgmanager from './modules/sys_mannager/orgmanager'
import deptmanager from './modules/sys_mannager/deptmanager'
import syslog from './modules/sys_mannager/syslog'
import fileupload from './modules/sys_mannager/fileupload'
import suppliers from './modules/project/suppliers'
import projectAppl from './modules/project/projectAppl'
import projectorgmember from './modules/project/projectorgmember'
import projectorgunit from './modules/project/projectorgunit'
import invitation from './modules/project/invitationVisa'
import home from './modules/sys_mannager/home'
import equipmentShipped from './modules/project/equipmentShipped'
import projectorglevel from './modules/project/projectorglevel'
import dataManagement from './modules/project/dataManagement'
import progress from './modules/progressApproval/index'
import productionValue from './modules/project/productionValue'
import sysNationResource from "./modules/sys_mannager/sysNationResource";
import matters from './modules/mattersManage/index'

/* 设置值 */
(modules => modules.forEach(value => t[value.name] = value['zh_CN']))
    ([
        dataDic, users,
        rules, menus,
        config, orgmanager,
        deptmanager, syslog,
        fileupload, suppliers,
        projectorgmember,projectorgunit,
        projectAppl,invitation,
        home,projectorglevel,
        equipmentShipped,
        dataManagement,productionValue,
        progress,matters,
        sysNationResource
    ]);

export default t
