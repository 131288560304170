<!--
表单控件属性设置组件,因为配置数据是引用关系，所以可以直接修改
-->
<template>
    <div>
        <el-collapse v-model="actives">
            <el-collapse-item title="样式" :name="1">
                <hd-form :config="form.config" :prop="form.prop" :record="selectItem" :columns="form.column" class="hbi-avue-form">
                    <template slot="margin">
                        <el-row v-if="selectItem.style && selectItem.style.margin && selectItem.style.margin.length == 4">
                            <el-col :span="6">
                                <el-input title="上边距" v-model="selectItem.style.margin[0]" />
                            </el-col>
                            <el-col :span="6">
                                <el-input title="右边距" v-model="selectItem.style.margin[1]" />
                            </el-col>
                            <el-col :span="6">
                                <el-input title="下边距" v-model="selectItem.style.margin[2]" />
                            </el-col>
                            <el-col :span="6">
                                <el-input title="左边距" v-model="selectItem.style.margin[3]" />
                            </el-col>
                        </el-row>
                    </template>
                </hd-form>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>
<script>
export default {
    name: "HbiWeightContainerProperties",
    data() {
        return {
            actives: [1],
            options: {},
            form: {
                prop: 'style',
                config: {
                    size: 'mini',
                    labelWidth: 70
                },
                column: [{
                        label: '栅格间距',
                        prop: 'gutter',
                        type: 'number',
                        min: 0,
                        max: 100
                    },
                    {
                        label: '背景颜色',
                        prop: 'backgroundColor',
                        tip: '点击选择背景颜色',
                        type: 'color',
                        default: '#FFFFFF',
                        span: 24,
                    },
                    {
                        label: '边距',
                        prop: 'margin',
                        default: ['0', '0', '0', '0'],
                        tip: 'top right bottom left',
                        span: 24,
                    },
                    {
                        label: '圆角',
                        prop: 'itemBorderRadius',
                        type: 'number',
                        min: 0,
                        max: 45,
                        default: 0,
                        span: 24,
                    },
                    {
                        label: 'flex模式',
                        prop: 'flex',
                        type: 'switch',
                        default: false
                    },
                    {
                        label: '水平排列方式',
                        prop: 'justify',
                        type: 'select',
                        default: 'start',
                        show: '$.style.flex',
                        dicData: ['start', 'center', 'end', 'space-between', 'space-around'].map(t => {
                            return { label: t, value: t }
                        })
                    },
                    {
                        label: '垂直排列方式',
                        prop: 'align',
                        type: 'select',
                        show: '$.style.flex',
                        dicData: ['top', 'middle', 'bottom'].map(t => {
                            return { label: t, value: t }
                        })
                    },

                ]
            }
        };
    },
    watch: {
        selectItem(val) {

            this.options = val.options || {}

        }
    },
    methods: {

    },
    props: {
        selectItem: {
            type: Object,
            required: true
        },

        hideModel: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>