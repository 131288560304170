<template>
  <!-- 使用必看
    1、 先引入
      import fileUpload from '@/components/fileupload/fileUpload'
    2、注册
      components:{
        fileUpload
      },
    3、引用 v-model中绑定 文件的id集合
       <fileUpload list-type="picture-card" v-model="formData.files" accept="image/*"> </fileUpload>

      上传的文件ID集合:<br>
        <div v-for="item in formData.files">
          {{item}}
        </div>
    4、父组件注意申明一个变量来接收最终的文件id集合
      return:{
        formData{
          files:[]
        }

      }

    5、如果是图片请参考:
        <fileupload v-model="formData.files"  accept="image/*" :limit="2" list-type="picture-card"  />

    6.imgDownBut='true'  显示图片下载按钮

   -->

  <!-- ######################### 分割线 ####################### -->

  <!--
 完整页面demo:
<template>
<div>

    <fileUpload v-model="formData.files"  > </fileUpload>

    上传的文件ID集合:<br>
   <div v-for="item in formData.files">
     {{item}}
   </div>


</div>
</template>
<script>
import fileUpload from '@/components/file-upload-download/fileUpload'

export default {
 data(){
   return {
     formData:{
       files:[]
     }

   }
 } ,
 components:{
   fileUpload
 },
 created(){

 },
 methods:{

 }
}

</script>


</script>


  -->

  <div class="upload-div">
    <el-upload
      ref="upload"
      :list-type="listType"
      :with-credentials="true"
      :action="doUpload"
      :file-list="fileList"
      :on-remove="handleRemove"
      :on-preview="handlePreview"
      :before-remove="beforeRemove"
      :on-success="handleSuccess"
      :on-error="handerError"
      :before-upload="beforeUpload"
      :accept="accept"
      :multiple="multiple && (multiple == true || multiple == 'true')"
      :limit="limit"
      :on-exceed="handleExceed"
      v-if="readOnly == null || readOnly == false"
    >
      <!-- <div v-if="limit == null || limit <= 0 || files.length < limit">  -->
      <i
        v-if="listType && listType.indexOf('picture') >= 0"
        class="el-icon-plus"
      >
        <br /><span style="font-size: 12px;">选择文件</span>
      </i>
      <!--   <i v-else-if="listType == 'picture-card'"  >
        <el-button   icon="el-icon-plus">请上传文件</el-button>
      </i> -->
      <div v-else>
        <el-button icon="el-icon-plus">请上传文件</el-button>
      </div>
      <!--  </div>   -->
    </el-upload>
    <div
      slot="tip"
      v-if="readOnly == null || readOnly == false"
      class="el-upload__tip"
    >
      {{
        accept &&
        accept.indexOf("image") >= 0 &&
        listType &&
        listType.indexOf("picture") >= 0
          ? "* 图片最大不能超过" +
            (this.fileSize == undefined ? 10 : this.fileSize) +
            "Mb,图片格式支持(bmp,jpg,jpeg,png)"
          : "* 文件最大不能超过" +
            (this.fileSize == undefined ? 10 : this.fileSize) +
            "Mb,文件格式支持(bmp,jpg,jpeg,png,xls,xlsx,doc,docx,pdf,mp4,wmv,zip,txt,csv)"
      }}
    </div>

    <div v-else v-loading="ulLoading">
      <div
        v-if="
          accept &&
            accept.indexOf('image') >= 0 &&
            listType &&
            listType.indexOf('picture-card') >= 0
        "
      >
        <ul >
          <li
            style="float:left;margin-right:20px;list-style: none;"
            v-for="(item, index) in fileList"
            :key="'file' + index"
          >
            <img
              @click="reviewDown(item.id)"
              :src="item.url"
              :class="[
                direction == null || direction == false ? 'avatar' : 'vertical'
              ]"
              :style="imgDownBut ? 'float: left;' : ''"
            />
            <el-button
              v-if="imgDownBut"
              @click="imgDown(item.id)"
              type="text"
              size="small"
              style="padding: 90px 0 16px 30px ;float: left;"
            >
              <img title="下载" src="~@/assets/img/icon_xiazai.svg" />
            </el-button>
            <div v-if="imgDownBut" style="clear:both"></div>
          </li>
        </ul>
      </div>

      <ul v-else class="el-upload-list el-upload-list--text">
        <li
          v-for="(item, index) in fileList"
          :key="'file' + item.id"
          :tabindex="index"
          class="el-upload-list__item pointer"
          @click="reviewDown(item.id, item.fileSuffix)"
        >
          <!---->
          <a class="el-upload-list__item-name">
            <i class="el-icon-document"></i> {{ item.originalFilename }}
          </a>

          <label class="el-upload-list__item-status-label">
            <i class="el-icon-upload-success el-icon-circle-check"></i>
          </label>

          <!---->
        </li>
      </ul>
    </div>

    <!--附件上传-->
    <el-dialog :append-to-body="true" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { deleteFile ,selectList , getUploadUrl , getDownUrl } from '@/api/sys/upload'
import lodash from 'lodash'
export default {
  name: "fileUpload",
  data() {
    return {
      fileList: [],
      ulLoading: false,
      doUpload: getUploadUrl(),
      dialogVisible: false,
      dialogImageUrl: ""
    };
  },
  watch: {
    value: {
      // 深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
         
        let fs = val;

        // 和fileList 中id集合对比
        const cids = this.fileList.map(t=> {
          if(t.id) return t.id 
          if(t.response.id) return t.response.id 
          return null 
        })
 

        if (JSON.stringify(val) == JSON.stringify(cids)) return;

        if (fs) { 
          this.fileList = [];
          this.initFiles();
        } else {
           
          this.fileList = [];
        }
      },
      deep: true
    } 
  }, 
  props: {
    value: {
      type: Array,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String
    },
    multiple: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number
    },
    direction: {
      type: Boolean,
      default: false
    },
    imgDownBut: {
      type: Boolean,
      default: false
    },
    fileSize: {
      type: Number
    },
    listType: {
      type: String
    }
  },
  created() {
    this.initFiles();
  },
  methods: {
    // 钩子函数上传附件成功后
    handleSuccess(res, file) {
      if (res.code == 401) {
        this.$message.error("当前账号已在其他地方登录,请重新登录!");
        this.$router.replace({ name: "login" });
        return;
      }
      if (!res || res.code != 0) {
        // 上传失败时候  去除进度条
        let list = this.$refs["upload"].uploadFiles;
        this.$refs["upload"].uploadFiles = list.filter(item => {
          return item.id != file.id;
        });
        this.$message.error(res.msg ? res.msg : "上传失败");
        return;
      }
      file.id = res.data.id;
      file.url = res.data.url;
 
      this.fileList.push(file)

      if (lodash.isNil(this.value)) {
        this.$emit("input", '');
      } else {
        const addData = [...this.value , res.data.id]
        this.$emit("input", addData);
      }

      this.$message("上传成功");
      
      this.$emit("uploadChange", {
        id: res.data.id,
        url: res.data.url,
        fileName: file.name,
        type: "add" 
      });
    },
    handerError(err, file, fileList) {
      console.log("error", err, file, fileList);
      
    },
    beforeUpload(file) {
      // console.log('file', file)
      const fileName = file.name;
      // const isJPG = file.type === 'image/jpeg';
      const isLt10M =
        file.size / 1024 / 1024 <
        (this.fileSize == undefined ? 10 : this.fileSize);

      var index1 = fileName.lastIndexOf(".");

      var index2 = fileName.length;
      var postf = fileName.substring(index1 + 1, index2); // 后缀名

    
      const fileType = file.type;
      if (
        this.accept &&
        this.accept.indexOf("image") >= 0 &&
        !this.isAssetTypeAnImage(postf)
      ) {
        this.$message.error("当前图片格式只支持:[png,jpg,jpeg,bmp]");
        return false;
      }

      if (!isLt10M) {
        this.$message.error(
          "上传文件大小不能超过" +
            (this.fileSize == undefined ? 10 : this.fileSize) +
            "MB!"
        );
      }
      return isLt10M;
    },
    isAssetTypeAnImage(ext) {
      return ["png", "jpg", "jpeg", "bmp"].indexOf(ext.toLowerCase()) !== -1;
    },
    // 文件溢出
    handleExceed(files, fileList) {
      this.$message.error("最多只能上传 " + this.limit + " 个文件");
    },
    handleRemove(file, fileList) {
     

      let id = file.id;
      if (id == null && file.response) {
        id = file.response.id;
      }
      let url = file.url;
      if (url == null && file.response) {
        url = file.response.fileUrl;
      }
      let itemId = file.itemId;
      if (itemId == null && file.response) {
        itemId = file.response.itemId;
      }

      // 判断是否再非物理删除的文件列表内 
      if (id != null && id != "") {
        // 后台删除 
        deleteFile(id).then(({ data }) => {
          if (data && data.code === 0) {

            const index_ = this.value.indexOf(id)
            if(index_ >= 0) { 
              this.$emit("input", this.value.filter((val, index) => index !== index_));
              this.fileList.splice(index_ , 1)
            }
           
            
            this.$emit("uploadChange", {
              id:  id,
              url:  url,
              type: "delete" 
            });
             
            this.$message("删除成功");
          }
        });
      } 
    },
    initFiles() {
      if (!this.value || this.value.length == 0) {
        return;
      }
      if (lodash.isNil(this.value)) {
        return;
      } 
      this.ulLoading = true
      selectList(this.value).then(({ data }) => {
        if (data && data.code === 0) {
          let efiles = data.data;
          let fs = [];
          if (efiles != null && efiles.length > 0) {
            efiles.forEach(t => {
              t.name = t.originalFilename;
              // t.url = t.fileUrl;
              fs.push(t.id);
            });
          }
          this.fileList = efiles;
          if(fs.length != this.value.length) {
            this.$emit("input", fs);
          }
        }
      }).finally(_ => {
        this.ulLoading = false
      });
    },
    // 浏览下载文件
    reviewDown(id, fileSuffix) {
      this.handlePreview({ id: id, fileSuffix: fileSuffix });
    },
    handlePreview(file) {
      if (file && file.id) {
        // 判断是不是图片 如果是图片进行预览
        if (
          (this.accept && this.accept.indexOf("image") >= 0) ||
          (file.fileSuffix && this.isAssetTypeAnImage(file.fileSuffix))
        ) {
          this.dialogImageUrl = getDownUrl(file.id) 
          this.dialogVisible = true;
        } else {
          window.location.href = getDownUrl(file.id) 
        }
      }
    },
    // 图片下载
    imgDown(id) {
      window.location.href = getDownUrl(id) 
    },

    beforeRemove(file, fileList) {
      // console.log('file remove', file)
      return !file.id || this.$confirm(`确定移除 ${file.name}？`);
    }
  }
};
</script>
<style lang="scss">
.upload-div {
  /*float: left;*/

  .el-upload--picture-card i {
    vertical-align: middle;
    display: inline-block;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 128px;
  height: 128px;
  display: block;
  object-fit: cover;
}
.vertical {
  width: 128px;
  height: 128px;
  margin-left: 5px;
  object-fit: cover;
  margin-top: 20px;
}
</style>
