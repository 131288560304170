<template>
    <div> 
        <avue-data-tabs :option="option"></avue-data-tabs>
    </div>
</template>
<script>
import { getDataByDatasource } from '../../../config/common/datasource.js'
export default {
    name: "hbi-data-tabs-item",
    data() {
        return {
            data: {},
            option: {}
        }
    },
    props: {
        // 表单数组
        record: {
            type: Object,
            required: true
        },
        // form-item 宽度配置
        models: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // 是否预览结果表单
        renderPreview: {
            type: Boolean,
            default: false
        },
        // 是否拖拽面板引用
        isDragPanel: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
        recordOptions() {
            const options = { ...this.record.layout }
            return options
        }
    },
    watch: {
        recordOptions: { //深度监听，可监听到对象、数组的变化
            handler() {
                this.init()
            },
            deep: true
        },
    },
    methods: {
        getData() {
            this.loading = true
            getDataByDatasource(this.record, this.models)
                .then((data) => {
                    console.log('data',data)
                    this.data = data
                    this.init()
                }).catch(e => {
                    if (e) {
                        this.$message.error(e)
                        console.error(e)
                    }

                }).finally(() => {
                    this.loading = false;
                });
        },

        init() {
            // title: '分类统计',
            //   subtitle: '实时',
            //   count: 7993,
            //   allcount: 10222,
            //   text: '当前分类总记录数',
            //   color: 'rgb(27, 201, 142)',
            //   key: '类'

    console.log('this.record.layout' , this.record.layout)
            const count = this.data.count || 0
            const allcount = this.data.allcount || 0

            this.option = {
                span: 24 ,
                animation: this.record.layout.animation ,
                data: [{
                    title: this.record.layout.title,
                    subtitle: this.record.layout.subtitle,
                    count: count,
                    allcount: allcount,
                    text: this.record.layout.text,
                    color: this.record.layout.color,
                    // key: '类'
                }]
            }
        }

    }
};
</script>