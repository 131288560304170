import Vue from 'vue'
import VueI18n from 'vue-i18n'
import cookies from 'vue-cookie'
import ElementLocale from 'element-ui/lib/locale'
import zhCNLocale from 'element-ui/lib/locale/lang/zh-CN'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhCN from './zh-CN'
import enUS from './en-US'

Vue.use(VueI18n)

export const messages = {
  'zh-CN': {
    '_lang': '简体中文',
    ...zhCN,
    ...zhCNLocale
  },
  'en-US': {
    '_lang': 'English',
    ...enUS,
    ...enLocale
  }
}

ElementLocale.i18n((key, value) => i18n.t(key, value))

const i18n = new VueI18n({
  locale: cookies.get('language') || 'zh-CN',
  messages
})

export default i18n;
// export default new VueI18n({
//   locale: cookies.get('language') || 'zh-CN',
//   messages
// })
