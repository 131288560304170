<template>
 <!--  <div>
    orgnzationData:: {{regionOrganzitionData}}  -->
   <el-cascader ref="organzitionCascader" :options="regionOrganzitionData" v-model="dataForm.organzationId" :props="orgnzationProp"
                 placeholder="请选择区域"   :key="refreh" :disabled="disabled" :filterable="filterable"
                 :show-all-levels="false" clearable :style="{'width':width}" @change="changeNode">
   
    </el-cascader> 
 <!--  </div>   -->
 
</template>

<script>
  import { getList,queryOrgParents,organizationForDm } from '@/api/sys/region'
  import cloneDeep from 'lodash/cloneDeep' 
  export default {
    name: 'organzition',
    data () {
      const this_ = this
      return {
        orgnzationList: [],
        orgnzationData: [], // list 的复制
        dataForm: {
          organzationId: []
        }, 
        refreh: 0,
        orgnzationProp: {
          multiple: this.multiple,
          emitPath: false,
          checkStrictly: true,
          lazy: this.lazy,
          lazyLoad: this.lazyLoad,
          //leaf: 'isleaf',
        },
        userChange: false,
      }
    },
    props: {
      field: null,
      form: null,
      width: {
        type: String,
        default: 250 + 'px'
      },
      multiple: {
        type: Boolean,
        default: true
      },
      disIds: {
        type: Array,
        default: () => []
      },
      disChild: { // disIds的禁用ID 是否连带下级一起禁用
        type: Boolean,
        default: false
      },
      disHidden: {  // disIds的禁用ID 直接隐藏
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }, 
      filterable:{
        type: Boolean,
        default:false
      },
      // 数据的最低层级
      minLevel: {
        type: [Number,String],
        default: 5
      },
      // 数据禁用最大层级
      maxLevel: {
        type: [Number,String]
      },
       
      lazy: {
        type: Boolean,
        default: true
      },
      defaultUserCode:{//是否默认赋予用户区域层级
        type: Boolean,
        default: false
      },
      code: {
        type: String,
        default: null
      }
     
    },
    inject: {
      elForm: {
        default: ''
      },
      elFormItem: {
        default: ''
      }
    },
    methods: {
      changeNode(v) {
        this.userChange = true
        setTimeout(() => {
          if(this.elForm && this.elFormItem && this.elFormItem.prop) {
            this.elForm.validateField(this.elFormItem.prop , ()=>{})
          }
        }, 50)
      },
      lazyLoad (node, resolve) {
        let id = node.value
        const type = node.type
        if(this.maxLevel && this.maxLevel >= type) {
          resolve(null);
          return
        }
        // 初始化进来没有id的时候赋予用户的层级
        if(this.defaultUserCode && !id) {
          id = this.$store.state.user.orgCode

        }
        // 通过调用resolve将子节点数据返回，通知组件数据加载完成 
        getList(id).then(({data}) => {
          if (data && data.code === 0) {
            const nodes = data.data

            if(this.maxLevel) {

              const mlevel = this.maxLevel
              nodes.forEach(t=> {

                if(t.type >= mlevel) {
                  t.isleaf = false
                }

              })
            }
            nodes.forEach(t=> {
              t.leaf = !t.isleaf
              t.value = t.id
              t.label = t.name
            })
 
            resolve(nodes);
          }
        })


      },
        
      getOrganzationList () {
        let level = this.maxLevel ? this.maxLevel : 3
        organizationForDm(null,level).then(({data}) => {
          if (data && data.code === 0) {
            ++this.refreh
            if(data.data && data.data.length > 0) {
              let orgs = data.data
              this.orgnzationList = this.hiddenDisabled(orgs)
              //this.orgnzationList = data.data
              this.resetDisabled()
            }
          }
        })
      },
      
      // 获取所有选中节点的名称
      getCheckedNames() {
        const checkNodes = this.$refs.organzitionCascader.getCheckedNodes()
        //console.log('checkNodes' , checkNodes)
        return checkNodes.map(t=>t.label) 
      },
      // 隐藏被屏蔽的数据
      hiddenDisabled(orgs) {
        if( (!this.disIds || this.disIds.length == 0 || !this.hiddenDisabled) ) {
          return orgs
        }
  

        const f = (t)=> {
          if (t instanceof Array) {
            for(let i = t.length - 1; i >= 0; i--) {
             
              if(this.disIds.indexOf(t[i].value) >= 0 ){
                  t.splice(i,1)
              } else {
                f(t[i])
              }
            }
          } else {
            if(t.children && t.children.length > 0) {
              let child_ = t.children

              for(let i = child_.length - 1; i >= 0; i--) {
                if(this.disIds.indexOf(child_[i].value) >= 0) {
                  child_.splice(i,1)
                } else {
                  f(child_[i])
                }
              }

             // t.children = child_
            }
          }
        }
        f(orgs)
        return orgs
      },
      resetDisabled () {
        // orgnzationList
        const orgs = cloneDeep(this.orgnzationList)

        if ( this.maxLevel != undefined || (this.disIds && this.disIds.length > 0) ) {
          const ids = this.disIds
          const f = (t , parentDisabled) => {
            if (t instanceof Array) {
              t.forEach(c => {
                f(c)
              })
            } else {
              let delete_ = false
              if (ids && ids.indexOf(t.value) >= 0) {
                t['disabled'] = true
              }
              // 2021-09-29 lyf 如果上级已经禁用 并且设置了下级级联禁用 则一起禁用
              else if(this.disChild && parentDisabled) {
                 t['disabled'] = true
              }

              if (!delete_ && t.children && t.children.length > 0) {
                t.children.forEach(c => {
                  f(c,t['disabled'])
                })
              }
            } 
            if(t.type  <= this.maxLevel-1){
              t['disabled'] = true
            }
          }

          f(orgs)
        }
        this.orgnzationData = orgs
      },
      initParent(code) {
        //console.log('initParent' , code)
        if(this.defaultUserCode && !code) {
          code = this.$store.state.user.orgCode 
        }
       
        queryOrgParents(code).then(({data}) => {
          if (data && data.code === 0) {
            ++this.refreh 
            this.orgnzationData = data.data
          }
        })
      },
      // 从区域树的数据上根据区域代码ID 获取数据 没有的话返回null
      findByOrg(code) {
        if(!this.orgnzationData || this.orgnzationData.length == 0)
          return false
        // 迭代循环查找

        let data_ ;

        const fn = (data)=>{

          for(let i = 0 ; i < data.length ; i++) {
            if(data[i].value == code) {
              data_ = data[i]
              break
            } else if(data[i].children && data[i].children.length > 0){

              fn(data[i].children)
            }
          }
        }

        fn(this.orgnzationData)

        return data_

      },
      
    },
    created () { 
      if(!this.lazy)
        this.getOrganzationList()

      // 2021-07-02 lyf 如果最小层级是默认的数据 则这里要判断当前用户的所属区域层级-3
      
    },
    activated(){
     
    },
    mounted(){
       //

    },
    computed: {
      getorganzitionValue () {
        return this.form[this.field]
      },
      dataFormOrganzationId () {
        return this.dataForm.organzationId
      },
      orgCode() { 
          return this.$store.state.user.orgCode
      }, 
     // 有数据省的过滤data
      regionOrganzitionData() {
          return this.orgnzationData
      }
    }, 
    watch: {
      getorganzitionValue: {
        immediate:true,
        handler (value, oldvalue) {
          if ((!value || value.length == 0) && this.getorganzitionValue) {
            this.dataForm.organzationId = null
            this.userChange = false
            //this.refreh++
          } else {
            // 绑定数据
            this.dataForm.organzationId = value

            // 2021-07-02 lyf 在有值的时候 如果是懒加载则顺着区域代码从后台查询所有关联的options的进行回填
            //console.log('this.lazy' ,value , this.lazy ,this.userChange, this.orgnzationData)
            if(this.lazy && !this.userChange && !this.findByOrg(value))
            //if(this.lazy && !this.userChange && (!this.orgnzationData || this.orgnzationData.length == 0))
              this.initParent(value)
          }
        }
      },
      disIds: {
        handler (value, oldvalue) {
          this.resetDisabled()
        }
      },
      minLevel: {
        handler (value, oldvalue) {
          this.dataForm.organzationId = ''
          if(!this.lazy)
            this.getOrganzationList()
        }
      },
        maxLevel: {
            handler (value, oldvalue) {
                this.resetDisabled()
            }
        },
      dataFormOrganzationId: {
        handler (newValue, oldvalue) {
          if (newValue) {
            this.$set(this.form, this.field, newValue)
          } else {
            this.$set(this.form, this.field, this.multiple ? [] : null)
          }

          this.$emit('change' , newValue)
/*
          if (newValue && newValue.organzationId && newValue.organzationId.length > 0) {
            this.form[this.field] = newValue.organzationId
          } else {
            this.form[this.field] = this.multiple ? [] : ''
          } */
        },
        deep: true
      }
    }
  }
</script>

<style>
.cascader-check {
  font-size: 13px;
  float: right;
}
</style>
