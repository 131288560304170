<template>
  <!-- 数据源配置-API数据 -->
  <el-dialog
    title="API数据配置"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :lock-scroll="false"
    :visible.sync="visible"
    width="70%">
    <el-row :gutter="15">
      <el-col :span="12">
        <el-form class="apiDataForm" :model="dataForm" :rules="dataRule" ref="apiDataForm">
          <el-form-item label="接口地址" prop="apiPath">
            <el-input v-model="dataForm.apiPath" placeholder="接口地址"></el-input>
          </el-form-item>
          <el-form-item label="请求方式" prop="apiMethod">
            <sysDictSelect v-model="dataForm.apiMethod" dictType="api_method" />
          </el-form-item>
          <el-form-item label="请求头" prop="apiHeaders">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 6}"
              v-model="dataForm.apiHeaders"
              placeholder="请求头：()=>{return {}}，可直接返回json对象">
            </el-input>
          </el-form-item>
          <el-form-item label="请求参数" prop="apiParams">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 6}"
              v-model="dataForm.apiParams"
              placeholder="请求参数：()=>{return {}}，可直接返回json对象">
            </el-input>
          </el-form-item>

          <el-button type="text" @click="getApiData">测试</el-button>

          <el-form-item label="数据格式化" prop="apiDataFormatter">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 6}"
              v-model="dataForm.apiDataFormatter"
              placeholder="数据格式化：(data)=>{return data}">
            </el-input>
          </el-form-item>

          <el-button type="text" @click="getDataPreview"> 刷新</el-button>

          <el-form-item label="预览数据">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 6}"
              v-model="dataForm.preview">
            </el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <code-editor class="apiCodeMirror" :disabled="true" v-model="dataTips" />
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">返回</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { tips } from '../common/datasource.js'
import http from '@/utils/httpRequest'
import { getSqlQuery } from '@/api/hbi/datamodel.js'
import { dynamicFun } from '../../../utils'
export default {
  components: {
    // MonacoEditor
  },
  data () {
    return {
      visible: false,
      dataForm: {
        apiPath: '',
        apiMethod: '',
        apiHeaders: '',
        apiParams: '',
        apiDataFormatter: '',
        preview:''
      },
      retData: {}, // api请求结果
      dataTips: tips,
      dataRule: {
        apiPath: [{ required: true, message: '接口地址不能为空', trigger: ['blur','change'] }],
        apiMethod: [{ required: true, message: '请选择请求方式', trigger: ['blur','change'] }]
      },
    }
  },
  methods: {
    /** 初始化 */
    init ({apiPath, apiMethod, apiHeaders, apiParams, apiDataFormatter}) {
      this.dataForm.apiPath = apiPath
      this.dataForm.apiMethod = apiMethod
      this.dataForm.apiHeaders = apiHeaders
      this.dataForm.apiParams = apiParams
      this.dataForm.apiDataFormatter = apiDataFormatter
      this.visible = true
      this.$nextTick(()=>{
        let formDoms = document.getElementsByClassName("apiDataForm")[0]
        let codeDoms = document.querySelector(".apiCodeMirror .CodeMirror")
        codeDoms.style.height = formDoms.offsetHeight + 'px'
        this.$refs['apiDataForm'].clearValidate()
      })
    },
    /** 提交数据 */
    dataFormSubmit () {
      this.$refs['apiDataForm'].validate((valid) => {
        if (valid) {
          const data_ = JSON.parse(JSON.stringify(this.dataForm))
          this.$emit("change", data_);
          this.visible = false
        }
      })
    },
    /** 测试根据api获取数据 */
    getApiData () {
      this.$refs['apiDataForm'].validate((valid) => {
        if (valid) {
          let headers = this.getValueByFun(this.dataForm.apiHeaders, null)
          let params = this.getValueByFun(this.dataForm.apiParams, null)
          if(this.dataForm.apiMethod == '1'){
            this.getMethod(headers, params)
          }else if(this.dataForm.apiMethod == '2'){
            this.postMethod(headers, params)
          }else{
            this.$message.error("接口请求失败：请求方式参数不正确。")
          }
        }
      })
    },
    /** get请求 */
    getMethod(headers = {}, params = {}){
      http({
        url: http.adornUrl(this.dataForm.apiPath),
        method: 'get',
        params: params
      }).then(({data}) => {
        this.retData = data
        this.getDataPreview()
      }).catch((err) => {
        console.error(err);
        this.$message.error(err)
      }).finally(_ => {})
    },
    /** post请求 */
    postMethod(headers = {}, params = {}){
      http({
        url: http.adornUrl(this.dataForm.apiPath),
        method: 'post',
        params: params
      }).then(({data}) => {
        this.retData = data
        this.getDataPreview()
      }).catch((err) => {
        console.error(err);
        this.$message.error(err)
      }).finally(_ => {})
    },
    /** 获取预览数据 */
    getDataPreview () {
      if (!this.retData) {
        this.$message.error('请先查询数据结果')
        return
      }
      if (this.dataForm.apiDataFormatter) {
        const formatterFun = eval(this.dataForm.apiDataFormatter)
        if (formatterFun) {
          const dvalue = formatterFun(this.retData)
          this.dataForm.preview = JSON.stringify(dvalue)
        }
      } else {
        this.dataForm.preview = JSON.stringify(this.retData)
      }
    },
    /** 根据函数字符串个数据获取执行函数的结果 */
    getValueByFun(funStr, data){
      if(funStr){
        const fun = eval(funStr)
        if (fun) {
          const dvalue = fun(data)
          return dvalue
        }
      }
      return data
    }
  }
}
</script>
<style scoped>
:deep(.el-dialog__body){
  max-height: 550px;
  overflow-y: auto;
}
</style>