<template>

  <!--
  <org value="1"/>

  -->


	<span>{{name}}</span>
</template>
<script>
  import {getOrgName} from '@/api/sys/region'

  export default {
    name: 'org',
    data () {
      return { 
        name: ''
      }
    },
    watch: {
      value: {//深度监听，可监听到对象、数组的变化
        handler (val, oldVal) {
          if (val != null && val != '' && val != undefined
            && val != 'undefined') {
            this.init(val)
          }else{
            this.name = ''
          }
        },
        deep: true
      }
    },
    props: ['value'],
    created () {
      // 计算数据字典的名称
      this.init(this.value) 
    },
    methods: {
      init (val) { 
        if(val) {
          getOrgName(val).then((data)=>{
            this.name = data
          })
 
        } else {
          this.name = ''
        }

      } 
    }
  }
</script>
