// 对外输出 包含组件的对外json定义、属性配置页面、展示页面 三部分
import BaseIndex from './index.vue'
import BaseProperties from './properties.vue'
import {styleConfig} from "../../../config/common/style.js";
import { getDataSourceConfig } from '../../../config/common/datasource.js'

const staticData = `
{"count": 44}
`

const datasourceConfig = getDataSourceConfig({model: false , dataStatic: staticData, dataSourceDefault: '1'})

/*
 * author wangchi
 * date 2020-07-06
 * description 数据组件件配置
 */
const list = [

    {
        type: "avue-data-rotate", // 提示
        label: "DataRotate数据展示", // 标题
        component: BaseIndex,
        properties: BaseProperties,

        formOptions: {
            config: {
                size: 'mini',
                labelWidth: 70,
                emptyBtn: false,
                submitBtn: false
            },
            group: [
                {...styleConfig}, // 样式,
                {...datasourceConfig} , // datasource

                {
                    label: '外观',
                    prop: 'layout',
                    collapse: false,
                    column: [
                        // count: '44',
                        // title: '用户注册数',
                        // icon: 'el-icon-setting',
                        // color: '#f39c12'
                        {
                            label: '标题',
                            default: '用户注册数',
                            prop: 'title',
                            span: 24,
                        },
                        {
                            label: '颜色',
                            prop: 'color',
                            default: '#f39c12' ,
                            type: 'color',
                            span: 24,
                        },
                        {
                            label: '图标',
                            prop: 'icon',
                            default: 'el-icon-setting' ,
                            span: 24,
                        },
                        {
                            label: '是否动画',
                            prop: 'animation',
                            type: 'switch',
                            span: 24,

                        }
                    ]
                },
                {
                    label: '事件',
                    prop: 'event',
                    custom: true,
                    collapse: false
                },
            ]
        },
        /* style: {
           backgroundColor: '',
           backgroundImage: '',
           itemBorderRadius: 0,
           margin: ['0', '0', '0', '0'],
           height: 100,
           color: '',
         },*/
        options: {
            tooptip: '', // 提示
            hidden: false, // 是否隐藏，false显示，true隐藏
        },
        key: ""
    }
];


export default list