<template>
  <transition name="mainApp">
    <router-view></router-view>
  </transition>
</template>
<style>
/* 解决element-ui的table表格控件表头与内容列不对齐问题 */
.el-table th.gutter {
  display: table-cell !important;
}
</style>
<script>
import microApp, { getActiveApps } from '@micro-zoe/micro-app'
import { messages } from '@/i18n'
import { getValue } from '@/api/sys/config'
export default {

  data() {
    return {
      value:'',
    };
  },

  computed: {
    // ...mapGetters(["themeName"])
    menuLayout: {
      set(val) {
        this.$store.commit("common/updateMenuLayout", val);
      },
      get() {
        return this.$store.state.common.menuLayout;
      }
    },
    breLayout: {
      set(val) {
        this.$store.commit("common/updateBreadcrumbLayout", val);
      },
      get() {
        return this.$store.state.common.breadcrumbLayout;
      }
    },
    breOpen: {
      set(val) {
        this.$store.commit("common/updateBreadcrumbOpen", val);
      },
      get() {
        return this.$store.state.common.breadcrumbOpen;
      }
    },
    tabOpen: {
      set(val) {
        this.$store.commit("common/updateTabOpen", val);
      },
      get() {
        return this.$store.state.common.tabOpen;
      }
    }
  },
  watch: {
    '$i18n.locale': 'i18nHandle'
  },
  created () {
    this.i18nHandle(this.$i18n.locale)
    this.getKey()
  },
  methods: {
    i18nHandle (val, oldVal) {

      this.$cookie.set('language', val)
      document.querySelector('html').setAttribute('lang', val)
      //document.title = messages[val].brand.lg
      document.title = this.value

      // 非登录页面，切换语言刷新页面
      if (this.$route.name !== 'login' && oldVal) {
        window.location.reload()
      }
    },
    //配置首页的名称
    getKey() {
      const key = 'name'
      getValue(key).then(({ data }) => {
        if (data && data.code == 0) {
          this.value = data.data
          document.title = this.value
          console.log(this.value)
        } else if (data && data.msg) {
          this.$message.error(data.msg)
        }
      }).catch((e) => {})
    },

  },
  activated() {
    let READYTOPROCESS = false;
    window.onbeforeunload = e => {
      if (!READYTOPROCESS) {
        //message to be returned to the popup box.
        var message = "你确定要关闭吗？",
          a = e || window.event;
        if (a) e.returnValue = message; // IE
        return message; // Safari
      }
    };
  },


  mounted() {
    var item = localStorage.getItem("theme_color");
    var menuLayout = localStorage.getItem("menu_layout");

    var openBre_ = localStorage.getItem("openBreadcrumb");
    var breLayout_ = localStorage.getItem("breadcrumbLayout");

    var openTabs_ = localStorage.getItem("openTabs");

    if (openBre_ == null) {
      openBre_ = true;
    }
    if (breLayout_ == null) {
      breLayout_ = "header";
    }

    if (breLayout_ != null) this.breLayout = breLayout_;
    if (openBre_ != null) this.breOpen = openBre_;

    if (openTabs_ != null) this.tabOpen = openTabs_;

    var classNames = "";
    if (item) {
      classNames += item + " ";
    }
    if (menuLayout && menuLayout == 'true') {
      this.menuLayout = true;
    } else {
      this.menuLayout = false;
    }
     classNames += " tr-sidebar";
    if (classNames) {

      document.body.className = classNames;
    }
  }
};
</script>

<style>
/* 隐藏gantt图过期的提醒弹窗 */
div.gantt_message_area.dhtmlx_message_area {
  z-index: 99999;
}
/* 隐藏多余的icon */
.el-input__icon.el-input__validateIcon.el-icon-circle-close {
  display: none;
}
</style>
