<!--
柱状图 或者线图
-->
<template>
    <div >
        <!-- :style="{'max-height': record.layout.height + 'px'}" -->
        <div v-if="record.layout.title" class="item-table-title" :style="{textAlign: record.layout.titlePosition || 'left'}">
            {{record.layout.title}}
        </div>
       
        <el-table :data="dataList" border stripe v-loading="dataListLoading" :span-method="spanMethod" :height="record.layout.autoHeight ? null : record.layout.height"  style="width: 100%;">
            <el-table-column type="index" width="50" header-align="center" align="center" v-if="record.layout.showIndex">
            </el-table-column>
            <!-- <HbiTableColumn :columns="columns" :models="models" /> -->
             
            <template v-for="(item , itemIdx ) in columns" > 
                <hbi-table-column :column="item" :key="item.label + itemIdx" :models="models" v-if="item.show == undefined || item.show == true">
                     <template slot-scope="{col,row,index,prop}"
                        slot="expand" > 
                            <span v-if="col.formater" v-html="formaterColumn(col.formater , row , row[prop])"></span>
                            <span v-else>{{row[prop]}}</span>   
                    </template>  
                </hbi-table-column> 
            </template> 
        </el-table>
        <el-pagination class="mod-footer" v-if="(!record.dataSource || record.dataSource.type == 2) && record.data && record.data.page" @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalCount" layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
    </div>
</template>
<script>
import { getDatamodelQuery } from '@/api/hbi/datamodel.js'
import { dynamicFun } from '../../../../utils/index.js'
import HbiTableColumn from './table-column'
import { getDataByDatasource } from '../../../config/common/datasource.js'


export default {
    components: {
        HbiTableColumn
    },
    data() {
        return {
            options: {},
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
            dataListLoading: false,
        }
    },
    props: {
        // 表单数组 
        record: {
            type: Object,
            required: true
        },
        // form-item 宽度配置
        formConfig: {
            type: Object,
            required: false
        },
        // form-item 宽度配置
        models: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // 是否预览结果表单
        renderPreview: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        recordOptions() {

            // const options = {}
            // for (let i in this.record) {
            //     options[i] = { ...this.record[i] }
            // }
            const options = {...this.record.layout } 
            return options

        },
        columns() {
            return this.record.columns || []
        },
        spanMethod() {
            if(this.record.layout.spanMethod) {
                try{
                    const fs = eval(this.record.layout.spanMethod)
                    return fs 
                } catch (error) {
                    console.error('hbi-table spanMethod error ',error)
                }
                 
            }
            return null
        }
    },
    // watch: {
    //     recordOptions: { //深度监听，可监听到对象、数组的变化
    //         handler() {
    //             this.init()
    //         },
    //         deep: true 
    //     },
    // },
    methods: {
        init() {
            this.currentChangeHandle(1)
        },
        // 获取数据列表 
        getDataList() {
          
            const this_ = this


            const dataSourceType = this.record.dataSource && this.record.dataSource.type ? this.record.dataSource.type : 2
            console.log('dataSourceType' , dataSourceType)
            if(dataSourceType == 2) {
                this.getDatModelList()
            } else {
                getDataByDatasource(this.record , this.models).then((data)=> {
                    console.log('data' , data)
                    this.dataList = data
                })
            }

            

        },
        getDatModelList() {
            const this_ = this
            
            const dataModelId = this.record.data.dataModelId 
            const page = this.record.data.page
            const limit = this.record.data.limit


            
            let resColumns = []
            let resColumnProps = {}
            if(this.record.columns) {
                console.log('mnbvcxz',this.record.columns)

                // 2022-07-26 lyf 迭代去重 判断children 
                const columnFs = (c)=> {
                    if(c instanceof Array) {
                        c.forEach(t=> columnFs(t))
                    } else {

                        // 判断c有没有children
                        if(c.children && c.children.length > 0) {
                            c.children.forEach(t=> columnFs(t))
                        } 
                        if(c.prop && !resColumnProps[c.prop]){
                            resColumns.push( { aliasName: c.prop })

                            resColumnProps[c.prop] = 1
                        }

                    }
                }

                columnFs(this.record.columns)
                // resColumns =  this.record.columns.map(t => {
                //     return { column: t.prop }
                // })
            }
 
            if(!dataModelId || !resColumns || resColumns.length == 0) {
                this.dataList = [] 
                return
            } 

            let filter = []
            
            if (this.record.filter) {
                filter = this.record.filter.map(t => {
                    let fvalue = t.value
                    if (t.type == '2') {
                        fvalue = dynamicFun(fvalue, this_.models)
                    }

                    return { column: t.column, filterType: t.filterType, value: fvalue }
                })
            }


            const params = {
                dataModelId: dataModelId,
                resColumns: resColumns,
                filters: filter,
                order: [],
                group: [],
                page: page,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize

            } 

            if(!page) { 
                params.limit = limit 
            }

            // 获取参数 

            this.dataListLoading = true;
            getDatamodelQuery(params).then(({ data }) => {
                if (data && data.code === 0) {
                    if (page) {
                        this.dataList = data.data.list
                        this.totalCount = data.data.totalCount

                        if (data.data.page != this.pageIndex) {
                            this.pageIndex = data.data.page
                        }

                    } else {
                        this.dataList = data.data
                    }

                } else {
                    if (data.msg) {
                        this.$message.error(data.msg)
                    }
                    this.dataList = []
                    this.totalCount = 0
                    this.pageIndex = 1
                }

            }).finally(() => {
                this.dataListLoading = false;
            });
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageIndex = 1;
            this.getDataList();
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.getDataList();
        },
        formaterColumn(script, row, columnValue) {
            if (script && columnValue) {
               // console.log('script' , script)
                const fn = eval(script)

                const html_ = fn(row , this.models)

                return html_

                //return dynamicFun(script, this.models, columnValue)
            }
            return columnValue
        }
    }
}
</script>
<style>
.item-table-title {
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 700;
    color: #303133;
    /* display: flex; */
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    /*text-align: right;*/
}
</style>