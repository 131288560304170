export default {
    /* 模块名字 */
    name: 'menus',

    /* 英文资源 */
    en_US: {
        refresh: 'Refresh',
        name: 'Name',
        enName:'English Name',
        parentMenu: 'Parent',
        icon: 'Icon',
        type: 'Type',
        dic: 'dic',
        menu: 'menu',
        button: 'button',
        number: 'Number',
        url: 'url',
        authTag: 'Auth Tag',
        hasEnable: 'Enable',
        enable: 'enable',
        disable: 'disable',
        deleteInfo: 'Are you sure you want to delete?',
        addMenuInfo: 'Add Menu',
        updateMenuInfo: 'Update Menu',
        routeName: 'routeName',
        filePath: 'file path',
        isEnable: 'enable',
        menuIcon: 'menu icon',
        recomInfo: 'Recommended Use ',
        menuUrlNotBlank: 'menu url not empty',
        routeNameNotBlank: 'route name not empty',
        fileNotBlank: 'file path not empty',
        menuNameNotBlank: 'menu name not empty',
        parentNameNotBlank: 'parent name not empty',



    },

    /* 中文资源 */
    zh_CN: {
        refresh: '刷新',
        name: '名称',
        enName:'英文名称',
        parentMenu: '上级菜单',
        icon: '图标',
        type: '类型',
        dic: '目录',
        menu: '菜单',
        button: '按钮',
        number: '排序号',
        url: '菜单Url',
        authTag: '授权标识',
        hasEnable: '开启',
        enable: '开启',
        disable: '关闭',
        deleteInfo: '确定进行删除操作?',
        addMenuInfo: '新增菜单',
        updateMenuInfo: '修改菜单',
        routeName: '路由名称',
        filePath: '文件地址',
        isEnable: '是否启用',
        menuIcon: '菜单图标',
        recomInfo: '全站推荐使用',
        menuUrlNotBlank: '菜单Url不能为空',
        routeNameNotBlank: '路由名称不能为空',
        fileNotBlank: '文件地址不能为空',
        menuNameNotBlank: '菜单名称不能为空',
        parentNameNotBlank: '上级菜单不能为空',




    }


}
