<!--
表单控件属性设置组件,因为配置数据是引用关系，所以可以直接修改
-->
<template>
  <div class="properties-centent">
   
    <div class="properties-body"> 
      <p class="hint-box" v-if="!selectItem || !selectItem.key">未选择控件</p>
      <el-form :key="selectItem ? selectItem.key : 'properties-form'" v-if="selectItem && selectItem.key" size="mini" :disabled="disabled" :model="selectItem">
 
        <el-form-item label="栅格数"  >
          <el-input-number v-model="selectItem.span" :min="0" :max="24" title="栅格数,最大24格"></el-input-number>
        </el-form-item>
        <el-form-item label="偏移栏位"  >
          <el-input-number v-model="selectItem.offset" :min="0" :max="24" title="偏移栏位"></el-input-number>
        </el-form-item>

         
        <!-- 区域选择 end --> 
        <!-- ############# 为自定义组件预备的插槽 start ############### -->

        <slot name="custom-properties"></slot>


         <!-- ############# 为自定义组件预备的插槽 end ############### -->
        

        <!-- ################### 布局  end ################################  -->

         <!-- tooptip 提示 -->
        <el-form-item  label="tooptip提示" > 
          <el-input type="textarea" v-model="options.tooptip"  placeholder="鼠标移动到组件上的提示信息" /> 
        </el-form-item> 
        <el-divider ></el-divider>

        <!-- ################### 校验   start ############################## -->
        <el-form-item  v-if="selectItem.rules  && selectItem.rules.length > 0 " label="校验" >
          <el-checkbox v-model="selectItem.rules[0].required" label="必填" />
          <el-input v-model="selectItem.rules[0].message"  placeholder="必填校验提示信息" />
          <Option v-model="selectItem.rules" type="rules" :disabled="disabled" />

          <el-divider ></el-divider>
        </el-form-item>
        <!-- ################### 校验   end ############################## -->

       

        <template v-if="selectItem && selectItem.options">
           <el-form-item label="动态显示">
            <!-- 每个元素都有隐藏条件 根据渲染数据的值来改变 --> 
            <el-switch
              v-model="selectItem.options.dynamicVisible"
              active-text="打开"
              inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="显示条件" v-if="selectItem.options.dynamicVisible">
            <!-- 每个元素都有隐藏条件 根据渲染数据的值来改变 -->
            <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入显示条件,$标识当前整个表单的绑定数据"
              v-model="selectItem.options.dynamicVisibleValue">
            </el-input>
          </el-form-item>
          <el-divider ></el-divider>
        </template>
        <!-- 条件禁用 lyf 2021-05-06-->
        <template v-if="selectItem.options && selectItem.options.disabled">
           <el-form-item label="动态禁用">
            <!-- 每个元素都有隐藏条件 根据渲染数据的值来改变 --> 
            <el-switch
              v-model="selectItem.options.dynamicDisabled"
              active-text="打开"
              inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="禁用条件" v-if="selectItem.options.dynamicDisabled">
            <!-- 每个元素都有隐藏条件 根据渲染数据的值来改变 -->
            <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入禁用条件,$标识当前整个表单的绑定数据,data标识当前事项实体数据"
              v-model="selectItem.options.dynamicDisabledValue">
            </el-input>
          </el-form-item>

        </template>
        

      </el-form>
    </div> 
  </div>
</template>
<script> 
import Option from "./option";
import Linkage from './linkage' 
export default {
  name: "formItemProperties",
  data() {
    return {
      options: {} 
    };
  },
  watch: {
    selectItem(val) { 
      

      this.options = val ? val.options || {} : {}

       // 判断 labelWidth 
      if(this.selectItem) {
        
        if(!Object.prototype.hasOwnProperty.call(this.selectItem, 'span')){
          this.$set(this.selectItem , 'span' , 24)
        }
        if(!Object.prototype.hasOwnProperty.call(this.selectItem, 'offset')){
          this.$set(this.selectItem , 'offset' , 0)
        }
      }
      
    }
  },  
  methods: { 
  },
  props: {
    selectItem: {
      type: Object 
    },

    hideModel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Option
  }
};
</script>
