var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
       margin: _vm.record.style && _vm.record.style.margin && _vm.record.style.margin.length > 0 ? _vm.record.style.margin.join('px ') + 'px' : '0px',
    })},[(_vm.record.type === 'button' && _vm.dynamicVisibleItem)?_c('el-button',{attrs:{"disabled":_vm.disabled || _vm.record.options.disabled,"type":_vm.record.options.type},domProps:{"textContent":_vm._s(_vm.record.label)},on:{"click":_vm.buttonClick}}):(_vm.record.type === 'text' && _vm.dynamicVisibleItem )?_c('div',{staticClass:"form-label",style:({
      fontSize: (_vm.record.options.fontSize || 13) + 'px', 
      textAlign: _vm.record.options.textAlign || 'left',
      color: _vm.record.options.fontColor || '#000000',
      fontWeight: _vm.record.options.fontBold ? 'bold': '',
      background: _vm.record.options.background || '#FFFFFFFF',
      })},[_c('label',{class:{ 'is-required': _vm.record.options.showRequiredMark || _vm.showRequiredMark },domProps:{"textContent":_vm._s(_vm.record.label)}})]):(_vm.record.type === 'alert' && _vm.dynamicVisibleItem )?_c('div',{staticClass:"form-label",style:({ 
      textAlign: _vm.record.options.textAlign || 'left' 
      })},[_c('el-alert',{attrs:{"title":_vm.record.options.title,"type":_vm.record.options.type,"description":_vm.record.options.description,"effect":_vm.record.options.effect,"closable":_vm.record.options.closable,"center":_vm.record.options.center,"close-text":_vm.record.options.closeText,"show-icon":_vm.record.options.showIcon}})],1):(_vm.record.type === 'html' && _vm.dynamicVisibleItem)?_c('div',{attrs:{"id":_vm.record.model,"name":_vm.record.model},domProps:{"innerHTML":_vm._s(_vm.record.options.defaultValue)}}):(_vm.dynamicVisibleItem)?_c('div',[( _vm.record.type === 'divider' && _vm.record.label !== '' && _vm.record.options.orientation )?_c('el-divider',{attrs:{"content-position":_vm.record.options.orientation,"direction":_vm.record.options.direction ? _vm.record.options.direction : 'horizontal'}},[_vm._v(" "+_vm._s(_vm.record.label)+" ")]):(_vm.record.type === 'divider' && _vm.record.label !== '')?_c('el-divider',{attrs:{"direction":_vm.record.options.direction ? _vm.record.options.direction : 'horizontal'}},[_vm._v(" "+_vm._s(_vm.record.label)+" ")]):(_vm.record.type === 'divider' && _vm.record.label === '')?_c('el-divider',{attrs:{"direction":_vm.record.options.direction ? _vm.record.options.direction : 'horizontal'}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }