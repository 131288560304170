import Vue from "vue";
import router from "@/router";
import store from "@/store";
import { isURL } from "./validate";
import {clearAll} from '@/api/sys/dict'
import moment from 'moment'
let status401 = false;

export function is401() {
  return status401;
}

export function set401(val) {
  status401 = val;
}

export function getTokenName() {
  return 'x-tr-mimosa-token'
}

export function getToken() { 
  const tokenName = getTokenName()
  const token = Vue.cookie.get(tokenName)

  return token 
}


/**
 * 获取uuid
 */
export function getUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    return (c === "x" ? (Math.random() * 16) | 0 : "r&0x3" | "0x8").toString(
      16
    );
  });
}

/**
 * 获取uuid
 */
export function getGUID() {
  return "xxxxxxxxxxxxx".replace(/[xy]/g, c => {
    return (  (Math.random() * 8) | 0 ).toString(
      10
    );
  });
}

export function back() {
  history.go(-1);
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
  return (
    JSON.parse(sessionStorage.getItem("permissions") || "[]").indexOf(key) !==
      -1 || false
  );
}

export function dynamicFun(script, model) {
  if (!script) return false;
  const func =
    script.indexOf("return") >= 0
      ? "{" + script + "}"
      : "return (" + script + ")";
  const Fn = new Function("$", func);
  return Fn(model);
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(
  data,
  id = "id",
  pid = "parentId",
  clear = false
) {
  var res = [];
  var temp = {};
  for (var i = 0; i < data.length; i++) {
    if (clear) data[i].children = [];
    temp[data[i][id]] = data[i];
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]["children"]) {
        temp[data[k][pid]]["children"] = [];
      }
      if (!temp[data[k][pid]]["_level"]) {
        temp[data[k][pid]]["_level"] = 1;
      }
      data[k]["_level"] = temp[data[k][pid]]._level + 1;
      temp[data[k][pid]]["children"].push(data[k]);
    } else {
      res.push(data[k]);
    }
  }

  return res;
}

export function urlQueryParse(url) {
  let querys = {};
  let str = url;
  let num = str.indexOf("?");
  str = str.substr(num + 1); //取得所有参数   stringvar.substr(start [, length ]

  var arr = str.split("&"); //各个参数放到数组里

  for (var i = 0; i < arr.length; i++) {
    num = arr[i].indexOf("=");
    if (num > 0) {
      const name = arr[i].substring(0, num);
      const value = arr[i].substr(num + 1);

      querys[name] = value;
    }
  }

  return querys;
}

export function formatSeconds(theTime) {
  let theTime1 = 0;
  let theTime2 = 0;
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
    }
  }
  let result = "" + parseInt(theTime) + "秒";
  if (theTime1 > 0) {
    result = "" + parseInt(theTime1) + "分" + result;
  }
  if (theTime2 > 0) {
    result = "" + parseInt(theTime2) + "小时" + result;
  }
  return result;
}

export function toRoute(routeName, target) {
  if (isURL(routeName)) {
    // 加参
    /*if(url.indexOf('?') > 0) {
          url = url
        } else {
          url = url
        }*/
    if (target && target == "this") {
      window.location.href = routeName;
    } else {
      window.open(routeName, target ? target : "_blank");
    }
  } else {
    // 判断路由中是否存在
    if (target && target == "this") {
      router.push({ name: routeName });
    } else {
      let routeUrl = router.resolve({
        name: routeName
        //params: {id:menu.menuId}
      });
      window.open(routeUrl.href, target ? target : "_blank");
    }
  }
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
  Vue.cookie.delete(getTokenName());
  Vue.cookie.delete('Username');
  store.commit("resetStore");
    // 清理字典缓存
  clearAll()

  // 清理sessionstore 
  window.sessionStorage.clear() 
  router.options.isAddDynamicMenuRoutes = false;
}

export function formatJson(filterVal, jsonData) {
  return jsonData ? jsonData.map(v => filterVal.map(j => v[j])) : [];
}

export function formatTableJson(columns, list) {
  const Header_ = [];
  const FilterVal_ = [];
  columns.forEach(c => {
    Header_.push(c.label);
    FilterVal_.push(c.prop);
  });
  // TOP200
  const data_ = formatJson(FilterVal_, list);
  data_.unshift(Header_);

  return data_;
}

export function isObjectValueEqual(a, b) {
  //取对象a和b的属性名
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);
  //判断属性名的length是否一致
  if (aProps.length != bProps.length) {
    console.log("aProps.length != bProps.length", aProps.length, bProps.length);
    return false;
  }
  //循环取出属性名，再判断属性值是否一致
  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];
    if (a[propName] !== b[propName]) {
      console.log("a[propName] !== b[propName]", a[propName], b[propName]);
      return false;
    }
  }
  return true;
}



/**
 * ***js时间日期格式化*** <br>
 * <p>
 * 模版字符串采用严谨格式，超出则会抛出异常，且每类格式只可出现一次，如:yyyy-mm-yyyy 格式会抛异常
 * </p>
 * y-年    length: 2/4位 <br>
 * q-季度    length: 1位 <br>
 * M-月    length: 1~2位 <br>
 * d-日    length: 1~2位 <br>
 * H-时    length: 1~2位24小时制，h：12小时制 <br>
 * m-分    length: 1~2位 <br>
 * s-秒    length: 1~2位 <br>
 * S-毫秒 length: 固定1位
 * @param {Date类型对象} date
 * @param {String类型模板字符串} fmt
 * @return 格式化后时间日期字符串
 * @author lyt
 */
export function dateFormat(date, fmt){
    if (arguments.length != 2) // 参数个数校验
        throw Error('arguments长度不合法');
    if (!date || (typeof date != 'object') || (date.constructor != Date)) // 参数合法性校验
        throw Error(arguments[0] + ':类型不为Date类型');
    if (/H+/.test(fmt) && /h+/.test(fmt))
        throw Error("小时格式错误，同类型只能连续出现一次！");
    /* 模板参数校验，正则验证方法 */
    var verify = function(Rex ){
        var arr = new RegExp(Rex).exec(fmt); // 获得匹配结果数组
        if (!arr) // 匹配失败返回
            return "";
        if (fmt.split(Rex).length > 2)  // 同一类型间隔出现多次
            throw Error("fmt格式错误：同类型只能连续出现一次！");
        return arr[0];
    };
    /**
     * 提供月、天、时、分、秒通用匹配替换
     * @param {对象o属性key} r
     * @param {r对应正则对象} rex
     **/
    var common = function(r, rex) {
        if(len !=1 && len !=2)
            throw Error("月份格式错误:M只能出现1/2次");
        len == 2 ? fmt=fmt.replace(rex, o[r].length==1 ? "0"+o[r] : o[r]) : fmt=fmt.replace(rex, o[r]);
    }
    var o = { // 数据存储对象
        "y+": date.getFullYear() + "", // 年
        "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
        "M+": date.getMonth() + 1 + "", // 月
        "d+": date.getDate() + "", // 日
        "H+": date.getHours() + "", // 24时
        "h+": date.getHours() + "", // 12时
        "m+": date.getMinutes() + "", // 分
        "s+": date.getSeconds() + "", // 秒
        "S+": date.getMilliseconds() // 毫秒
    }
    for(var r in o) {
        var rex, len, temp;
        rex = new RegExp(r);
        temp = verify(rex); // 匹配所得字符串
        len = temp.length; // 长度
        if(!len || len == 0) 
            continue;
        if(r == "y+") { 
            if(len !=2 && len != 4)
                throw Error("年份格式错误:y只能出现2/4次");
            len == 2 ? fmt=fmt.replace(rex, o[r].substr(2,3)) : fmt=fmt.replace(rex, o[r]);
        } else if(r == "q+") {
            if(len != 1)
                throw Error("季度格式错误:q只能出现1次");
            fmt=fmt.replace(rex, o[r]);
        } else if(r == "h+") {
            if(len !=1 && len !=2)
                throw Error("小时格式错误:h只能出现1/2次");
            var h = (o[r] > 12 ? o[r]-12 : o[r]) + "";
            len == 2 ? fmt=fmt.replace(rex, h.length==1 ? "0"+h : h) : fmt=fmt.replace(rex, h);
        }  else if(r == "S+") {
            if(len != 1) 
                throw Error("毫秒数格式错误:S只能出现1次");
            fmt=fmt.replace(rex, o[r]);
        }else {    // (r=="M+" || r=="d+" || r=="H+" || r=="m+" || r=="s+")
            common(r, rex)
        }  
    }
    return fmt;
}
export function dateFormat_ymd (row, column) {
    let date = row[column.property]
    if (date == undefined) {
        return '';
    }
    return moment(date).format('YYYY-MM-DD')
}

export function datePipe(timestamp, format = 'yyyy-MM-dd hh:mm:ss') {
    if(!timestamp){
        return ""
    }
    let date = new Date(timestamp);
    var o = {
        'y+': date.getFullYear(),
        'M+': date.getMonth() + 1, // 月份 "d+": value.getDate(), // 日
        'd+': date.getDate(),
        'H+': date.getHours(), // 小时 "m+": value.getMinutes(), // 分 "s+": value.getSeconds(), // 秒
        'h+': date.getHours(), // 小时 "m+": value.getMinutes(), // 分 "s+": value.getSeconds(), // 秒
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        "S+": date.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp('(' + k + ')').test(format)) {
            format = format.replace(
                RegExp.$1,
                RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
            );
        }
    }
    return format;
}

// 排列数组为多维数组
export function sliceArray(array,size) {
  const arrNum = Math.ceil(array.length/size, 10);
  let index = 0; // 定义初始索引
  let resIndex = 0; // 用来保存每次拆分的长度
  const result = [];
  while(index< arrNum){
      result[index]= array.slice(resIndex,size+resIndex);
      resIndex += size;
      index++;
  }
  return result; 
}

// 转换选项值，用于从后台接口查询的选项
export function formatOptions(array, nameField, valueField, value) {
  let arr = array;
  for (let i = 0; i < arr.length; i++) {
    if (value == arr[i].get(valueField)) {
      return arr[i].get(nameField);
    }
  }
  return value; 
}

// 转换选项值，用于从后台接口查询的选项
export function formatOptionsKeyToName(array, value) {
  let arr = array;
  for (let i = 0; i < arr.length; i++) {
    if (value == arr[i].key) {
      return arr[i].name;
    }
  }
  return value; 
}
