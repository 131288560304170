<template>
  <!--
    sample :  <sysDictSelect  v-model="form.sex" dictType="sex" />
     其中form 必须为外部页面传递过来,
     field是当前需要将选择的数据绑定到form中哪个字段
         dictType为数据字典中的类型

    -->
    <div>

    <el-select v-if="multiple"   v-model="dictForm.values" multiple :multiple-limit="multipleLimit"  :placeholder="placeholder" @change="changeSelect" :clearable="clearable" :disabled="disabled" :filterable="filterable" :style="styleclass"  @blur="handleBlur">
      <template v-for="item in dicts">
        <el-option 
          v-if=" (!excludeValue || (excludeValue.indexOf(item.name) < 0  && excludeValue.split(',').filter(t=>item.name.indexOf(t) >=0).length <= 0 && excludeValue.indexOf(item.value) < 0  && excludeValue.split(',').filter(t=>item.value.indexOf(t) >=0).length <= 0) )
            && (
              !includeValue || includeValue.indexOf(item.name) >= 0 || includeValue.indexOf(item.value) >= 0
              )
          "
          :key="item.value"
          :label="item.name"
          :value="item.value">
        </el-option>
      </template> 
    </el-select>
    <el-select v-else v-model="dictForm.value" :placeholder="placeholder" @change="changeSelect" :clearable="clearable"
               :disabled="disabled" :filterable="filterable" :style="styleclass"  @blur="handleBlur">
      <template  v-for="item in dicts">
        <el-option 
          v-if="(!excludeValue || (excludeValue.indexOf(item.name) < 0  && excludeValue.split(',').filter(t=>item.name.indexOf(t)>=0).length <= 0 && excludeValue.indexOf(item.value) < 0  && excludeValue.split(',').filter(t=>item.value.indexOf(t) >=0).length <= 0))
            &&
           (
              !includeValue || includeValue.indexOf(item.name) >= 0 || includeValue.indexOf(item.value) >= 0
              )
            "
          :key="item.value"
          :label="item.name"
          :value="item.value">
        </el-option>
      </template> 
    </el-select>
   </div>
</template>
<script>
  import {putDictType, getDictType, getDictTypeValues} from '@/api/sys/dict'

  export default {
    data () {
      return {
        dicts: [],
        dictForm: {
          values: [],
          value: null,
          title: '' // 当前选中的title
        }
      }
    },
    props: {
      //form: null,
      //field: null,
      value: {
        type: [String , Number , Array],
        // required: true
      },
      dictType: {
        type: String,
        required: true
      },
      styleclass: {
        type: String,
        default: 'width:100%'
      },
      placeholder: {
        type: String,
        default: '请选择'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      clearable: {
        type: Boolean,
        default: true
      },
      multipleLimit: {
        type: Number,
        default: 0
      },
      excludeValue: {
        type: String
      },
      // 只包含哪些数据
      includeValue: {
        type: String
      },
      filterable: {
        type: Boolean,
        default: false
      }

    },
    inject: {
      elForm: {
        default: ''
      },
      elFormItem: {
        default: ''
      }
    },
    watch: {
      dictType (val) {
        if (this.dictType) {
          this.initData()
        }
      },
      value: {// 深度监听，可监听到对象、数组的变化
        handler (val, oldVal) {
          // console.log('watch val' , val)
          if (!val || val == '') {
            this.dictForm.values = []
            this.dictForm.value = null
            this.dictForm.title = null
          } else {
            this.putSelect()
          }
        },
        deep: true
      }
    },
    created () {
      // 根据类型查询数据 
      if (this.dictType) {
        this.initData()
      }
    },
    methods: {
      initData () {
        getDictTypeValues(this.dictType).then((list) => {
          this.dicts = list

          this.putSelect(true)
        })
      },
      // 返回所有待选的数据
      getAll() {
        return this.dicts
      },
      // 获取选中项的名称 如果是multiple则是数组，单选就直接返回
      getSelectName() {
        if(this.multiple) {
          // 多选
          if(!this.dictForm.values) {
            return []
          }
          const names = this.dictForm.values.map(t=> {
            const fs = this.dicts.filter(f=>f.value == t)
            if(fs && fs.length > 0){
              return fs[0].name
            }
            return null
          })

          return names 

        } else {

          if(!this.dictForm.value) {
            return null
          }
          const fs = this.dicts.filter(f=>f.value == this.dictForm.value)
          if(fs && fs.length > 0){
            return fs[0].name
          }
          return null
        }
      },
      // 选择回填
      putSelect (toInit = false) {
        if (!toInit && (!this.dicts || this.dicts.length == 0)) {
          this.initData()
        } else {
          // 判断当前是否有值 有值回填
          let select = this.value
          // console.log('select' , select)
          if (this.multiple) {
              //  多选
            if (!(select instanceof Array)) {
              select = [select]
            }
            let selectValues = []
              // 2021-03-03 lyf 从values里过滤不存在于字典的值
            for (let i = 0; i < select.length; i++) {
              if (this.dicts.filter(t => t.value == select[i]).length > 0) {
                selectValues.push(select[i])
              }
            }
            this.dictForm.values = selectValues

            if (selectValues.length != select.length) {
              this.changeSelect(this.dictForm.values)
            }
          } else {
              // 单选
            for (var i in this.dicts) {
              if (this.dicts[i].value == select) {
                this.dictForm.value = this.dicts[i].value
                this.dictForm.title = this.dicts[i].dictName
                return
              }
            }
              // 到这里了说明没选上,用当前值
              // this.dictForm.value = select;
              // this.dictForm.title = select;
            this.changeSelect(null)
          }
        }
      },
      // 选择回调
      changeSelect (value) {  
        this.$emit('input', value)
        this.$emit('change', value)
        // setTimeout(() => {
        //   if(this.elForm && this.elFormItem && this.elFormItem.prop) {
        //     this.elForm.validateField(this.elFormItem.prop , ()=>{})
        //   }
        // }, 50) 
      },
      handleBlur(event) {
        // setTimeout(() => {
        //   this.$emit('blur', event);
        // }, 50)
      } 
    }
  }
</script>
<style>
  /*.el-select-dropdown{
    max-width: 232px;
  }
  .el-select-dropdown__item{
    display: inline-block;
  }
  .el-select-dropdown__item span {
    min-width: 300px;
    display: inline-block;
  }*/
</style>
