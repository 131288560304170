<!--
表单控件属性设置组件,因为配置数据是引用关系，所以可以直接修改
-->
<template>
  <BaseProperties ref="baseProperties" :selectItem="selectItem" :selectItemKey="selectItem.key"
                  v-if="selectItem && selectItem.type ">
  </BaseProperties>
</template>
<script>
import BaseProperties from '../../../config/data-properties.vue'

export default {
  name: "HbiChartFunnelProperties",
  components: {
    BaseProperties
  },
  data() {
    return {}
  },

  methods: {
    init() {


    }
  },
  props: {
    selectItem: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>