import httpRequest from './httpRequest'
import { getUserName } from '@/api/sys/user'

let users = {}; 
 

//从local中获取当前已有的列表
var sessionUsers_ = window.sessionStorage.getItem('users');
if (sessionUsers_ != null && sessionUsers_ != '') {
  //console.log('sessionDicts_' , sessionDicts_)
  try {
    users =  JSON.parse(sessionUsers_);
   }catch(error) {
    users = []
   }
 
}
 
function putUser(id , info) {
 
  users[id] = info 
  const json_ = JSON.stringify(users)
  window.sessionStorage.setItem('users' , json_);
 
}


/**
* 根据部门ID返回部门名称
* @param {id} 部门ID 
*/
export function getInfo(id) {
 
  
  //判断缓存中是否存在
  return new Promise((resolve, reject)=>{

    if(!id) {
      reject()
      return
    }

    if(users[id]) {
      resolve(users[id])
      return 
    }

 
    getUserName(id).then(({data}) => {
           
      if (data.code === 0) {
        const info = data.data
        if(info) { 
          // 放入缓存 然后返回
          putUser(id , info) 

          resolve(info)
          return
        }
      }  
      reject()
    }).catch((e)=>{
      reject()
    })

  })
   
}


 