<template>
    <el-container class="form-design">
        <el-header height="50px" style="text-align: center;background:#1e7cdd;line-height:50px;">
            <span class="el-card__header">
                <slot name="formName"></slot>
            </span>
            <span style="float: right;">
                <slot name="controlButton">
                </slot>
                <el-button style="margin-right:20px;margin-left:20px;" v-if="clear" type="text" size="medium" icon="el-icon-delete" @click="handleClear">清空</el-button>
                <el-button style="margin-right:20px;" v-if="preview" type="text" size="medium" icon="el-icon-view" @click="handlePreview">预览</el-button>
                <el-button style="margin-right:20px;" v-if="imp" type="text" size="medium" icon="el-icon-download" @click="handleImport">导入</el-button>
                <el-button style="margin-right:20px;" v-if="exp" type="text" size="medium" icon="el-icon-upload2" @click="handleGenerateJson">导出</el-button>
            </span>
        </el-header>
        <el-container>
            <el-main>
                <el-card class="box-card form-contains">
                    <DesignPanel :data="data" ref="dragPanel" :dragType="dragType" :selectForm="selectForm" @changeSelectItem="changeSelectItem" />
                </el-card>
            </el-main>
            <el-aside width="320px">
                <Properties :data="data" :selectItem="selectItem">
                    <template slot="custom-properties" :selectItem="selectItem">
                        <slot name="custom-properties" :selectItem="selectItem"></slot>
                    </template>
                    <template slot="form-extend-properties" :data="data">
                        <slot name="form-extend-properties" :data="data"></slot>
                    </template>
                    <template slot="extend-tab" :data="data">
                        <slot name="extend-tab" :data="data"></slot>
                        <el-tab-pane label="组件列表" name="itemList">
                            <DragPanel @handleDragType="handleDragType" :basic-item="basicItem" :personal-item="personalItem" :layout-item="layoutItem" />
                        </el-tab-pane>
                    </template>
                </Properties>
            </el-aside>
        </el-container>
        <Preview v-if="previewVisible" ref="preview" />
        <previewCode ref="model" v-if="modelVisible" />
        <el-dialog title="模板数据" :visible.sync="importVisible" :append-to-body="true" style="top:20px;" width="850px">
            <el-input type="textarea" :rows="3" v-model="importText">
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="importVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="importModel">确 定</el-button>
            </span>
        </el-dialog>
    </el-container>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'

import DragPanel from './drag-panel/index'
import DesignPanel from './design-panel/index'
import Properties from './properties/index'

import Preview from '../preview/index'
import previewCode from "../preview/preview-code";


export default {
    name: 'hbi-design',
    data() {
        return {
            previewVisible: false,
            modelJson: {},

            modelVisible: false,
            importVisible: false,
            importText: '',

            // 2022-03-23 lyf 增加dargType 当前拖拽的组件类型
            dragType: '',

            renderVisisble: false,
            // 基础配置
            data: {
                list: [],
                config: {
                   
                }
            },

            previewOptions: {
                width: 850
            },
            selectItem: {} // 选中的元素
        }
    },
    watch: {
        data: {
            handler() {
                if (this.selectForm && this.selectForm.id) {
                    // 修改数据发生变化
                    //this.selectForm.change = true 
                    const jsonForm = JSON.stringify(this.selectForm.htmlModel)
                    const jsonData = JSON.stringify(this.data)
                    if (jsonForm != jsonData) {
                        this.$set(this.selectForm, 'change', true)
                        this.$set(this.selectForm, 'htmlModel', cloneDeep(this.data))
                    }
                }
            },
            //对象的深度监听deep，默认为false不进行深度监听
            deep: true
        },
        selectForm: {
            handler(newValue, oldValue) {
                if (newValue && newValue.id != (oldValue ? oldValue.id : '')) {
                    // 修改数据发生变化

                    const htmlModel = newValue.htmlModel

                    let jsonModel = htmlModel ? (typeof htmlModel == 'object' ? htmlModel : JSON.parse(htmlModel)) : null

                    this.initModel(cloneDeep(jsonModel))

                }
            },
            //对象的深度监听deep，默认为false不进行深度监听
            deep: true
        },

    },
    props: {
        selectForm: {
            type: Object,
        },
        customComponents: {
            type: Array,
            default: () => []
        },
        config: {
            type: Object,
            default: () => { return {} }
        },
        // 按钮显示隐藏 
        clear: {
            type: Boolean,
            default: true
        },
        preview: {
            type: Boolean,
            default: true
        },
        reder: {
            type: Boolean,
            default: true
        },
        imp: {
            type: Boolean,
            default: true
        },
        exp: {
            type: Boolean,
            default: true
        },
        // 基础组件需要展示的列表 或者false全部不展示
        basicItem: {
            type: [Boolean, Array],
            default: true
        },
        // 个性化组件需要展示的列表 或者false全部不展示
        personalItem: {
            type: [Boolean, Array],
            default: true
        },
        //布局组件需要展示的列表 或者false全部不展示
        layoutItem: {
            type: [Boolean, Array],
            default: true
        }
    },
    provide: function() {
        return {
            customC: this.customComponents,
            ngConfigC: this.config
        }
    },
    components: {
        DesignPanel,
        DragPanel,
        Properties,
        Preview,
        previewCode
    },
    created() {
        // if( this.customComponents && this.customComponents.length > 0) {
        //   window.customComponents = this.customComponents
        // }
        if (this.config.httpConfig) {
            window.httpConfig = this.config.httpConfig
        }

        // if(this.config.dict && this.config.dict.length > 0 && !window.ng_dict_) {
        //   window.ng_dict_ = this.config.dict  
        // }
    },
    methods: {
        changeSelectItem(item) {
            this.selectItem = item
        },
        handlePreview() {
            this.previewVisible = true
            this.$nextTick(() => {
                this.$refs.preview.init(this.data)
            })
        },
        handleClear() {
            this.$confirm('清空后无法恢复,请确认操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.data.list = []
            })

        },
        // 导入
        handleImport() {
            this.importVisible = true
            this.importText = ''
        },
        handleDragType(dragType) {
            this.dragType = dragType
        },
        importModel() {
            if (!this.importText) {
                alert('文本不能为空')
                return
            }

            const importData = JSON.parse(this.importText)
            if (importData) {
                this.data = importData
            }

            this.importVisible = false


        },
        initModel(model) {
            if (model)
                this.data = model
            else {
                this.data.list = []
            }

            this.selectItem = {}
            this.$refs.dragPanel.selectItem = {}
        },
        getModel() {
            return this.data
        },
        handleGenerateJson() {

            this.modelVisible = true
            this.$nextTick(() => {
                this.$refs.model.init(this.data)
            })


        }
    }
}
</script>
<style>
</style>