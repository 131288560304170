import http from '@/utils/httpRequest'

/**
 * 获取分页列表，可选参数：
 * page: 当前页；
 * limit: 每页数量；
 * name: 分组名称-关键字；
 * @param {*} params 查询参数对象
 * @returns 分页对象
 */
export function getList (params) {
  return http({
    url: http.adornUrl('/hbi/datacategory/list'),
    method: 'get',
    params: params
  })
}

/**
 * 全部列表获取
 * @returns 全部分组信息列表
 */
export function getAllList () {
  return http({
    url: http.adornUrl('/hbi/datacategory/alllist'),
    method: 'get',
    params: {}
  })
}

/**
 * 根据id数组删除数据
 * @param {Array} ids id数组
 * @returns 删除结果
 */
export function deleteData (ids = []) {
  return http({
    url: http.adornUrl("/hbi/datacategory/delete"),
    method: "post",
    data: http.adornData(ids, false)
  })
}

/**
 * 根据ID获取分组信息
 * @param {number|string} id id主键
 * @returns 分组信息
 */
export function getById (id) {
  return http({
    url: http.adornUrl(`/hbi/datacategory/info/${id}`),
    method: "get"
  })
}

/**
 * 根据id是否为空判断更新（id不为空）或新增（id为空）
 * @param {*} data 更新或新增的对象
 * @returns 更新或新增结果
 */
export function saveOrUpdate (data) {
  return http({
    url: http.adornUrl(`/hbi/datacategory/${!data.id ? "save" : "update"}`),
    method: "post",
    data: http.adornData(data)
  })
}