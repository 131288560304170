// 设置命名空间属性数据
const BINAMESPACE = {}

// 存放命名组件的引用
const BINS = {}

// 存储bi数据的总的对象
window.BIDATA = { BINAMESPACE, BINS }

/**
 * 返回url的查询参数对象
 *
 * @param {string} value url地址-默认使用的当前窗口的地址
 * @returns 查询参数对象
 */
window.getQuerys = function(value) {
  let url = value || window.location.href

  // 没有查询参数
  // 替换不需要的字符
  url = url.replaceAll('#', '')

  if (!url.split('?')[1]) {
    const obj = {}
    // 如果存在定义的默认区划代码 并且 没有区划码
    if (!obj.adCode && window.BIDATA.defaultAreaCode) {
      obj.adCode = window.BIDATA.defaultAreaCode
    }
    return obj
  }

  // 有查询参数
  const params = url.split('?')[1].split('&')
  const obj = {}
  params.map(v => (obj[v.split('=')[0]] = v.split('=')[1]))
  // 如果存在定义的默认区划代码 并且 没有区划码
  if (!obj.adCode && window.BIDATA.defaultAreaCode) {
    obj.adCode = window.BIDATA.defaultAreaCode
  }
  return obj
}

/**
 * 将对象的属性转换为的查询的参数
 */
window.objToQuery = function(obj) {
  let querys = []
  Object.keys(obj).forEach(key => {
    let value = encodeURI(obj[key])
    if (!value) {
      return
    }
    querys.push(`${key}=${value}`)
  })
  if (querys.length > 0) {
    querys[0] = '?' + querys[0]
  }
  return querys.join('&')
}

/**
 * 获取数据中的范围方法
 *
 * @param {string} name 名称
 */
window.getRangeByName = function(name) {
  if (BINAMESPACE[name] && BINAMESPACE[name].value) {
    // console.info('getRangeByName: BINAMESPACE[name].value = ', BINAMESPACE[name].value)
    return BINAMESPACE[name].value
  }
  // console.info('getRangeByName: ', 1)
  return 1
}

/**
 * 重置数据
 */
window.resetBIDATA = () => {
  // 设置命名空间属性数据
  const BINAMESPACE = {}

  // 存放命名组件的引用
  const BINS = {}

  window.BIDATA = { BINAMESPACE, BINS }
}

export default BINAMESPACE
