<!--
表单控件属性设置组件,因为配置数据是引用关系，所以可以直接修改
-->
<template>
    <BaseProperties ref="baseProperties" :selectItem="selectItem" :selectItemKey="selectItem.key" v-if="selectItem && selectItem.type ">
        <template slot="radius">
            <el-row class="pie-radius" v-if="selectItem.layout.radius">
                <el-col :span="12">
                    <el-input-number v-model="selectItem.layout.radius[0]" controls-position="right" :min="0" :max="50" placeholder="内半径" style="width: 100%;"></el-input-number>
                </el-col>
                <el-col :span="12">
                    <el-input-number v-model="selectItem.layout.radius[1]" controls-position="right" :min="1" :max="99" placeholder="外半径" style="width: 100%;"></el-input-number>
                </el-col>
            </el-row>
        </template>
        <template slot="borderRadius">
            <el-row class="pie-radius" v-if="selectItem.layout.radius">
                <el-col :span="12">
                    <el-input-number v-model="selectItem.layout.borderRadius[0]" controls-position="right" :min="0" :max="50" placeholder="内半径" style="width: 100%;"></el-input-number>
                </el-col>
                <el-col :span="12">
                    <el-input-number v-model="selectItem.layout.borderRadius[1]" controls-position="right" :min="1" :max="99" placeholder="外半径" style="width: 100%;"></el-input-number>
                </el-col>
            </el-row>
        </template>
    </BaseProperties>
</template>
<script>
import BaseProperties from '../../../config/data-properties.vue'
export default {
    name: "HbiChartPieProperties",
    components: {
        BaseProperties
    },
    data() {
        return { 
        }
    },

    methods: {
        init() { 
          
             
        } 
    },
    props: {
        selectItem: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>
<style lang="scss">
.dataset-item {
    .el-form-item__label {
        width: 100%;
    }
}

.hbi-avue-form {
    .avue-group {
        .el-collapse-item__header {
            margin-bottom: 0px;

            .avue-group__header {
                height: 30px;
                line-height: 30px;

                .avue-group__title {
                    font-size: 13px;
                }
            }
        }
    }

}

.hbi-avue-form-pie {
    .pie-radius {

        .el-input__inner,
        input {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }

}
</style>