
// 图例的初始化json 
export const legend = {

	show: false,
	icon: '' ,
	orient: '' , // 布局朝向
	horizontal: '' , // 水平放行
	vertical: '' , // 垂直方向
	fontSize: '' ,
	fontColor: ''

}


// 公共图例配置
export const legendConfig = [
	
	{
        label: '显示',
        prop: 'show',
        type: "switch", 
        default: true ,
        span: 24, 
        // control: (val)=> {
        // 	console.log('legend show' , val )
        // 	return {
        // 			orient: {
        // 				display: !val
        // 			},
        // 			horizontal: {
        // 				display: !val
        // 			},
        // 			vertical: {
        // 				display: !val
        // 			},
        // 			fontSize: {
        // 				display: !val
        // 			},
        // 			fontColor: {
        // 				display: !val
        // 			}
        // 		}
        // }
    },
    {
    	label: '图标',
    	prop: 'icon',
    	type: 'select',
        default: 'circle',
    	dicData: [
    		{label: 'circle' , value: 'circle'},
    		{label: 'rect' , value: 'rect'},
    		{label: 'roundRect' , value: 'roundRect'},
    		{label: 'triangle' , value: 'triangle'},
    		{label: 'diamond' , value: 'diamond'},
    		{label: 'pin' , value: 'pin'},
    		{label: 'arrow' , value: 'arrow'},
    	],
    	span: 24
    },
    { 
    	
    	label: '布局朝向',
        prop: 'orient',
        type: "radio",
        default: 'horizontal' ,
        button:true,
        dicData: [
        	{label: '水平' , value: 'horizontal'},
        	{label: '垂直' , value: 'vertical'} 
        ],
        span: 24 
    },
     { 
    	
    	label: '字体大小',
        prop: 'fontSize',
        type: "number",
        min: 10,
        max: 20, 
        span: 24 
    },
    { 
    	
    	label: '字体颜色',
        prop: 'fontColor',
        type: "color", 
        span: 24 
    }, 
    {
        label: '水平位置',
        prop: 'horizontal',
        type: "radio",
        default: 'center',
        button:true,
        dicData: [
        	{label: '左' , value: 'left'},
        	{label: '中' , value: 'center'},
        	{label: '右' , value: 'right'},
            {label: '自定义' , value: 'custom'}
        ],
        span: 24,  
    },
     {
        label: '左部偏移',
        prop: 'left',
        type: "input", 
        placeholder: '支持数字像素和10%百分比' ,  
        show: '$.legend.horizontal == "custom"',
        span: 24,  
    },
    {
        label: '垂直位置',
        prop: 'vertical',
        type: "radio",
         default: 'top',
        button: true,
        dicData: [
        	{label: '上' , value: 'top'},
        	{label: '中' , value: 'middle'},
        	{label: '下' , value: 'bottom'},
            {label: '自定义' , value: 'custom'}
        ],
        span: 24, 
    },
    {
        label: '顶部偏移',
        prop: 'top',
        type: "input", 
        placeholder: '支持数字像素和10%百分比' ,  
        show: '$.legend.vertical == "custom"',
        span: 24,  
    },
    {
        label: '右部偏移',
        prop: 'right',
        type: "input", 
        placeholder: '支持数字像素和10%百分比' ,  
        span: 24,  
    },
    {
        label: '底部偏移',
        prop: 'bottom',
        type: "input", 
        placeholder: '支持数字像素和10%百分比' ,  
        span: 24,  
    },

]


export const getChartLegend = (legendConfig)=> {
 
	let chartLegend = null
	if(legendConfig && legendConfig.show == true) {
		chartLegend = {
			icon: legendConfig.icon,
			left: legendConfig.horizontal == 'custom' ? legendConfig.left : legendConfig.horizontal,
			top: legendConfig.vertical  == 'custom' ? legendConfig.top : legendConfig.vertical,
            right: legendConfig.right,
            bottom: legendConfig.bottom,
			orient: legendConfig.orient,
			textStyle: {
				fontColor: legendConfig.fontColor,
				fontSize: legendConfig.fontSize
			}
		}
	}

	return chartLegend
}

