<!--
表单控件属性设置组件,因为配置数据是引用关系，所以可以直接修改
-->
<template> 
   
    <div v-if="selectItem && selectItem.type">   
      <el-form-item  label="边距"  >
        <el-row v-if="selectItem.style && selectItem.style.margin && selectItem.style.margin.length == 4">
            <el-col :span="5">
              <el-input title="上边距" v-model="selectItem.style.margin[0]" />
            </el-col>
            <el-col :span="5">
              <el-input title="右边距" v-model="selectItem.style.margin[1]" />
            </el-col>
            <el-col :span="5">
              <el-input title="下边距" v-model="selectItem.style.margin[2]" />
            </el-col>
            <el-col :span="5">
              <el-input title="左边距" v-model="selectItem.style.margin[3]" />
            </el-col>
        </el-row>
      </el-form-item>
     
        <!-- 公共部分 标签 字段key 数据key start -->
        <el-form-item  label="标签" v-if="!hideModel">
          <el-input v-model="selectItem.label" placeholder="请输入" />
        </el-form-item>
       
        <el-form-item  label="数据字段" v-if="!hideModel && !noModel.includes(selectItem.type)" >
          <el-input v-model="selectItem.model" placeholder="请输入" :disabled="(selectItem.item != undefined && selectItem.item.id != undefined) "/>
        </el-form-item>
        <el-form-item label="标签宽度" v-if="!hideModel && !noModel.includes(selectItem.type)">
          <el-input-number v-model="selectItem.options.labelWidth" title="-1表示跟随整体表达配置的宽度"></el-input-number>
        </el-form-item>
         <el-divider ></el-divider>
        <!-- 公共部分 标签 字段key 数据key end -->

        <!-- input textarea start -->
        <template v-if="selectItem.type == 'input' || selectItem.type == 'textarea'">
          <el-form-item  label="占位内容"  >
            <el-input placeholder="请输入" v-model="selectItem.options.placeholder" />
          </el-form-item>
          
          <el-form-item  label="宽度">
            <el-input placeholder="请输入" v-model="selectItem.options.width" />
          </el-form-item>
           
          <el-form-item label="默认值" >
            <el-input  v-model="selectItem.options.defaultValue" :type="selectItem.type"
              :placeholder=" typeof selectItem.options.format === 'undefined' ? '请输入' : selectItem.options.format"
            />
          </el-form-item>  
            
          <el-form-item  label="最大长度"  >
            <el-input-number  v-model="selectItem.options.maxLength" placeholder="最大长度,为0表示不限制"  :min="0"/>  
           
          </el-form-item>
          
          <el-form-item  label="前后缀"  v-if="selectItem.type === 'input'">
            <el-input placeholder="前缀标签" v-model="selectItem.options.prepend">
              <template slot="prepend">前缀</template>
            </el-input>
            <el-input placeholder="后缀标签" v-model="selectItem.options.append"  >
               <template slot="append">后缀</template>
            </el-input> 
          </el-form-item>
           <el-divider ></el-divider>
          <el-form-item  v-if="selectItem.type === 'textarea'" label="输入框行数" >
            <el-input-number  style="width:100%" v-model="selectItem.options.rows" placeholder="输入框行数"  /> 
          </el-form-item>
          
          <el-form-item   label="操作属性" >
            <el-checkbox v-model="selectItem.options.hidden"  label="隐藏" />
            <el-checkbox v-model="selectItem.options.disabled"  label="禁用" /> 
            <el-checkbox v-if="selectItem.type == 'input'" v-model="selectItem.options.clearable" label="可清除" /> 
          </el-form-item>
        </template>
        <!-- input textarea end -->

        <!-- number start -->
        <template v-if="selectItem.type == 'number'">
          <el-form-item  label="占位内容"  >
            <el-input placeholder="请输入" v-model="selectItem.options.placeholder" />
          </el-form-item>
          <el-form-item  label="宽度">
            <el-input placeholder="请输入" v-model="selectItem.options.width" />
          </el-form-item>

          <el-form-item  label="步长">
            <el-input-number v-model="selectItem.options.step" placeholder="请输入" />
          </el-form-item>
          <el-form-item  label="最小值">
            <el-input-number v-model="selectItem.options.min" placeholder="请输入" />
          </el-form-item>
          <el-form-item  label="最大值">
            <el-input-number v-model="selectItem.options.max" placeholder="请输入"/>
          </el-form-item>
          <el-form-item  label="数值精度" >
            <el-input-number :min="0"  :max="5"  v-model="selectItem.options.precision"  placeholder="请输入" />
          </el-form-item> 
          <el-form-item  label="默认值" >
            <el-input-number
              :step="selectItem.options.step"
              :min="selectItem.options.min || -Infinity"
              :max="selectItem.options.max || Infinity"
              v-model="selectItem.options.defaultValue" 
            />
          </el-form-item>
          <el-divider ></el-divider>
          <el-form-item  label="后缀">
          <!--   <el-input placeholder="前缀标签" v-model="options.prepend">
              <template slot="prepend">前缀</template>
            </el-input> -->
            <el-input placeholder="后缀标签" v-model="selectItem.options.append"  >
               <template slot="append">后缀</template>
            </el-input>
          </el-form-item>
          <el-divider ></el-divider>
          <el-form-item   label="操作属性" >
            <el-checkbox v-model="selectItem.options.hidden"  label="隐藏" />
            <el-checkbox v-model="selectItem.options.disabled"  label="禁用" />  
          </el-form-item>
        </template>
        <!-- number end -->

        <!-- select start -->
        <template v-if="selectItem.type == 'select'">
          <el-form-item  label="占位内容"  >
            <el-input placeholder="请输入" v-model="selectItem.options.placeholder" />
          </el-form-item>
          <el-form-item  label="宽度">
            <el-input placeholder="请输入" v-model="selectItem.options.width" />
          </el-form-item>
          <el-divider ></el-divider>
          <el-form-item  label="选项配置" >
            <el-radio-group   v-model="selectItem.options.dynamic">
              <el-radio-button :label="0">静态数据</el-radio-button>
              <el-radio-button :label="1">动态数据</el-radio-button>
              <el-radio-button :label="2" v-if="hasDict">数据字典</el-radio-button>
            </el-radio-group> 
          </el-form-item>
          <el-form-item label-width="0px" >
              <!-- 远程赋值配置 --> 
            <div v-if="selectItem.options.dynamic == 1">
                <el-input size="mini" v-model="selectItem.options.remoteFunc">
                  <template slot="prepend">远端方法</template>
                </el-input>
                <el-input size="mini" v-model="selectItem.options.dataPath"  title="假设当前接口返回的数据结构为:{code:200,data:[{id:1,name:'11'},{id:2,name:'22'}]} , 则当前的dataPath填写: data">
                  <template slot="prepend">列表数据dataPath</template>
                </el-input>
                <el-input size="mini" v-model="selectItem.options.remoteValue">
                  <template slot="prepend">值字段</template>
                </el-input>
                <el-input size="mini" v-model="selectItem.options.remoteLabel">
                  <template slot="prepend">标签字段</template>
                </el-input> 
            </div>  
            <div v-else-if="selectItem.options.dynamic == 2">
              <el-autocomplete 
                v-model="selectItem.options.dictType"
                :fetch-suggestions="queryDictSearch"
                value-key="type"
                placeholder="请输入"
                @select="handleDictSelect"
              >
                <template slot="prepend">字典分类</template>
                <template slot-scope="{ item }">
                  <span class="name">{{ item.type }}</span> 
                </template>
              </el-autocomplete>
            </div>
            <!-- 本地赋值 -->
            <Option v-show="selectItem.options.dynamic == 0" :type="selectItem.type" v-model="selectItem.options.options" />
          </el-form-item>
          <el-divider ></el-divider>
          <!-- 联动配置 2021-03-12 lyf -->
          <el-form-item label="联动关联">
            <el-switch
              v-model="selectItem.options.linkage"
              active-text="是"
              inactive-text="否">
            </el-switch> 
          </el-form-item>
          <template v-if="selectItem.options.linkage">
            <!-- 联动关联中如果事本地数据则只有脚本关联,如果是远程数据则包含远程搜索 -->
            <Linkage v-model="selectItem.options.linkData" />
          </template>
          <el-divider ></el-divider>
          <!-- select 本地配置才有默认值 -->
          <el-form-item v-if="selectItem.options.dynamic == 0" label="默认值">
            <el-select v-model="selectItem.options.defaultValue"  :clearable="true">
              <el-option
                v-for="(item, index) in selectItem.options.options"
                :key="item.value + index"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-divider ></el-divider>
          <el-form-item   label="操作属性" >
            <el-checkbox v-model="selectItem.options.hidden"  label="隐藏" />
            <el-checkbox v-model="selectItem.options.disabled"  label="禁用" /> 
            <el-checkbox v-model="selectItem.options.clearable" label="可清除" />
            <el-checkbox v-model="selectItem.options.multiple" label="多选" />
            <el-checkbox v-model="selectItem.options.showSearch" label="可搜索" @change="(v)=>{
              if(!v){
                selectItem.options.onlineSearch = false
              }
            }"/>
            <el-checkbox v-model="selectItem.options.onlineSearch" v-if="selectItem.options.showSearch" label="实时搜索"  />
          </el-form-item> 
          <el-divider ></el-divider>
          <el-form-item   label="实时搜索回调" v-if="selectItem.options.showSearch && selectItem.options.onlineSearch"> 
             <el-input type="textarea" placeholder="搜索带参,eg: name=$, $表示当前输入关键字" v-model="selectItem.options.onlineParams" />
          </el-form-item> 
          <el-divider ></el-divider>
          <el-form-item   label="选择后回调"  > 
             <el-input type="textarea" placeholder="选择后回调方法,eg: $.A004=$select.name, $表示当前表单数据,$select标示当前选择元素实体" v-model="selectItem.options.selectCb" />
          </el-form-item> 
          <el-form-item  v-if="selectItem.options.clearable" label="清除后回调"  > 
             <el-input type="textarea" placeholder="清除后回调方法,eg: $.A004= '', $表示当前表单数据" v-model="selectItem.options.clearCb" />
          </el-form-item> 
        </template>
         <!-- select end -->

        <!-- checkbox start -->
        <template v-if="selectItem.type == 'checkbox'">
          <el-form-item  label="选项配置" >
            <el-radio-group   v-model="selectItem.options.dynamic">
              <el-radio-button :label="0">静态数据</el-radio-button>
              <el-radio-button :label="1">动态数据</el-radio-button> 
              <el-radio-button :label="2" v-if="hasDict">数据字典</el-radio-button> 
            </el-radio-group> 
          </el-form-item>
          <el-form-item label-width="0px" >
            <!-- 远程赋值配置 --> 
            <div v-if="selectItem.options.dynamic == 1">
                <el-input size="mini" v-model="selectItem.options.remoteFunc">
                  <template slot="prepend">远端方法</template>
                </el-input>
                <el-input size="mini" v-model="selectItem.options.dataPath"  title="假设当前接口返回的数据结构为:{code:200,data:[{id:1,name:'11'},{id:2,name:'22'}]} , 则当前的dataPath填写: data">
                  <template slot="prepend">列表数据dataPath</template>
                </el-input>
                <el-input size="mini" v-model="selectItem.options.remoteValue">
                  <template slot="prepend">值字段</template>
                </el-input>
                <el-input size="mini" v-model="selectItem.options.remoteLabel">
                  <template slot="prepend">标签字段</template>
                </el-input> 
            </div>  
            <div v-else-if="selectItem.options.dynamic == 2">
              <el-autocomplete 
                v-model="selectItem.options.dictType"
                :fetch-suggestions="queryDictSearch"
                value-key="type"
                placeholder="请输入"
                @select="handleDictSelect"
              >
                <template slot="prepend">字典分类</template>
                <template slot-scope="{ item }">
                  <span class="name">{{ item.type }}</span> 
                </template>
              </el-autocomplete>
            </div>
            <!-- 本地赋值 -->
            <Option v-show="selectItem.options.dynamic == 0" :type="selectItem.type" v-model="selectItem.options.options" />
          </el-form-item>

           <el-divider ></el-divider>
          <!-- 联动配置 2021-03-12 lyf -->
          <el-form-item label="联动关联">
            <el-switch
              v-model="selectItem.options.linkage"
              active-text="是"
              inactive-text="否">
            </el-switch> 
          </el-form-item>
          <template v-if="selectItem.options.linkage">
            <!-- 联动关联中如果事本地数据则只有脚本关联,如果是远程数据则包含远程搜索 -->
            <Linkage v-model="selectItem.options.linkData" />
          </template>
          <el-divider ></el-divider>

          <!-- select 本地配置才有默认值 -->
          <el-form-item v-if="selectItem.options.dynamic == 0" label="默认值">
            <el-checkbox-group
              :options="selectItem.options.options"
              v-model="selectItem.options.defaultValue"
            >
              <el-checkbox  v-for="(checkitem, index) in  [].concat(selectItem.options.options)" :label="checkitem.value" :key="checkitem.value + index"> 
                {{checkitem.label}}
              </el-checkbox>
          </el-checkbox-group>
          </el-form-item>
           <el-divider ></el-divider>
          <el-form-item   label="操作属性" >
            <el-checkbox v-model="selectItem.options.hidden"  label="隐藏" />
            <el-checkbox v-model="selectItem.options.disabled"  label="禁用" />    
          </el-form-item> 
        </template>
        <!-- checkbox end -->

         <!-- radio start -->
        <template v-if="selectItem.type == 'radio'">
          <el-form-item  label="选项配置" >
            <el-radio-group   v-model="selectItem.options.dynamic">
              <el-radio-button :label="0">静态数据</el-radio-button>
              <el-radio-button :label="1">动态数据</el-radio-button>
              <el-radio-button :label="2" v-if="hasDict">数据字典</el-radio-button> 
            </el-radio-group> 
          </el-form-item>
          <el-form-item label-width="0px" >
             <!-- 远程赋值配置 --> 
            <div v-if="selectItem.options.dynamic == 1">
                <el-input size="mini" v-model="selectItem.options.remoteFunc">
                  <template slot="prepend">远端方法</template>
                </el-input>
                <el-input size="mini" v-model="selectItem.options.dataPath" title="假设当前接口返回的数据结构为:{code:200,data:[{id:1,name:'11'},{id:2,name:'22'}]} , 则当前的dataPath填写: data">
                  <template slot="prepend">列表数据dataPath</template>
                </el-input>
                <el-input size="mini" v-model="selectItem.options.remoteValue">
                  <template slot="prepend">值字段</template>
                </el-input>
                <el-input size="mini" v-model="selectItem.options.remoteLabel">
                  <template slot="prepend">标签字段</template>
                </el-input> 
            </div> 
            <div v-else-if="selectItem.options.dynamic == 2">
              <el-autocomplete 
                v-model="selectItem.options.dictType"
                :fetch-suggestions="queryDictSearch"
                value-key="type"
                placeholder="请输入"
                @select="handleDictSelect"
              >
                <template slot="prepend">字典分类</template>
                <template slot-scope="{ item }">
                  <span class="name">{{ item.type }}</span> 
                </template>
              </el-autocomplete>
            </div> 
            <!-- 本地赋值 -->
            <Option v-show="selectItem.options.dynamic == 0" :type="selectItem.type" v-model="selectItem.options.options" /> 
          </el-form-item>
           <el-divider ></el-divider>
          <!-- 联动配置 2021-03-12 lyf -->
          <el-form-item label="联动关联">
            <el-switch
              v-model="selectItem.options.linkage"
              active-text="是"
              inactive-text="否">
            </el-switch> 
          </el-form-item>
          <template v-if="selectItem.options.linkage">
            <!-- 联动关联中如果事本地数据则只有脚本关联,如果是远程数据则包含远程搜索 -->
            <Linkage v-model="selectItem.options.linkData" />
          </template>
          <el-divider ></el-divider>

          <!-- select 本地配置才有默认值 -->
          <el-form-item v-if="selectItem.options.dynamic == 0" label="默认值">
            <el-radio-group
              :options="selectItem.options.options"
              v-model="selectItem.options.defaultValue"
            >
              <el-radio  v-for="(checkitem, index) in  [].concat(selectItem.options.options)" :label="checkitem.value" :key="checkitem.value + index"> 
                {{checkitem.label}}
              </el-radio>
          </el-radio-group>
          </el-form-item>
           <el-divider ></el-divider>
          <el-form-item   label="操作属性" >
            <el-checkbox v-model="selectItem.options.hidden"  label="隐藏" />
            <el-checkbox v-model="selectItem.options.disabled"  label="禁用" />    
          </el-form-item> 
        </template>
        <!-- radio end -->

         <!-- date start -->
        <template v-if="selectItem.type == 'date' || selectItem.type == 'time' || selectItem.type == 'datePicker'">
          
          <el-form-item  v-if=" (selectItem.type == 'date' || selectItem.type == 'datePicker' ) && options.range " label="占位内容" >
          <el-input placeholder="请输入" v-model="options.rangeStartPlaceholder" />
          <el-input placeholder="请输入" v-model="options.rangeEndPlaceholder" />
          </el-form-item>
          <el-form-item v-else label="占位内容"  >
            <el-input placeholder="请输入" v-model="selectItem.options.placeholder" />
          </el-form-item>

          <el-form-item  label="宽度">
            <el-input placeholder="请输入" v-model="selectItem.options.width" />
          </el-form-item> 
           <el-divider ></el-divider>
          <el-form-item label="默认值" >
            <el-input
              v-if="selectItem.type == 'time' || !selectItem.options.range"
              v-model="selectItem.options.defaultValue"
              :placeholder="
                (typeof selectItem.options.format === 'undefined' ? '' : selectItem.options.format) + ',当前日期使用now'
              "
            />
            <el-input
              v-if="(selectItem.type == 'date' || selectItem.type == 'datePicker' ) && selectItem.options.range"
              v-model="selectItem.options.rangeDefaultValue[0]"
              :placeholder="
                '起始时间' + (typeof selectItem.options.format === 'undefined' ? '' : selectItem.options.format)
              "
            />
            <el-input
              v-if="(selectItem.type == 'date' || selectItem.type == 'datePicker' ) && selectItem.options.range"
              v-model="selectItem.options.rangeDefaultValue[1]"
              :placeholder="
                '结束时间' + ( typeof selectItem.options.format === 'undefined' ? '' : selectItem.options.format)
              "
            /> 
            <!-- <el-input  v-model="options.defaultValue"
              :placeholder=" typeof options.format === 'undefined' ? '请输入' : options.format"
            /> -->
          </el-form-item> 
          <el-form-item  label="时间格式" >
            <el-input  v-model="selectItem.options.format"  :placeholder="selectItem.type == 'date' ? 'YYYY-MM-DD' : (selectItem.type == 'datePicker' ? 'YYYY-MM-DD HH:mm:ss' : 'HH:mm:ss' )" />
          </el-form-item>
           <el-divider ></el-divider>
          <el-form-item   label="操作属性" >
            <el-checkbox v-model="selectItem.options.hidden"  label="隐藏" />
            <el-checkbox v-model="selectItem.options.disabled"  label="禁用" /> 
            <el-checkbox v-model="selectItem.options.clearable" label="可清除" /> 
            <el-checkbox v-if="selectItem.type == 'date' || selectItem.type == 'datePicker'" v-model="selectItem.options.range" label="范围选择" />
          </el-form-item>
        </template>
        <!-- date end -->

        <!-- rate start -->
        <template v-if="selectItem.type == 'rate'">
          <el-form-item v-if="typeof selectItem.options.max !== 'undefined'" label="最大值">
          <el-input-number v-model="selectItem.options.max" placeholder="请输入" @change="(v)=>{  
                if(selectItem.options.defaultValue > v){
                  selectItem.options.defaultValue = v
                } 
            }"/>
          </el-form-item>
          <el-form-item label="默认值">
            <el-rate  v-model="selectItem.options.defaultValue"  :allowHalf="selectItem.options.allowHalf" :max="options.max"  />
          </el-form-item>
        
           <el-divider ></el-divider>
          <el-form-item   label="操作属性" >
            <el-checkbox v-model="selectItem.options.hidden"  label="隐藏" />
            <el-checkbox v-model="selectItem.options.disabled"  label="禁用" /> 
            <el-checkbox v-model="selectItem.options.allowHalf" label="允许半选" />
          </el-form-item>
        </template>
        <!-- date end -->

        <!--  rate start -->
        <template v-if="selectItem.type == 'slider'">
          <el-form-item  label="宽度">
            <el-input placeholder="请输入" v-model="selectItem.options.width" />
          </el-form-item>
           <el-form-item  label="步长">
            <el-input-number v-model="selectItem.options.step" placeholder="请输入" />
          </el-form-item>
          <el-form-item  label="最小值">
            <el-input-number v-model="selectItem.options.min" placeholder="请输入" />
          </el-form-item>
          <el-form-item  label="最大值">
            <el-input-number v-model="selectItem.options.max" placeholder="请输入"/>
          </el-form-item>
          <el-form-item label="默认值"  >
            <el-input-number
              :step="selectItem.options.step"
              :min="selectItem.options.min || -Infinity"
              :max="selectItem.options.max || Infinity"
              v-model="selectItem.options.defaultValue"  />
          </el-form-item>
           <el-divider ></el-divider>
          <el-form-item   label="标记marks">
            <br>
            <Option style="width: 100%;" :keyNumber="true" type="keyvalue" v-model="selectItem.options.marks" />
          </el-form-item> 
            
           <el-divider ></el-divider>
          <el-form-item   label="操作属性" >
            <el-checkbox v-model="selectItem.options.hidden"  label="隐藏" />
            <el-checkbox v-model="selectItem.options.disabled"  label="禁用" /> 
            <el-checkbox v-model="selectItem.options.showInput"  label="显示输入框" />
          </el-form-item>
        </template>
        <!-- date end --> 

        <!-- 级联选择器 start -->
        <template v-if="selectItem.type == 'cascader'">
          <el-form-item  label="选项配置" >
            <el-radio-group   v-model="selectItem.options.dynamic">
              <el-radio-button :label="0">静态数据</el-radio-button>
              <el-radio-button :label="1">动态数据</el-radio-button> 
            </el-radio-group> 
          </el-form-item>
          <el-form-item label-width="0px" > 
            <!-- 远程赋值配置 --> 
            <div v-if="selectItem.options.dynamic == 1">
                <el-input size="mini" v-model="selectItem.options.remoteFunc">
                  <template slot="prepend">远端方法</template>
                </el-input>
                <el-input size="mini" v-model="selectItem.options.dataPath">
                  <template slot="prepend">列表数据jsonPath</template>
                </el-input>
                <el-input size="mini" v-model="selectItem.options.remoteValue">
                  <template slot="prepend">值字段</template>
                </el-input>
                <el-input size="mini" v-model="selectItem.options.remoteLabel">
                  <template slot="prepend">标签字段</template>
                </el-input> 
                <el-input size="mini" v-model="selectItem.options.remoteChildren" >
                  <template slot="prepend">下级字段</template>
                </el-input>
            </div>   
            <!-- 本地赋值 -->
            <Option v-show="selectItem.options.dynamic == 0" :type="selectItem.type" v-model="selectItem.options.options" />
          </el-form-item>
             <el-divider ></el-divider>
          <el-form-item   label="操作属性" >
            <el-checkbox v-model="selectItem.options.hidden"  label="隐藏" />
            <el-checkbox v-model="selectItem.options.disabled"  label="禁用" />   
            <el-checkbox v-model="selectItem.options.clearable" label="可清除" />
            <el-checkbox v-model="selectItem.options.multiple" label="多选" />
            <el-checkbox v-model="selectItem.options.showSearch" label="可搜索" /> 
          </el-form-item> 
        </template>
        <!-- 级联选择器 end -->

 

         <!-- 开关 switch start-->
        <template v-if="selectItem.type == 'switch'"> 
          <!-- 开关的label -->
          <el-form-item   label="打开标签值">
            <el-input placeholder="请输入" v-model="selectItem.options.activeText" /> 
          </el-form-item>
           <el-form-item   label="关闭标签值" >
            <el-input placeholder="请输入" v-model="selectItem.options.inactiveText" /> 
          </el-form-item>
          <el-form-item  label="默认值">
            <el-switch v-model="selectItem.options.defaultValue" />
          </el-form-item>
          <el-divider ></el-divider>
          <el-form-item   label="操作属性" >
            <el-checkbox v-model="selectItem.options.hidden"  label="隐藏" />
            <el-checkbox v-model="selectItem.options.disabled"  label="禁用" />  
          </el-form-item> 
        </template> 
        <!-- 开关 switch  end -->

   
       
    </div>  
</template>
<script> 
import Option from "../../../hbi-design/properties/option";
import Linkage from '../../../hbi-design/properties/linkage' 
export default {
  name: "HbiInputBaseProperties",
  data() {
    return {
      options: {},
      noModel : [ "button",
            "divider",
            "card", 
            "grid",
            "table",
            "alert",
            "text",
            "html"]
    };
  },
  watch: {
    selectItem(val) {  

      
    }
  },
  computed: {
    hasDict() {
      return this.ngConfig && this.ngConfig.dict && this.ngConfig.dict.length > 0
    }
  },
  inject: {
      ngConfig: {
        from: 'ngConfigC',
        default: ()=>({})
      },
  },
  methods: {
    init() {
      this.options = this.selectItem.options || {}

       // 判断 labelWidth 
      if(!this.hideModel && !Object.prototype.hasOwnProperty.call(this.options, 'labelWidth')){
        this.$set(this.options , 'labelWidth' , -1)
      }
   
      if( !Object.prototype.hasOwnProperty.call(this.selectItem, 'style')) {
        const margin = {margin: ['0', '0', '0', '0']}
        this.$set(this.selectItem , 'style' ,  margin)
      }
    },
     relyCbColumn(v) {
      this.$set(this.options , 'relyCbColumn' , v)
     // this.selectItem.options['relyCbColumn'] = v
    },
    queryDictSearch(queryString, cb) {
      const dicts = this.ngConfig && this.ngConfig.dict ? this.ngConfig.dict : null
      if(!dicts || dicts.length == 0) {
        cb([])
      }

      const ls = {}
      const types = []
      dicts.forEach(t=> {
        const type = t.type 
        if(!ls[type]) {
          ls[type] = type 

          types.push(t)
        } 
      })

      // 关键字过滤
      const fs = types.filter(t=> t.type.indexOf(queryString) >= 0)
  
      cb(fs)

    },
    handleDictSelect(item) {
    }
  },
  props: {
    selectItem: {
      type: Object,
      required: true
    },

    hideModel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Option , Linkage
  }
};
</script>
