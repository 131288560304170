var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upload-div"},[(_vm.readOnly == null || _vm.readOnly == false)?_c('el-upload',{ref:"upload",attrs:{"list-type":_vm.listType,"with-credentials":true,"action":_vm.doUpload,"file-list":_vm.fileList,"on-remove":_vm.handleRemove,"on-preview":_vm.handlePreview,"before-remove":_vm.beforeRemove,"on-success":_vm.handleSuccess,"on-error":_vm.handerError,"before-upload":_vm.beforeUpload,"accept":_vm.accept,"multiple":_vm.multiple && (_vm.multiple == true || _vm.multiple == 'true'),"limit":_vm.limit,"on-exceed":_vm.handleExceed}},[(_vm.listType && _vm.listType.indexOf('picture') >= 0)?_c('i',{staticClass:"el-icon-plus"},[_c('br'),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("选择文件")])]):_c('div',[_c('el-button',{attrs:{"icon":"el-icon-plus"}},[_vm._v("请上传文件")])],1)]):_vm._e(),(_vm.readOnly == null || _vm.readOnly == false)?_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" "+_vm._s(_vm.accept && _vm.accept.indexOf("image") >= 0 && _vm.listType && _vm.listType.indexOf("picture") >= 0 ? "* 图片最大不能超过" + (this.fileSize == undefined ? 10 : this.fileSize) + "Mb,图片格式支持(bmp,jpg,jpeg,png)" : "* 文件最大不能超过" + (this.fileSize == undefined ? 10 : this.fileSize) + "Mb,文件格式支持(bmp,jpg,jpeg,png,xls,xlsx,doc,docx,pdf,mp4,wmv,zip,txt,csv)")+" ")]):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.ulLoading),expression:"ulLoading"}]},[(
          _vm.accept &&
            _vm.accept.indexOf('image') >= 0 &&
            _vm.listType &&
            _vm.listType.indexOf('picture-card') >= 0
        )?_c('div',[_c('ul',_vm._l((_vm.fileList),function(item,index){return _c('li',{key:'file' + index,staticStyle:{"float":"left","margin-right":"20px","list-style":"none"}},[_c('img',{class:[
                _vm.direction == null || _vm.direction == false ? 'avatar' : 'vertical'
              ],style:(_vm.imgDownBut ? 'float: left;' : ''),attrs:{"src":item.url},on:{"click":function($event){return _vm.reviewDown(item.id)}}}),(_vm.imgDownBut)?_c('el-button',{staticStyle:{"padding":"90px 0 16px 30px","float":"left"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.imgDown(item.id)}}},[_c('img',{attrs:{"title":"下载","src":require("@/assets/img/icon_xiazai.svg")}})]):_vm._e(),(_vm.imgDownBut)?_c('div',{staticStyle:{"clear":"both"}}):_vm._e()],1)}),0)]):_c('ul',{staticClass:"el-upload-list el-upload-list--text"},_vm._l((_vm.fileList),function(item,index){return _c('li',{key:'file' + item.id,staticClass:"el-upload-list__item pointer",attrs:{"tabindex":index},on:{"click":function($event){return _vm.reviewDown(item.id, item.fileSuffix)}}},[_c('a',{staticClass:"el-upload-list__item-name"},[_c('i',{staticClass:"el-icon-document"}),_vm._v(" "+_vm._s(item.originalFilename)+" ")]),_vm._m(0,true)])}),0)]),_c('el-dialog',{attrs:{"append-to-body":true,"visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl,"alt":""}})])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"el-upload-list__item-status-label"},[_c('i',{staticClass:"el-icon-upload-success el-icon-circle-check"})])
}]

export { render, staticRenderFns }