<template> 
  <!--
  <user value="1"/> 
  -->  
  <span  :key="value" style="cursor: pointer;">
 		{{username}}
	</span> 
 
	
</template>
<script>
  import {getInfo} from '@/utils/user'

  export default {
    name: 'user',
    data () {
      return { 
        username: ''
      }
    },
    watch: {
      value: {//深度监听，可监听到对象、数组的变化
        handler (val, oldVal) { 
          if (val != null && val != '' && val != undefined
            && val != 'undefined') {
            this.init(val)
          }else{
            this.info = {}
          }
        },
        deep: true
      }
    },
    props: {
      value: {
        type: String
      }
    },
    created () {
      // 计算数据字典的名称
      this.init(this.value) 
    }, 
    methods: {
      init (val) { 
        if(val) {
          getInfo(val).then((data)=>{ 
            this.username = data
          })
 
        } else {
          this.username = ''
        }

      } 
    }
  }
</script>
