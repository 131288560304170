<template>
  <!--
	sample :  <sysDictRadio :form="form" field="sex" dictType="sex" />
	 其中form 必须为外部页面传递过来,
	 field是当前需要将选择的数据绑定到form中哪个字段
	 	dictType为数据字典中的类型

	-->
 
    <el-radio-group
      v-model="dictForm.value"
      :placeholder="placeholder"
      :disabled="disabled"
      @change="changeSelect"
    >
      <el-radio :label="item.value" v-for="item in dicts" :key="item.value">{{
        item.name
      }}</el-radio>
    </el-radio-group> 
   
</template>
<script>
import { putDictType, getDictType, getDictTypeValues } from "@/api/sys/dict";
export default {
  data() {
    return {
      dicts: [],
      dictForm: {
        value: "",
        title: "" // 当前选中的title
      }
    };
  },
  props: {
    // form: null,
    // field: null,
    value: {
      type: [String , Number],
      required: true
    },
    dictType: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: "请选择"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  inject: {
    elForm: {
      default: ''
    },
    elFormItem: {
      default: ''
    }
  },
  watch: {
    dictType(val) {
      if (this.dictType) {
        this.initData();
      }
    },
    value: {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        //console.log('watch val' , val)


        if (val != this.dictForm.value) {
          if(!val || val == "" ) {
            this.dictForm.value = null;
            this.dictForm.title = null;
          } else {
             this.putSelect();
          }
         
        } 
      },
      deep: true
    }
  },
  created() {
    // 根据类型查询数据
    // console.log('select' , this.form , this.field , this.dictType)
    if (this.dictType) {
      this.initData();
    }
  },
  methods: {
    initData() {
      getDictTypeValues(this.dictType).then(list => {
        this.dicts = list;

        // 判断当前是否有值 有值回填
        const select = this.value ;
        if (select) {
          for (var i in this.dicts) {
            if (this.dicts[i].value == select) {
              this.dictForm.value = this.dicts[i].value;
              this.dictForm.title = this.dicts[i].name;
              return;
            }
          }

          // 到这里了说明没选上,用当前值
          this.dictForm.value = select;
          this.dictForm.title = select;
        }
      });
    },
    // 选择回填
    putSelect() {
      if (!this.dicts || this.dicts.length == 0) {
        this.initData();
      } else {
        // 判断当前是否有值 有值回填
        const select = this.value
        // console.log('select' , select)
        if (select) {
          for (var i in this.dicts) {
            if (this.dicts[i].value == select) {
              this.dictForm.value = this.dicts[i].value;
              this.dictForm.title = this.dicts[i].name;
              return;
            }
          }

          // 到这里了说明没选上,用当前值
          this.dictForm.value = select + "";
          this.dictForm.title = select + "";
        }
      }
    },
    // 选择回调
    changeSelect(value) {
      //this.form[this.field] = value ;
      //this.$set(this.form, this.field, value);

      this.$emit("change", value);
      this.$emit('input' , value);
       
      setTimeout(() => {
        if(this.elForm && this.elFormItem && this.elFormItem.prop) {
          this.elForm.validateField(this.elFormItem.prop , ()=>{})
        }
      }, 50) 
    }
  }
};
</script>
<style></style>
