export default {
    name:'home',
    en_US:{
        refresh:'Refresh',
        more:'More',
        notice:'Notice',
        warning:'Warning',
        pendingMessages:'Pending Messages',
        approvedMessage:'Approved Messages',
        myMessages:'My Messages',
        iStarted:'My Initiation',
        refreshing:'Refreshing',
        processName:'Process Name',
        taskNode:'Task Node',
        operate:'Operate',
        originator:'Originator',
        receivingTime:'Receiving Time',
        approvalNode:'Approval Node',
        approvalTime:'Approval Time',
        timeConsuming:'Time-Consuming',
        approvalRecord:'Approval Record',
        details:'Details',
        currentNode:'Current Node',
        submitTime:'Submit Time',
        markedAsRead:'Mark Read',
        delete:'Delete',
        cancellationRequest:'Cancel',
        handle:'Handle',
        processStatus:'Process Status',
        ongoing:'Ongoing',
        finished:'Finished',
        cancelled:'Cancelled',
        all:'All',
        todo:'Todo',
        unread:'Unread',
        read:'Read',
        markRead:'Mark All Read',
        messageCenter: 'Message Center',
    },
    zh_CN:{
        refresh:'刷新',
        more:'更多',
        notice:'通知',
        warning:'预警',
        pendingMessages:'待审批消息',
        approvedMessage:'已审批消息',
        myMessages:'我的消息',
        iStarted:'我的发起',
        refreshing:'刷新中...',
        processName:'流程名称',
        taskNode:'任务节点',
        operate:'操作',
        originator:'流程发起人',
        receivingTime:'接收时间',
        approvalNode:'审批节点',
        approvalTime:'审批时间',
        timeConsuming:'耗时',
        approvalRecord:'审批纪录',
        details:'详情',
        currentNode:'当前节点',
        submitTime:'提交时间',
        markedAsRead:'标为已读',
        delete:'删除',
        cancellationRequest:'取消申请',
        handle:'处理',
        processStatus:'流程状态',
        ongoing:'进行中',
        finished:'已完成',
        cancelled:'已取消',
        all:'全部',
        todo:'待办',
        unread:'未读',
        read:'已读',
        markRead:'全部标为已读',
        messageCenter:'消息中心',
    }
}
