
// grid的初始化json 
export const grid = {

	show: false,
	left: '10%' ,
	right: '10%' , 
	top: '10%' , 
	bottom: '10%' 

}

 
// 公共grid配置
export const gridConfig = [
	
	{
        label: '显示边框',
        prop: 'show',
        type: "switch",  
        default: false,
        span: 24,  
    },
    {
    	label: '左间距',
    	prop: 'left', 
        default: '10%',
    	span: 24
    },
    {
        label: '右间距',
        prop: 'right', 
        default: '10%',
        span: 24
    }, 
    {
        label: '上间距',
        prop: 'top', 
        default: '10%',
        span: 24
    },
    {
        label: '下间距',
        prop: 'bottom', 
        default: '10%',
        span: 24
    }, 

]


export const getChartGrid = (gridConfig)=> {
  

	return gridConfig
}

