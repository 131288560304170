import http from '@/utils/httpRequest'


export function getList(params) {
  return http({
          url: http.adornUrl('/sys/user/list'),
          method: 'get',
          params: params
        })
}
/**
* 重置密码
*/
export function resetPass(data) {
	return http({
            url: http.adornUrl(`/sys/user/resetPass`),
            method: "post",
            data: data
        })
}

export function deleteUser(ids = []) {
	return http({
            url: http.adornUrl("/sys/user/delete"),
            method: "post",
            data: http.adornData(ids, false)
          })
}

export function getById(id) {
	return http({
        url: http.adornUrl(`/sys/user/info/${id}`),
        method: "get"
        })
}

// 根据自身token信息获取用户信息
export function userInfo() {
  return http({
        url: http.adornUrl("/sys/user/info"),
        method: "get",
        params: http.adornParams() 
      })
}

// 更新用户密码
export function updatePassword(oldPass , newPass) {
 return http({
            url: http.adornUrl("/sys/user/password"),
            method: "post",
            data: http.adornData({
              password: oldPass,
              newPassword: newPass
            })
          })
}
 
export function saveOrUpdate(data) {
	return http({
            url: http.adornUrl(
              `/sys/user/${!data.id ? "save" : "update"}`
            ),
            method: "post",
            data: data
        })
}

// 用户自己更新自己的信息
export function updateInfo(data) {
  return http({
      url: http.adornUrl(`/sys/user/updateInfo`),
      method: 'post',
      data: http.adornData(data)
  })
}

export function login(userNo , userPass , uuid , captcha){
  return  http({
            url: http.adornUrl("/auth/account"),
            method: "post",
            data: http.adornData({
              userNo: userNo,
              password: userPass,
              uuid: uuid,
              captcha: captcha
            })
          })
}

// 验证码url
export function getCaptchaUrl(uuid) {
  return http.adornUrl(`/auth/captcha.jpg?uuid=${uuid}`)
}

// 根据userId获取用户名称
export function getUserName(userId) {
  return http({
      url: http.adornUrl(`/sys/user/userName/${userId}` ),
      method: 'get' 
    })
}

//根据用户名查询电话、部门
export function queryUserName(params) {
  return http({
  url: http.adornUrl(`/sys/user/queryUserName` ),
  method: 'post',
  params: params
  })
  }

export function oalogin(ticket){
    return  http({
        url: http.adornUrl("/auth/account_oa"),
        method: "post",
        data: http.adornData({
            ticket: ticket
        })
    })
}

export function oaloginByUserId(userId){
    return  http({
        url: http.adornUrl("/auth/oaAccount"),
        method: "post",
        data: http.adornData({
            userId: userId
        })
    })
}

export function logout(data){
    return  http({
        url: http.adornUrl("/auth/logout"),
        method: "post",
        data: http.adornData(data)
    })
}
export function getUserPhone(phone,departmentIds){
    return http({
        url: http.adornUrl(`/sys/user/getUserPhone/${phone}/${departmentIds}`),
        method:"get",
    })
}
 