<template>
    <!-- 数据源配置-静态数据 -->
    <el-dialog title="SQL数据配置" :close-on-click-modal="false" :modal-append-to-body="false" :lock-scroll="false" :visible.sync="visible" width="70%">
        <!--   <div class="mod-config"> -->
        <el-row :gutter="15">
            <el-col :span="12">
                <el-form :models="dataForm">
                    <el-form-item label="数据源">
                        <avue-select v-model="dataForm.dataSourceId" placeholder="请选择内容" type="tree" :dic="datasources"></avue-select>
                    </el-form-item>
                    <el-form-item label="SQL文本">
                        <el-input type="textarea" :rows="5" v-model="dataForm.sqlText" placeholder="请输入sql查询语句"></el-input>
                    </el-form-item>
                     <el-form-item label="动态参数">
                        <!-- dataSqlParams -->
                         <avue-array v-model="dataForm.dataSqlParams" placeholder="全局表单数据为$,eg: $.mame , $.name ? $.name : ''"></avue-array>
                       
                    </el-form-item>
                    
                    <el-button type="text" @click="getSqlData"> 测试</el-button>
                    <el-form-item label="数据格式化">
                        <el-input type="textarea" :rows="5" v-model="dataForm.formatter" placeholder="(data , models)=>{return ....}"></el-input>
                    </el-form-item>
                    <el-button type="text" @click="getDataPreview"> 刷新</el-button>
                    <el-form-item label="预览数据">
                        <el-input type="textarea" :rows="5" v-model="dataForm.preview"></el-input>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="12">
                <code-editor :disabled="true" v-model="dataTips" />
              <!--   <div style="height: 300px;overflow: auto;"> {{dataTips}} </div> -->
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">返回</el-button>
            <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { tips } from '../common/datasource.js'
import { getAllDsList } from '@/api/hbi/dynamicdatasource.js'
import { getSqlQuery } from '@/api/hbi/datamodel.js'
import { dynamicFun } from '../../../utils'
//import MonacoEditor from '@/views/modules/avueData/components/editor'
export default {
    components: {
       // MonacoEditor
    },
    data() {
        return {
            visible: false,
            dataForm: {
                dataSourceId: '',
                sqlText: '',
                formatter: '',
                dataSqlParams: [] ,
                preview: ''
            },
            retData: {}, // sql查询出来的记过
            datasources: [],
            dataTips: tips
        }
    },
  methods: {
        init(dataSourceId, sqlText, formatter , dataSqlParams = []) {
            this.dataForm.dataSourceId = dataSourceId
            this.dataForm.sqlText = sqlText
            this.dataForm.formatter = formatter
            this.dataForm.dataSqlParams = dataSqlParams
            this.visible = true

            this.initDatasources()
        },
        dataFormSubmit() {
            if (!this.dataForm.dataSourceId) {
                this.$message.error('数据源不能为空')
                return
            }
            if (!this.dataForm.sqlText) {
                this.$message.error('SQL不能为空')
                return
            }

            const data_ = {dataSourceId: this.dataForm.dataSourceId , sqlText: this.dataForm.sqlText , dataFormatter: this.dataForm.formatter , dataSqlParams: this.dataForm.dataSqlParams} 
            this.$emit("change", data_);
            this.visible = false
        },
        initDatasources() {
            getAllDsList().then(({ data }) => {
                const list = data.data
                this.datasources = list.map(t => {
                    return { label: t.name, value: t.id, desc: t.dsType }
                    //            label:'选项1',

                    // desc:'描述1',
                    // disabled:true,
                    // value:0
                })
            })
        },
        getSqlData() {
            if (!this.dataForm.dataSourceId) {
                this.$message.error('数据源不能为空')
                return
            }
            if (!this.dataForm.sqlText) {
                this.$message.error('SQL不能为空')
                return
            }
         
            let params = this.dataForm.dataSqlParams
            const models = {}
            params = params.map(t=> {
              if(t=='userId'){

                return this.$store.state.user.userId
              }
              if(t=='deptId'){
                return  this.$store.state.user.deptId
              }
                const tvalue = dynamicFun(t , models)
                return tvalue
            })

            const sqlData = { datasourceId: this.dataForm.dataSourceId, sqlText: this.dataForm.sqlText  ,params:params }


            // getSqlData(this.dataForm.dataSourceId, this.dataForm.sqlText , this.dataForm.formatter , params).then((data)=> {

            // })
            getSqlQuery(sqlData).then(({ data }) => {
                if (data && data.code == 0) {

                    const totalCount = data.data.totalCount
                    const currentCount = data.data.currentCount 

                    if(totalCount > currentCount) {

                        this.$message.info(`当前sql数据总量为:[${totalCount}],目前查询返回结果数量限制为:[${currentCount}]`)
                    }
                    this.retData = data.data.rows


                    this.getDataPreview()
             


                } else if (data && data.msg) {
                    this.$message.error(data.msg)
                }
            })

        },
        getDataPreview() {
            if (!this.retData) {
                this.$message.error('请先查询数据结果')
                return
            }
            if (this.dataForm.formatter) {

                const formatterFun = eval(this.dataForm.formatter)
                if(formatterFun) {
                    //const dvalue = dynamicFun(this.dataForm.formatter, this.retData)
                    const dvalue = formatterFun(this.retData)
                    this.dataForm.preview = JSON.stringify(dvalue)
                }

               
            } else {
                this.dataForm.preview = JSON.stringify(this.retData)
            }
        }
    }
}
</script>