 // 公共style配置
 export const styleConfig = {
     label: '样式',
     prop: 'style',
     collapse: false,
     column: [{
             label: '背景颜色',
             prop: 'backgroundColor',
             tip: '点击选择背景颜色',
             type: 'color',
             default: '#FFFFFF',
             span: 24,
         },
         {
             label: '边距',
             prop: 'margin',
             default: ['0', '0', '0', '0'],
             tip: 'top right bottom left',
             span: 24,
         },
         {
             label: '圆角',
             prop: 'itemBorderRadius',
             type: 'number',
             min: 0,
             max: 45,
             default: 0,
             span: 24,
         }
     ]
 }