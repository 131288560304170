export default {
  namespaced: true,
  state: {
    microApps: {}
  },
  mutations: {
    updateMicroApps(state, microApps) {
      state.microApps = microApps;
    }
  }
};
