/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
  return /^[a-zA-Z0-9\u4e00-\u9fa5_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(s);
}
/**
 * 邮政编码
 * @param {*} s
 * @returns
 */
export function isPostCode(s) {
  return /^(0[1-7]|1[0-356]|2[0-7]|3[0-6]|4[0-7]|5[1-7]|6[1-7]|7[0-5]|8[013-6])\d{4}$/.test(s);
}
/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
  //return /^1[0-9]{10}$/.test(s);
  return  /^1\d{10}$|^(0\d{2,3}-?|\(0\d{2,3}\))?[1-9]\d{4,7}(-\d{1,8})?$/.test(s)
}
/**
 * 精确手机号码
 * @param {*} s
 */
 export function isNicetyMobile (s) {
  return /^(1(([38]\d)|(4[57])|(5[0-35-9])|66|(7[0135-8])|(9[89]))\d{8})$/.test(s)
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone(s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s);
}
/**
 * 精确电话号码（可含区号和分机号）
 * @param {*} s
 * @returns
 */
export function isNicetyPhone (s) {
  return /^(((\d{3}-\d{8}|\d{4}-\d{7})(-\d{1,4})?)|(\d{5}))$/.test(s)
}

/**
 * 整数
 * @param {*} s
 * @returns
 */
export function isInteger (s) {
  return /^[-]?\d+$/.test(s)
}
/**
 * 正整数及0
 * @param {*} s
 * @returns
 */
export function positiveInteger (s) {
  return /^\d+$/.test(s)
}
/**
 * 最大两位小数，允许负数
 * @param {*} s
 * @returns
 */
export function twoDecimalPlaces (s) {
  // return /^(?!-0$)(?![-]?00+$)[-]?\d+(\.\d{1,2})?$/.test(s) //排除 多个0、-0的情况
  return /^[-]?\d+(\.\d{1,2})?$/.test(s)
}

/**
 * ip地址
 * @param {*} s
 */
export function isIPAddr(s) {
  return /^((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])(?::(?:[0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$/.test(s);
}

/**
 * URL地址
 * @param {*} s
 */
 export function isURL(s) {
  return /^(ht|f)tps?:\/\/[\w-]+(\.[\w-]+)+(:\d{1,5})?(\/?.*)?$/.test(s) ||
    /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/.test(s);
  // return /^http[s]?:\/\/.*/.test(s);
}

/**
 *  身份证号
 * @param {*} s
 */
export function isNoCode(s) {
  return /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/.test(s);
}

export function isPassPortCard(card) {
  // 护照
  // 规则： 14/15开头 + 7位数字, G + 8位数字, P + 7位数字, S/D + 7或8位数字,等
  // 样本： 141234567, G12345678, P1234567
  var reg = /^([a-zA-z]|[0-9]){5,17}$/;
  return reg.test(card)
}

/**
 *  身份证号。
 * @param {*} s
 */
export const isCardCode = function(rule, value, callback) {
  let name = this.name ? this.name : '身份证号码/护照'
  if(!rule.required && !value){
    callback()
  }else{
    if(!value || value.length == 0){
      callback(new Error(`${name}不能为空`))
    }else if(!isNoCode(value)&&!isPassPortCard(value)){
      callback(new Error(`${name}格式不正确`))
    }else{
      callback()
    }
  }
};

/**
 *  仅允许数字和。
 * @param {*} s
 */
export const cbsNumber= function (rule, value, callback) {
  let y=/^\d+(\.\d+)*$/.test(value);
  if(!y){
    callback(new Error(`仅允许输入数字+点格式例如：1.2`))
  }else{
    callback()
  }

}

/**
* 校验密码
*/
export function validatorPassword(pass) {
	if(!pass) {
		return false
	}
	if(pass.length < 8 || pass.length > 20) {
		return false
	}
	return /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,20}$/.test(pass)
}




////////////
/**
 * 校验是否为 电话号码或手机号码
 */
export const validateTelephone = function(rule, value, callback) {
  let name = this.name ? this.name : '该号码'
  if(!rule.required && !value){
    callback()
  }else{
    if(!value || value.length == 0){
      callback(new Error(`${name}不能为空`))
    }else if(!isNicetyMobile(value) && !isNicetyPhone(value)){
      callback(new Error(`${name}格式不正确`))
    }else{
      callback()
    }
  }
};

/**
 * 校验是否为 电话号码或手机号码
 */
export const validateTelephonet = function(rule, value, callback) {
  let name = this.name ? this.name : '该号码'
  if(!rule.required && !value){
    callback()
  }else{
    if(!value || value.length == 0){
      callback(new Error(`${name}不能为空`))
    }else if(!isPhone(value) && !isMobile(value)){
      callback(new Error(`${name}格式不正确`))
    }else{
      callback()
    }
  }
};
/**
 * 校验是否为 电话号码
 */
export const validateMobile = function(rule, value, callback) {
  let name = this.name ? this.name : '电话号码'
  if(!rule.required && !value){
    callback()
  }else{
    if(!value || value.length == 0){
      callback(new Error(`${name}不能为空`))
    }else if(!isNicetyMobile(value)){
      callback(new Error(`${name}格式不正确`))
    }else{
      callback()
    }
  }
};
/**
 * 校验是否为 手机号码
 */
export const validatePhone = function(rule, value, callback) {
  let name = this.name ? this.name : '手机号码'
  if(!rule.required && !value){
    callback()
  }else{
    if(!value || value.length == 0){
      callback(new Error(`${name}不能为空`))
    }else if(!isNicetyPhone(value)){
      callback(new Error(`${name}格式不正确`))
    }else{
      callback()
    }
  }
};
/**
 * 校验邮箱
 */
export const validateEmail = function(rule, value, callback) {
  let name = this.name ? this.name : '电子邮箱'
  if(!rule.required && !value){
    callback()
  }else{
    if(!value || value.length == 0){
      callback(new Error(`${name}不能为空`))
    }else if(!isEmail(value)){
      callback(new Error(`${name}格式不正确`))
    }else{
      callback()
    }
  }
};
/**
 * 校验邮政编码
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
export const validatePostCode = function(rule, value, callback) {
  let name = this.name ? this.name : '邮编'
  if(!rule.required && !value){
    callback()
  }else{
    if(!value || value.length == 0){
      callback(new Error(`${name}不能为空`))
    }else if(!isPostCode(value)){
      callback(new Error(`${name}格式不正确`))
    }else{
      callback()
    }
  }
};

/**
 * 校验是否为0-999999999的整数
 */
export const validateInt = function(rule, value, callback) {
  let name = this.name ? this.name : '该项'
  if(!rule.required && !value){
    callback()
  }else{
    if(!value || value.length == 0){
      callback(new Error(`${name}不能为空`))
    }else if(!positiveInteger(value) || value > 999999999){
      callback(new Error(`${name.length > 4 ? '该项':''}仅允许0-999999999的整数`))
    }else{
      callback()
    }
  }
};
/**
 * 校验金额，仅保留两位小数，最大999999999999
 */
export const validateMoney = function(rule, value, callback) {
  let name = this.name ? this.name : '该金额'
  if(!rule.required && !value){
    callback()
  }else{
    if(!value || value.length == 0){
      callback(new Error(`${name}不能为空`))
    }else if(!/^[-]?\d+(\.\d+)?$/.test(value)){
      callback(new Error(`${name}仅允许填写数字`))
    }else if(!twoDecimalPlaces(value)){
      callback(new Error(`${name}最多保留两位小数`))
    }else if(value > 999999999999){
      callback(new Error(`${name.length > 4 ? '该项':''}最大不超过999999999999`))
    }else{
      callback()
    }
  }
}

/*
URL校验
 */
export const validateUrl = function(rule, value, callback) {
  let name = this.name ? this.name : '网址'
  if(!rule.required && !value){
    callback()
  }else{
    if(!value || value.length == 0){
      callback(new Error(`${name}不能为空`))
    }else if(!isURL(value)){
      callback(new Error(`${name}格式不正确`))
    }else{
      callback()
    }
  }
};

/**
 * 数字只能保留一位小数，且不能为负数
 * @param rule
 * @param value
 * @param callback
 */
export const validateYear = function (rule, value, callback) {
  let name = this.name ? this.name : '该项'
  if(!value || value.length == 0){
    callback(new Error(`${name}不能为空`))
  }else if(!/^[-]?\d+(\.\d+)?$/.test(value)) {
    callback(new Error(`${name}仅允许填写数字`))
  }else if (!/^[-]?\d+(\.\d{1,1})?$/.test(value)) {
    callback(new Error(`${name}最多保留一位小数`));
  } else if(!/^[+]?\d+(\.\d{1,1})?$/.test(value)) {
    callback(new Error(`${name}不能为负数`));
  }else{
    callback();
  }
};

/**
 * 校验数字，根据绑定参数可以判断 非负数、小数位数。
 * 可绑定参数：
 * 1、校验字段名称name，用于展示提示信息；
 * 2、非负数notMinus，true为大于等于0，false为全部数字
 * 3、小数位数decimal，默认两位；
 * 例如：
 * validator: validateNumber
 * validator: validateNumber.bind({name:'建设投资总预算'})
 * validator: validateNumber.bind({decimal:2})
 * validator: validateNumber.bind({name:'建设投资总预算', notMinus:true, decimal:2})
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
export const validateNumber = function(rule, value, callback){
  if(!rule.required && !value){
    callback()
  }else{
    let name = this.name ? this.name : '该项';//校验提示的名称
    let notMinus = typeof(this.notMinus)=="undefined" ? false : this.notMinus;//true不允许小数，默认不限制
    let decimal = (typeof(this.decimal)=="undefined" || this.decimal < 0) ? 2 : this.decimal;//小时位数，默认2
    // let reg = new RegExp(`^${notMinus ? '' : '[-+]?'}\\d+${decimal === 0 ? '' : ('(\\.\\d{1,'+ decimal +'})?')}$`)
    if(!value || value.length == 0){
      callback(new Error(`${name}不能为空`))
    }else if(!/^[-+]?\d+(\.\d+)?$/.test(value)){
      callback(new Error(`${name}仅允许填写数字`))
    }else{
      if(notMinus && /^-\d+(\.\d+)?$/.test(value)){
        callback(new Error(`${name}不能为负数`))
        return
      }
      if(decimal === 0 && /^[-+]?\d+\.\d+$/.test(value)){
        callback(new Error(`${name}不能包含小数`))
        return
      }
      if(decimal != 0){
        let dReg = new RegExp(`[-+]?\\d+(\\.\\d{${decimal}}\\d+)`)
        if(dReg.test(value)){
          callback(new Error(`${name}小数最多${decimal}位`))
          return
        }
      }
      // if(!reg.test(value)){
      //   callback(new Error(`${name}格式不正确`))
      // }else{
      //   callback()
      // }
      callback()
    }
  }
}
/**
 * 校验ip
 * @param {*} s 待校验值
 * @returns 是否校验通过
 */
export function isIp (s) {
  const regexStr = /^((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])(?::(?:[0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$/
  return regexStr.test(s) || /^localhost$/.test(s)
}
/**
 * 校验ip端口号
 * @param {*} s 待校验值
 * @returns 是否校验通过
 */
export function isPort (s) {
  const regexStr = /^(?:[0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/
  return regexStr.test(s)
}
/**
 * 校验ip地址是否正确
 * @param {*} rule 校验参数
 * @param {*} value 待校验的值
 * @param {*} callback 回调
 */
export const validateIP = function (rule, value, callback) {
  let name = this.name ? this.name : 'ip地址'
  if (!rule.required && !value) {
    callback()
  } else {
    if (!value || value.length == 0) {
      callback(new Error(`${name}不能为空`))
    } else if (!isIp(value)) {
      callback(new Error(`${name}格式不正确`))
    } else {
      callback()
    }
  }
};
/**
 * 校验ip端口号是否正确
 * @param {*} rule 校验参数
 * @param {*} value 待校验的值
 * @param {*} callback 回调
 */
export const validatePort = function (rule, value, callback) {
  let name = this.name ? this.name : '端口号'
  if (!rule.required && !value) {
    callback()
  } else {
    if (!value || value.length == 0) {
      callback(new Error(`${name}不能为空`))
    } else if (!isPort(value)) {
      callback(new Error(`${name}格式不正确`))
    } else {
      callback()
    }
  }
};

/**
 * 数字转汉字大写
 * @param num
 * @returns {string}
 */
export const toChineseNum =function (n) {
  var fraction = ["角", "分"];
  var digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
  var unit = [["元", "万", "亿"], ["", "拾", "佰", "仟"]];
  var head = n < 0 ? "负" : "";
  n = Math.abs(n);
  var s = "";
  for (var i = 0; i < fraction.length; i++) {
    s += (
        digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
    ).replace(/零./, "");
  }
  s = s || "整";
  n = Math.floor(n);
  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = "";
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
  }
  return (
      head +
      s
          .replace(/(零.)*零元/, "元")
          .replace(/(零.)+/g, "零")
          .replace(/^整$/, "零元整")
  )
}

/**
 * 判断是否为空
 * @param {*} val 待校验值
 * @returns 校验结果
 */
export function validatenull (val) {
  if (typeof val == 'boolean') {
    return false;
  }
  if (typeof val == 'number') {
    return false;
  }
  if (val instanceof Array) {
    if (val.length == 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true;
  } else {
    if (val == 'null' || val == null || val == 'undefined' || val == undefined || val == '') return true;
    return false;
  }
  return false;
}

/**
 * 数字转汉字大写
 * @param num
 * @returns {string}
 */
export const convertCurrency =function (money){
  //汉字的数字
  var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
  //基本单位
  var cnIntRadice = new Array('', '拾', '佰', '仟');
  //对应整数部分扩展单位
  var cnIntUnits = new Array('', '万', '亿', '兆');
  //对应小数部分单位
  var cnDecUnits = new Array('角', '分', '毫', '厘');
  //整数金额时后面跟的字符
  var cnInteger = '整';
  //整型完以后的单位
  var cnIntLast = '元';
  //最大处理的数字
  var maxNum = 999999999999999.9999;
  //金额整数部分
  var integerNum;
  //金额小数部分
  var decimalNum;
  //输出的中文金额字符串
  var chineseStr = '';
  //分离金额后用的数组，预定义
  var parts;
  // 传入的参数为空情况
  if(money == '') {
    return '';
  }
  money = parseFloat(money)
  if(money >= maxNum){
    return ''
  }
  // 传入的参数为0情况
  if(money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr
  }
  // 转为字符串
  money = money.toString();
  // indexOf 检测某字符在字符串中首次出现的位置 返回索引值（从0 开始） -1 代表无
  if(money.indexOf('.') == -1){
    integerNum = money;
    decimalNum = ''
  }else{
    parts = money.split('.');
    integerNum = parts[0];
    decimalNum = parts[1].substr(0,4);
  }
  //转换整数部分
  if(parseInt(integerNum,10) > 0){
    let zeroCount  = 0;
    let IntLen = integerNum.length
    for(let i = 0; i < IntLen; i++){
      let n = integerNum.substr(i,1);
      let p = IntLen - i - 1;
      let q = p / 4;
      let m = p % 4;
      if( n == '0'){
        zeroCount ++ ;
      }else{
        if(zeroCount > 0){
          chineseStr += cnNums[0]
        }
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if(m == 0 && zeroCount < 4){
        chineseStr += cnIntUnits[q];
      }
    }
    // 最后+ 元
    chineseStr += cnIntLast;
  }
  // 转换小数部分
  if(decimalNum != ''){
    let decLen = decimalNum.length;
    for(let i = 0; i <decLen; i++){
      let n = decimalNum.substr(i,1);
      if(n != '0'){
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if(chineseStr == ''){
    chineseStr += cnNums[0] + cnIntLast + cnInteger;
  }else if(decimalNum == ''){
    chineseStr += cnInteger;
  }

  return chineseStr
}
/**
 * 校验是否包含特殊字符
 * @param string
 * @returns {boolean}
 */
export const checkSpecificKey = function(str) {
  var specialKey = "[`~!@#$%^&*()_\\-+=<>?:\"{}|,.;'\\\\[\\]·~！@#￥%……&*（）——\\-+={}|《》？：“”【】、；‘'，。、]‘'";
  for (var i = 0; i < str.length; i++) {
    if (specialKey.indexOf(str.substr(i, 1)) != -1) {
      return false;
    }
  }
  return true;
}

/**
 * 校验数组对象中所有属性是否为空
 * @returns {boolean}
 * @param ObjectValue
 */
export const judgeObjectComplete = function (ObjectValue) {
    let flag = true
    for (let key in ObjectValue) {
        if (!ObjectValue[key]) {
            flag = false
        }
    }
    return flag
}
export const judgeListComplete = function (list) {
    let isNotComplete = list.findIndex(item => {
        return judgeObjectComplete(item) === false
    })
    return isNotComplete > -1
}
