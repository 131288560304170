// 对外输出 包含组件的对外json定义、属性配置页面、展示页面 三部分
import BaseIndex from './index.vue'
import BaseProperties from './properties.vue'
import {styleConfig} from "../../../config/common/style.js";
import { getDataSourceConfig } from '../../../config/common/datasource.js'

const staticData = `{
  "count": 7993,
  "allcount": 10222 
}`

const datasourceConfig = getDataSourceConfig({model: false , dataStatic: staticData, dataSourceDefault: '1'})

/*
 * author wangchi
 * date 2020-07-06
 * description 数据组件件配置
 */
const list = [

  {
    type: "avue-data-DataTabs", // 提示
    label: "AvueDataTabs数据展示", // 标题
    component: BaseIndex,
    properties: BaseProperties,

    formOptions: {
      config: {
        size: 'mini',
        labelWidth: 70,
        emptyBtn: false,
        submitBtn: false
      },
      group: [
        {...styleConfig}, // 样式,
        {...datasourceConfig} , // datasource

        {
          label: '外观',
          prop: 'layout',
          collapse: false,
          column: [
          // title: '分类统计',
          //   subtitle: '实时',
          //   count: 7993,
          //   allcount: 10222,
          //   text: '当前分类总记录数',
          //   color: 'rgb(27, 201, 142)',
          //   key: '类'
          // animation  是否动画  Boolean false/true  true
          // decimals  小数点位数 Number  — 0
            {
              label: '标题',
              default: '分类统计',
              prop: 'title',
              span: 24,
            },
            {
              label: '子标题',
              default: '实时',
              prop: 'subtitle',
              span: 24,
            },
            {
              label: '描述',
              default: '当前分类总数',
              prop: 'text',
              span: 24,
            },
             {
              label: '颜色',
              prop: 'color',
              type: 'color',
                 default: 'rgb(27, 201, 142)' ,
              span: 24,
            },
            {
              label: '是否动画',
              prop: 'animation',
              type: 'switch',
              span: 24,

            }
          ]
        },
        {
          label: '事件',
          prop: 'event',
          custom: true,
          collapse: false
        },
      ]
    },
   /* style: {
      backgroundColor: '',
      backgroundImage: '',
      itemBorderRadius: 0,
      margin: ['0', '0', '0', '0'],
      height: 100,
      color: '',
    },*/
    options: {
      tooptip: '', // 提示
      hidden: false, // 是否隐藏，false显示，true隐藏
    },
    key: ""
  }
];


export default list